import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { ISummaryData, ITemplate, ITemplateHandles } from '../interfaces/Template.interface';
import {
  Card,
  CardBody,
  CardHeader,
  Checkbox,
  Collapsible3,
  Hyperlink,
  ITextAreaHandles,
  SVG,
  TextArea,
} from 'digit.commons.ui-components-app';
import { FORMATTER } from '../../utility/formatHandler';
import './Template.scss';
import { SUMMARY_LABELS } from '../../constants/decisionTree/template-labels';
import { WizardMode } from '../../constants/containers/request-theme';
import { TemplateComponents } from './TemplateComponents';
import { ITenantData } from '../../../commons/api/tenantData/TenantData.interface';
import TenantDataApi from '../../../commons/api/tenantData/TenantData.api';
import { TEXT_AREA_ALLOWED_CHARACTERS_REGEX } from '../../../portal-app/constants/regex';

const SummaryTemplate: React.ForwardRefRenderFunction<ITemplateHandles, ITemplate> = (props, ref) => {
  const [tenant, setTenant] = useState<ITenantData>(null);

  const { summaryData, showTenant, hideTextArea, showAddedInformation } = props.step.props;
  const { processData, addInformationToStatusMappingId } = props;

  const summary = useRef({});
  const formRef = useRef<HTMLFormElement>();
  const textAreaRef = useRef<ITextAreaHandles>();

  const summaryDataToUse =
    props.possibleContent[props.step.id] && props.possibleContent[props.step.id].summaryData
      ? props.possibleContent[props.step.id].summaryData
      : summaryData;

  useImperativeHandle(ref, () => ({
    validateTemplate: () => {
      return hideTextArea || textAreaRef.current.isValid();
    },
  }));

  useEffect(() => {
    if (showTenant && !(processData['summary'] && processData['summary']['AntragstellerIn'])) {
      getTenantDetails()
        .then(response => setTenant(response.data.tenant))
        .catch(_ => {
          /* do nothing */
        });
    }

    /* in case of returning from the edit step we use the already fetched tenant data from summary.AntragstellerIn and summary.E-Mail-Adresse to display the tenant accordingly */
    if (processData['summary'] && processData['summary']['AntragstellerIn']) {
      summary.current = {
        ...summary.current,
        AntragstellerIn: { ...processData['summary']['AntragstellerIn'] },
        'E-Mail-Adresse': processData['summary']['E-Mail-Adresse'],
      };
      props.decisionCallback(
        props.step.id,
        [].concat({
          key: props.step.id,
          value: summary.current,
        })
      );
    } else {
      /* otherwise we just push what we currently know and add the tenant is the useEffect down below */
      props.decisionCallback(props.step.id, [].concat({ key: props.step.id, value: summary.current }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      if (showTenant && tenant !== null) {
        summary.current = {
          AntragstellerIn: {
            Name: `${tenant.details.academicTitle} ${tenant.details.firstName} ${tenant.details.lastName}`,
            Adresse: `${FORMATTER.formatAddressWithCity(tenant.details)}`,
            Telefon: `${FORMATTER.phoneNumberFormat(tenant.details.telNumberMobile)}`,
          },
          'E-Mail-Adresse': tenant.details.email,
          ...summary.current,
        };
        props.decisionCallback(props.step.id, [].concat({ key: props.step.id, value: summary.current }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tenant]
  );

  const getTenantDetails = async () => {
    return await TenantDataApi.fetchTenantAndClientFeatures();
  };

  const renderSummarySection = (data: ISummaryData, index: number) => {
    if (data.type === 'checkbox') {
      return renderSummaryCheckbox(data, index);
    }
    let selectedValues;
    if (processData[data.taskId]) {
      selectedValues = props.getTextForRepresentation(data.taskId);
    } else {
      if (data.taskId === 'sync' && props.theme !== 'syncedForm') {
        return;
      }
      selectedValues = processData['summary'][data.title] ? processData['summary'][data.title][0] : 'Unbekannt';
    }
    summary.current = {
      ...summary.current,
      /* make sure each decision is sent as an array - used for PDF generation */
      [data.title]: [].concat(selectedValues),
    };
    return (
      <dl className="Template__dl-card" key={index}>
        <dt>{data.title}</dt>
        <dd>
          <ul className="Template__ul">
            {[].concat(selectedValues).map((selectedValue, index) => (
              <li key={`${data.title}-${index}`}>
                {data.strong ? (
                  <strong>{FORMATTER.highlightRentalObjectType(selectedValue?.name ?? selectedValue)}</strong>
                ) : (
                  FORMATTER.highlightRentalObjectType(selectedValue?.name ?? selectedValue)
                )}
              </li>
            ))}
            {data.description && <li key={`${data.title}-description`}>{data.description}</li>}
          </ul>
          {props.possibleContent[data.taskId] && data.linkTo && (
            <Hyperlink
              id={`wizard-edit-${data.taskId}`}
              to="#"
              iconAfter={SVG.arrow_right}
              onClick={() => props.switchFlows(data.linkTo, 'summary', WizardMode.EDIT_MODE_ENTER)}
            >
              Bearbeiten
            </Hyperlink>
          )}
        </dd>
      </dl>
    );
  };

  const renderAddedInformation = () => {
    if (!processData['addedInformation']) {
      return null;
    }
    /* we only care about the information that was added for the current status mapping */
    const informationToAdd = Object.entries(processData['addedInformation']).filter(
      ([key, _]) => key === addInformationToStatusMappingId
    );
    return (
      <>
        <h3 className="Template__title">Informationen, die nachgereicht werden</h3>
        {informationToAdd.map(([_, value], index) => renderAddedInformationData(value, index))}
      </>
    );
  };

  const renderAddedInformationData = (value, index) => {
    const renderUpload = !!value['uploads'],
      renderText = !!value['text'];
    const uploadNames = value['uploads'] && value['uploads'].map(upload => upload.name);
    if (uploadNames) {
      summary.current = {
        ...summary.current,
        'Nachgereichte Dokumente': [].concat(uploadNames),
      };
    }
    if (renderText) {
      summary.current = {
        ...summary.current,
        'Zusätzliche Informationen': value['text'],
      };
    }
    return (
      <>
        {renderUpload && (
          <dl className="Template__dl" key={`added-uploads-${index}`}>
            <dt>Dokumente</dt>
            <dd>
              <ul className="Template__ul">
                {uploadNames.map((uploadName, index) => (
                  <li key={`added-uploads-${index}`}>{uploadName}</li>
                ))}
              </ul>
            </dd>
          </dl>
        )}
        {renderText && (
          <dl className="Template__dl" key={`added-text-${index}`}>
            <dt>Zusätzliche Informationen</dt>
            <dd>
              <ul className="Template__ul">
                <li key={'added-text'}>{value['text']}</li>
              </ul>
            </dd>
          </dl>
        )}
      </>
    );
  };

  const getBooleanValueFromProcessData = (taskId: string) =>
    processData[taskId] && processData[taskId].length === 1 && processData[taskId][0] === 'true';

  const renderSummaryCheckbox = (data: ISummaryData, index: number) => {
    summary.current = {
      ...summary.current,
      /* make sure each decision is sent as an array - used for PDF generation */
      [data.title]: [].concat(
        getBooleanValueFromProcessData(data.taskId) ? data.positiveText || 'Ja' : data.negativeText || 'Nein'
      ),
    };
    return (
      <dl className="Template__dl" key={index}>
        <dt>{data.title}</dt>
        <dd>
          <Checkbox
            id={`summary-${index}`}
            checked={getBooleanValueFromProcessData(data.taskId)}
            onChange={checkedValue => propertyCheckHandler(data, checkedValue)}
          >
            {data.description}
          </Checkbox>
          {data.explanation &&
            TemplateComponents.renderSections(data.explanation, props.step.id, 'explanation', 'Template__explanation')}
        </dd>
      </dl>
    );
  };

  const propertyCheckHandler = (data: ISummaryData, checkedValue: boolean) => {
    props.decisionCallback(
      props.step.id,
      [].concat(
        {
          key: props.step.id,
          value: {
            ...summary.current,
            [data.title]: [].concat(checkedValue ? data.positiveText || 'Ja' : data.negativeText || 'Nein'),
          },
        },
        { key: data.taskId, value: [].concat(checkedValue.toString()) }
      )
    );
  };

  const text = processData[props.step.id] && processData[props.step.id]['Nachricht'];

  const { title, messageLabel, messageTitle, requester, eMailAddress } = SUMMARY_LABELS;

  return (
    <>
      <form id={'wizard-summary-form-id'} ref={formRef}>
        <h3 className="Template__heading">{props.step.subtitle || title}</h3>
        <Card id={'wizard-summary'}>
          <CardHeader title={'Zusammenfassung'} />
          <CardBody>
            {summaryDataToUse.map((summary: ISummaryData, index: number) => renderSummarySection(summary, index))}
            {((tenant && tenant.details) || (processData['summary'] && processData['summary']['AntragstellerIn'])) && (
              <>
                <dl className="Template__dl-card">
                  <dt>{requester}</dt>
                  <dd>
                    <ul className="Template__ul">
                      <li>
                        {(processData['summary'] &&
                          processData['summary']['AntragstellerIn'] &&
                          processData['summary']['AntragstellerIn']['Name']) ??
                          `${tenant.details.academicTitle} ${tenant.details.firstName} ${tenant.details.lastName}`}
                      </li>
                      <li>
                        {(processData['summary'] &&
                          processData['summary']['AntragstellerIn'] &&
                          processData['summary']['AntragstellerIn']['Adresse']) ??
                          FORMATTER.formatAddressWithCity(tenant.details)}
                      </li>
                      <li>
                        {FORMATTER.phoneNumberFormat(
                          (processData['summary'] &&
                            processData['summary']['AntragstellerIn'] &&
                            processData['summary']['AntragstellerIn']['Telefon']) ??
                            tenant.details.telNumberMobile
                        )}
                      </li>
                    </ul>
                    {/* can be used in the future to change contact data.
              <Hyperlink
                  id={`wizard-edit-contact-data`}
                  to="#"
                  iconAfter={SVG.arrow-right}
                  
                  onClick={() => props.switchFlows('kontakt-ändern', 'summary', WizardMode.EDIT_MODE_ENTER)}
              >
                Kontaktdaten bearbeiten
              </Hyperlink>*/}
                  </dd>
                </dl>
                <dl className="Template__dl-card">
                  <dt>{eMailAddress}</dt>
                  <dd>
                    <ul className="Template__ul">
                      <li>
                        {(processData['summary'] && processData['summary']['E-Mail-Adresse']) ?? tenant.details.email}
                      </li>
                    </ul>
                  </dd>
                </dl>
              </>
            )}
          </CardBody>
        </Card>
        {showAddedInformation && renderAddedInformation()}
        {hideTextArea ? null : (
          <Collapsible3
            id="wizard-summary"
            key="wizard-summary-collapsible"
            title={messageTitle}
            initialyOpen={text && text.length > 0}
          >
            <TextArea
              id="wizard"
              label={messageLabel}
              maxLength={500}
              onChange={e => {
                props.decisionCallback(
                  props.step.id,
                  [].concat({
                    key: props.step.id,
                    value: {
                      ...summary.current,
                      Nachricht: [e.target.value][0].replace('\n', '\\n').replace('\\n', String.fromCharCode(13, 10)),
                    },
                  })
                );
              }}
              value={text}
              allowedCharacterRegex={TEXT_AREA_ALLOWED_CHARACTERS_REGEX}
              ref={textAreaRef}
            />
          </Collapsible3>
        )}
      </form>
    </>
  );
};

export default forwardRef(SummaryTemplate);
