import React from 'react';

class Formatter {
  formatAddressWithCity(addressData: any) {
    let address = `${addressData.streetName}  ${addressData.streetnumber}`;
    if (addressData.stairAndDoor) {
      address = address + `/${addressData.stairAndDoor}`;
    }
    address = address + `, ${addressData.zipcode}  ${addressData.city}`;
    return address;
  }

  /** formats a given phone number, returns an empty string for an undefined, empty or if the phone number after removing the prefixes is shorter than 2 digits */
  phoneNumberFormat(phoneNumber: string): string {
    if (!phoneNumber) {
      return '';
    }
    return phoneNumber;
  }

  highlightRentalObjectType(rentalObjectString: string): string | JSX.Element {
    if (rentalObjectString.indexOf('·') > 0) {
      return (
        <>
          <em>{rentalObjectString.slice(0, rentalObjectString.indexOf('·'))}</em>
          {rentalObjectString.slice(rentalObjectString.indexOf('·') + 1, rentalObjectString.length)}
        </>
      );
    } else {
      return rentalObjectString;
    }
  }
}

export const FORMATTER = new Formatter();
