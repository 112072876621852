import React from 'react';
import { Hyperlink } from 'digit.commons.ui-components-app';
import { TENANT_PORTAL_SERVICE_NUMBER } from './general';
import { DATE_FORMATTER } from '../../commons/utility/DateFormatter';

export interface StatusInformation {
  apiStatus: string;
  visual: string;
  badgeType: string;
  additionalInformation: (...any) => string;
}

export const STATUS = {
  /* status for forms */
  draft: {
    apiStatus: 'DRAFT',
    visual: 'Entwurf',
    badgeType: 'blue',
    additionalInformation(email: string) {
      return '';
    },
  },
  submitted: {
    apiStatus: 'SUBMITTED',
    visual: 'Gesendet',
    badgeType: 'yellow',
    additionalInformation(email: string) {
      return 'Ihr Antrag wurde gesendet. Wir informieren Sie direkt hier im Portal über den aktuellen Status der Bearbeitung.';
    },
  },
  arrived: {
    apiStatus: 'ARRIVED',
    visual: 'Eingelangt',
    badgeType: 'yellow',
    additionalInformation(email: string, isPipeWorkflow: boolean) {
      return 'Ihr Antrag ist eingelangt. Wir informieren Sie direkt hier im Portal über den aktuellen Status der Bearbeitung.';
    },
  },
  inprogress: {
    apiStatus: 'INPROGRESS',
    visual: 'In Bearbeitung',
    badgeType: 'yellow',
    additionalInformation(email: string) {
      return (
        <p>
          Eine Mitarbeiterin oder ein Mitarbeiter von uns bearbeitet nun Ihren Antrag. Sobald ein Ergebnis vorliegt,
          finden Sie diesen hier als Dokument.
        </p>
      );
    },
  },
  incomplete: {
    apiStatus: 'INCOMPLETE',
    visual: 'Unvollständig',
    badgeType: 'red',
    additionalInformation(email: string) {
      return '';
    },
  },
  approved: {
    apiStatus: 'APPROVED',
    visual: 'Genehmigt',
    badgeType: 'grey',
    additionalInformation(email: string) {
      return 'Wir konnten Ihren Antrag genehmigen. Alle weiteren Details dazu finden Sie im Genehmigungsschreiben.';
    },
  },
  declined: {
    apiStatus: 'DECLINED',
    visual: 'Abgelehnt',
    badgeType: 'grey',
    additionalInformation(email: string) {
      return 'Leider konnten wir Ihren Antrag nicht genehmigen. Informationen zu der Begründung finden Sie im Ablehnungsschreiben.';
    },
  },
  completed: {
    apiStatus: 'COMPLETED',
    visual: 'Abgeschlossen',
    badgeType: 'grey',
    additionalInformation(email: string, workflow?: string) {
      return (
        <p>
          {COMPLETED_STATUS_TEXT[workflow] ||
            'Wir haben die Bearbeitung Ihres Antrags abgeschlossen. Das Ergebnis der Bearbeitung (Genehmigt oder Abgelehnt) finden Sie in dem angehängten Dokument.'}
        </p>
      );
    },
  },
  cancelled: {
    apiStatus: 'CANCELLED',
    visual: 'Von Mieter*in storniert',
    badgeType: 'grey',
    additionalInformation(email: string) {
      return 'Dieser Antrag wurde durch den Mieter oder die Mieterin selbst zurückgezogen und ist daher abgeschlossen. Alle Informationen finden Sie in dem angehängten Dokument.';
    },
  },
  wrw_info_pending: {
    apiStatus: 'WRW_INFO_PENDING',
    visual: 'Weitere Prüfung',
    badgeType: 'red',
    additionalInformation(email: string) {
      return (
        <>
          Es sind weitere Überprüfungen notwendig, ohne diese Ihr Antrag nicht genehmigt werden kann. Wir melden uns bei
          Ihnen, sobald die Überprüfungen abgeschlossen sind.{' '}
          <strong>Ihr Antrag ist bis zur notwendigen Prüfung gehemmt.</strong>
        </>
      );
    },
  },
  wrw_info_resolved: {
    apiStatus: 'WRW_INFO_RESOLVED',
    visual: 'In Bearbeitung',
    badgeType: 'yellow',
    additionalInformation(email: string) {
      return 'Eine Mitarbeiterin oder ein Mitarbeiter von uns bearbeitet nun Ihren Antrag. Sobald ein Ergebnis vorliegt, werden wir Sie hier in Ihrem persönlichen Mieterportal darüber informieren.';
    },
  },
  tenant_info_pending: {
    apiStatus: 'TENANT_INFO_PENDING',
    visual: 'Informationen ausständig',
    badgeType: 'red',
    additionalInformation(email: string) {
      return (
        <>
          Es sind Informationen ausständig, ohne diese Ihr Antrag nicht genehmigt werden kann. Bitte übermitteln Sie uns
          diese so rasch wie möglich.{' '}
          <strong>Ihr Antrag ist bis zur Nachreichung dieser Informationen abgelehnt.</strong>
        </>
      );
    },
  },
  tenant_info_resolved: {
    apiStatus: 'TENANT_INFO_RESOLVED',
    visual: 'Informationen nachgereicht',
    badgeType: 'yellow',
    additionalInformation(email: string) {
      return 'Eine Mitarbeiterin oder ein Mitarbeiter von uns bearbeitet nun Ihren Antrag. Sobald ein Ergebnis vorliegt, werden wir Sie hier in Ihrem persönlichen Mieterportal darüber informieren.';
    },
  },
  hb_info_pending: {
    apiStatus: 'HB_INFO_PENDING',
    visual: 'Antwort ausstehend',
    badgeType: 'red',
    additionalInformation(email: string, expiryDate: number) {
      return (
        <>
          Klicken Sie untenstehende Schaltfläche, um Ihre Antwort zur Bestellung abzugeben. Bitte nehmen sie den Auftrag
          bis {DATE_FORMATTER.dateFormat(expiryDate)} {DATE_FORMATTER.formatTime(expiryDate, 'HH:mm')} an; ansonsten
          gilt er als abgelehnt.
        </>
      );
    },
  },
  hb_info_resolved: {
    apiStatus: 'HB_INFO_RESOLVED',
    visual: 'Antwort gesendet',
    badgeType: 'yellow',
    additionalInformation(email: string) {
      return 'Sobald Sie eine Bestätigung erhalten können Sie mit der Bestellung beginnen.';
    },
  },
  hb_finish_pending: {
    apiStatus: 'HB_FINISH_PENDING',
    visual: 'Fertigstellung ausstehend',
    badgeType: 'red',
    additionalInformation() {
      return 'Sie können die Bestellung nach Abschluss hier fertigstellen.';
    },
  },
  hb_finish_resolved: {
    apiStatus: 'HB_FINISH_RESOLVED',
    visual: 'Fertigstellung gesendet',
    badgeType: 'yellow',
    additionalInformation() {
      return 'Ihre Fertigstellung wurde abgeschickt.';
    },
  },
  hb_finalizing: {
    apiStatus: 'HB_FINALIZING',
    visual: 'In Abschlussphase',
    additionalInformation() {
      return 'Es findet gerade die Abrechnung Ihrer Bestellung statt (TODO: Stefan, Text?)';
    },
  },
  rejected_due_to_missing_information: {
    apiStatus: 'REJECTED_DUE_TO_MISSING_INFORMATION',
    visual: 'Abgelehnt',
    badgeType: 'grey',
    additionalInformation(email: string) {
      return 'Leider konnten wir Ihren Antrag auf Grund von fehlenden Unterlagen nicht genehmigen. Informationen zu der Begründung finden Sie im Ablehnungsschreiben.';
    },
  },
  notcompletable: {
    apiStatus: 'NOTCOMPLETABLE',
    visual: 'Antrag nicht abschliessbar',
    badgeType: 'grey',
    additionalInformation(email: string) {
      return ''; // TODO we need the texts here, see comment in KUN-3412
    },
  },
  /* status for documents */
  requested: {
    apiStatus: 'REQUESTED',
    visual: 'Angefordert',
    badgeType: 'yellow',
    additionalInformation(email: string) {
      return '';
    },
  },
  readytodownload: {
    apiStatus: 'READYTODOWNLOAD',
    visual: 'Bereit zum Herunterladen',
    badgeType: 'grey',
    additionalInformation(email: string) {
      return '';
    },
  },
  expired: {
    apiStatus: 'EXPIRED',
    visual: 'Abgelaufen',
    badgeType: 'grey',
    additionalInformation(email: string) {
      return '';
    },
  },
  rejected: {
    apiStatus: 'REJECTED',
    visual: 'Abgelehnt',
    badgeType: 'grey',
    additionalInformation(email: string) {
      return '';
    },
  },
  furtherinquiry: {
    apiStatus: 'FURTHERINQUIRY',
    visual: 'Weitere Prüfung',
    badgeType: 'red',
    additionalInformation(email: string) {
      return 'Unsere Mitarbeiter*innen müssen Ihre Anfrage genauer prüfen und verschiedene Daten zusammenführen. Wir bitten um etwas Geduld.';
    },
  },
  documentnotavailable: {
    apiStatus: 'DOCUMENTNOTAVAILABLE',
    visual: 'Dokument nicht verfügbar',
    badgeType: 'grey',
    additionalInformation(email: string) {
      return (
        <>
          <p>Das von Ihnen angeforderte Dokument kann leider nicht ausgestellt werden.</p>
          <p>
            Das kann verschiedene Gründe haben, z.B.:
            <ul className={'RequestDetails__additionalInfo-list'}>
              <li>Es handelt sich um eine Hausmeisterwohnung.</li>
              <li>Sie haben Ihre Wohnung bereits gekündigt.</li>
              <li>Es steht eine Räumungsklage gegen Sie an.</li>
            </ul>
          </p>
        </>
      );
    },
  },
  datanotavailable: {
    apiStatus: 'DATANOTAVAILABLE',
    visual: 'Daten nicht verfügbar',
    badgeType: 'grey',
    additionalInformation(email: string) {
      return (
        <>
          <p>
            Das von Ihnen angeforderte Dokument kann leider nicht ausgestellt werden, da die benötigten Daten im Moment
            nicht in unseren Systemen gefunden wurden.
          </p>
          <p>
            Sie können zu einem späteren Zeitpunkt versuchen, einen neuen Antrag zu stellen. Sollte das Problem
            weiterhin bestehen, wenden Sie sich bitte an unsere Service-Nummer unter{' '}
            <Hyperlink id="cookie-policy-service-number" to={`tel: ${TENANT_PORTAL_SERVICE_NUMBER}`} customHref={true}>
              {TENANT_PORTAL_SERVICE_NUMBER}
            </Hyperlink>
            .
          </p>
        </>
      );
    },
  },
  failed: {
    apiStatus: 'FAILED',
    visual: 'Ansuchen nicht abschließbar',
    badgeType: 'grey',
    additionalInformation(email: string) {
      return 'Ihr Ansuchen konnte aus technischen Gründen nicht abgeschlossen werden. Bitte versuchen Sie es erneut.';
    },
  },
  hb_declined: {
    apiStatus: 'HB_DECLINED',
    visual: '',
    badgeType: '',
    additionalInformation(email: string) {
      return '';
    },
  },
};

export const STATUS_API_TO_ENUM = (status: string): StatusInformation => {
  let statusEnum = null;
  for (let s of Object.keys(STATUS)) {
    if (s === status.toLowerCase()) {
      statusEnum = { ...STATUS[s] };
      break;
    }
  }
  return statusEnum;
};

const COMPLETED_STATUS_TEXT = {
  requestServiceCard:
    'Wir haben die Bearbeitung Ihres Antrags abgeschlossen. Die neue Service-Karte wird demnächst per Post gesendet.',
  unlockPayAuthorityServiceCard:
    'Wir haben die Bearbeitung Ihres Antrags abgeschlossen. Sie können die Zahlungsfunktion am Kassenautomaten nun mit dieser Service-Karte verwenden.',
  hbOrder: 'Die Bestellung wurde fertiggestellt.',
};
