import { IExtendedError } from './ExtendedError.interface';

class ExtendedError extends Error implements IExtendedError {
  code: number;
  orig?: string;

  constructor(code, message, orig, name?: string, stack?: string) {
    super(message);
    this.code = code;
    this.orig = orig;
    this.name = name;
    this.stack = stack;
  }
}

export default ExtendedError;
