import React from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Data,
  Hyperlink,
  Key,
  SVG,
  Value,
} from 'digit.commons.ui-components-app';
import { FORMATTER } from '../../utility/formatHandler';
import { LAUNDRY_DAY_BASE_ROUTE } from '../../constants/routes';
import { SUMMARY_ANNOUNCEMENT } from '../../constants/general';
import './Announcement.scss';
import { parseISO8601 } from '../../../commons/utility/DateFunctions';
import { IAnnouncementProps, ILaundryAnnouncementData } from '../../../commons/api/announcement/Announcement.interface';

const SummaryReminderAnnouncement: React.FC<IAnnouncementProps> = props => {
  const { id, announcement } = props;
  const { booking } = announcement.data as ILaundryAnnouncementData;
  const { title, reminder, linkBookingText, now, today, tomorrow, until, washCabin } = SUMMARY_ANNOUNCEMENT;

  const generateSummaryAnnouncementBody = () => {
    switch (announcement.type) {
      case 'NOW':
        return (
          <Data id={`${id}-now`}>
            <Key>{now}</Key>
            <Value>
              <ul>
                <li>
                  <em>
                    {until} {FORMATTER.formatTime(parseISO8601(booking.endingAt), 'HH.mm')}
                  </em>
                </li>
                <li>
                  {washCabin} {booking.laundryName}
                </li>
                <li>{booking.laundryAddress}</li>
              </ul>
            </Value>
          </Data>
        );
      case 'TODAY':
        return (
          <Data id={`${id}-today`}>
            <Key>{today}</Key>
            <Value>
              <ul>
                <li>
                  <em>
                    {FORMATTER.formatTimeSlotFromDates(
                      parseISO8601(booking.startingAt),
                      parseISO8601(booking.endingAt),
                      'HH.mm'
                    )}{' '}
                  </em>
                  {`· ${washCabin} ${booking.laundryName}`}
                </li>
                <li> {`${booking.laundryAddress}`}</li>
              </ul>
            </Value>
          </Data>
        );
      case 'TOMORROW':
        return (
          <Data id={`${id}-tomorrow`}>
            <Key>{tomorrow}</Key>
            <Value>
              <span>
                <em>{FORMATTER.formatDateTime(parseISO8601(booking.startingAt), 'dd., D MMMM')}</em>,{' '}
                {FORMATTER.formatTimeSlotFromDates(
                  parseISO8601(booking.startingAt),
                  parseISO8601(booking.endingAt),
                  'HH.mm'
                )}
              </span>
            </Value>
          </Data>
        );
    }
  };

  return (
    <Card id={id} className="Announcement" badge={reminder} badgeType={'green'}>
      <CardHeader title={title} icon={SVG.laundry} />
      <CardBody>
        <>{generateSummaryAnnouncementBody()}</>
      </CardBody>
      <CardFooter>
        <Hyperlink id={id} to={LAUNDRY_DAY_BASE_ROUTE} iconAfter={SVG.arrow_right}>
          {linkBookingText}
        </Hyperlink>
      </CardFooter>
    </Card>
  );
};

export default SummaryReminderAnnouncement;
