export const TENANT_PORTAL_SERVICE_NUMBER = '+43 5 75 75 3700';
export const CUSTOMER_SERVICE_NUMBER = '+43 5 75 75 75';
export const NATUERLICH_SICHER_NUMBER = '+43 5 75 75 800';

export const SUMMARY_ANNOUNCEMENT = {
  title: 'Waschtage',
  badgeLocked: 'Nutzungs-Sperre',
  reminder: 'Erinnerung',
  linkBookingText: 'Details anzeigen',
  tomorrow: 'Morgen ist Waschtag',
  today: 'Heute ist Waschtag!',
  now: 'Sie können jetzt waschen!',
  serviceNotAvailable: 'Service im Moment nicht erreichbar - bitte probieren Sie es später noch mal.',
  until: 'noch bis',
  washCabin: 'Waschkabine',
};

export const BACK_TO_OVERVIEW = 'Zurück zur Übersicht';
export const MINUTE = 60 * 1000;
export const HOUR = 60 * 60 * 1000;

export const ICS_EXPORT_LABELS = {
  FILENAME_PREFIX: 'WienerWohnen_',
  FILENAME_LIST: 'Waschtage.ics',
  FILENAME_SINGLE: 'Waschtag.ics',
};
