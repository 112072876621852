import { Configuration } from '../../../Configuration';
import { stdpAuthenticationRequiredHandler } from '../../../AuthenticationState';
import { AbstractRequestApi } from '../AbstractRequestApi';

class BuildingComplexAnnouncementApi extends AbstractRequestApi {
  constructor() {
    super(
      () => Configuration.apiConfig().buildingComplexApi,
      'BuildingComplexAnnouncementApi',
      () => Configuration.queryConfig(),
      () => stdpAuthenticationRequiredHandler
    );
  }
}

export default new BuildingComplexAnnouncementApi();
