export const AVAILABLE_RESTAURANT_SEARCH_LINK = 'https://wienerwohnen.at/lokale';
export const AVAILABLE_RESTAURANT_INFO_LINK = 'https://www.wienerwohnen.at/interessentin/geschaeftslokale.html';
export const AVAILABLE_FLATS_INFO_LINK = 'https://www.wienerwohnen.at/interessentin/gemeindewohnung.html';
export const AVAILABLE_PARKING_INFO_LINK = 'https://www.wienerwohnen.at/interessentin/garagenplaetze.html';
export const AVAILABLE_BIKE_BOX_INFO_LINK = 'https://www.wienerwohnen.at/interessentin/fahrradboxen.html';
export const OPERATING_COSTS_LINK = 'https://www.wienerwohnen.at/mieterin/wohnkosten/Betriebskosten.html';
export const FLATSWAP_LINK = 'https://www.wienerwohnen.at/mieterin/tauschwechsel/wohnungstausch.html';
export const PRECONDITIONS_FLATSWAP_LINK =
  'https://www.wienerwohnen.at/mieterin/tauschwechsel/grundvoraussetzungen.html';
export const FLAT_TRANSFER_LINK = 'https://www.wienerwohnen.at/mieterin/mietende/wohnungsweitergabe.html';
export const REPORT_INCIDENT_LINK = 'https://www.wienerwohnen.at/mieterin/servicemieterin/kontakt.html';
export const DIRECT_ALLOCATION_LINK = 'https://www.wienerwohnen.at/mieterin/mietende/direktvergabemieter.html';
export const INTERESTED_PERSON_LINK = 'https://www.wienerwohnen.at/interessentin.html';
export const HOUSE_RULES_LINK =
  'https://www.wienerwohnen.at/mieterin/lebenimgemeindebau/gemeinsameregeln/hausordnung.html';
export const UTILITY_BILL_LINK = 'https://www.wienerwohnen.at/mieterin/wohnkosten/betriebskostenabrechnung.html';
export const OPERATING_COST_LAW_LINK =
  'https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10002531';
export const HOUSINGBENEFITS_LINK =
  'https://www.wien.gv.at/amtshelfer/bauen-wohnen/wohnbaufoerderung/unterstuetzung/wohnbeihilfe-antrag.html';
export const DIRECT_DEBIT_LINK =
  'https://mein.wien.gv.at/formularserver-mw/user/formular.aspx?path=(mw%2ffinanzen)&pid=39b35f07203b454b9c4a9fbf6d9289c1&pn=B4de0679f646e45fc8c309ce6b14c1c4b';
export const EMPTY_DIRECT_DEBIT_LINK =
  'https://www.wien.gv.at/formularserver/user/formular.aspx?pid=9e7ea9890a97422ca08f5a1382c3e01e&pn=B63f3d40a13d3462585c61c5db35cc7f9';
export const WOHNBERATUNG_LINK = 'https://www.wohnberatung-wien.at/';
export const WOHNPARTNER_LINK = 'https://www.wohnpartner-wien.at/';
export const DATA_PROTECTION_LINK = 'https://www.wien.gv.at/info/datenschutz/magistrat/index.html';
export const WIENERLINIEN_LINK =
  'https://www.wienerlinien.at/eportal3/ep/channelView.do/pageTypeId/66526/channelId/-46649';
export const MEIN_WIEN_LINK = 'https://mein.wien.gv.at/';
export const MEIN_WIEN_PROFILE_LINK = 'https://mein.wien.gv.at/Mein-Profil/';
export const WIEN_LINK = 'https://www.wien.gv.at/';
export const SERVICECARD_REGISTER_LINK = 'https://servicekarte.wienerwohnen.at/';
export const FORGOT_PASSWORD_LINK = 'https://mein.wien.gv.at/Zugangsdaten-vergessen/';
export const FACEBOOK_POLICY_LINK = 'https://de-de.facebook.com/policy.php';
export const GOOGLE_POLICY_LINK = 'https://policies.google.com/?hl=de';
export const INFORMATION_RENT_ARREARS_SUPPORT = 'https://www.wienerwohnen.at/hilfe';
