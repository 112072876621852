/**
 * If there are any arguments then format them inside parenthesis otherwise return empty string
 * @param data
 */
export function formatArgumentsClause(data: any) {
  let result = '';
  for (let key in data) {
    if (data[key]) {
      result += key + ': ';
      if (typeof data[key] === 'number') {
        result += '' + data[key];
      }
      if (typeof data[key] === 'string' && data[key] !== '') {
        result += '"' + data[key] + '"';
      }
    }
    result += ', ';
  }

  return result.length > 2 ? '(' + result.substr(0, result.length - 2) + ')' : '';
}
