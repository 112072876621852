import React from 'react';
import './RentalAccountTeaserCard.scss';
import { Card, CardHeader, CardFooter, Hyperlink, SVG } from 'digit.commons.ui-components-app';
import { RENTAL_ACCOUNT_LABELS } from '../../constants/containers/rental-account-labels';
import { RENTAL_ACCOUNT } from '../../constants/routes';

interface IProps {
  id: string;
}

export default function RentalAccountTeaserCard(props: IProps) {
  const { title, showRentalAccountButton } = RENTAL_ACCOUNT_LABELS;

  return (
    <Card id={`${props.id}-rental-account-teaser`} className="RentalAccountTeaserCard">
      <CardHeader title={title} icon={SVG.rental_account} />
      <CardFooter>
        <Hyperlink id={'rental-account-teaser'} to={RENTAL_ACCOUNT} iconAfter={SVG.arrow_right}>
          {showRentalAccountButton}{' '}
        </Hyperlink>
      </CardFooter>
    </Card>
  );
}
