import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { ITemplate, ITemplateHandles } from '../interfaces/Template.interface';
import { ITextAreaHandles, NumericInput, TextArea } from 'digit.commons.ui-components-app';
import './Template.scss';
import { TEXT_AREA_ALLOWED_CHARACTERS_REGEX } from '../../../portal-app/constants/regex';

const NumericInputTemplate: React.ForwardRefRenderFunction<ITemplateHandles, ITemplate> = (props, ref) => {
  const stepId = props.step.id;
  const numericInput = props.possibleContent[stepId].numericInput;
  const textArea = props.possibleContent[stepId].textArea;
  const { processData } = props;

  const textAreaRef = useRef<ITextAreaHandles>();

  useEffect(() => {
    if ((processData && !processData[stepId]) || !processData[stepId][0]) {
      props.decisionCallback(
        stepId,
        [].concat({ key: stepId, value: [`${numericInput.summaryPrefix || ''}${numericInput.defaultValue || 0}`] })
      );
    }
  }, []);

  useImperativeHandle(ref, () => ({
    validateTemplate: () => {
      return isTemplateValid();
    },
  }));

  const isTemplateValid = (): boolean =>
    textArea ? textAreaRef.current.isValid() && isNumericInputValid() : isNumericInputValid();

  const isNumericInputValid = (): boolean =>
    processData && !!processData[stepId] && !!processData[stepId][0] && processData[stepId][0].length > 0;

  const getNumericInputValue = () => {
    if (isNumericInputValid()) {
      return parseInt((processData[stepId][0] as string).replace(numericInput.summaryPrefix, ''));
    } else return numericInput.defaultValue || 0;
  };

  const getTextAreaInputValue = () => {
    if (processData[stepId] && processData[stepId].length > 1) {
      return processData[stepId][1];
    } else {
      return '';
    }
  };

  const onNumericInputHandler = (value: number) => {
    props.decisionCallback(stepId, buildDecisionCallbackValue(value));
  };

  const onTextAreaInputHandler = e => {
    props.decisionCallback(stepId, buildDecisionCallbackValue(e.currentTarget.value, true));
  };

  const buildDecisionCallbackValue = (value: string | number, isTextArea?: boolean) => {
    if (isTextArea) {
      return [].concat({ key: stepId, value: [processData[stepId][0]].concat(value) });
    } else {
      let decisionCallbackValue = [];
      if (
        !isNaN(value as number) &&
        !(numericInput.min && numericInput.min > value) &&
        !(numericInput.max && numericInput.max < value)
      ) {
        decisionCallbackValue = [`${numericInput.summaryPrefix || ''}${value}`];
      }
      if (processData[stepId] && processData[stepId].length > 1) {
        decisionCallbackValue = decisionCallbackValue.concat(processData[stepId][1]);
      }
      return [].concat({ key: stepId, value: decisionCallbackValue });
    }
  };

  const onErrorHandler = (value: number): string => {
    if (numericInput.min && numericInput.min > value) {
      return numericInput.belowMinError;
    } else if (numericInput.max && numericInput.max < value) {
      return numericInput.aboveMaxError;
    } else if (isNaN(value)) {
      return 'Geben Sie einen Wert an.';
    }
    return null;
  };

  return (
    props.possibleContent[stepId] && (
      <>
        <NumericInput
          id={'wizard'}
          min={numericInput.min}
          max={numericInput.max}
          defaultValue={getNumericInputValue()}
          onChange={onNumericInputHandler}
          error={onErrorHandler}
        >
          {numericInput.title}
        </NumericInput>
        {textArea && (
          <>
            {/* temporary added heading, but it's the same as the TextArea's label */}
            <h3 className="Template__heading">{textArea.title}</h3>
            <TextArea
              id={'wizard'}
              label={textArea.label}
              description={textArea.description}
              value={getTextAreaInputValue()}
              maxLength={textArea.maxLength}
              required={textArea.required}
              onChange={onTextAreaInputHandler}
              allowedCharacterRegex={TEXT_AREA_ALLOWED_CHARACTERS_REGEX}
              ref={textAreaRef}
            />
          </>
        )}
      </>
    )
  );
};

export default forwardRef(NumericInputTemplate);
