import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef, useState } from 'react';
import { ITemplate, ITemplateHandles } from '../interfaces/Template.interface';
import ApplicationFormApi from '../../../commons/api/applicationForm/ApplicationForm.api';
import { TemplateComponents } from './TemplateComponents';
import { AFWizardUtility } from '../../utility/AFWizardUtility';
import { IProcessData, IStep } from '../interfaces/DecisionTree.interface';
import {
  ITextAreaHandles,
  IUploadHandles,
  IUploadInProgress,
  SVG,
  TextArea,
  Upload,
  UploadInProgressState,
} from 'digit.commons.ui-components-app';
import { TEXT_AREA_ALLOWED_CHARACTERS_REGEX } from '../../../portal-app/constants/regex';

interface IFile {
  id: string;
  name: string;
}

interface IFileState {
  files: IFile[];
  allFiles: string[];
}

const getPreviouslyUploadedFilesFromProcessData = (
  step: IStep,
  processData: IProcessData,
  statusMappingId: string
): IFile[] => {
  if (
    step.isAddInformationStep &&
    AFWizardUtility.getNestedProcessData(processData, `${step.id}.${statusMappingId}.uploads`)
  ) {
    return AFWizardUtility.getNestedProcessData(processData, `${step.id}.${statusMappingId}.uploads`);
  } else if (!step.isAddInformationStep && processData[step.id]) {
    return processData[step.id];
  }
  return [];
};

type UploadedFilesAction = { type: 'add'; file: IFile } | { type: 'remove'; file: IFile };
type UploadFileProgressAction =
  | { type: 'add'; uploadInProgress: IUploadInProgress }
  | { type: 'remove'; uploadInProgress: IUploadInProgress };

const UploadTemplate: React.ForwardRefRenderFunction<ITemplateHandles, ITemplate> = (props, ref) => {
  const ATTACHMENTS = 'allAttachedDocuments';
  const { processData, possibleContent, step, decisionCallback, download, addInformationToStatusMappingId } = props;
  const uploadRef = useRef<IUploadHandles>(null);
  const textAreaRef = useRef<ITextAreaHandles>(null);
  const allAttachedDocuments: Array<string> = processData[ATTACHMENTS] ? processData[ATTACHMENTS] : Array<string>();
  const uploadedFilesFromProcessData = getPreviouslyUploadedFilesFromProcessData(
    step,
    processData,
    addInformationToStatusMappingId
  );

  const [actualFiles, setActualFiles] = useState<IFileState>(null);
  /* calling decisionCallback in the dispatcher itself resulted in a warning and this way decisionCallback is only called once per dispatch */
  useEffect(() => {
    if (actualFiles) {
      if (step.isAddInformationStep) {
        decisionCallback(
          step.id,
          [
            {
              key: `${step.id}.${addInformationToStatusMappingId}.uploads`,
              value: actualFiles.files,
            },
          ],
          step.isAddInformationStep
        );
      } else {
        decisionCallback(
          step.id,
          [
            { key: step.id, value: actualFiles.files },
            { key: ATTACHMENTS, value: actualFiles.allFiles },
          ],
          step.isAddInformationStep
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualFiles]);

  useImperativeHandle(ref, () => ({
    validateTemplate: () => {
      if (step.isAddInformationStep) {
        if (
          !(
            processData &&
            processData[step.id] &&
            processData[step.id][addInformationToStatusMappingId] &&
            (processData[step.id][addInformationToStatusMappingId]['uploads'] ||
              processData[step.id][addInformationToStatusMappingId]['text'])
          )
        ) {
          uploadRef.current.forceError(step.props.errorMessage);
          textAreaRef.current.forceError(step.props.errorMessage);
          return false;
        }
        return textAreaRef.current.isValid();
      } else {
        uploadRef.current.validate();
        if (step.props.textArea && !textAreaRef.current.isValid()) {
          return false;
        }
        return !step.props.required || (processData && !!processData[step.id] && processData[step.id].length !== 0);
      }
    },
  }));

  /**
   * Should be probably considered for a refactoring. Currently we need two reducer as we hold the list of files
   * that are actually in the processData and a list of files being processed with the state values
   */
  const uploadProgressReducer = (files: Array<IUploadInProgress>, action: UploadFileProgressAction) => {
    const copy = new Map<string, IUploadInProgress>();
    files.forEach(file => {
      copy.set(file.name, file);
    });

    switch (action.type) {
      case 'add':
        copy.set(action.uploadInProgress.name, action.uploadInProgress);
        return Array.from(copy.values());
      case 'remove':
        copy.delete(action.uploadInProgress.name);
        return Array.from(copy.values());
    }
  };

  const [uploadInProgressFiles, dispatchProgress] = useReducer(
    uploadProgressReducer,
    uploadedFilesFromProcessData
      ? uploadedFilesFromProcessData.map(file => {
          return { name: file.name, state: UploadInProgressState.SUCCESS };
        })
      : []
  );

  const reducer = (files: Array<IFile>, action: UploadedFilesAction) => {
    const filesCopy = files ? [...files] : [];

    switch (action.type) {
      case 'add':
        filesCopy.push(action.file);
        if (!allAttachedDocuments.find(file => file === action.file.id)) allAttachedDocuments.push(action.file.id);
        setActualFiles({ files: filesCopy, allFiles: allAttachedDocuments });
        dispatchProgress({
          type: 'add',
          uploadInProgress: { name: action.file.name, state: UploadInProgressState.SUCCESS },
        });
        return filesCopy;
      case 'remove':
        const filteredFilesCopy = filesCopy.filter(file => file.id !== action.file.id);
        setActualFiles({
          files: filteredFilesCopy,
          allFiles: allAttachedDocuments.filter(attachment => attachment !== action.file.id),
        });
        dispatchProgress({
          type: 'remove',
          uploadInProgress: { name: action.file.name, state: UploadInProgressState.SUCCESS },
        });
        return filteredFilesCopy;
    }
  };

  const [uploadedFiles, dispatch] = useReducer(reducer, uploadedFilesFromProcessData);

  const fileChangeHandler = (changedFiles: FileList) => {
    Array.from(changedFiles).forEach(async newFile => {
      const entry = Array.from(uploadedFiles || []).find(file => file.name === newFile.name);
      if (!entry) {
        await ApplicationFormApi.upload(newFile)
          .then(response => dispatch({ type: 'add', file: { id: response.id, name: response.name } }))
          .catch(_ => {
            dispatchProgress({
              type: 'add',
              uploadInProgress: { name: newFile.name, state: UploadInProgressState.FAILED },
            });
          });
      }
    });

    uploadedFiles &&
      uploadedFiles.forEach(oldFile => {
        if (!Array.from(changedFiles).find(file => file.name === oldFile.name)) {
          dispatch({ type: 'remove', file: { id: oldFile.id, name: oldFile.name } });
        }
      });
  };

  const createInitialFileList = (): Array<File> => {
    if (!uploadedFiles) {
      return [];
    }
    const files = Array<File>();
    uploadedFiles.forEach(file => files.push(new File([], file.name)));
    return files;
  };

  const onTextAreaInputHandler = e => {
    decisionCallback(
      step.id,
      [
        {
          key: `${step.id}.${addInformationToStatusMappingId}.text`,
          value: e.currentTarget.value,
        },
      ],
      step.isAddInformationStep
    );
  };

  const getUploadIcon = () => {
    let uploadIcon;
    if (possibleContent[step.id] && possibleContent[step.id].overrideUploadProps) {
      uploadIcon = possibleContent[step.id].overrideUploadProps.titleIcon;
    } else if (step.props.titleIcon) {
      uploadIcon = step.props.titleIcon;
    }

    if (uploadIcon?.endsWith('-small')) {
      uploadIcon = uploadIcon.replace('-small', '');
    }

    return uploadIcon ? SVG.byName(uploadIcon) : null;
  };

  return (
    <>
      {step.props.sections &&
        TemplateComponents.renderSections(step.props.sections, step.id, 'upload-information', null, null, download)}
      {step.props.subquestion && <h3 className="Template__heading">{step.props.subquestion}</h3>}
      <Upload
        id={step.id}
        key={step.id}
        title={
          possibleContent[step.id] && possibleContent[step.id].overrideUploadProps
            ? possibleContent[step.id].overrideUploadProps.title
            : step.props.title
        }
        titleIcon={getUploadIcon()}
        description={
          possibleContent[step.id] && possibleContent[step.id].overrideUploadProps
            ? possibleContent[step.id].overrideUploadProps.description
            : step.props.description
        }
        required={step.props.required}
        errorMessage={step.props.errorMessage}
        onFilesChange={files => fileChangeHandler(files)}
        previouslyUploadedFiles={createInitialFileList()}
        uploadInProgressList={uploadInProgressFiles}
        srOnly={step.props.srOnly}
        ref={uploadRef}
      />
      {/* added isAddInformationStep to the condition as the TextArea is not configured to work in a different context */}
      {step.props.textArea && step.isAddInformationStep && (
        <>
          <h3 className="Template__heading">{step.props.textArea.title}</h3>
          {step.props.textArea.paragraph && (
            <span className="Template__paragraph Template__text">{step.props.textArea.paragraph}</span>
          )}
          <TextArea
            id={step.id}
            label={step.props.textArea.label}
            description={step.props.textArea.description}
            value={AFWizardUtility.getNestedProcessData(
              processData,
              `${step.id}.${addInformationToStatusMappingId}.text`,
              ''
            )}
            maxLength={step.props.textArea.maxLength}
            required={step.props.textArea.required}
            onChange={onTextAreaInputHandler}
            allowedCharacterRegex={TEXT_AREA_ALLOWED_CHARACTERS_REGEX}
            ref={textAreaRef}
          />
        </>
      )}
    </>
  );
};

export default forwardRef(UploadTemplate);
