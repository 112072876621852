import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { IProcessData, IStep } from './interfaces/DecisionTree.interface';

import { ITemplateHandles } from './interfaces/Template.interface';
import { IContentData, IDownloadInformation } from './interfaces/PossibleContent.interface';
import { IApplicationForm } from '../../commons/api/applicationForm/ApplicationForm.interface';
import { WizardMode } from '../constants/containers/request-theme';
import MultiFieldsetTemplate from './templates/MultiFieldsetTemplate';
import SingleFieldsetTemplate from './templates/SingleFieldsetTemplate';
import InformationTemplate from './templates/InformationTemplate';
import DoubleSubmissionTemplate from './templates/DoubleSubmissionTemplate';
import SummaryTemplate from './templates/SummaryTemplate';
import ConfirmationTemplate from './templates/ConfirmationTemplate';
import DocumentRequestTemplate from './templates/DocumentRequestTemplate';
import CalendarTemplate from './templates/CalendarTemplate';
import UploadTemplate from './templates/UploadTemplate';
import NumericInputTemplate from './templates/NumericInputTemplate';
import TextInputTemplate from './templates/TextInputTemplate';

interface IStepContainer {
  step: IStep;
  decisionCallback: (stepId: string, selection: any, deleteEmptyDecision?: boolean) => void;
  possibleContent: IContentData;
  processData: IProcessData;
  theme: string;
  getTextForRepresentation: (stepId: string) => string;
  switchFlows: (
    flow: string,
    keepDecisionsUpToTask: string,
    editMode: WizardMode,
    excludeFormId?: string,
    draftProcessData?: IProcessData
  ) => void;
  doubleSubmission: IApplicationForm;
  doubleSubmissionText?: string;
  deleteDraft: (applicationFormId: string) => void;
  download?: IDownloadInformation;
  addInformationToStatusMappingId?: string;
  onDismissHandler?: () => void;
}

const StepContainer: React.ForwardRefRenderFunction<ITemplateHandles, IStepContainer> = (props, ref) => {
  const components = {
    MultiFieldsetTemplate,
    SingleFieldsetTemplate,
    InformationTemplate,
    SummaryTemplate,
    ConfirmationTemplate,
    DocumentRequestTemplate,
    CalendarTemplate,
    UploadTemplate,
    NumericInputTemplate,
    TextInputTemplate,
  };

  const templateRef = useRef<ITemplateHandles>(null);

  useImperativeHandle(ref, () => ({
    validateTemplate: () => templateRef.current.validateTemplate(),
  }));

  if (!components[props.step.template]) throw Error(`Unknown Template <${props.step.template}>`);

  return props.possibleContent[props.step.id] &&
    props.possibleContent[props.step.id].isDoubleSubmission &&
    props.doubleSubmission ? (
    <DoubleSubmissionTemplate
      step={props.step}
      processData={props.processData}
      possibleContent={props.possibleContent}
      decisionCallback={props.decisionCallback}
      theme={props.theme}
      getTextForRepresentation={props.getTextForRepresentation}
      doubleSubmission={props.doubleSubmission}
      doubleSubmissionText={props.doubleSubmissionText}
      switchFlows={props.switchFlows}
      deleteDraft={props.deleteDraft}
      ref={templateRef}
    />
  ) : (
    React.createElement(components[props.step.template], {
      processData: props.processData,
      step: props.step,
      possibleContent: props.possibleContent,
      decisionCallback: props.decisionCallback,
      theme: props.theme,
      getTextForRepresentation: props.getTextForRepresentation,
      switchFlows: props.switchFlows,
      doubleSubmission: props.doubleSubmission,
      doubleSubmissionText: props.doubleSubmissionText,
      download: props.download,
      addInformationToStatusMappingId: props.addInformationToStatusMappingId,
      onDismissHandler: props.onDismissHandler,
      ref: templateRef,
    })
  );
};
export default forwardRef(StepContainer);
