import React from 'react';

import './DownTimeInfoBar.scss';

import { AuthenticationState } from '../../../AuthenticationState';
import { InfoBar } from 'digit.commons.ui-components-app';
import BridgeApi from '../../../commons/api/Bridge';

const DownTimeInfoBar: React.FC = props => {
  const downTimeId = `downtime-${AuthenticationState.downtime.startDate}`;

  const onClose = () => {
    BridgeApi.setLocalStoreAppItem(downTimeId, 'false');
  };

  const isOpen = () => {
    return BridgeApi.getLocalStoreAppItem(downTimeId) !== 'false';
  };

  return (
    <div className={'DownTimeInfoBar'}>
      <div className={'DownTimeInfoBar__content'}>
        {/* infobar id is unique to the downtime event - so startDate must be part of the ID */}
        <InfoBar id={downTimeId} text={AuthenticationState.downtime.reason} onClose={onClose} isOpen={isOpen()} />
      </div>
    </div>
  );
};
export default DownTimeInfoBar;
