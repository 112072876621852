import React from 'react';
import { IAnnouncement, IPipeAnnouncementData } from '../../../commons/api/announcement/Announcement.interface';
import { Card, CardBody, CardFooter, CardHeader, Hyperlink, SVG } from 'digit.commons.ui-components-app';
import './InBoxAnnouncement.scss';
import { PIPE_ANNOUNCEMENT } from '../../constants/component-labels';

export interface PipeAnnouncementProps {
  id: string;
  announcement: IAnnouncement;
  onClose?: () => any;
}

export const PipeAnnouncement: React.FC<PipeAnnouncementProps> = ({ id, announcement, onClose }) => {
  const pipeAnnouncement = (announcement.data as IPipeAnnouncementData).newsData;

  const close = () => {
    announcement.closeFunction();
    onClose();
  };

  const badgeDetailsForType = {
    NEWS: { svg: SVG.alert, title: 'NACHRICHT', modifier: 'yellow' },
    ALERT: { svg: SVG.bell, title: 'AUFGEPASST', modifier: 'red' },
    INFORMATIVE: { svg: SVG.lightbulb, title: 'WISSENSWERTES', modifier: 'green' },
    CLEANING: { svg: SVG.construction_flat, title: 'SANIERUNG', modifier: 'green' },
  };

  return (
    <Card
      id={`${id}-pipe-announcement`}
      badge={badgeDetailsForType[announcement.type].title}
      badgeType={badgeDetailsForType[announcement.type].modifier}
      className="InBoxAnnouncement"
      onDismiss={close}
    >
      <CardHeader title={pipeAnnouncement.title} icon={badgeDetailsForType[announcement.type].svg} />
      <CardBody>{pipeAnnouncement.text}</CardBody>
      {pipeAnnouncement.link && pipeAnnouncement.link.trim().length > 0 ? (
        <CardFooter>
          <Hyperlink
            id={`${id}-pipe-announcement`}
            isExternal={true}
            to={pipeAnnouncement.link}
            iconAfter={SVG.external_link}
            className={'in-box__description-link'}
          >
            {PIPE_ANNOUNCEMENT.linkText}
          </Hyperlink>
        </CardFooter>
      ) : (
        <></>
      )}
    </Card>
  );
};
