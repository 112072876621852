import React from 'react';
import { Hyperlink, SVG } from 'digit.commons.ui-components-app';
import './NavigationHeader.scss';

interface INavigationHeader {
  backHandler: () => void;
  backText: string;
  abortHandler?: () => void;
  abortText?: string;
  title: string;
}

const NavigationHeader: React.FC<INavigationHeader> = ({ backHandler, backText, title, abortHandler, abortText }) => {
  return (
    <div className={'NavigationHeader'}>
      <Hyperlink id={'navigation-header-back'} onClick={backHandler} iconBefore={SVG.arrow_left}>
        {backText}
      </Hyperlink>
      <span className={'NavigationHeader__title'}>{title}</span>
      {abortText && abortHandler && (
        <Hyperlink id={'navigation-header-abort'} onClick={abortHandler}>
          {abortText}
        </Hyperlink>
      )}
    </div>
  );
};

export default NavigationHeader;
