import { Configuration, IIdpConfig } from '../../Configuration';
import { AuthStates } from './authenticationState/AuthenticationStateData.interface';

export interface TokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
}

export interface LocalStorageForApp {
  [key: string]: any;
}

export interface AppData {
  oAuthToken: string;
  oAuthTokenDeclinedAt?: number; //time in millis since last epoch
  refreshToken: string;
  username: string;
  authState: string;
  redirectFrom?: string;
  localStorage?: string;
}

export interface ICheckAuthenticationStateCallback {
  (state: string);
}

interface InitializeAppDataCallback {
  (appData: AppData): void;
}

/**
 * Interface provided on window
 */
interface IBridge {
  AppData: AppData;
  appDataInitializedCallbacks: InitializeAppDataCallback[];
  ha: {
    /*
     * provided by native client so we can provide them with AppData
     */
    setAppData: (appData: string) => void;

    /*
     * provided by native client so we can tell them when a navigation occurs
     */
    selectNavigationItem: (
      item: string,
      displayTopNav: boolean,
      displayBottomNav: boolean,
      title: string,
      useCustomTopBar: boolean
    ) => void;

    /**
     * provided by native client, we call it to get called back with the AppData
     */
    getAppData: () => void;
    /*
     * provided by us and called by native client to update our AppData
     */
    getAppDataCallback: (jsonParameter: string) => void;

    /*
     * provided by us so that the native client can check the authentification state
     * the authentification state is provided back to the native client through the supplied callback function
     */
    checkAuthenticationState: (callback: ICheckAuthenticationStateCallback) => void;

    selectRedirectFrom: (item: string) => void;

    getRedirectFrom: () => string;
  };
}

const encodeBody = body => {
  const formBody = [];
  for (const property in body) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(body[property]);
    formBody.push(encodedKey + '=' + encodedValue);
  }
  return formBody.join('&');
};

/* which params are passed over the bridge - !order matters! */
export interface INavigationItem {
  value: string;
  displayTopNav: boolean;
  displayBottomNav: boolean;
  /* we need that for android as we need to color the top white in case of our own top bar */
  useCustomTopBar: boolean;
  title?: string;
}

export const NavigationItems = {
  NAVIGATION_START: {
    value: 'NAVIGATION_START',
    displayTopNav: false,
    displayBottomNav: true,
    useCustomTopBar: false,
  } as INavigationItem,
  NAVIGATION_CONTACT: {
    value: 'NAVIGATION_CONTACT',
    displayTopNav: true,
    displayBottomNav: false,
    useCustomTopBar: false,
    title: 'Kontakt',
  } as INavigationItem,
  NAVIGATION_ACCOUNT: {
    value: 'NAVIGATION_ACCOUNT',
    displayTopNav: false,
    displayBottomNav: true,
    useCustomTopBar: false,
  } as INavigationItem,
  NAVIGATION_SERVICE_CARD: {
    value: 'NAVIGATION_SERVICE_CARD',
    displayTopNav: false,
    displayBottomNav: true,
    useCustomTopBar: false,
  } as INavigationItem,
  NAVIGATION_LAUNDRY_DAY: {
    value: 'NAVIGATION_LAUNDRY_DAY',
    displayTopNav: false,
    displayBottomNav: true,
    useCustomTopBar: false,
  } as INavigationItem,
  NAVIGATION_COOKIE_POLICY: {
    value: 'NAVIGATION_COOKIE_POLICY',
    displayTopNav: true,
    displayBottomNav: false,
    useCustomTopBar: false,
    title: 'Cookie-Richtlinie',
  } as INavigationItem,
  NAVIGATION_DATA_PROTECTION: {
    value: 'NAVIGATION_DATA_PROTECTION',
    displayTopNav: true,
    displayBottomNav: false,
    useCustomTopBar: false,
    title: 'Datenschutzbestimmungen',
  } as INavigationItem,
  NAVIGATION_PROFILE: {
    value: 'NAVIGATION_PROFILE',
    displayTopNav: true,
    displayBottomNav: false,
    useCustomTopBar: false,
    title: 'Profil',
  } as INavigationItem,
  NAVIGATION_REGISTRATION: {
    value: 'NAVIGATION_REGISTRATION',
    displayTopNav: false,
    displayBottomNav: true,
    useCustomTopBar: false,
    title: 'Registrierung',
  } as INavigationItem,
  NAVIGATION_LAUNDRY_DAY_BOOKING: {
    value: 'NAVIGATION_LAUNDRY_DAY_BOOKING',
    displayTopNav: true,
    displayBottomNav: false,
    useCustomTopBar: false,
    title: 'Waschtag reservieren',
  } as INavigationItem,
  NAVIGATION_LAUNDRY_DAY_DETAIL: {
    value: 'NAVIGATION_LAUNDRY_DAY_DETAIL',
    displayTopNav: true,
    displayBottomNav: false,
    useCustomTopBar: false,
    title: 'Details',
  } as INavigationItem,
  NAVIGATION_LAUNDRY_DAY_CANCELLATION: {
    value: 'NAVIGATION_LAUNDRY_DAY_CANCELLATION',
    displayTopNav: true,
    displayBottomNav: false,
    useCustomTopBar: false,
    title: 'Stornieren',
  } as INavigationItem,
  NAVIGATION_LAUNDRY_DAY_RESERVATION: {
    value: 'NAVIGATION_LAUNDRY_DAY_RESERVATION',
    displayTopNav: true,
    displayBottomNav: false,
    useCustomTopBar: false,
    title: 'Waschtag reservieren',
  } as INavigationItem,
  NAVIGATION_LAUNDRY_DAY_REMOTE_OPENING: {
    value: 'NAVIGATION_LAUNDRY_DAY_REMOTE_OPENING',
    displayTopNav: true,
    displayBottomNav: false,
    useCustomTopBar: false,
    title: 'Tür fernöffnen',
  } as INavigationItem,
  NAVIGATION_WHATSNEW: {
    value: 'NAVIGATION_WHATSNEW',
    displayTopNav: true,
    displayBottomNav: false,
    useCustomTopBar: false,
    title: 'Neuigkeiten',
  } as INavigationItem,
  NAVIGATION_APPLICATION_FORM_LANDING: {
    value: 'NAVIGATION_APPLICATION_FORM_LANDING',
    displayTopNav: false,
    displayBottomNav: true,
    useCustomTopBar: true,
    title: 'Anträge und Dokumente',
  } as INavigationItem,
  NAVIGATION_APPLICATION_FORM_DETAILS: {
    value: 'NAVIGATION_APPLICATION_FORM_DETAILS',
    displayTopNav: false,
    displayBottomNav: true,
    useCustomTopBar: true,
    title: 'Antragdetails',
  } as INavigationItem,
  NAVIGATION_APPLICATION_FORM_PRE_SELECTION: {
    value: 'NAVIGATION_APPLICATION_FORM_PRE_SELECTION',
    displayTopNav: false,
    displayBottomNav: false,
    useCustomTopBar: true,
    title: 'Vorauswahl',
  } as INavigationItem,
  NAVIGATION_APPLICATION_FORM_WIZARD: {
    value: 'NAVIGATION_APPLICATION_FORM_WIZARD',
    displayTopNav: false,
    displayBottomNav: false,
    useCustomTopBar: true,
    title: 'Antrag stellen',
  } as INavigationItem,
  NAVIGATION_MORE_MENU: {
    value: 'NAVIGATION_MORE_MENU',
    displayTopNav: false,
    displayBottomNav: true,
    useCustomTopBar: false,
    title: 'Mehr',
  } as INavigationItem,
};

class Bridge {
  private bridge: IBridge;
  private pendingTokenPromise: Promise<string>;
  private lastTokenRequestedAt: Date;
  private min_time_span_between_token_refreshes_in_millis = 60 * 1000;

  idpConfigResolver: () => IIdpConfig;

  constructor() {
    this.bridge = this.setupBridge();
    this.idpConfigResolver = Configuration.idpConfig;
    this.bridge.ha.getAppData();
  }

  onAppDataInitialized(callback: InitializeAppDataCallback) {
    if (this.bridge.AppData) {
      callback(this.bridge.AppData);
    } else {
      this.bridge.appDataInitializedCallbacks.push(callback);
    }
  }

  change_min_timespan_for_testing(timeSpanInMillis) {
    this.min_time_span_between_token_refreshes_in_millis = timeSpanInMillis;
  }

  clear_for_test() {
    this.pendingTokenPromise = null;
    this.lastTokenRequestedAt = null;
  }

  setLocalStoreAppItem(key: string, value: string) {
    const localStoraegObj = {
      ...this.forceParseLocalStorage(),
    };
    localStoraegObj[key] = value;
    this.bridge.AppData.localStorage = JSON.stringify(localStoraegObj);
    this.setAppDataOnBrigeAndLogToConsole();
  }

  getLocalStoreAppItem(key: string) {
    return this.forceParseLocalStorage()[key];
  }

  removeLocalStoreAppItem(key: string) {
    const localStorageObj = {
      ...this.forceParseLocalStorage(),
    };

    delete localStorageObj[key];
    this.bridge.AppData.localStorage = JSON.stringify(localStorageObj);
    this.setAppDataOnBrigeAndLogToConsole();
  }

  forceParseLocalStorage() {
    try {
      return JSON.parse(this.bridge.AppData.localStorage);
    } catch (e) {
      // console.debug("Parsing error: ", e)
      return {};
    }
  }

  setAppDataOnBrigeAndLogToConsole() {
    console.debug('js-bridge: Sending AppData to NativeApp =>');
    this.logAppData();
    this.bridge.ha.setAppData(JSON.stringify(this.bridge.AppData));
  }

  setUserName(userName: string) {
    console.debug('js-bridge: setting user name ', userName);
    this.bridge.AppData.username = userName;
    this.setAppDataOnBrigeAndLogToConsole();
  }

  setOAuthTokenDeclinedByUser() {
    console.info('js-bridge: OAuth Token has been declined by user');
    this.bridge.AppData.oAuthTokenDeclinedAt = Date.now();
    this.setAppDataOnBrigeAndLogToConsole();
  }

  setAuthState(state: AuthStates) {
    console.debug('js-bridge: setting authState ', state);
    this.bridge.AppData.authState = state;
    this.setAppDataOnBrigeAndLogToConsole();
  }

  setRedirectFrom(redirectFrom) {
    console.debug('js-bridge: setRedirectFrom ', redirectFrom);
    this.bridge.AppData.redirectFrom = redirectFrom;
    this.setAppDataOnBrigeAndLogToConsole();
  }

  getRedirectFrom() {
    return this.bridge.AppData.redirectFrom || '/';
  }

  cleanToken() {
    delete this.bridge.AppData.refreshToken;
    delete this.bridge.AppData.oAuthToken;
    console.debug('js-bridge: token cleaned', this.bridge.AppData);
    this.setAppDataOnBrigeAndLogToConsole();
  }

  selectNavigationItem(item: INavigationItem) {
    try {
      this.bridge.ha &&
        this.bridge.ha.selectNavigationItem &&
        this.bridge.ha.selectNavigationItem(
          item.value,
          item.displayTopNav,
          item.displayBottomNav,
          item.title,
          item.useCustomTopBar
        );
    } catch (error) {
      // Until selectNavigationItem implementation
    }
  }

  async getInitialOAuthToken(requestCode: string): Promise<void> {
    this.lastTokenRequestedAt = new Date();
    const result = await fetch(this.idpConfigResolver().requestTokenUrl, {
      method: 'post',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept-Language': 'en-US,en;q=0.5',
      },
      body: encodeBody({
        code: requestCode,
        grant_type: 'authorization_code',
        client_secret: this.idpConfigResolver().clientSecret,
        client_id: this.idpConfigResolver().clientId,
      }),
    });
    const json = (await result.json()) as TokenResponse;
    console.debug('js-bridge: received OAuth token response: ', json);
    this.bridge.AppData = {
      ...this.bridge.AppData,
      oAuthToken: json.access_token,
      refreshToken: json.refresh_token,
    } as AppData;
    this.bridge.ha && this.setAppDataOnBrigeAndLogToConsole();
  }

  async invalidateOAuthToken(): Promise<void> {
    await fetch(this.idpConfigResolver().invalidateTokenUrl, {
      method: 'get',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
      },
    })
      .then(response => {
        if (response.ok) {
          console.info('js-bridge: invalidated OAuth token ');
          this.bridge.AppData = {
            ...this.bridge.AppData,
            oAuthToken: 'logout',
            refreshToken: undefined,
          } as AppData;
          this.bridge.ha && this.setAppDataOnBrigeAndLogToConsole();
        } else {
          throw new Error('call to ' + this.idpConfigResolver().invalidateTokenUrl + ' returned ' + response.status);
        }
      })
      .catch(err => {
        console.debug('js-bridge: error during OAuth token invalidation', err);
        throw err;
      });
  }

  askToRefreshOAuthToken(): boolean {
    if (!this.isAvailable()) {
      console.info('js-bridge: not refreshing token, bridge is not available');
      return false;
    }
    if (!this.bridge.AppData.refreshToken) {
      console.info('js-bridge: cannot refresh OAuth token, as we have no refresh token');
      return false;
    }
    if (this.pendingTokenPromise) {
      console.info('js-bridge: already refreshing OAuth token');
      return false;
    }
    if (this.tooEarlyToRefreshTheOathToken()) {
      console.info('js-bridge: too early to refresh OAuth token');
      return false;
    }
    console.info('js-bridge: refreshing OAuth token as requested...');
    this.pendingTokenPromise = this.refreshToken();
    /*
     * clear the pending promise once it completes
     * so that later requests get a simple resoloved promise to the AppData.oauthToken
     */
    this.pendingTokenPromise.then(() => {
      this.pendingTokenPromise = null;
    });
    return true;
  }

  isBeingRefreshed(oathToken: string): boolean {
    if (this.pendingTokenPromise && oathToken === this.bridge.AppData.oAuthToken) {
      console.debug('js-bridge: token ', oathToken, ' is being refreshed');
      return true;
    }
    console.debug('js-bridge: OAuth token ', oathToken, ' is not being refreshed');
    return false;
  }

  hasChanged(oathToken: string): boolean {
    if (this.bridge.AppData.oAuthToken !== oathToken) {
      console.debug('js-bridge: oathToken ', oathToken, ' has changed to ', this.bridge.AppData.oAuthToken);
      return true;
    }
    return false;
  }

  /**
   * returns a promise of a OAuth token string, which may or may not be already resolved.
   * null is returned if there is some kind of error
   */
  getOAuthTokenPromise(): Promise<string> {
    if (!this.isAvailable()) {
      /*
       * Bridge is not available so resolve a null token
       */
      console.info('js-bridge: returning null OAuth token as bridge is not available!!');
      return Promise.resolve(null);
    }
    if (this.pendingTokenPromise) {
      console.info('js-bridge: returning promise for pending new OAuth token...');
      return this.pendingTokenPromise;
    }
    console.debug('js-bridge: returning promise for existing OAuth token ', this.bridge.AppData.oAuthToken);
    return Promise.resolve(this.bridge.AppData.oAuthToken);
  }

  private tooEarlyToRefreshTheOathToken(): boolean {
    if (!this.lastTokenRequestedAt) {
      return false;
    }
    return this.lastTokenRequestedAt.getTime() + this.min_time_span_between_token_refreshes_in_millis > Date.now();
  }

  isAvailable() {
    return this.bridge.AppData != null && this.bridge.ha != null;
  }

  isAvailableAndHasNoOAuthToken() {
    return this.isAvailable() && !this.bridge.AppData.oAuthToken;
  }

  getAppData() {
    return this.bridge.AppData;
  }

  getOauthToken() {
    return this.bridge.AppData && this.bridge.AppData.oAuthToken;
  }

  hasOauthTokenBeenDeclined(): boolean {
    if (this.idpConfigResolver().oauthDeclinePeriodInSeconds != null) {
      return (
        this.bridge.AppData &&
        this.bridge.AppData.oAuthTokenDeclinedAt + this.idpConfigResolver().oauthDeclinePeriodInSeconds * 1000 >
          Date.now()
      );
    } else {
      //fallback - oauthDeclinePeriodInSeconds is not specified so the OAuth token, once declined is declined 'forever'
      return this.bridge.AppData && this.bridge.AppData.oAuthTokenDeclinedAt != null;
    }
  }

  /**
   * calls the server to get a fresh token and returns a Promise of the fresh token string
   */
  private refreshToken(): Promise<string> {
    const url = this.idpConfigResolver()
      .refreshTokenUrl.replace(/\{refresh_token\}/, this.bridge.AppData.refreshToken)
      .replace(/\{client_secret\}/, this.idpConfigResolver().clientSecret)
      .replace(/\{client_id\}/, this.idpConfigResolver().clientId);

    this.lastTokenRequestedAt = new Date();
    return fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept-Language': 'en-US,en;q=0.5',
      },
    })
      .then(
        (response: Response) => {
          if (!response.ok) {
            if (response.status === 557) {
              console.info('js-bridge: token is expired');
              this.cleanToken();
            }
            //just return an empty promise on error
            return null;
          }
          return response.json();
        },
        error => {
          console.debug('js-bridge: error refreshing OAuth token ', error, ' returning null token');
        }
      )
      .then((json: any) => {
        if (!json) {
          //just return an empty promise on error
          return null;
        }
        const refres = json as TokenResponse;
        this.bridge.AppData = {
          ...this.bridge.AppData,
          oAuthToken: refres.access_token,
          refreshToken: refres.refresh_token,
        } as AppData;
        console.debug('js-bridge: New AppData from refresh token response ', this.bridge.AppData);
        this.setAppDataOnBrigeAndLogToConsole();
        return refres.access_token;
      });
  }

  setupBridge(): IBridge {
    if (!window['appDataInitializedCallbacks']) {
      window['appDataInitializedCallbacks'] = [];
    }

    if (!window['ha']) {
      console.info('js-bridge: mocking native side...');
      /*
       * there is no js-bridge so we fake the native app functions side...
       */
      window['ha'] = {
        setAppData: (appData: string) => {
          console.debug('js-bridge: setting app data on localStorage: ', appData);
          if (typeof Storage !== 'undefined') {
            window.localStorage.setItem('mieterportal-app.appData', appData);
          }
        },
        selectNavigationItem: (value: string, displayTopNav: boolean, title: string) =>
          console.debug('js-bridge: selecting navigation item: ', value, ' ', displayTopNav, ' ', title),

        getAppData: () => {
          console.info('js-bridge: getAppData()');
          /*
           * try to load appdata from browsers local storage
           */
          let theAppData: AppData = null;
          if (typeof Storage !== 'undefined' && window.localStorage.getItem('mieterportal-app.appData')) {
            const appDataString = window.localStorage.getItem('mieterportal-app.appData');
            console.debug('js-bridge: restoring app data from localStorage: ', appDataString);
            theAppData = JSON.parse(appDataString) as AppData;
          } else {
            theAppData = {
              //not sure what to set, .. all undefined for now...
              oAuthToken: undefined,
              oAuthTokenDeclinedAt: undefined,
              refreshToken: undefined,
              username: undefined,
              authState: undefined,
              localStorage: undefined,
              redirectFrom: undefined,
            } as AppData;
          }
          window['ha'].getAppDataCallback(theAppData);
        },
      };
    } else {
      //Keep for debugging purposes
      //console.debug(' *** Found BRIDGE HA Interface : setupBridge ');
    }

    /*
     * we implement the below bridge functions
     */
    window['ha'].getAppDataCallback = jsonParameter => {
      console.debug('js-bridge: getAppDataCallback received ', jsonParameter);
      this.bridge.AppData = { ...this.bridge.AppData, ...jsonParameter };
      if (this.bridge.appDataInitializedCallbacks) {
        this.bridge.appDataInitializedCallbacks.forEach(cb => cb(this.bridge.AppData));
        //we have notified them so now we remove the callbacks, they are not interested in later updates of AppData
        this.bridge.appDataInitializedCallbacks = null;
      }
      console.debug('js-bridge: AppData now ', this.bridge.AppData);
    };
    /*
     * bridge function checkAuthenticationState is implemented in AuthenticationStateApi to break a circular dependency problem
     * so we do nothing else here
     */
    this.logAppData();
    return (window as any) as IBridge;
  }

  logAppData() {
    const bridge = (window as any) as IBridge;
    if (bridge.AppData) {
      for (const name in bridge.AppData) {
        console.debug(`js-bridge: AppData.${name}: ${bridge.AppData[name]}`);
      }
    } else {
      console.debug(`js-bridge: AppData is ${bridge.AppData}`);
    }
  }
}

export default new Bridge();
