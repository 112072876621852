import {
  queryBuildingComplexDetails,
  queryBuildingComplexDetailsForDetailPage,
  queryBuildingComplexName,
  queryContactInformation,
  queryIsPahoTenant,
} from './BuildingComplex.graphql';
import { IExtendedError } from '../ExtendedError.interface';
import { Configuration } from '../../../Configuration';
import {
  IBuildingComplexName,
  IComplexDetails,
  IContactInformation,
  IIsPahoTenant,
  ITenantBasedBuildingComplexDetailsAndContacts,
} from './BuildingComplex.interface';
import { stdpAuthenticationRequiredHandler } from '../../../AuthenticationState';
import { IGraphQL } from '../GraphQL.interface';
import { ITenancyContract } from '../tenantData/TenantData.interface';
import { piwikEvents } from '../../utility/piwikEvents';
import { AbstractRequestApi } from '../AbstractRequestApi';

class BuildingComplexApi extends AbstractRequestApi {
  constructor() {
    super(
      () => Configuration.apiConfig().buildingComplexApi,
      'BuildingComplexApi',
      () => Configuration.queryConfig(),
      () => stdpAuthenticationRequiredHandler
    );
  }

  fetchBuildingComplexDetails(sapId: string, detailPage: boolean): Promise<IGraphQL<IComplexDetails>> {
    const query = {
      query: detailPage ? queryBuildingComplexDetailsForDetailPage(sapId) : queryBuildingComplexDetails(sapId),
    };
    return this.makeCacheableGraphQlRequest(
      Configuration.apiConfig().paths.pathToGraphQL,
      query.query,
      'fetchBuildingComplexDetails'
    )
      .then(response => {
        return {
          data: response.data.getBuildingComplexDetails,
          extensions: response.extensions,
        } as IGraphQL<IComplexDetails>;
      })
      .catch((error: IExtendedError) => {
        return this.handleResponse(error, 'BuildingComplexDetails');
      });
  }

  fetchBuildingComplexName(sapId: string): Promise<IGraphQL<IBuildingComplexName>> {
    const query = {
      query: queryBuildingComplexName(sapId),
    };
    return this.makeCacheableGraphQlRequest(
      Configuration.apiConfig().paths.pathToGraphQL,
      query.query,
      'fetchBuildingComplexName'
    )
      .then(response => {
        return {
          data: {
            name: response.data.getBuildingComplexName.name,
          },
          extensions: response.extensions,
        } as IGraphQL<IBuildingComplexName>;
      })
      .catch((error: IExtendedError) => {
        return this.handleResponse(error, 'BuildingComplexName');
      });
  }

  fetchContactInformation(sapId: string): Promise<IGraphQL<IContactInformation[]>> {
    const query = {
      query: queryContactInformation(sapId),
    };
    return this.makeCacheableGraphQlRequest(
      Configuration.apiConfig().paths.pathToGraphQL,
      query.query,
      'fetchContactInformation'
    )
      .then(response => {
        return { data: [...response.data.getContactInformation], extensions: response.extensions } as IGraphQL<
          IContactInformation[]
        >;
      })
      .catch((error: IExtendedError) => {
        return this.handleResponse(error, 'BuildingComplexContactInformation');
      });
  }

  async fetchBuildingComplexAndContactsForTenancyContract(
    tenancyContracts: ITenancyContract[]
  ): Promise<ITenantBasedBuildingComplexDetailsAndContacts[]> {
    let result: ITenantBasedBuildingComplexDetailsAndContacts[];
    const errors: IExtendedError[] = [];

    const buildingComplexDetails: IComplexDetails[] = await Promise.all(
      tenancyContracts.map(tenancyContract => {
        return this.fetchBuildingComplexDetails(tenancyContract.tplnr, false).catch(e => {
          errors.push(e);
          return e;
        });
      })
    ).then(result => result.map(item => item.data));
    const contactInformation: IContactInformation[][] = await Promise.all(
      tenancyContracts.map(tenancyContract => {
        return this.fetchContactInformation(tenancyContract.tplnr).catch(e => {
          errors.push(e);
          return e;
        });
      })
    ).then(result => result.map(item => item.data));

    if (!errors.isNullOrEmpty()) {
      piwikEvents.trackEvent(
        'Technischer Fehler',
        'Hof-Informationen und Kontakte',
        'Fehler beim Laden der Hof-Informationen und Kontakte basierend auf Vertragsdaten'
      );

      result = [];
    } else {
      result = tenancyContracts.map((tenancyContract, index) => {
        return {
          tenancyContract: tenancyContract,
          buildingComplexDetails: buildingComplexDetails[index],
          contactInformation: contactInformation[index],
        } as ITenantBasedBuildingComplexDetailsAndContacts;
      });
    }

    return new Promise<ITenantBasedBuildingComplexDetailsAndContacts[]>(resolve => resolve(result));
  }

  fetchIsPahoTenant(): Promise<IGraphQL<IIsPahoTenant>> {
    const query = {
      query: queryIsPahoTenant(),
    };
    return this.makeCacheableGraphQlRequest(
      Configuration.apiConfig().paths.pathToGraphQL,
      query.query,
      'queryIsPahoTenant'
    )
      .then(response => {
        return {
          data: {
            isPAHOTenant: response.data.isPAHOTenant,
          },
          extensions: response.extensions,
        } as IGraphQL<IIsPahoTenant>;
      })
      .catch((error: IExtendedError) => {
        return this.handleResponse(error, 'IsPahoTenant');
      });
  }
}

export default new BuildingComplexApi();
