import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { ITemplate, ITemplateHandles } from '../interfaces/Template.interface';
import { Checkbox, Download, SVG } from 'digit.commons.ui-components-app';
import { INFORMATION_LABELS } from '../../constants/decisionTree/template-labels';
import './Template.scss';
import { TemplateComponents } from './TemplateComponents';
import { IAdditionalInformation } from '../interfaces/PossibleContent.interface';
import { DOWNLOAD_HELPER } from '../../../portal-app/utility/downloadHelper';

const InformationTemplate: React.ForwardRefRenderFunction<ITemplateHandles, ITemplate> = (props, ref) => {
  const { possibleContent } = props;
  const stepId = props.step.id;

  const [accepted, setAccepted] = useState<boolean>(props.processData[stepId] ? props.processData[stepId] : false);
  const formRef = useRef<HTMLFormElement>();
  const pdfRef = useRef<HTMLDivElement>();

  useImperativeHandle(ref, () => ({
    validateTemplate: () => validateTemplate(),
  }));

  const generateAdditionalContent = (additionalContent: IAdditionalInformation) => {
    return (
      <>
        <h4 className={'Template__subtitle'}>{additionalContent.subtitle}</h4>
        <p className={'Template__paragraph'}>{additionalContent.paragraph}</p>
        <Download
          id={`${stepId}-information`}
          icon={SVG.pdf}
          // url={additionalContent.download.downloadLink}
          // target={'_self'}
          onClick={() =>
            DOWNLOAD_HELPER.downloadResource(
              `${window.location.href.split('/dokumente')[0]}${additionalContent.download.downloadLink}`,
              additionalContent.download.downloadLink.split('/')[1]
            )
          }
        >
          {additionalContent.download.description}
        </Download>
      </>
    );
  };

  const validateTemplate = () => {
    return formRef.current.checkValidity();
  };

  const onCheckHandler = checkedValue => {
    setAccepted(checkedValue);
    props.decisionCallback(stepId, [].concat({ key: stepId, value: [].concat(checkedValue) }));
  };

  const { checkBoxText } = INFORMATION_LABELS;

  return (
    <>
      <form id={'wizard-information-form-id'} ref={formRef}>
        <div ref={pdfRef} className={'Template__sections'}>
          {TemplateComponents.renderSections(
            possibleContent[stepId] && possibleContent[stepId].sections,
            stepId,
            'wizard-information',
            null,
            props.switchFlows
          )}
        </div>
        {possibleContent[stepId] &&
          possibleContent[stepId].additionalInformation &&
          generateAdditionalContent(possibleContent[stepId].additionalInformation[0])}
        {possibleContent[stepId] && !possibleContent[stepId].isCloseStep && !possibleContent[stepId].isNegativeFlow && (
          <Checkbox id="wizard-information" onChange={e => onCheckHandler(e)} required={true} checked={accepted}>
            {checkBoxText}
          </Checkbox>
        )}
      </form>
      {possibleContent[stepId] &&
        possibleContent[stepId].collapsibles &&
        TemplateComponents.renderCollapsible(possibleContent[stepId].collapsibles, stepId, props.switchFlows)}
    </>
  );
};

export default forwardRef(InformationTemplate);
