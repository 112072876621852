import React, { useEffect } from 'react';
import BridgeApi from '../../../commons/api/Bridge';
import { Configuration } from '../../../Configuration';
import { useHistory } from 'react-router-dom';

const Logout: React.FC<{}> = () => {
  const history = useHistory();

  useEffect(() => {
    BridgeApi.invalidateOAuthToken().then(
      () => {
        document.location.href = Configuration.idpConfig().logoutUrl;
      },
      () => {
        history.goBack(); /* TODO */
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
};

export default Logout;
