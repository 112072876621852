import React from 'react';
import { CUSTOMER_SERVICE_NUMBER } from '../component-labels';
import { Hyperlink } from 'digit.commons.ui-components-app';

export const INVALIDCONTRACT_LABELS = {
  pageTitle: 'Vertrag abgelaufen - Wiener Wohnen Mieter*innenportal',
  title: 'Vertrag abgelaufen',
  meta: 'Vertrag abgelaufen',
  errorPanel: {
    title: 'Ihr Mietvertrag ist abgelaufen',
    errorText: (
      <div>
        <p>
          Sie sind nicht mehr als Mieter*in bei Wiener Wohnen gemeldet und können daher nicht auf das Mieter*innenportal
          zugreifen.
        </p>
        <p>
          Bei dringenden Fragen wenden Sie sich bitte an unsere Service-Nummer unter{' '}
          <Hyperlink id={'contact-service-number'} to={`tel: ${CUSTOMER_SERVICE_NUMBER}`} customHref={true} isInline>
            {CUSTOMER_SERVICE_NUMBER}
          </Hyperlink>
          .
        </p>
      </div>
    ),
  },
};
