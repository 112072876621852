export const queryBuildingComplexDetails = (sapId: string) => `
query {operationName} {  
    getBuildingComplexDetails(sapId: "${sapId}", isMobile: true) {
        name
        numberOfCarParkingSpot
        numberOfMotorcycleParkingSpot
        numberOfStores
        addresses {
            type
            city
            street
            zipcode
            streetnumber
        }
        yearOfConstructions {
            yearFrom
            yearTo
            yearAround
        }
        laundries {
            location
        }
        documents {
            category
            url
        }
        imageUrl
    }
}`;

export const queryBuildingComplexDetailsForDetailPage = (sapId: string) => `
query {operationName} {  
    getBuildingComplexDetails(sapId: "${sapId}", isMobile: true) {
        name
        numberOfFlats
        numberOfStairs
        numberOfCarParkingSpot
        laundries {location}
        numberOfStores
        numberOfMotorcycleParkingSpot
        bicycleBox
        rollatorBox
        hobbyRoom
        communityRoom
        childrenPlayRoom
        workshop
        documents {
            category
            url
        }
        imageUrl
    }
}`;

export const queryBuildingComplexDescription = (sapId: string) => `
query {operationName} {  
    getBuildingComplexDescription(sapId: "${sapId}") {
        eponymDescriptionText
        retrospectionDescriptionText
        historyDescriptionText
        architectureDescriptionText
        artOnConstructionDescriptionText
        personalitiesDescriptionText
        loreDescriptionText
        architects{
            firstname
            lastname
            biographyText
        }
        sources {
            firstname
            lastname
            titleText
            descriptionText
            publicationPlace
            publicationDate
        }
    }
}`;

export const queryBuildingComplexName = (sapId: string) => `
query {operationName} {  
    getBuildingComplexName(sapId: "${sapId}") {
        name
    }
}`;

export const queryContactInformation = (sapId: string) => `
query {operationName} {  
    getContactInformation(sapId: "${sapId}") {
        competenceType
        authority
        firstname
        lastname
        contact {
          type
          prefix
          address
        }
    }
}`;

export const queryIsPahoTenant = () => `
query {operationName} {  
    isPAHOTenant
}`;
