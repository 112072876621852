import React from 'react';
import ReactDOM from 'react-dom';
import './commons/styles/main.scss';
import App from './App';
import { Configuration } from './Configuration';
import { BrowserRouter } from 'react-router-dom';
import { AuthenticationState, stdpAuthenticationRequiredHandler } from './AuthenticationState';
import 'digit.commons.ui-components-app/dist/index.css';
import smoothscroll from 'smoothscroll-polyfill';
import BridgeApi from './commons/api/Bridge';
import LaundryDaySCSInitializer from './laundryday/LaundryDaySCSInitializer';
import ApplicationFormSCSInitializer from './applicationform/ApplicationFormSCSInitializer';
/** the scrollbehaviour is not supported by safari ios.
 * The library smoothscroll should be only used when user uses an ios device.
 * Chrome supports the scrollbehaviour natively. */
if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
  smoothscroll.polyfill();
}

Configuration.init()
  .then(() =>
    Promise.all([
      LaundryDaySCSInitializer.init(Configuration.laundryDayConfig(), stdpAuthenticationRequiredHandler),
      ApplicationFormSCSInitializer.init(Configuration.applicationFormConfig(), stdpAuthenticationRequiredHandler),
    ])
  )
  .then(() => {
    BridgeApi.onAppDataInitialized(() =>
      AuthenticationState.init().then(() => {
        ReactDOM.render(
          <BrowserRouter>
            <App />
          </BrowserRouter>,
          document.getElementById('root')
        );
      })
    );
  });
