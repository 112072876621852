import React from 'react';
import { Button, Icon, Overlay, Section, SVG } from 'digit.commons.ui-components-app';
import { RequestWorkflow, WizardMode } from '../../constants/containers/request-theme';
import { WIZARD_OVERLAYS } from '../../constants/containers/wizard-labels';
import './WizardOverlay.scss';
import '../../decisionTree/templates/Template.scss';
import { APPLICATION_FORM_OVERLAY_LABELS } from '../../constants/containers/request-and-documents-labels';

interface IWizardOverlayProps {
  requestCloseTitle: string;
  closeText: string;
  cancel: boolean;
  workflow?: string;
  canSave: boolean;
  isCloseStep: boolean;
  saveDraftText: string;
  saveDraftDone: boolean;
  setSaveDraftDone: (boolean) => void;
  onSaveDraftButton: () => void;
  showDeleteDraftDialog: boolean;
  setShowDeleteDraftDialog: (boolean) => void;
  onDeleteDraftButton: () => void;
  onDismissHandler: () => void;
  resumeHandler: () => void;
  mode?: WizardMode;
}

interface OverlayProps {
  id: string;
  body: JSX.Element;
  show: boolean;
}

export const WizardOverlay: React.FC<IWizardOverlayProps> = props => {
  const { closeRequest, saveOrCloseRequest, savedRequest, closeDocumentRequest, closeAddInformation } = WIZARD_OVERLAYS;
  const { title, body, secondaryButtonText, primaryButtonText } = APPLICATION_FORM_OVERLAY_LABELS;
  const {
    requestCloseTitle,
    onDismissHandler,
    closeText,
    onSaveDraftButton,
    saveDraftText,
    cancel,
    canSave,
    isCloseStep,
    setSaveDraftDone,
    workflow,
    saveDraftDone,
    resumeHandler,
    mode,
    showDeleteDraftDialog,
    setShowDeleteDraftDialog,
    onDeleteDraftButton,
  } = props;

  let overlay: OverlayProps = {
    show: cancel,
    id: 'cancel-document-request',
    body: (
      <Section
        id={'cancel-document-request'}
        title={closeDocumentRequest.title}
        sectionClassName={'WizardOverlay__section'}
      >
        <p className={'WizardOverlay__paragraph'}>{closeDocumentRequest.textBody}</p>
        <Button id={'cancel-document-request-close'} onClick={onDismissHandler} className={'WizardOverlay__button'}>
          {closeText}
        </Button>
        <Button id={'cancel-document-request-secondary'} onClick={resumeHandler} modifier={'secondary'}>
          {closeDocumentRequest.secondaryButton}
        </Button>
      </Section>
    ),
  };

  if (workflow === RequestWorkflow.CONSTRUCTION) {
    if (cancel && !canSave) {
      overlay = {
        show: cancel,
        id: 'cancel-request',
        body: (
          <Section id={'cancel-request'} title={closeRequest.title} sectionClassName={'WizardOverlay__section'}>
            <p className={'WizardOverlay__paragraph'}>{closeRequest.textBody}</p>
            <Button id={'cancel-request-close'} onClick={onDismissHandler} className={'WizardOverlay__button'}>
              {requestCloseTitle}
            </Button>
            <Button id={'cancel-request-secondary'} onClick={resumeHandler} modifier={'secondary'}>
              {closeRequest.secondaryButton}
            </Button>
          </Section>
        ),
      };
    }
    if (cancel && canSave && !isCloseStep) {
      overlay = {
        show: cancel,
        id: 'save-and-cancel-request',
        body: (
          <Section
            id={'save-and-cancel-request'}
            title={saveOrCloseRequest.title}
            sectionClassName={'WizardOverlay__section'}
          >
            <p className={'WizardOverlay__paragraph'}>{saveOrCloseRequest.textBody}</p>
            <Button id={'save-and-cancel-request-save'} onClick={onSaveDraftButton} className={'WizardOverlay__button'}>
              {saveDraftText}
            </Button>
            <Button id={'save-and-cancel-request-secondary'} onClick={onDismissHandler} modifier={'secondary'}>
              {requestCloseTitle}
            </Button>
          </Section>
        ),
      };
    }
  }

  if (saveDraftDone) {
    overlay = {
      show: saveDraftDone,
      id: 'save-request',
      body: (
        <Section id={'save-request'} title={savedRequest.title} sectionClassName={'WizardOverlay__section'}>
          <div className={'Template__icon-group'}>
            <Icon icon={SVG.request_saved} />
          </div>
          <p className={'WizardOverlay__paragraph'}>{savedRequest.textBody}</p>
          <Button
            id={'save-request-close'}
            onClick={() => {
              setSaveDraftDone(false);
              onDismissHandler();
            }}
            className={'WizardOverlay__button'}
          >
            {requestCloseTitle}
          </Button>
          <Button id={'save-request-secondary'} onClick={resumeHandler} modifier={'secondary'}>
            {savedRequest.secondaryButton}
          </Button>
        </Section>
      ),
    };
  }

  if (mode && mode === WizardMode.ADD_INFORMATION) {
    overlay = {
      show: cancel,
      id: 'cancel-add-information',
      body: (
        <Section
          id={'cancel-add-information'}
          title={closeAddInformation.title}
          sectionClassName={'WizardOverlay__section'}
        >
          <p className={'WizardOverlay__paragraph'}>{closeAddInformation.textBody}</p>
          <Button id={'cancel-add-information-close'} onClick={onDismissHandler} className={'WizardOverlay__button'}>
            {requestCloseTitle}
          </Button>
          <Button id={'cancel-add-information-resume'} onClick={resumeHandler} modifier={'secondary'}>
            {closeAddInformation.secondaryButton}
          </Button>
        </Section>
      ),
    };
  }

  if (showDeleteDraftDialog) {
    overlay = {
      show: showDeleteDraftDialog,
      id: 'delete-draft',
      body: (
        <Section id={'delete-draft'} title={title} sectionClassName={'WizardOverlay__section'}>
          <p className={'WizardOverlay__paragraph'}>{body}</p>
          <Button id={'delete-draft'} onClick={onDeleteDraftButton} className={'WizardOverlay__button'}>
            {primaryButtonText}
          </Button>
          <Button id={'delete-draft-cancel'} onClick={resumeHandler} modifier={'secondary'}>
            {secondaryButtonText}
          </Button>
        </Section>
      ),
    };
  }

  return (
    <Overlay show={overlay.show} id={overlay.id} className="WizardOverlay">
      {overlay.body}
    </Overlay>
  );
};
