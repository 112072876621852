import React from 'react';
import { DATE_FORMATTER } from '../../../commons/utility/DateFormatter';

export const DOUBLE_SUBMISSION_LABELS = {
  getExpirationText(date: number) {
    return (
      <>
        Das Dokument wurde erst vor kurzem von Ihnen angefordert. Sie können dieses Dokument{' '}
        <em>ab {DATE_FORMATTER.dateFormat(date)} neu anfordern</em>, bis dahin ist folgendes Dokument gültig.
      </>
    );
  },
  mainTenantConfirmationDownloadLabel: 'Hauptmieterbestätigung',
  monthlyRentDetailsParagraph:
    'Sie haben Ihren Zinszettel zu diesem Datum bereits einmal angefordert. Das Dokument steht Ihnen jederzeit zum Herunterladen bereit.',
  getMonthlyRentDetailsDownloadLabel: (month: string, year: string) => `Zinszettel ${month} ${year}`,
  alreadyRequested:
    'Das Dokument wurde erst vor kurzem von Ihnen angefordert. Es kann bis zu 24 Stunden dauern, bis Sie dieses herunterladen können.',
  alreadySentTitle: 'Sie haben diesen Antrag bereits gesendet.',
  alreadySavedTitle: 'Sie haben diesen Antrag bereits gespeichert.',
  alreadySentParagraph:
    'Die Bearbeitung wird insgesamt 1 bis 4 Wochen dauern. Den aktuellen Status und alle wichtigen Informationen zu Ihrem Antrag finden Sie hier:',
  alreadySavedParagraph: 'Wenn Sie Ihren Antrag fortsetzen möchten, klicken Sie bitte auf Antrag fortsetzen:',
  alreadyRequestedServiceCard: 'Sie haben bereits einen Antrag zur Nachbestellung dieser Service-Karte gestellt',
  showDetails: 'Details anzeigen',
  continueFlow: 'Antrag fortsetzen',
  backLink: 'Zurück zu Anträge und Dokumente',
  rentalAccountInfoDownloadLabel: 'Mietzinskonto-Information',
};
