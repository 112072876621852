import React, { Fragment } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Collapsible3,
  Document,
  Download,
  Hyperlink,
  Icon,
  SVG,
} from 'digit.commons.ui-components-app';
import {
  ICollapsible,
  ICollapsibleContent,
  IDocument,
  IDownloadInformation,
  IHyperlink,
  IIcon,
  IList,
  ISection,
  ITextFormat,
} from '../interfaces/PossibleContent.interface';
import { IFieldsetOption } from '../interfaces/Template.interface';
import { WizardMode } from '../../constants/containers/request-theme';
import parse from 'html-react-parser';
import { IProcessData } from '../interfaces/DecisionTree.interface';

class TemplateComps {
  /** renders a Collapsible2 with either paragraphs or links as content */
  renderCollapsible(collapsibles: ICollapsible[], stepId: string, switchFlows: any) {
    return collapsibles.map((collapsible, colIndex) => (
      <div className="Template__collapsible--conditional" key={colIndex}>
        <Collapsible3 key={`collapsible-${colIndex}`} id={`${stepId}-${colIndex}`} title={collapsible.title}>
          {collapsible.collapsibleContent.map((content, contentIndex: number) => {
            return this.renderCollapsibleContent(stepId, content, colIndex, contentIndex, switchFlows);
          })}
        </Collapsible3>
      </div>
    ));
  }

  renderCollapsibleContent(
    stepId: string,
    content: ICollapsibleContent,
    colIndex: number,
    contentIndex: number,
    switchFlows?: (
      flow: string,
      keepDecisionsUpToTask: string,
      editMode: WizardMode,
      excludeFormId?: string,
      draftProcessData?: IProcessData
    ) => void
  ) {
    if (content.mixed) {
      return (
        <p key={`collapsible-${contentIndex}`}>
          {content.mixed.map((mixedContent, mixedContentIndex) =>
            mixedContent.strong ? (
              <em>
                {mixedContent.text ||
                  this.renderCollapsibleHyperlink(
                    stepId,
                    mixedContent.hyperlink,
                    colIndex,
                    contentIndex,
                    mixedContentIndex,
                    switchFlows
                  )}
              </em>
            ) : (
              mixedContent.text ||
              this.renderCollapsibleHyperlink(
                stepId,
                mixedContent.hyperlink,
                colIndex,
                contentIndex,
                mixedContentIndex,
                switchFlows
              )
            )
          )}
        </p>
      );
    } else {
      return (
        <Fragment key={`collapsible-${contentIndex}`}>
          {this.generateNonMixedCollapsibleContent(stepId, content, colIndex, contentIndex, switchFlows)}
        </Fragment>
      );
    }
  }

  generateNonMixedCollapsibleContent(
    stepId: string,
    content: ICollapsibleContent,
    colIndex: number,
    contentIndex: number,
    switchFlows: (flow: string, decisionsToKeep: string, editMode: WizardMode) => void
  ) {
    if (content.text) {
      return (
        <p className={`Template__paragraph${content.decreasedMarginBottom ? '-close' : ''}`} key={contentIndex}>
          {content.text}
        </p>
      );
    } else if (content.hyperlink) {
      return this.renderCollapsibleHyperlink(stepId, content.hyperlink, colIndex, contentIndex, null, switchFlows);
    } else if (content.list) {
      return this.generateList(content.list, stepId, false, switchFlows);
    } else if (content.download) {
      return (
        <p className="Template__paragraph-end">
          <Download
            id={`${stepId}-collapsible-${colIndex}`}
            url={content.download.downloadLink}
            icon={content.download.icon}
            target={'_self'}
          >
            {content.download.description}
          </Download>
        </p>
      );
    }
    return null;
  }

  private renderCollapsibleHyperlink(
    stepId: string,
    hyperlink: IHyperlink,
    colIndex: number,
    contentIndex: number,
    mixedContentIndex?: number,
    switchFlows?: (
      flow: string,
      keepDecisionsUpToTask: string,
      editMode: WizardMode,
      excludeFormId?: string,
      draftProcessData?: IProcessData
    ) => void
  ) {
    let uniqueKey = `${stepId}-collapsible-${colIndex}-item-${contentIndex}${
      mixedContentIndex ? `-mixed-` + mixedContentIndex : ''
    }`;
    return (
      <Hyperlink
        id={uniqueKey}
        key={uniqueKey}
        to={hyperlink.to}
        iconAfter={SVG.byName(hyperlink.iconAfter)}
        iconBefore={SVG.byName(hyperlink.iconBefore)}
        isExternal={hyperlink.isExternal}
        customHref={hyperlink.customHref}
        isInline={hyperlink.isInline}
        onClick={
          hyperlink.keepDecisionUpToTask && switchFlows
            ? e => {
                e.preventDefault();
                switchFlows(hyperlink.to, hyperlink.keepDecisionUpToTask, WizardMode.NO_EDIT_MODE);
              }
            : () => {}
        }
      >
        {hyperlink.text}
      </Hyperlink>
    );
  }

  getOptionsContent(options: IFieldsetOption[]): IFieldsetOption[] {
    return options.map(option => ({
      text: this.getOptionText(option),
      disabled: option.disabled,
      value: option.value,
    }));
  }

  getOptionText(option: IFieldsetOption): JSX.Element | string {
    if (option.forbidden) {
      return (
        <>
          <Icon icon={SVG.exclamation_mark_black} /> {option.text}
        </>
      );
    } else if (option.description) {
      return (
        <dl className={'Template__fieldset-dl'}>
          <dt>{option.description}</dt>
          <dd>{option.text}</dd>
        </dl>
      );
    } else if (option.icon) {
      // Wohnung · Adresse would be too long, hence we split off the first part but leave the icon to clarify
      return (
        <>
          <Icon icon={SVG.byName(option.icon)} />
          {(option.text as string).split('·')[1]}
        </>
      );
    }
    return option.text;
  }

  renderDocument(content: IDocument, stepId: string, sectionTitle: string) {
    let icon = content.icon;
    if (content.icon.endsWith('-small')) {
      icon = icon.replace('-small', '');
    }
    return (
      <Card id={`${stepId}-document-card`}>
        <CardHeader title={sectionTitle} />
        <CardBody>
          <Document
            id={`${stepId}-document`}
            title={content.title}
            icon={SVG.byName(icon)}
            description={content.description}
            linkText={content.linkText}
            linkDestination={content.linkDestination}
            linkIsExternal={content.linkIsExternal}
          />
        </CardBody>
      </Card>
    );
  }

  renderIcon(content: IIcon[], stepId: string) {
    return (
      <div className={content.length > 1 ? 'Template__icon-group' : null}>
        {content.map((icon, id) => (
          <Icon key={`${stepId}-${id}`} icon={SVG.byName(icon.icon)} />
        ))}
      </div>
    );
  }

  renderSections(
    sections: ISection[],
    stepId: string,
    id: string,
    className?: string,
    switchFlows?: (
      flow: string,
      keepDecisionsUpToTask: string,
      editMode: WizardMode,
      excludeFormId?: string,
      draftProcessData?: IProcessData
    ) => void,
    download?: IDownloadInformation
  ) {
    return (
      sections &&
      sections.map((section, index) => {
        let sectionContent;
        if (section.paragraph) {
          sectionContent = this.generateParagraph(
            section.paragraph,
            index,
            id,
            className || (section.increasedMarginBottom && 'Template__paragraph Template__paragraph-separation'),
            download
          );
        } else if (section.infoIcon) {
          sectionContent = (
            <Icon
              icon={SVG.byName(section.infoIcon)}
              className={'Template__icon--information'}
              key={`${id}-icon-${index}`}
            />
          );
        } else if (section.list) {
          sectionContent = this.generateList(
            section.list,
            `${stepId}-section-list-${index}`,
            section.marginInbetween,
            null,
            section.title
          );
        } else if (section.hyperlink) {
          sectionContent = this.generateHyperlink(section.hyperlink, index, id);
        } else if (section.collapsible) {
          sectionContent = this.renderCollapsible(
            section.collapsible,
            `${stepId}-section-collapsible-${index}`,
            switchFlows
          );
        } else if (section.document) {
          sectionContent = this.renderDocument(section.document, `${stepId}-section-document-${index}`, section.title);
        } else if (section.icons) {
          sectionContent = this.renderIcon(section.icons, `${stepId}-section-icon-${index}`);
        } else sectionContent = null;

        // list gets a card, so no additional section header wrapped around
        if (section.title && !(section.list || section.document)) {
          return (
            <article className={`Template__article`} key={`${id}-article-${index}`}>
              <h3 className="Template__heading">{section.title}</h3>
              {sectionContent}
            </article>
          );
        } else {
          return sectionContent;
        }
      })
    );
  }

  generateParagraph(
    paragraphs: ITextFormat[],
    index: number,
    id: string,
    className?: string,
    downloadFromLocation?: IDownloadInformation
  ) {
    const paragraphContent = paragraphs.map((paragraph, idx) => {
      if (paragraph.strong) {
        return <strong key={`${id}-strong-${idx}`}>{paragraph.text}</strong>;
      } else if (paragraph.hyperlink) {
        return this.generateHyperlink(paragraph.hyperlink, idx, id);
      } else if (paragraph.download) {
        const download = paragraph.download.useFromLocation ? downloadFromLocation : paragraph.download;
        return (
          <span className={'Template__text'} key={`${id}-download-${idx}`}>
            <Download
              id={`${id}-collapsible-${idx}`}
              url={download.downloadLink}
              icon={download.icon}
              target={'_blank'}
            >
              {download.description}
            </Download>
          </span>
        );
      }
      return paragraph.text;
    });

    return (
      <span
        className={className ? `${className} Template__text` : 'Template__paragraph Template__text'}
        key={`${id}-paragraph-${index}`}
      >
        {paragraphContent}
      </span>
    );
  }

  generateHyperlink(hyperlink: IHyperlink, idx: number, id: string) {
    return (
      <Hyperlink
        id={`${id}-hyperlink-${idx}`}
        to={hyperlink.to}
        iconBefore={SVG.byName(hyperlink.iconBefore)}
        iconAfter={SVG.byName(hyperlink.iconAfter)}
        isExternal={hyperlink.isExternal}
        isInline={hyperlink.isInline}
        key={`${id}-hyperlink-${idx}`}
      >
        {hyperlink.text}
      </Hyperlink>
    );
  }

  generateList(
    list: IList[],
    stepId: string,
    marginInbetween?: boolean,
    switchFlows?: (
      flow: string,
      keepDecisionsUpToTask: string,
      editMode: WizardMode,
      excludeFormId?: string,
      draftProcessData?: IProcessData
    ) => void,
    title?: string
  ) {
    const li = list.map((item, idx) => {
      let formattedItem;
      if (item.differentFormats) {
        formattedItem = (
          <>
            {item.differentFormats.map(differentFormat =>
              differentFormat.strong ? <strong key={idx}>{differentFormat.text}</strong> : differentFormat.text
            )}
          </>
        );
      } else if (item.strong) {
        formattedItem = <strong key={idx}>{item.text}</strong>;
      } else if (item.hyperlink) {
        formattedItem = switchFlows
          ? this.renderCollapsibleHyperlink(stepId, item.hyperlink, 0, idx, null, switchFlows)
          : this.generateHyperlink(item.hyperlink, idx, `${stepId}-list-hyperlink-${idx}`);
      }
      return (
        <li
          key={`${stepId}-li-${idx}`}
          className={marginInbetween ? 'Template__info-list-margins' : 'Template__info-list'}
        >
          {formattedItem ? formattedItem : item.text}
        </li>
      );
    });

    return title ? (
      <Card id={`${stepId}-wizard-list}`} badge={'ACHTUNG'} badgeType={'red'} className={'Template__card'}>
        <CardHeader title={title} icon={SVG.exclamation_mark_filled} />
        <CardBody>
          <ul className="Template__list-card">{li}</ul>
        </CardBody>
      </Card>
    ) : (
      <ul className="Template__list">{li}</ul>
    );
  }
}

export const TemplateComponents = new TemplateComps();
