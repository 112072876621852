import React from 'react';
import './WhatsNewAnnouncement.scss';
import {
  Badge,
  Button,
  Card,
  CardBody,
  DescriptionList,
  Hyperlink,
  IDescription,
  SVG,
} from 'digit.commons.ui-components-app';
import { IContentNode } from '../../../commons/api/content/Content.interface';
import { WHATS_NEW } from '../../constants/routes';
import { WHATS_NEW_ANNOUNCEMENT_LABELS } from '../../constants/containers/whats-new-labels';
import { IFeature } from './WhatsNewAnnouncement.interface';

interface IWhatsNewAnnouncementProps {
  features: IContentNode<IFeature>[];
  onCloseHandler: () => void;
}

const MAX_VISIBLE_FEATURES = 3;

export const WhatsNewAnnouncement: React.FC<IWhatsNewAnnouncementProps> = ({ features, onCloseHandler }) => {
  const { badge, details, acknowledged } = WHATS_NEW_ANNOUNCEMENT_LABELS;

  return features?.length > 0 ? (
    <Card id={'whats-new-announcement'} className="WhatsNewAnnouncement">
      <header>
        <Badge id={'whats-new-announcement'} modifier="GREEN" iconBefore={SVG.alert}>
          {badge}
        </Badge>
      </header>
      <CardBody>
        <DescriptionList
          descriptions={features
            .map(item => ({ ...item.content, date: item.publishDate } as IDescription))
            .slice(0, MAX_VISIBLE_FEATURES)}
        />
        <div className={'WhatsNewAnnouncement__history-link'}>
          <Hyperlink
            id={'whats-new-history'}
            to={WHATS_NEW}
            iconAfter="arrow-right"
            onClick={() => {
              onCloseHandler();
            }}
          >
            {details}
          </Hyperlink>
        </div>
        <div
          className={`WhatsNewAnnouncement__ack-button WhatsNewAnnouncement__ack-button${
            features?.length > MAX_VISIBLE_FEATURES ? '--50' : '--100'
          }`}
        >
          <Button id={'whats-new-acknowledge'} onClick={onCloseHandler}>
            {acknowledged}
          </Button>
        </div>
      </CardBody>
    </Card>
  ) : (
    <></>
  );
};
