import React from 'react';
import { Hyperlink, SVG } from 'digit.commons.ui-components-app';
import { CUSTOMER_SERVICE_NUMBER, NATUERLICH_SICHER_NUMBER, TENANT_PORTAL_SERVICE_NUMBER } from '../general';
import { COMPLEX_INFORMATION, CONTACT_LINK, REQUEST_SERVICECARD_LINK } from '../links';

export const LAUNDRY_DAY_LANDING = {
  title: 'Waschtage',
  meta: '',
  welcomeVideo: {
    badge: 'Aufgepasst!',
    title: 'Sie sind das erste Mal hier?',
    subtitle: 'Die Wiener Wohnen Waschküchenordnung - damit immer alles sauber über die Bühne geht',
  },
  permanentVideoTitle: 'Waschküchenordnung - Video',
  reservation: 'Waschtag reservieren',
  maxBookingsDay:
    'Sie haben heute bereits alle für Sie verfügbaren Waschtage reserviert. Wenn Sie einen anderen Termin reservieren möchten, müssen Sie zuerst eine Ihrer Reservierungen stornieren.',
  maxBookingsMonth:
    'Sie haben diesen Monat bereits alle für Sie verfügbaren Waschtage reserviert. Wenn Sie einen anderen Termin reservieren möchten, müssen Sie zuerst eine Ihrer Reservierungen in diesem Monat stornieren.',
  lockedBookingInfo: (
    <>
      <p>
        Sie dürfen derzeit aufgrund Ihrer Sperrung keine neuen Waschtage reservieren. Bitte wenden Sie sich an die
        Wiener Wohnen Service-Nummer für nähere Informationen:
      </p>
      <Hyperlink id="locked-info" to={`tel:${TENANT_PORTAL_SERVICE_NUMBER}`} customHref={true} isInline={true}>
        {TENANT_PORTAL_SERVICE_NUMBER}
      </Hyperlink>
    </>
  ),
  reservationOverview: {
    title: 'Waschtage - Meine Reservierungen',
    reservationTitle: 'Reservierungen',
    yourReservation: 'Meine Reservierungen',
    allReservation: 'Alle Reservierungen',
    exportAllReservations: 'Alle Termine exportieren',
    downloadAllReservations: 'Ausgewählte Termine herunterladen',
    noLaundryDay: 'Solange Sie gesperrt sind, können Sie Waschtage nicht wahrnehmen.',
  },
  locked: {
    lockedInfo: 'Sie wurden gesperrt',
    badge: 'Nutzungs-Sperre',
    body: (
      <>
        <p>
          Die Nutzung der Waschküche ist Ihnen im Moment nicht erlaubt. Sie können daher auch keine Waschtage
          reservieren. Bitte wenden Sie sich an die <em>Wiener Wohnen Service-Nummer</em> für nähere Informationen:
        </p>
        <Hyperlink id="locked" to={`tel:${CUSTOMER_SERVICE_NUMBER}`} customHref={true}>
          {CUSTOMER_SERVICE_NUMBER}
        </Hyperlink>
      </>
    ),
    linkText: 'Alle Kontakte anzeigen',
    linkTo: '/kontakt',
  },
  broken: {
    malfunction: 'Störungsmeldung',
    brokenWasher: 'Waschmaschine defekt',
    brokenDryer: 'Trockner defekt',
    brokenWasherAndDryer: 'Waschgeräte defekt',
    brokenInfo: (washCabinInfo: string, kindOfWasher: string) =>
      `In der ${washCabinInfo} ${kindOfWasher} gemeldet. Die Wartungsfirma ist bereits informiert.`,
    closeInformation: 'Meldung schließen',
    machineBooked: 'Sie haben aktuell folgenden Waschtag auf dieser Maschine reserviert: ',
    aBrokenWasher: ' wurde eine defekte Waschmachine',
    aBrokenDryer: ' wurde ein defekter Trockner',
    aBrokenWasherAndDryer: ' wurden Waschmaschine und Trockner als defekt',
  },
  next: {
    caution: 'Achtung: ',
    today: 'Heute',
    washerBroken: 'Waschmaschine derzeit defekt',
    dryerBroken: 'Trockner derzeit defekt',
    washerAndDryerBroken: 'Waschgeräte derzeit alle defekt',
    booking: 'Nächster Waschtag',
  },
  soon: {
    reminder: 'Erinnerung',
    laundryDayTomorrow: 'Morgen ist Waschtag',
    laundryDayToday: 'Heute ist Waschtag',
    laundryDayNow: 'Sie können jetzt waschen',
    tomorrowInfo:
      'Sie brauchen diesen Waschtag nicht? Bitte stornieren Sie ihn rechtzeitig, damit andere ihn nutzen können.',
    todayInfo: (washCabinInfo: string) => `Die Waschkabine "${washCabinInfo}" ist von `,
    nowInfo: (washCabinInfo: string) => `Die Waschkabine "${washCabinInfo}" ist`,
    forYou: 'für Sie reserviert.',
    cancelBooking: 'Reservierung stornieren',
    closeReminder: 'Erinnerung schließen',
  },
  errorMessages: {
    noServiceCard: {
      title: 'Leider können Sie derzeit keine Waschtage reservieren',
      errorText:
        'Um einen Waschtage online reservieren zu können, benötigen Sie eine Wiener Wohnen Service-Karte. Wir konnten allerdings keine Ihnen zugeteilte Service-Karte finden.',
      description: (
        <>
          <em>Ihre nächsten Schritte</em>
          <ol className={'List'}>
            <li>
              Wenn Sie bereits eine Service-Karte haben, und diese trotzdem nicht in unserem System gefunden wurde,
              rufen Sie uns bitte unter{' '}
              <Hyperlink id="laundry-day-service-nr" customHref={true} to={`tel: ${CUSTOMER_SERVICE_NUMBER}`}>
                {CUSTOMER_SERVICE_NUMBER}
              </Hyperlink>{' '}
              an.
            </li>
            <li>
              Wenn Sie noch keine Service-Karte bekommen haben, können Sie eine{' '}
              <Hyperlink
                id={'laundry-day-request-servicecard'}
                isInline={true}
                to={REQUEST_SERVICECARD_LINK}
                isExternal={true}
              >
                neue Service-Karte beantragen
              </Hyperlink>
              .
            </li>
            <li>
              Sollten Sie nur eine Fahrradbox gemietet haben, können wir Ihnen leider keine Service-Karte ausstellen und
              auch keine Benutzung der Waschküchen anbieten.
            </li>
          </ol>
        </>
      ),
    },
    bookingResultsErrorJsx: (
      <>
        <b>Hoppala!</b>
        <p>
          Wir können im Moment leider nicht auf Ihre Reservierungen zugreifen. Bitte probieren Sie es später noch
          einmal.
        </p>
      </>
    ),
    bookingAllowancesErrorJsx: (
      <>
        <b>Hoppala!</b>
        <p>
          Wir können im Moment leider nicht auf den Reservierungs-Service zugreifen. Bitte probieren Sie es später noch
          einmal.
        </p>
      </>
    ),
    serviceError: {
      title: 'Service nicht erreichbar',
      text:
        'Leider kann zur Zeit nicht auf den Waschtage-Service zugegriffen werden. Bitte versuchen Sie es später noch einmal.',
      description: (
        <>
          {' '}
          Bei dringenden Fragen wenden Sie sich bitte an die naTÜRlich sicher Service-Nummer unter{' '}
          <Hyperlink id="laundry-day-landing" to={`tel:${NATUERLICH_SICHER_NUMBER}`} customHref={true}>
            {NATUERLICH_SICHER_NUMBER}
          </Hyperlink>
        </>
      ),
    },
    otherError: {
      title: 'Leider können wir keine Waschküche für Sie finden',
      description: (
        <>
          <p>
            Online Waschtagsreservierungen sind nur für Waschküchen mit dem “naTÜRlich-sicher” System möglich. Bei
            älteren Waschküchen können Termine weiterhin leider nur bei der Hausbesorgerin oder dem Hausbesorger
            reserviert werden. Ob Ihr Hof eine Waschküche hat und die Kontaktdaten Ihrer Hausbesorgerin oder Ihres
            Hausbesorgers finden Sie unter{' '}
            <Hyperlink id={'laundry-day-landing-hof'} isInline={true} to={COMPLEX_INFORMATION}>
              Hof-Informationen
            </Hyperlink>
            {'.'}
          </p>{' '}
          <p>
            <b>Bitte beachten Sie:</b> Wenn Sie nur einen Parkplatz bei Wiener Wohnen gemietet haben, können Sie die
            Waschküchen leider nicht benutzen.
          </p>
          <p>
            Sollte die Waschküche Ihres Hofes bereits ein naTÜRlich-sicher System haben, und diese trotzdem nicht in
            unserem System gefunden wurde, rufen Sie uns bitte unter{' '}
            <Hyperlink id="laundry-day-service-nr" customHref={true} to={`tel: ${CUSTOMER_SERVICE_NUMBER}`}>
              {CUSTOMER_SERVICE_NUMBER}
            </Hyperlink>{' '}
            an.
          </p>
        </>
      ),
    },
  },
  contact: {
    header: 'Kontakt',
    body: (
      <>
        <p className={'LaundryDay__contact-p'}>
          Bei dringenden Fragen rund um die Waschküchen rufen Sie bitte die <em>naTÜRlich sicher Service-Nummer</em> an:
        </p>
        <Hyperlink id="laundry-day-contact-servicenumber" to={`tel:${NATUERLICH_SICHER_NUMBER}`} customHref={true}>
          {NATUERLICH_SICHER_NUMBER}
        </Hyperlink>
      </>
    ),
    footer: (
      <Hyperlink id={'laundry-day-online-contact'} to={CONTACT_LINK} iconAfter={SVG.arrow_right} isExternal={true}>
        Online-Kontaktformular
      </Hyperlink>
    ),
  },
  faq: {
    title: 'Häufige Fragen',
    accordionQandA: [
      {
        accordionTitle: 'Wie viele Waschtage kann ich reservieren?',
        accordionBody: (
          <>
            <p>Sie können, zusätzlich zu Ihrem Regelwaschtag, in folgendem Umfang Reservierungen vornehmen:</p>
            <ul className={'LaundryDay__faq-list'}>
              <li>
                Pro Monat, in dem die Reservierung erfolgt, können maximal vier Termine zusätzlich gebucht werden.
              </li>
              <li>
                Pro Kalendertag und pro Anmeldung können maximal zwei Reservierungen zusätzlich vorgenommen werden.
              </li>
              <li>Reservierungen sind nur für die nächsten 30 Tage möglich.</li>
            </ul>
          </>
        ),
      },
      {
        accordionTitle: 'Was, wenn ich doch nicht an meinen reservierten Waschtag waschen kann?',
        accordionBody: (
          <>
            <p>
              Sollten Sie Ihren reservierten Waschtag nicht wahrnehmen können, bitten wir Sie darum diesen rechtzeitig
              (vor Start der reservierten Zeit) zu stornieren. Sie finden alle reservierten Waschtage im Bereich
              Waschtage. Um einen Waschtag zu stornieren, wählen Sie zuerst den gewünschten Waschtage aus und klicken
              anschließend auf „Waschtag stornieren“. Sie können stattdessen einen anderen Waschtag reservieren. Sollte
              der reservierte Zeitabschnitt bereits begonnen haben, gilt der Waschtag als verbraucht und kann nicht mehr
              storniert werden.
            </p>
          </>
        ),
      },
      {
        accordionTitle: 'Ein Waschgerät wurde defekt gemeldet. Was passiert nun?',
        accordionBody: (
          <p>
            Sollten Sie eine Benachrichtigung bekommen haben, dass ein Waschgerät in Ihrer reservierten Waschküche
            defekt gemeldet wurde, bleibt Ihre Reservierung trotzdem bestehen. Wir versuchen den Schaden so schnell wie
            möglich zu beheben. Sollten Sie einen Ersatztermin haben wollen, müssen Sie den reservierten Waschtag zuerst
            stornieren.
          </p>
        ),
      },
    ],
  },
};
