import React from 'react';
import { Helmet } from 'react-helmet';
import { ErrorPanel, Section, SVG } from 'digit.commons.ui-components-app';
import { DOWNTIME_LABELS } from '../../constants/containers/downtime-labels';
import { PAGE_TITLE_APP } from '../../constants/component-labels';
import { AuthenticationState } from '../../../AuthenticationState';

interface IRedirectProps {
  redirectPath: string;
  isLoggedIn: boolean;
}

const DownTime: React.FC<IRedirectProps> = () => {
  const { pageTitle, meta, errorPanel } = DOWNTIME_LABELS;
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta charSet="utf-8" />
        <meta name={'description'} content={meta} />
      </Helmet>
      <Section id={'down-time'} className="DownTime" title={PAGE_TITLE_APP}>
        <ErrorPanel
          id={'down-time'}
          title={errorPanel.title}
          errorText={AuthenticationState.downtime.reason}
          descriptionText={errorPanel.solution}
          icon={SVG.sofa}
        />
      </Section>
    </>
  );
};

export default DownTime;
