import { piwikEvents } from '../../../commons/utility/piwikEvents';
import ApplicationFormApi from '../../../commons/api/applicationForm/ApplicationForm.api';
import { IProcessData } from '../../decisionTree/interfaces/DecisionTree.interface';
import { AFWizardUtility } from '../../utility/AFWizardUtility';
import { STATUS } from '../../constants/status';
import { IFlowStructure } from '../../../commons/api/applicationForm/ApplicationForm.interface';
import DecisionTreeLogic from '../../decisionTree/logic/DecisionTreeLogic';
import { IPossibleContent } from '../../decisionTree/interfaces/PossibleContent.interface';

class AFWizardCalls {
  async getFlowStructure(flow: string, formId: string): Promise<IFlowStructure> {
    // return { json: JSON.stringify(jsonFlow), doubleSubmissions: [] }; // can be used for manually testing json flows
    try {
      const response = await ApplicationFormApi.fetchFlowStructure(flow, formId);
      if (response && response.data.getFlowJson) {
        return response.data.getFlowJson;
      }
    } catch (e) {
      piwikEvents.trackEvent(
        'technischer Fehler',
        'Anträge und Dokumente',
        'Fehler beim Laden des angeforderten Flows'
      );
    }
  }

  async save(
    status: string,
    processDataToSave: IProcessData,
    decisionTreeLogic: DecisionTreeLogic,
    processData: IProcessData,
    databaseId: string,
    possibleContent: IPossibleContent,
    belongsToStatusMapping: string = null
  ): Promise<string> {
    const swenrSmenr = processDataToSave['rentalObject'] ? processDataToSave['rentalObject'][0].split('-') : ['', ''];
    try {
      const response = await ApplicationFormApi.submitApplicationFormData(
        decisionTreeLogic.getTheme(),
        decisionTreeLogic.getWorkflow(),
        processDataToSave,
        status,
        { tplnr: processDataToSave['rentalObject'] },
        processDataToSave[
          possibleContent.externalSystemCodeMapping
            ? possibleContent.externalSystemCodeMapping.overrideCodeMappingTaskId
            : decisionTreeLogic.getPipeContentPageInformationTaskId()
        ],
        swenrSmenr[0],
        swenrSmenr[1],
        databaseId,
        belongsToStatusMapping
      );
      if (status === STATUS.draft.apiStatus) {
        piwikEvents.trackEvent(
          'Anträge und Dokumente',
          `Erstellung ${AFWizardUtility.getThemeInGerman(decisionTreeLogic.getTheme())}`,
          `Antrag ${AFWizardUtility.getChangesInGerman(
            processDataToSave['changes']
          )} wurde erfolgreich zwischengespeichert`
        );
      } else if (status === STATUS.hb_info_resolved.apiStatus) {
        piwikEvents.trackEvent(
          'Anträge und Dokumente',
          'Bestellung beantworten',
          'Bestellung wurde erfolgreich beantwortet'
        );
      } else {
        piwikEvents.trackEvent(
          'Anträge und Dokumente',
          `Erstellung ${
            processDataToSave['summary']['Antragsdetails'] ||
            AFWizardUtility.getThemeInGerman(decisionTreeLogic.getTheme())
          }`,
          processDataToSave['summary']['Antrag']
            ? `Antrag ${processDataToSave['summary']['Antrag']} wurde erfolgreich eingereicht`
            : `Antrag ${AFWizardUtility.getThemeInGerman(decisionTreeLogic.getTheme())} wurde erfolgreich eingereicht`
        );
      }
      return response.data.saveApplicationForm;
    } catch (e) {
      if (status === STATUS.draft.apiStatus) {
        piwikEvents.trackEvent(
          'Anträge und Dokumente',
          `Erstellung ${AFWizardUtility.getThemeInGerman(decisionTreeLogic.getTheme())}`,
          `Antrag ${AFWizardUtility.getChangesInGerman(
            processData['changes']
          )} konnte nicht erfolgreich zwischengespeichert werden`
        );
      } else if (status === STATUS.hb_info_resolved.apiStatus) {
        piwikEvents.trackEvent(
          'Anträge und Dokumente',
          'Bestellung beantworten',
          'Bestellung konnte nicht erfolgreich beantwortet werden'
        );
      } else {
        piwikEvents.trackEvent(
          'Anträge und Dokumente',
          `Erstellung ${
            processDataToSave['summary']['Antragsdetails'] ||
            AFWizardUtility.getThemeInGerman(decisionTreeLogic.getTheme())
          }`,
          processDataToSave['summary']['Antrag']
            ? `Antrag ${processDataToSave['summary']['Antrag']} konnte nicht erfolgreich eingereicht werden`
            : `Antrag ${AFWizardUtility.getThemeInGerman(
                decisionTreeLogic.getTheme()
              )} konnte nicht erfolgreich eingereicht werden`
        );
      }
      throw e;
    }
  }
}

export const ApplicationFormWizardCalls = new AFWizardCalls();
