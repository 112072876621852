import {
  AuthStates,
  IAuthStateData,
  IDowntime,
} from './commons/api/authenticationState/AuthenticationStateData.interface';
import AuthenticationStateApi from './commons/api/authenticationState/AuthenticationState.api';
import { INVALID_CONTRACT, PRELANDING, REGISTRATION } from './portal-app/constants/routes';
import { Configuration } from './Configuration';
import BridgeApi from './commons/api/Bridge';

export class AuthenticationState {
  static isLoggedIn: boolean = false;
  static authRedirectUrl: string = PRELANDING;
  static redirectFrom: string = '/';
  static isInDowntime: boolean = false;
  static isAccessToPortalBlocked: boolean = false;
  static oneWeekBeforeDowntime: boolean = false;
  static downtime: IDowntime = null;

  /**
   * The auth-state-check API call no longer throws an exception - so the Ajax/Bridge "OAuth refresh on Exception" logic is not triggered
   * so we explicitly perform a OAuth refresh if the user is not AUTHENTICATED
   * @param allowRetry
   */
  static async retrieveAuthenticationStateWithRetry(allowRetry: boolean): Promise<any> {
    const oathToken = BridgeApi.getOauthToken();
    return AuthenticationStateApi.retrieveAuthenticationState().then(response => {
      if (response.state === AuthStates.AUTHENTICATED) {
        return response;
      } else {
        if (
          BridgeApi.askToRefreshOAuthToken() ||
          BridgeApi.isBeingRefreshed(oathToken) ||
          BridgeApi.hasChanged(oathToken)
        ) {
          console.log('Retrying retrieveAuthenticationState as we received ', response.state);
          //If the BridgeApi is refreshing the token right now (because we asked it to), then the next ajax call  will
          //be chained on the Promise of that refresh (see makeRequest.tsx)
          return AuthenticationState.retrieveAuthenticationStateWithRetry(false);
        } else {
          console.log('retrieveAuthenticationState returned', response);
          console.log(
            "Can't retry retrieveAuthenticationState - Bridge didn't perform a OAuth token refresh, current OAuth token =",
            BridgeApi.getOauthToken()
          );
          //we can't refresh so return what we have
          return response;
        }
      }
    });
  }

  static async init(): Promise<any> {
    try {
      console.log('Logging AppData before AuthCheck call...');
      BridgeApi.logAppData();
      const response = await AuthenticationState.retrieveAuthenticationStateWithRetry(true);
      this.downtime = response.downtime;
      this.oneWeekBeforeDowntime = oneWeekBeforeDowntime(response);
      this.isInDowntime = currentlyInDowntime(response);

      /* report the state change to the native app accordingly */
      if (BridgeApi.isAvailable()) BridgeApi.setAuthState(response.state);
      if (this.isInDowntime && response.downtime?.blockAccessToPortal) {
        this.isAccessToPortalBlocked = response.downtime?.blockAccessToPortal;
      } else {
        switch (response.state) {
          case AuthStates.AUTHENTICATED:
            // If we are properly authenticated, then we should prompt the user to upgrade to an OAuth token
            const { authParams, authRedirectUrl, authUrl } = Configuration.idpConfig();
            console.log(
              `-- OAuth accept/deny page configured: ${(authParams && authRedirectUrl && authUrl) !== undefined}`
            );
            console.log(`-- BridgeApi.isAvailable: ${BridgeApi.isAvailable()}`);
            this.isLoggedIn = true;
            this.authRedirectUrl = authRedirectUrl;
            if (
              authParams &&
              authRedirectUrl &&
              authUrl &&
              BridgeApi.isAvailableAndHasNoOAuthToken() &&
              !BridgeApi.hasOauthTokenBeenDeclined() &&
              !window.location.href.includes('oauthtokenretriever')
            ) {
              document.location.href = `${authUrl}${authParams}&redirect_uri=${authRedirectUrl}`;
            }
            break;
          case AuthStates.OTFU:
            this.isLoggedIn = false;
            this.authRedirectUrl = REGISTRATION;
            break;
          case AuthStates.INVALID_CONTRACT:
            this.isLoggedIn = false;
            this.authRedirectUrl = INVALID_CONTRACT;
            break;
          default:
            this.isLoggedIn = false;
            this.redirectFrom = window.location.pathname;
            this.authRedirectUrl = PRELANDING;
            if (BridgeApi.isAvailable()) BridgeApi.setAuthState(AuthStates.ANONYMOUS);
            break;
        }
      }
    } catch (error) {
      console.log('AuthenticationState.init:: Received error retrieving authentication state', error);
      this.isLoggedIn = false;
      this.redirectFrom = window.location.pathname;
      this.authRedirectUrl = PRELANDING;
      this.isInDowntime = true;
      this.isAccessToPortalBlocked = true;
      this.downtime = {
        blockAccessToPortal: true,
        startDate: 0,
        endDate: 9999999999999,
        reason:
          'Es kommt derzeit zur Störungen und Ausfällen, an der Behebung wird bereits mit Hochdruck gearbeitet. Bitte entschuldigen Sie die Unannehmlichkeiten.',
      } as IDowntime;
      if (BridgeApi.isAvailable()) BridgeApi.setAuthState(AuthStates.ANONYMOUS);
    }
  }
}

export function oneWeekBeforeDowntime(authState: IAuthStateData): boolean {
  if (authState.downtime == null) return false;
  const now = Date.now();
  return now >= oneWeekBefore(authState.downtime.startDate) && now < authState.downtime.startDate;
}

export function currentlyInDowntime(authState: IAuthStateData): boolean {
  if (authState.downtime == null) return false;
  const now = Date.now();
  return now >= authState.downtime.startDate && now < authState.downtime.endDate;
}

function oneWeekBefore(date: number): number {
  return date - 1000 * 60 * 60 * 24 * 7;
}

export const stdpAuthenticationRequiredHandler = () => {
  // this case only happens if we have no valid refresh token
  // in this case the tokens (oauth and refresh) in the app data got already cleared by the bridge code
  console.log('stdp authentication required');
  AuthenticationState.isLoggedIn = false;
  window.location.href = PRELANDING;
};
