import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { APPLICATION_FORM_BASE_ROUTE, DETAILS_ROUTE, PRE_SELECTION_ROUTE, WIZARD_ROUTE } from './constants/routes';
import ApplicationFormLanding from './containers/ApplicationFormLanding/ApplicationFormLanding';
import ApplicationFormPreSelection from './containers/ApplicationFormPreSelection/ApplicationFormPreSelection';
import ApplicationFormWizard from './containers/ApplicationFormWizard/ApplicationFormWizard';
import ApplicationFormDetails from './containers/ApplicationFormDetails/ApplicationFormDetails';

const ApplicationFormRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path={APPLICATION_FORM_BASE_ROUTE}>
        <ApplicationFormLanding />
      </Route>
      <Route exact path={PRE_SELECTION_ROUTE}>
        <ApplicationFormPreSelection />
      </Route>
      <Route exact path={WIZARD_ROUTE}>
        <ApplicationFormWizard />
      </Route>
      <Route path={DETAILS_ROUTE}>
        <ApplicationFormDetails />
      </Route>
    </Switch>
  );
};

export default ApplicationFormRoutes;
