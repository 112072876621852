import { ILDConfiguration } from './laundryday/LaundryDaySCSInitializer';
import { IAFConfiguration } from './applicationform/ApplicationFormSCSInitializer';

export class Configuration {
  static configuration: IConfiguration;
  static DEFAULT_FETCH_TIMEOUT = 15000;

  static apiConfig(): IApiConfig {
    return Configuration.configuration.api;
  }

  static idpConfig(): IIdpConfig {
    return Configuration.configuration.idp;
  }

  static piwikConfig(): IPiwikConfig {
    return Configuration.configuration.piwik;
  }

  static sentryConfig(): ISentryConfig {
    return Configuration.configuration.sentry;
  }

  static applicationConfig(): IApplicationConfig {
    return Configuration.configuration.application;
  }

  static queryConfig(): IQueryConfig {
    return Configuration.configuration.query
      ? Configuration.configuration.query
      : { timeoutInMillis: this.DEFAULT_FETCH_TIMEOUT };
  }

  static laundryDayConfig(): ILDConfiguration {
    return Configuration.configuration['laundry-day-scs'];
  }

  static applicationFormConfig(): IAFConfiguration {
    return Configuration.configuration['application-form-scs'];
  }

  static downloads(): any {
    return Configuration.configuration.externalDownloads;
  }

  static serverSentEvents(): IServerSentEventsConfig {
    return Configuration.configuration.serverSentEvents;
  }

  static async init(): Promise<any> {
    const conf = (window as any).conf;
    if (!conf || conf === '!!configuration!!') {
      const configurationPromise = await fetch('/configuration.json');
      return configurationPromise.json().then(json => {
        Configuration.configuration = json;
        console.log(json);
      });
    } else {
      return (Configuration.configuration = conf);
    }
  }
}

export interface IConfiguration {
  application: IApplicationConfig;
  serverSentEvents: IServerSentEventsConfig;
  api: IApiConfig;
  idp: IIdpConfig;
  piwik: IPiwikConfig;
  sentry: ISentryConfig;
  query: IQueryConfig;
  externalDownloads: any;
}

interface IApiConfig {
  feedbackApi: string;
  tenantApi: string;
  registrationApi: string;
  rentalApi: string;
  buildingComplexApi: string;
  authenticationStateApi: string;
  serviceCardApi: string;
  contentApi: string;
  paths: IPaths;
}

interface IPaths {
  pathToGraphQL: string;
  pathToRegistration: string;
  pathToCheckMDM: string;
  pathToDeactivation: string;
  pathToStateCheck: string;
  pathToTriggerChangeName: string;
  pathToServiceCard?: string;
}

export interface IIdpConfig {
  registrationUrl: string;
  loginWithPasswordUrl: string;
  loginWithMobileSignatureUrl: string;
  logoutUrl: string;
  mobileSignatureActivationUrl: string;
  requestTokenUrl: string;
  refreshTokenUrl: string;
  invalidateTokenUrl: string;
  clientSecret: string;
  clientId: string;
  authUrl: string;
  authRedirectUrl: string;
  authParams: string;
  oauthDeclinePeriodInSeconds: number;
}

interface IPiwikConfig {
  PIWIK: string;
  PIWIK_SITE_ID: number;
  enableHeatMapSessionRecording: boolean;
}

interface ISentryConfig {
  DSN: string;
  env: string;
}

export interface IApplicationConfig {
  applicationProxyCacheTimeout: number;
  currentMinAppVersion: string;
}

export interface IQueryConfig {
  registrationTimoutInMillis?: number;
  timeoutInMillis: number;
}

export interface IServerSentEventsConfig {
  endpointUrl: string;
  transports: string[];
  connectionRetryTimeout: number;
}
