import { IExtendedError } from '../ExtendedError.interface';
import {
  mutationTenantContactData,
  queryRentalObjectType,
  queryTenancyContracts,
  queryTenantNameAndFeatures,
  queryTenantWithDetailsAndFeatures,
  queryTenantWithEmail,
} from './TenantData.graphql';
import { Configuration } from '../../../Configuration';
import { stdpAuthenticationRequiredHandler } from '../../../AuthenticationState';
import {
  ITenancyContracts,
  ITenantAndClientFeatures,
  ITenantData,
  ITenantNameAndClientFeatures,
  ITenantUpdateData,
} from './TenantData.interface';
import { IGraphQL } from '../GraphQL.interface';
import { AbstractRequestApi } from '../AbstractRequestApi';

class TenantDataApi extends AbstractRequestApi {
  constructor() {
    super(
      () => Configuration.apiConfig().tenantApi,
      'TenantDataApi',
      () => Configuration.queryConfig(),
      () => stdpAuthenticationRequiredHandler
    );
  }

  fetchTenantAndClientFeatures(gepart?: string): Promise<IGraphQL<ITenantAndClientFeatures>> {
    return this.makeCacheableGraphQlRequest(
      Configuration.apiConfig().paths.pathToGraphQL,
      queryTenantWithDetailsAndFeatures(gepart),
      'queryTenantAndClientFeatures'
    ).catch((error: IExtendedError) => {
      return this.handleResponse(error);
    });
  }

  fetchTenantContracts(gepart?: string, rentalObjectType?: boolean): Promise<IGraphQL<ITenancyContracts>> {
    return this.makeCacheableGraphQlRequest(
      Configuration.apiConfig().paths.pathToGraphQL,
      rentalObjectType ? queryRentalObjectType(gepart) : queryTenancyContracts(gepart),
      'queryTenancyContracts'
    ).catch((error: IExtendedError) => {
      return this.handleResponse(error, 'fetchTenantContracts');
    });
  }

  fetchTenantNameAndFeatures(gepart?: string): Promise<IGraphQL<ITenantNameAndClientFeatures>> {
    return this.makeCacheableGraphQlRequest(
      Configuration.apiConfig().paths.pathToGraphQL,
      queryTenantNameAndFeatures(gepart),
      'queryTenantName'
    ).catch((error: IExtendedError) => {
      return this.handleResponse(error, 'fetchTenantNameAndFeatures');
    });
  }

  updateTenantContactData(
    email: string,
    telNumber: string,
    telNumberMobile: string
  ): Promise<IGraphQL<ITenantUpdateData>> {
    const query = {
      query: mutationTenantContactData(email, telNumber, telNumberMobile),
    };

    return this.postGraphQlRequest(
      Configuration.apiConfig().paths.pathToGraphQL,
      'mutateTenantContactData',
      query
    ).catch((error: IExtendedError) => {
      return this.handleResponse(error);
    });
  }

  fetchTenantMail(gepart?: string): Promise<IGraphQL<ITenantNameAndClientFeatures>> {
    return this.getTenantMail(gepart).catch((error: IExtendedError) => this.handleResponse(error));
  }

  private getTenantMail(gepart?: string): Promise<IGraphQL<ITenantNameAndClientFeatures>> {
    const query = {
      query: queryTenantWithEmail(gepart),
    };
    return this.makeCacheableGraphQlRequest(
      Configuration.apiConfig().paths.pathToGraphQL,
      query.query,
      'getTenantMail'
    );
  }
}

export default new TenantDataApi();
