import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { ITextAreaHandles, TextArea } from 'digit.commons.ui-components-app';
import { ITemplate, ITemplateHandles } from '../interfaces/Template.interface';
import './Template.scss';
import { TemplateComponents } from './TemplateComponents';
import { TEXT_AREA_ALLOWED_CHARACTERS_REGEX } from '../../../portal-app/constants/regex';

const TextInputTemplate: React.ForwardRefRenderFunction<ITemplateHandles, ITemplate> = (props, ref) => {
  const { processData, decisionCallback, step } = props;

  const textAreaRef = useRef<ITextAreaHandles>(null);

  useImperativeHandle(ref, () => ({
    validateTemplate: () => {
      if (step.props.textArea.required) {
        if (!(processData && processData[step.id])) {
          textAreaRef.current.forceError(step.props.errorMessage);
          return false;
        }
        return textAreaRef.current.isValid();
      }
      return true;
    },
  }));

  const onTextAreaInputHandler = e => {
    decisionCallback(step.id, [
      {
        key: step.id,
        value: e.currentTarget.value,
      },
    ]);
  };

  return (
    <>
      <h3 className="Template__heading">{step.props.title}</h3>
      {step.props.sections && TemplateComponents.renderSections(step.props.sections, step.id, 'text-input')}
      <TextArea
        id={step.id}
        label={step.props.textArea.label}
        description={step.props.textArea.description}
        value={processData && processData[step.id] ? processData[step.id] : ''}
        maxLength={step.props.textArea.maxLength}
        required={step.props.textArea.required}
        onChange={onTextAreaInputHandler}
        allowedCharacterRegex={TEXT_AREA_ALLOWED_CHARACTERS_REGEX}
        ref={textAreaRef}
      />
    </>
  );
};

export default forwardRef(TextInputTemplate);
