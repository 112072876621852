import moment, { Moment } from 'moment-timezone';
import { HOUR } from '../../laundryday/constants/general';

export const timezone = 'Europe/Vienna';
export const date_locale = 'de-at';

/**
 * Creates a new moment from some input, if the timezone is not specified in the input
 * then it is taken to be "Europe/Vienna"
 *
 * If the input is a date in a different timezone, then it is converted to the Europe/Vienna timezone
 *
 * @param input
 */
export function newMoment(input?: moment.MomentInput): Moment {
  return moment.tz(input, timezone).locale(date_locale);
}

/**
 * The date is parsed in Europe/Vienna timezone if the format is missing timezone information
 * eg  2020-06-06T13:00:00.000+0200
 * @param dateString
 */
export function parseISO8601(dateString: string): number {
  return moment.tz(dateString, timezone).locale(date_locale).valueOf();
}

export function isFutureDateTime(day: number, time: string, offset: number = 0): boolean {
  return toTimeOfDay(day, time) + offset > Date.now();
}

export function toTimeOfDay(day: number, time: string): number {
  const m = newMoment(day);
  m.hour(Number(time.substring(0, 2)));
  m.minute(Number(time.substring(3, 5)));
  return m.valueOf();
}

export function isHappeningNow(start: number, end: number): boolean {
  const now = Date.now();
  return start <= now && now < end;
}

export function isToday(date: number): boolean {
  const startOfDay = newMoment().startOf('day').valueOf();
  const endOfDay = newMoment().endOf('day').valueOf();
  return startOfDay <= date && date <= endOfDay;
}

export function endedTimeAgo(end: number, timeAgo: number): boolean {
  return new Date().getTime() - end <= timeAgo && new Date().getTime() - end >= 0;
}

export function format(date: number, format: string): string {
  return newMoment(date).format(format);
}

export function getFullYear(date: number): number {
  return newMoment(date).year();
}

export function isNowOrWasBeforeAndHour(date: number, start: string, end: string) {
  const endDate = toTimeOfDay(date, end);
  return isHappeningNow(toTimeOfDay(date, start), endDate) || endedTimeAgo(endDate, HOUR);
}
