import React, { Component } from 'react';
import { Badge, Card, CardBody, SVG } from 'digit.commons.ui-components-app';

interface DefaultAnnouncementComponentProps {
  id: string;
  announcement: any;
  className: string;
  onClose: () => any;
}

export class DefaultAnnouncementComponent extends Component<DefaultAnnouncementComponentProps, {}> {
  render() {
    const { id, announcement, className, onClose } = this.props;

    const close = () => {
      announcement.closeFunction().then(onClose());
    };

    return (
      <Card id={id} className={className} background={true} onDismiss={close}>
        <CardBody>
          <>
            <Badge id={`${id}-badge`} modifier="DECLINED" iconBefore={SVG.laundry}>
              Wichtige Information
            </Badge>
            <h3>{announcement.title}</h3>
            <p>{announcement.body}</p>
          </>
        </CardBody>
      </Card>
    );
  }
}
