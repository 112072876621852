import { IExtendedError } from '../ExtendedError.interface';
import ApplicationFormSCSInitializer from '../../../applicationform/ApplicationFormSCSInitializer';
import {
  getAllApplicationFormData,
  getAllSynchronizedApplicationFormData,
  getApplicationFormDetailData,
  mutationDeleteApplicationForm,
  mutationSubmitApplicationFormData,
  queryFlowStructure,
  queryFlowStructureByTheme,
} from './ApplicationForm.graphql';
import { IProcessData } from '../../../applicationform/decisionTree/interfaces/DecisionTree.interface';
import {
  IApplicationForm,
  IApplicationFormDetailData,
  IApplicationFormsData,
  IDeleteApplicationFormData,
  IDocument,
  IFlowStructureByThemeData,
  IFlowStructureData,
  ISaveApplicationFormData,
  ISynchronizedApplicationFormsData,
} from './ApplicationForm.interface';
import { Method } from '../ApiMethodEnum';
import { AbstractRequestApi } from '../AbstractRequestApi';
import { IGraphQL } from '../GraphQL.interface';
import { DOWNLOAD_HELPER } from '../../../portal-app/utility/downloadHelper';

class ApplicationFormApi extends AbstractRequestApi {
  constructor() {
    super(
      () => ApplicationFormSCSInitializer.apiConfig().applicationFormApi,
      'ApplicationFormApi',
      () => ApplicationFormSCSInitializer.queryConfig(),
      () => ApplicationFormSCSInitializer.authenticationRequiredHandler()
    );
  }

  /**
   * This function does a post request to the server to store the application form data
   * @param theme which theme was worked on
   * @param workflow which workflow is choosen
   * @param json the json for the processdata
   * @param status an status of the ticket see -> statusEnum
   * @param tplnr technische platz nummer
   * @param contentPageKey to which sap content page mapping the form belongs
   * @param swenr wirtschaftseinheit (first part of tplnr)
   * @param smenr auch irgendsowas (secnod part of tplnr)
   * @param id if the form already exists (saved as draft or adding information when sap blocked the form)
   * @param belongsToStatusMapping to which sap blocked state the information belongs
   */
  submitApplicationFormData(
    theme: string,
    workflow: string,
    json: IProcessData,
    status: string,
    tplnr: any,
    contentPageKey: string,
    swenr: string,
    smenr: string,
    id?: string,
    belongsToStatusMapping?: string
  ): Promise<IGraphQL<ISaveApplicationFormData>> {
    const query = {
      query: mutationSubmitApplicationFormData(
        workflow,
        theme,
        unescape(escape(JSON.stringify(json)))
          .replace(/\\/g, '\\\\')
          .replace(/"/g, '\\"'),
        status,
        JSON.stringify(tplnr).replace(/"/g, '\\"'),
        contentPageKey,
        swenr,
        smenr,
        id,
        belongsToStatusMapping
      ),
    };
    return this.postGraphQlRequest(
      ApplicationFormSCSInitializer.apiConfig().paths.pathToGraphQL,
      'submitApplicationFormMutation',
      query
    );
  }

  fetchAllApplicationFormData(): Promise<IGraphQL<IApplicationFormsData>> {
    return this.getAllApplicationFormData()
      .then((response: IGraphQL<IApplicationFormsData>) => {
        response.data.getApplicationFormData.forEach(
          (a: IApplicationForm) => (a.statusMappings = a.statusMappings.reverse())
        );
        return response;
      })
      .catch((error: IExtendedError) => {
        return this.handleResponse(error);
      });
  }

  private getAllApplicationFormData(): Promise<IGraphQL<IApplicationFormsData>> {
    const query = {
      query: getAllApplicationFormData(),
    };
    return this.makeCacheableGraphQlRequest(
      ApplicationFormSCSInitializer.apiConfig().paths.pathToGraphQL,
      query.query,
      'getAllApplicationFormData'
    );
  }

  fetchAllSynchronizedApplicationFormData(): Promise<IGraphQL<ISynchronizedApplicationFormsData>> {
    return this.getAllSynchronizedApplicationFormData()
      .then((response: IGraphQL<ISynchronizedApplicationFormsData>) => {
        response.data.getApplicationFormDataWithFullSync.forEach(
          (a: IApplicationForm) => (a.statusMappings = a.statusMappings.reverse())
        );
        return response;
      })
      .catch((error: IExtendedError) => {
        return this.handleResponse(error);
      });
  }

  private getAllSynchronizedApplicationFormData(): Promise<IGraphQL<ISynchronizedApplicationFormsData>> {
    const query = {
      query: getAllSynchronizedApplicationFormData(),
    };
    return this.makeCacheableGraphQlRequest(
      ApplicationFormSCSInitializer.apiConfig().paths.pathToGraphQL,
      query.query,
      'getApplicationFormDataWithFullSync'
    );
  }

  fetchApplicationFormDetailData(applicationFormShortId: string): Promise<IGraphQL<IApplicationFormDetailData>> {
    return this.getApplicationFormDetailData(applicationFormShortId)
      .then((response: any) => {
        const statusMappings = response.data.getApplicationFormDetailData.applicationForm.statusMappings;
        response.data.getApplicationFormDetailData.applicationForm.statusMappings = statusMappings.reverse();
        return response;
      })
      .catch((error: IExtendedError) => {
        return this.handleResponse(error);
      });
  }

  private getApplicationFormDetailData(applicationFormShortId: string): Promise<IGraphQL<IApplicationFormDetailData>> {
    const query = {
      query: getApplicationFormDetailData(applicationFormShortId),
    };
    return this.makeCacheableGraphQlRequest(
      ApplicationFormSCSInitializer.apiConfig().paths.pathToGraphQL,
      query.query,
      'getApplicationFormDetailData'
    );
  }

  deleteApplicationForm(applicationFormId: string): Promise<IGraphQL<IDeleteApplicationFormData>> {
    const query = {
      query: mutationDeleteApplicationForm(applicationFormId),
    };
    return this.postGraphQlRequest(
      ApplicationFormSCSInitializer.apiConfig().paths.pathToGraphQL,
      'deleteApplicationFormMutation',
      query
    );
  }

  fetchFlowStructure(flow: string, excludeDuplicate?: string): Promise<IGraphQL<IFlowStructureData>> {
    return this.getFlowStructure(flow, excludeDuplicate)
      .then(response => {
        //we need to reverse the status mappings, as the UI expects the newest status first
        response.data.getFlowJson.doubleSubmissions.forEach(doubleSubmission =>
          doubleSubmission.statusMappings.reverse()
        );
        return response;
      })
      .catch((error: IExtendedError) => {
        return this.handleResponse(error);
      });
  }

  private getFlowStructure(flow: string, excludeDuplicate?: string): Promise<IGraphQL<IFlowStructureData>> {
    const query = {
      query: queryFlowStructure(flow, excludeDuplicate),
    };
    return this.makeCacheableGraphQlRequest(
      ApplicationFormSCSInitializer.apiConfig().paths.pathToGraphQL,
      query.query,
      'getFlowStructure'
    );
  }

  fetchFlowStructureByTheme(theme: string, applicationFormId: string): Promise<IGraphQL<IFlowStructureByThemeData>> {
    return this.getFlowStructureByTheme(theme, applicationFormId).catch((error: IExtendedError) => {
      return this.handleResponse(error);
    });
  }

  private getFlowStructureByTheme(
    theme: string,
    applicationFormId: string
  ): Promise<IGraphQL<IFlowStructureByThemeData>> {
    const query = {
      query: queryFlowStructureByTheme(theme, applicationFormId),
    };
    return this.makeCacheableGraphQlRequest(
      ApplicationFormSCSInitializer.apiConfig().paths.pathToGraphQL,
      query.query,
      'getFlowStructureByTheme'
    );
  }

  upload(file: File): Promise<IDocument | any> {
    return this.makeUploadDocumentRequest(file).catch((error: IExtendedError) => {
      return this.handleResponse(error);
    });
  }

  private makeUploadDocumentRequest(file: File): Promise<Response> {
    return this._makeRequest(
      `${ApplicationFormSCSInitializer.apiConfig().paths.pathToDocument}/upload`,
      Method.POST,
      file
    );
  }

  downloadWithIds(applicationFormId: string, documentId: string, filename: string) {
    this._makeRequest(
      `${ApplicationFormSCSInitializer.apiConfig().paths.pathToDocument}/${applicationFormId}/${documentId}`,
      Method.GET
    )
      .then((response: Response) => response.blob())
      .then(blob => DOWNLOAD_HELPER.saveAsDataURL(blob, filename));
  }
}

export default new ApplicationFormApi();
