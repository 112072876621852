export const CALENDAR_TEMPLATE_LABELS = {
  yearTitle: 'Bitte wählen Sie ein Jahr',
  yearTitleOneOption: 'Jahr',
  yearErrorMessage: 'Bitte wählen Sie ein Jahr aus, um weiter zu kommen',
  monthTitle: 'Bitte wählen Sie ein Monat',
  monthTitleOneOption: 'Monat',
  monthErrorMessage: 'Bitte wählen Sie ein Monat aus, um weiter zu kommen',
};

export const INFORMATION_LABELS = {
  informationTitle: 'Vor der Änderung',
  documentInformation:
    'Nachdem Sie von uns eine Genehmigung erhalten haben, sind Sie dazu verpflichtet noch weitere Dokumente einzuholen. Ohne diese Dokumente dürfen Sie nicht mit der Montage starten.',
  checkBoxText: 'Ich kenne diese Bedingungen und möchte einen Antrag stellen.',
};

export const SUMMARY_LABELS = {
  title: 'Bitte überprüfen Sie Ihre Angaben',
  editLinkText: 'Bearbeiten',
  requester: 'AntragstellerIn',
  eMailAddress: 'E-Mail-Adresse',
  messageTitle: 'Wollen Sie Ihrem Antrag noch eine Nachricht anfügen?',
  messageLabel: 'Ihre Nachricht',
};
