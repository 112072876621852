export const queryAnnouncements = () => {
  return `query {operationName} {
            getAnnouncements {
              id
              title
              body
              category
              type
              priority
              data
            }
          }`;
};

export const mutationCloseAnnouncement = (id: string) => `
mutation {operationName} {
    closeAnnouncement(id: "${id}")
  }
`;
