import React from 'react';
import { ILaundry, IRentalObject } from '../../commons/api/laundryDay/LaundryDay.interface';
import { format } from '../../commons/utility/DateFunctions';
import { ILaundryInformation } from '../containers/LaundryDayInterface';

class Formatter {
  formatRentalObjectAddress = (rentalObject: any) =>
    `${rentalObject.street} ${rentalObject.houseNumber}/${
      rentalObject.stair.length > 0 ? `${rentalObject.stair}/` : ' Tür '
    }${rentalObject.door}`;

  formatLaundryAddress = (laundry: any) => {
    if (laundry.street === null) {
      return '';
    }
    return `${laundry.street} ${laundry.houseNumber}${laundry.stair.length > 0 ? `/${laundry.stair}` : ''}`;
  };

  formatLaundryNameWithStair = (laundry: any) =>
    `${this.formatLaundryWithNumber(laundry)} \u00B7 Stiege ${laundry ? laundry.stair : ''}`;

  formatLaundryAddressWithLaundryNumber = (laundry: ILaundryInformation) =>
    `${laundry.street} ${laundry.houseNumber}${laundry.stair.length > 0 ? ` / ${laundry.stair}` : ''} / ${
      laundry.laundryNumber
    }`;

  formatLaundryWithNumber = (laundry: any) => `Waschkabine ${laundry ? laundry.laundryNumber : ''}`;

  formatRentalObjectAddressForDropdown = (rentalObject: IRentalObject) => (
    <span>
      <em>{rentalObject.street}</em>{' '}
      {`${rentalObject.houseNumber}/${rentalObject.stair.length > 0 ? `${rentalObject.stair}/` : ''}${
        rentalObject.door
      }`}
    </span>
  );

  formatLaundryAddressForDropdown = (laundry: ILaundry) => (
    <span>
      <em>Waschkabine {laundry.laundryNumber}</em> &#183; Stiege {laundry.stair}
    </span>
  );

  //14.00–18.00
  formatTimeSlot(start: string, end: string): JSX.Element {
    return (
      <>
        <time dateTime={start.replace('.', ':')}>{start}</time>–<time dateTime={end.replace('.', ':')}>{end}</time>
      </>
    );
  }

  //14.00–18.00
  formatTimeSlotFromDates(start: number, end: number, displayFormat: string): JSX.Element {
    return (
      <>
        {this.formatTime(start, displayFormat)}—{this.formatTime(end, displayFormat)}
      </>
    );
  }

  formatTime(date: number, displayFormat: string): JSX.Element {
    return <time dateTime={format(date, 'HH:mm')}>{format(date, displayFormat)}</time>;
  }

  formatDateTime(date: number, displayFormat: string): JSX.Element {
    //eg Mo., 29 August
    return <time dateTime={format(date, 'YYYY-MM-DD')}>{format(date, displayFormat)}</time>;
  }

  formatWeekdayShort(date: number): string {
    return format(date, 'dd.');
  }
}

export const FORMATTER = new Formatter();
