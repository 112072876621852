import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { IApplicationForm, IFlowStructureData } from '../../../commons/api/applicationForm/ApplicationForm.interface';
import { ITenantAndClientFeatures } from '../../../commons/api/tenantData/TenantData.interface';
import TenantDataApi from '../../../commons/api/tenantData/TenantData.api';
import ApplicationFormApi from '../../../commons/api/applicationForm/ApplicationForm.api';
import { useFetch } from '../../../commons/hooks/useFetch';
import {
  ICleanUpProcessData,
  IDecisionCallback,
  IProcessData,
  IStep,
} from '../../decisionTree/interfaces/DecisionTree.interface';
import { IContentData, IDownloadInformation } from '../../decisionTree/interfaces/PossibleContent.interface';
import { RequestWorkflow, WizardMode } from '../../constants/containers/request-theme';
import DecisionTreeLogic from '../../decisionTree/logic/DecisionTreeLogic';
import { piwikEvents } from '../../../commons/utility/piwikEvents';
import { STATUS } from '../../constants/status';
import { ERROR_WIZARD_API_CALLS } from '../../constants/errors';
import { ApplicationFormWizardCalls } from './ApplicationFormWizardCalls';
import { APPLICATION_FORM_BASE_ROUTE } from '../../constants/routes';
import { AFWizardUtility } from '../../utility/AFWizardUtility';
import { WIZARD_LABELS } from '../../constants/containers/wizard-labels';
import { Button, ErrorBox, ErrorPanel, LoadingIndicator, Section } from 'digit.commons.ui-components-app';
import StepContainer from '../../decisionTree/StepContainer';
import './ApplicationFormWizard.scss';
import NavigationHeader from '../../components/NavigationHeader/NavigationHeader';
import { ITemplateHandles } from '../../decisionTree/interfaces/Template.interface';
import { WizardOverlay } from '../../components/WizardOverlay/WizardOverlay';
import { NavigationItems } from '../../../commons/api/Bridge';

interface LocationState {
  closeRoute: string;
  processData: IProcessData;
  formId: string;
  backlinkText?: string;
  backlinkRoute?: string;
  selectedRentalObject?: string;
  selectedServiceCard?: string;
  download?: IDownloadInformation;
  addInformation?: boolean;
  addInformationToStatusMapping?: string;
  latestStatus?: string;
}

interface WizardBaseInformationState {
  /** the loaded json structure */
  flow: any;
  /** the logic for navigating through the json */
  decisionTreeLogic: DecisionTreeLogic;
  editMode: IEditFlow;
}

interface IEditFlow {
  mode: WizardMode;
  lastEditFlow?: string;
}

/** for edit step needed */
interface IPreviousFlow {
  previousFlow: string;
  previousDecisionStep: string;
  previousFormId: string;
  previousStepIndex: number;
  previousFlowLength: number;
}

interface FlowInformationState {
  currentStep: IStep;
  /** stores all decisions that have been made */
  processData: IProcessData;
  /** represents the possible choices considering the previous decisions */
  possibleContent: IContentData;
}

const ApplicationFormWizard: React.FC = () => {
  const {
    proceed,
    send,
    request,
    cancelRequest,
    cancelText,
    saveEdit,
    handInInformation,
    saveDraft,
    requestCloseTitle,
    cancelAddInformationTitle,
    close,
    back,
    hbAnswer,
    hbFinish,
  } = WIZARD_LABELS;
  /* save state */
  const [databaseId, setDatabaseId] = useState<string>();

  /* basic flow states */
  const [baseInformation, setBaseInformation] = useState<WizardBaseInformationState>({
    flow: null,
    decisionTreeLogic: null,
    editMode: {
      mode: WizardMode.NO_EDIT_MODE,
    },
  });
  const [flowInformation, setFlowInformation] = useState<FlowInformationState>({
    currentStep: null,
    processData: { currentStep: [] },
    possibleContent: null,
  });
  /* extracting commonly used variables from them */
  const { decisionTreeLogic, flow, editMode } = baseInformation;
  const { processData, currentStep, possibleContent } = flowInformation;

  /* Load/Save related states */
  const [isLoadingFlowStructure, setLoadingFlowStructure] = useState(true);
  const [isProcessing, setProcessing] = useState<boolean>(false);
  const [saveError, setSaveError] = useState<string>('');
  const [loadError, setLoadError] = useState<boolean>(false);

  /* double submission states */
  const [doubleSubmissions, setDoubleSubmissions] = useState<IApplicationForm[]>();
  const [doubleSubmission, setDoubleSubmission] = useState<IApplicationForm>();

  /* needed for switching between flows, resuming drafts or opening up sub-flows (to change something on the summary page) */
  const [keepDecisionsUpToTask, setKeepDecisionsUpToTask] = useState<string>('');
  const [preEditFlow, setPreEditFlow] = useState<IPreviousFlow>(null);
  const [previousProcessData, setPreviousProcessData] = useState<IProcessData>();

  /* for deletion if a double submission is a draft*/
  const [applicationFormIdToDelete, setApplicationFormIdToDelete] = useState<string>();
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  /* Overlay related states */
  const [cancel, setCancel] = useState<boolean>(false);
  const [saveDraftDone, setSaveDraftDone] = useState(false);

  const templateRef = useRef<ITemplateHandles>(null);

  const params = useParams<{ wizard: string }>();
  const location = useLocation<LocationState>();
  const history = useHistory();

  const { data, isFetchInProgress, fetchErrors } = useFetch<IFlowStructureData & ITenantAndClientFeatures>(
    {
      [ApplicationFormApi.orig]: () =>
        ApplicationFormApi.fetchFlowStructure(params.wizard, location.state && location.state.formId),
      [TenantDataApi.orig]: () => TenantDataApi.fetchTenantAndClientFeatures(),
    },
    NavigationItems.NAVIGATION_APPLICATION_FORM_WIZARD
  );

  useEffect(() => {
    if (!fetchErrors.isNullOrEmpty()) {
      if (fetchErrors.some(error => error.orig === 'ApplicationFormApi')) {
        piwikEvents.trackEvent(
          'technischer Fehler',
          'Anträge und Dokumente',
          'Fehler beim Laden des angeforderten Flows'
        );
        setLoadError(true);
        return;
      } else {
        piwikEvents.trackEvent(
          'technischer Fehler',
          'Anträge und Dokumente',
          'Fehler beim Laden der Mieter Kontaktdaten'
        );
      }
    }
    if (!isFetchInProgress) {
      prepareInitialData();
    }
  }, [isFetchInProgress]);

  const prepareInitialData = () => {
    if (location && location.state && location.state.formId) {
      setDatabaseId(location.state.formId);
    }
    const requestedFlowStructure = data.getFlowJson;
    setDoubleSubmissions(requestedFlowStructure.doubleSubmissions);
    const requestedFlow = JSON.parse(requestedFlowStructure.json).data;
    setBaseInformation({
      flow: requestedFlow,
      decisionTreeLogic: new DecisionTreeLogic(requestedFlow),
      editMode: {
        mode: location.state?.addInformation ? WizardMode.ADD_INFORMATION : WizardMode.NO_EDIT_MODE,
      },
    });
    setLoadingFlowStructure(false);
  };

  /* second useEffect comes into force once the form has changed */
  useEffect(
    () => {
      /* we make sure that the form is really loaded - otherwise it would be triggered when useState is initialized as well */
      if (flow) {
        let initialProcessData: IProcessData = null;
        let initialPossibleContent: IContentData = null;
        let tenantData = {};

        if (location && location.state) {
          if (location.state.selectedRentalObject) {
            initialProcessData = { currentStep: [], rentalObject: [location.state.selectedRentalObject] };
          }
          if (location.state.selectedServiceCard) {
            initialProcessData = {
              currentStep: [],
              ...initialProcessData,
              servicecard: [location.state.selectedServiceCard],
            };
          }
        }

        if (flow.workflow === RequestWorkflow.DOCUMENT) {
          // for non-construction forms we'll fetch the tenant's details from the get-go
          tenantData = data.tenant;
        }
        // in case we proceed a saved draft, we want to use the stored process data
        const proceedDraft = location && location.state && !!location.state.processData;
        if (proceedDraft) {
          initialProcessData = { ...location.state.processData };
          if (location?.state?.addInformation) {
            initialProcessData = { ...initialProcessData, currentStep: [] };
          }
        }
        if (editMode.mode === WizardMode.EDIT_MODE_ENTER || editMode.mode === WizardMode.EDIT_DRAFT) {
          initialProcessData = processData;
        }
        // in case a workflow links to another and we want to keep the decisions made up to a certain task, it will be set here
        else if (keepDecisionsUpToTask && processData.currentStep.length > 0 && !location?.state?.addInformation) {
          initialProcessData = decisionTreeLogic.cleanUpProcessData(
            processData,
            keepDecisionsUpToTask,
            initialPossibleContent
          );
        }
        const result = decisionTreeLogic.getStepForInitialization(
          initialProcessData,
          initialPossibleContent,
          editMode.mode
        );
        // in case a draft is opened, we update the flow, otherwise we set the initial flow data
        if (proceedDraft) {
          updateFlow(result.processData, result.step);
        } else {
          setFlowInformation({
            currentStep: result.step,
            processData: { ...result.processData, ...tenantData },
            possibleContent: result.possibleContent,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [baseInformation]
  );

  useEffect(() => {
    setDoubleSubmission(null);
    if (currentStep) {
      /* we always check whether double submissions apply to the next step unless we are the very end or isDoubleSubmission is in the possible content immediately, i.e., at the very first step */
      if (
        currentStep.index === decisionTreeLogic.getStepLength() - 1 ||
        (possibleContent[currentStep.id] && possibleContent[currentStep.id].isDoubleSubmission)
      ) {
        setDoubleSubmission(AFWizardUtility.getDoubleSubmission(possibleContent, currentStep, doubleSubmissions));
      } else {
        const nextStep = decisionTreeLogic.getStepByIndex(currentStep.index + 1);
        if (nextStep && possibleContent[nextStep.id] && possibleContent[nextStep.id].isDoubleSubmission) {
          setDoubleSubmission(AFWizardUtility.getDoubleSubmission(possibleContent, nextStep, doubleSubmissions));
        }
      }
    }
  }, [processData, currentStep, decisionTreeLogic, possibleContent, doubleSubmissions]);

  const stepIndex = preEditFlow ? preEditFlow.previousStepIndex : currentStep && currentStep.index;

  const updateFlow = (processData: IProcessData, currentStep: IStep, cleanUp?: ICleanUpProcessData) => {
    let updatedPossibleContent = decisionTreeLogic.getAllPossibleMatchingContent(processData);
    let updatedProcessData: IProcessData = processData;
    /* cleaning up is a temporary fix for construction flows to remove all future decisions when the user went back and changed something */
    if (
      editMode.mode === WizardMode.NO_EDIT_MODE &&
      Object.keys(processData).length > 1 &&
      decisionTreeLogic.getWorkflow() === RequestWorkflow.CONSTRUCTION &&
      cleanUp &&
      cleanUp.cleanUp &&
      cleanUp.currentStepId
    ) {
      updatedProcessData = decisionTreeLogic.cleanUpProcessData(
        decisionTreeLogic.removeImpossibleProcessData(cleanUp.currentStepId, processData, updatedPossibleContent),
        cleanUp.currentStepId,
        updatedPossibleContent
      );
      updatedPossibleContent = decisionTreeLogic.getAllPossibleMatchingContent(updatedProcessData);
    }
    setFlowInformation({
      currentStep: currentStep,
      processData: updatedProcessData,
      possibleContent: updatedPossibleContent,
    });
  };

  const onBackHandler = () => {
    if (isConfirmationStep) {
      const backlinkRoute = location?.state?.backlinkRoute ?? APPLICATION_FORM_BASE_ROUTE;
      history.push({ pathname: backlinkRoute, state: { toRequestForms: true } });
      return;
    }
    if (stepIndex > 0) {
      try {
        if (doubleSubmission && editMode.mode === WizardMode.EDIT_MODE_LEFT) {
          switchFlows(editMode.lastEditFlow, currentStep.id, WizardMode.EDIT_MODE_ENTER);
        } else if (editMode.mode === WizardMode.EDIT_MODE_ENTER) {
          setFlowInformation(prevState => ({ ...prevState, processData: previousProcessData }));
          switchFlows(
            preEditFlow.previousFlow,
            preEditFlow.previousDecisionStep,
            WizardMode.EDIT_MODE_LEFT,
            preEditFlow.previousFormId
          );
        } else {
          const result = decisionTreeLogic.getPreviousStep(currentStep.index, possibleContent, processData);
          updateFlow(result.processData, result.step);
        }
        window.scrollTo(0, 0);
      } catch (e) {
        onCancelHandler();
      }
    } else {
      onCancelHandler();
    }
  };

  const onCancelHandler = () => {
    setCancel(true);
  };

  const onDismissHandler = () => {
    const closeRoute = location?.state?.closeRoute;
    history.push(closeRoute ? closeRoute : { pathname: APPLICATION_FORM_BASE_ROUTE, state: { toRequestForms: true } });
  };

  const onResumeHandler = () => {
    setSaveDraftDone(false);
    setCancel(false);
  };

  const onNextHandler = async () => {
    if (AFWizardUtility.isCloseStep(possibleContent, currentStep)) {
      onDismissHandler();
      return;
    }
    if (!templateRef.current.validateTemplate()) {
      return;
    }
    if (editMode.mode === WizardMode.EDIT_MODE_ENTER) {
      await switchFlows(
        preEditFlow.previousFlow,
        preEditFlow.previousDecisionStep,
        WizardMode.EDIT_MODE_LEFT,
        preEditFlow.previousFormId
      );
    } else {
      const result = decisionTreeLogic.getNextStep(processData, possibleContent, currentStep.index);
      let hasError = false;
      /* we send the data to the backend if the last step is finished */
      if (currentStep.isSubmitStep) {
        setProcessing(true);
        try {
          // default status when saving a form
          let status = STATUS.submitted.apiStatus;
          // in case the last status was an open answer from a HB (status = 83), we set the status to HB_INFO_RESOLVED
          if (location.state?.latestStatus === STATUS.hb_info_pending.apiStatus) {
            status = STATUS.hb_info_resolved.apiStatus;
          } else if (location.state?.latestStatus === STATUS.hb_finish_pending.apiStatus) {
            status = STATUS.hb_finish_resolved.apiStatus;
          }
          // when we are adding information (status = 80), we set the status to TENANT_INFO_RESOLVED
          else if (editMode.mode === WizardMode.ADD_INFORMATION) {
            status = STATUS.tenant_info_resolved.apiStatus;
          }
          // documents always get the REQUESTED status
          else if (decisionTreeLogic.getWorkflow() === RequestWorkflow.DOCUMENT) {
            status = STATUS.requested.apiStatus;
          }
          await save(status, result.processData);
        } catch (e) {
          hasError = true;
          setSaveError(ERROR_WIZARD_API_CALLS.serviceUnavailable.submitErrorText);
        }
        setProcessing(false);
      }
      if (!hasError) {
        /* if there was no error message we proceed */
        updateFlow(result.processData, result.step);
      } else {
        /* if there was an error, then remove the last step from the currentStep list
         * otherwise resuming a saved draft will continue on the last respectively the confirmation step
         */
        let processDataCopy: IProcessData = Object.assign({}, processData);
        processDataCopy = {
          currentStep: processDataCopy.currentStep.pop(),
          ...processDataCopy,
        };
        updateFlow(processDataCopy, currentStep);
      }
    }
    window.scrollTo(0, 0);
  };

  /**
   * switches from the current to a given flow (including the base URL) and keeps the decisions, which were made, up to a certain task
   *
   * @param newFlow url of the flow (can include the base URL)
   * @param keepDecisionsUpToTask (the name of the task up to which the decisions should be kept
   * @param mode whether this is just a subflow to edit information
   * @param excludeFormId when resuming a draft, this form id will be excluded from duplicates
   * @param draftProcessData in case a draft is resumed we will overwrite the current process data with the ones from the draft
   */
  const switchFlows = async (
    newFlow: string,
    keepDecisionsUpToTask: string,
    mode: WizardMode,
    excludeFormId?: string,
    draftProcessData?: IProcessData
  ) => {
    /* in case of double submissions the process data will be passed and overwritten */
    if (draftProcessData) {
      setFlowInformation(prevState => ({ ...prevState, processData: draftProcessData }));
      /* if there are process data to be loaded then we also have to set the database id, otherwise the draft will not be proceeded, but a new form with the same data */
      setDatabaseId(excludeFormId);
    } else {
      setKeepDecisionsUpToTask(keepDecisionsUpToTask);
    }
    history.replace({ pathname: newFlow });
    newFlow = newFlow.replace(`${APPLICATION_FORM_BASE_ROUTE}/`, '');
    const requestedFlowStructure = await ApplicationFormWizardCalls.getFlowStructure(newFlow, excludeFormId);
    setDoubleSubmissions(requestedFlowStructure.doubleSubmissions);
    const requestedFlow = JSON.parse(requestedFlowStructure.json).data;
    setPreEditFlow(
      mode === WizardMode.EDIT_MODE_ENTER
        ? {
            previousFlow: params.wizard,
            previousDecisionStep: keepDecisionsUpToTask,
            previousFormId: location.state && location.state.formId,
            previousFlowLength: decisionTreeLogic.getStepLength(),
            previousStepIndex: currentStep.index,
          }
        : null
    );
    setPreviousProcessData(processData);
    setBaseInformation({
      flow: requestedFlow,
      decisionTreeLogic: new DecisionTreeLogic(requestedFlow),
      editMode: { mode: mode, lastEditFlow: mode === WizardMode.EDIT_MODE_ENTER ? newFlow : editMode.lastEditFlow },
    });
  };

  const save = async (status: string, processDataToSave?: IProcessData) => {
    if (doubleSubmission) return;

    if (!processDataToSave) {
      processDataToSave = processData;
    }

    const savedUUID = await ApplicationFormWizardCalls.save(
      status,
      processDataToSave,
      decisionTreeLogic,
      processData,
      databaseId,
      possibleContent,
      location.state?.addInformationToStatusMapping
    );
    setDatabaseId(savedUUID);
  };

  const onSaveDraftButton = async () => {
    try {
      await save(STATUS.draft.apiStatus);
    } catch (error) {
      setSaveError(ERROR_WIZARD_API_CALLS.serviceUnavailable.saveDraftErrorText);
    } finally {
      setSaveDraftDone(true);
    }
  };

  const decisionCallback = (
    currentStepId: string,
    decisions: IDecisionCallback[],
    deleteEmptyDecision: boolean = false
  ) => {
    let processDataStateCopy = Object.assign({}, processData);
    decisions.forEach(decision => {
      if (decision.key.includes('.')) {
        if (
          deleteEmptyDecision &&
          (decision.value.length === 0 || (decision.value.length === 1 && decision.value[0] === ''))
        ) {
          AFWizardUtility.deleteFromProcessDataByPath(processDataStateCopy, decision.key);
        } else {
          AFWizardUtility.assignValueToLatestKeyOfNestedProcessDataEntry(decision, processDataStateCopy);
        }
      } else processDataStateCopy[decision.key] = decision.value;
    });
    updateFlow(processDataStateCopy, currentStep, { cleanUp: true, currentStepId });
  };

  const getNextButtonText = () => {
    if (loadError || saveError) return;
    if (editMode.mode === WizardMode.EDIT_MODE_ENTER) {
      return saveEdit;
    } else if (AFWizardUtility.isCloseStep(possibleContent, currentStep)) {
      return cancelRequest;
    } else if (currentStep.isSubmitStep && location.state?.latestStatus === STATUS.hb_info_pending.apiStatus) {
      return hbAnswer;
    } else if (currentStep.isSubmitStep && location.state?.latestStatus === STATUS.hb_finish_pending.apiStatus) {
      return hbFinish;
    } else if (currentStep.isSubmitStep && editMode.mode === WizardMode.ADD_INFORMATION) {
      return handInInformation;
    } else if (currentStep.isSubmitStep && decisionTreeLogic.getWorkflow() === RequestWorkflow.DOCUMENT) {
      return request;
    } else if (currentStep.isSubmitStep) {
      return send;
    }
    return proceed;
  };
  const deleteDraft = async (applicationFormId?: string) => {
    // when deleteDraft is called from DoubleSubmissionTemplate we just render the Overlay
    if (!showDeleteDialog && applicationFormId) {
      setShowDeleteDialog(true);
      setApplicationFormIdToDelete(applicationFormId);
      // when the overlay is opened and an applicationFormIdToDelete is set, we perform the actual delete and reload
    } else if (showDeleteDialog && applicationFormIdToDelete) {
      try {
        await ApplicationFormApi.deleteApplicationForm(applicationFormIdToDelete);
        const processDataWithoutStepArray = {
          ...processData,
          currentStep: [''],
        };
        setFlowInformation(prevState => ({ ...prevState, processData: processDataWithoutStepArray }));
        // for seamless reloading we request a new (but same) flow structure after the deletion
        await switchFlows(
          history.location.pathname.split('/')[2],
          processData.currentStep[processData.currentStep.length - 1],
          WizardMode.DELETED_DRAFT,
          applicationFormIdToDelete
        );
      } catch (e) {
        setLoadError(true);
      } finally {
        setShowDeleteDialog(false);
      }
    } else {
      // otherwise we will make sure the dialog doesn't show up
      setShowDeleteDialog(false);
    }
  };

  const getTextForRepresentation = (stepId: string) => {
    if (currentStep.glossary[stepId]) {
      return decisionTreeLogic.getGlossaryText(currentStep, stepId, processData);
    }
    return processData[stepId];
  };

  const canSave =
    (currentStep && currentStep.canSave) ||
    (possibleContent && possibleContent[currentStep.id] && possibleContent[currentStep.id].canSave);

  const showButtons = (): boolean => {
    if (!currentStep) {
      return false;
    }
    if (currentStep.id === 'confirmation') {
      return possibleContent && possibleContent[currentStep.id] && possibleContent[currentStep.id].isDoubleSubmission;
    }
    return !currentStep.props.backLink;
  };

  const isCloseStep = AFWizardUtility.isCloseStep(possibleContent, currentStep);

  const isConfirmationStep = currentStep && currentStep.id === 'confirmation';

  const renderWizardBody = () => {
    if (loadError) {
      return (
        <ErrorPanel
          id={'wizard-error'}
          title={ERROR_WIZARD_API_CALLS.serviceUnavailable.title}
          errorText={ERROR_WIZARD_API_CALLS.serviceUnavailable.loadErrorText}
          descriptionText={ERROR_WIZARD_API_CALLS.serviceUnavailable.description}
        />
      );
    }
    return (
      <>
        {saveError && <ErrorBox id={'wizard-error'} description={saveError} />}
        <Section
          id={'application-form-wizard'}
          title={AFWizardUtility.getTitle(
            possibleContent,
            processData,
            decisionTreeLogic,
            currentStep,
            doubleSubmission
          )}
          srOnly={currentStep.id === 'confirmation'}
        >
          <StepContainer
            step={currentStep}
            processData={processData}
            possibleContent={possibleContent}
            decisionCallback={decisionCallback}
            theme={decisionTreeLogic && decisionTreeLogic.getTheme()}
            getTextForRepresentation={getTextForRepresentation}
            doubleSubmission={doubleSubmission}
            doubleSubmissionText={decisionTreeLogic && decisionTreeLogic.getDoubleSubmissionText()}
            switchFlows={switchFlows}
            deleteDraft={deleteDraft}
            download={location?.state?.download}
            addInformationToStatusMappingId={location?.state?.addInformationToStatusMapping}
            onDismissHandler={onDismissHandler}
            ref={templateRef}
          />
          {showButtons() && (
            <>
              <Button
                id={'application-form-wizard-forward'}
                className={'ApplicationFormWizard__button'}
                onClick={onNextHandler}
                disabled={isLoadingFlowStructure}
                loading={isProcessing}
              >
                {getNextButtonText()}
              </Button>
              {canSave && !isCloseStep && (
                <Button
                  id={'application-form-wizard-save-draft'}
                  className={'ApplicationFormWizard__button'}
                  onClick={onSaveDraftButton}
                  modifier={'secondary'}
                >
                  {saveDraft}
                </Button>
              )}
            </>
          )}
        </Section>
      </>
    );
  };

  return (
    <>
      <NavigationHeader
        backHandler={onBackHandler}
        backText={back}
        abortHandler={isCloseStep || isConfirmationStep ? onDismissHandler : onCancelHandler}
        abortText={isCloseStep || isConfirmationStep ? close : cancelText}
        title={
          flow
            ? AFWizardUtility.getNavigationTitle(flow.workflow, editMode.mode, location?.state?.latestStatus)
            : 'wird geladen ...'
        }
      />
      <div className={'ApplicationFormWizard__body'}>
        <WizardOverlay
          requestCloseTitle={
            editMode.mode && editMode.mode === WizardMode.ADD_INFORMATION
              ? cancelAddInformationTitle
              : requestCloseTitle
          }
          closeText={close}
          cancel={cancel}
          canSave={canSave}
          isCloseStep={isCloseStep}
          saveDraftText={saveDraft}
          saveDraftDone={saveDraftDone}
          setSaveDraftDone={setSaveDraftDone}
          onSaveDraftButton={onSaveDraftButton}
          showDeleteDraftDialog={showDeleteDialog}
          setShowDeleteDraftDialog={setShowDeleteDialog}
          onDeleteDraftButton={deleteDraft}
          onDismissHandler={onDismissHandler}
          resumeHandler={onResumeHandler}
          mode={editMode.mode}
          workflow={decisionTreeLogic?.getWorkflow()}
        />
        {(decisionTreeLogic && currentStep) || loadError || saveError ? (
          renderWizardBody()
        ) : (
          <LoadingIndicator id={'application-form-wizard'} />
        )}
      </div>
    </>
  );
};

export default ApplicationFormWizard;
