export const LANDING_LABELS = {
  title: 'Startseite',
  pageTitle: 'Wiener Wohnen Mieter*innenportal',
  meta: 'Startseite - Ihr persönliches Portal bei Wiener Wohnen',
  welcome: 'Willkommen',
  tiles: {
    bezirksnews: {
      title: 'Bezirksnews',
      text: ' Immer aktuell - die Wiener Wohnen Bezirksnews!',
      link: 'Jetzt anschauen!',
      href: 'https://www.wienerwohnen.at/bezirksnews',
    },
    rent: {
      title: 'Mietzinskonto',
      text: 'Alle Informationen zu Ihrem Mietzinskonto für Ihre Mietobjekte auf einen Blick.',
      link: 'Mietzinskonto ansehen',
    },
    laundry: {
      title: 'Waschtage',
      link: 'Jetzt Waschtage reservieren',
      serviceNotAvailable: 'Service im Moment nicht erreichbar - bitte probieren Sie es später noch mal.',
    },
    serviceCard: {
      title: 'Service-Karte',
      link: 'Infos und Partner anzeigen',
    },
    applicationForms: {
      title: 'Anträge und Dokumente',
      link: 'Jetzt Anträge stellen und ansehen',
    },
    complexInformation: {
      title: 'Hof-Informationen',
      text: 'Hier finden Sie alle Informationen und Dokumente rund um Ihren Hof.',
      link: 'Hof-Informationen ansehen',
    },
  },
};
