export const queryContent = (category: string, history: boolean) => {
  return `query {operationName} {
            content(category: "${category}", history: ${history}) {
              json
              publishDate
              tags
            }
          }`;
};

export const mutationAcknowledgeContent = (category: string) => `
mutation {operationName} {
    ackContent(category: "${category}")
  }
`;
