import React from 'react';
import { Card, CardBody, CardFooter, CardHeader, Hyperlink, SVG } from 'digit.commons.ui-components-app';
import { LAUNDRY_DAY_LANDING } from '../../constants/containers/laundry-day-landing';
import { SUMMARY_ANNOUNCEMENT } from '../../constants/general';
import { LAUNDRY_DAY_BASE_ROUTE } from '../../constants/routes';
import './Announcement.scss';

interface YouAreBlockedAnnouncementProps {
  id: string;
}

const YouAreBlockedSummaryAnnouncement: React.FC<YouAreBlockedAnnouncementProps> = props => {
  const { id } = props;
  const { lockedInfo, badge } = LAUNDRY_DAY_LANDING.locked;
  const { title, linkBookingText } = SUMMARY_ANNOUNCEMENT;

  return (
    <Card id={id} className="Announcement" badge={badge} badgeType={'red'}>
      <CardHeader title={title} icon={SVG.laundry_caution} />
      <CardBody>
        <em className="Announcement__info--locked">{lockedInfo}</em>
      </CardBody>
      <CardFooter>
        <Hyperlink id={id} to={LAUNDRY_DAY_BASE_ROUTE} iconAfter={SVG.arrow_right}>
          {linkBookingText}
        </Hyperlink>
      </CardFooter>
    </Card>
  );
};

export default YouAreBlockedSummaryAnnouncement;
