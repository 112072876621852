import React, { useContext, useEffect, useRef, useState } from 'react';
import './Landing.scss';
import { Helmet } from 'react-helmet';
import { piwikEvents } from '../../../commons/utility/piwikEvents';
import { LANDING, LAUNDRY_DAY, SERVICE_CARD } from '../../constants/routes';
import { LANDING_LABELS } from '../../constants/containers/landing-labels';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Hyperlink,
  LoadingIndicator,
  Section,
  SVG,
} from 'digit.commons.ui-components-app';
import LocalContactCard from '../../components/LocalContactCard/LocalContactCard';
import RentalAccountTeaserCard from '../../components/RentalAccountTeaserCard/RentalAccountTeaserCard';
import TenantDataApi from '../../../commons/api/tenantData/TenantData.api';
import { NavigationItems } from '../../../commons/api/Bridge';
import { IAnnouncement, IAnnouncements } from '../../../commons/api/announcement/Announcement.interface';
import { DefaultAnnouncementComponent } from '../../components/Announcement/DefaultAnnouncementComponent';
import { RentalAccountAnnouncement } from '../../components/RentalAccountAnnouncement/RentalAccountAnnouncement';
import { FORMATTER } from '../../utility/formatHandler';
import YouAreBlockedSummaryAnnouncement from '../../../laundryday/components/Announcement/YouAreBlockedSummaryAnnouncement';
import DefectSummaryAnnouncement from '../../../laundryday/components/Announcement/DefectSummaryAnnouncement';
import SummaryReminderAnnouncement from '../../../laundryday/components/Announcement/SummaryReminderAnnouncement';
import { WhatsNewAnnouncement } from '../../components/Announcement/WhatsNewAnnouncement';
import { ContentCategory, ContentTag, IContent, IContentNode } from '../../../commons/api/content/Content.interface';
import ContentApi from '../../../commons/api/content/Content.api';
import { WHATS_NEW_ANNOUNCEMENT_MATOMO_LABELS } from '../../constants/containers/whats-new-labels';
import { IFeature } from '../../components/Announcement/WhatsNewAnnouncement.interface';
import { CovidSupportLandingAnnouncement } from '../../components/RentalAccountAnnouncement/CovidSupportLandingAnnouncement';
import { ITenancyContracts, ITenantNameAndClientFeatures } from '../../../commons/api/tenantData/TenantData.interface';
import { useFetch } from '../../../commons/hooks/useFetch';
import LaundryDayAnnouncementApi from '../../../commons/api/announcement/LaundryDayAnnouncement.api';
import RentalAnnouncementApi from '../../../commons/api/announcement/RentalAnnouncement.api';
import BuildingComplexApi from '../../../commons/api/buildingComplex/BuildingComplex.api';
import {
  IIsPahoTenant,
  ITenantBasedBuildingComplexDetailsAndContacts,
} from '../../../commons/api/buildingComplex/BuildingComplex.interface';
import { InBoxAnnouncement } from '../../components/Announcement/InBoxAnnouncement';
import { APPLICATION_FORM_BASE_ROUTE } from '../../../applicationform/constants/routes';
import BuildingComplexAnnouncementApi from '../../../commons/api/announcement/BuildingComplexAnnouncement.api';
import { PipeAnnouncement } from '../../components/Announcement/PipeAnnouncement';
import ApplicationFormApi from '../../../commons/api/applicationForm/ApplicationForm.api';
import { TenantDataContext } from '../../context/TenantDataContext';
import ApplicationFormAnnouncementApi from '../../../commons/api/announcement/ApplicationFormAnnouncementApi';
import FormAnnouncement from '../../../applicationform/components/Announcements/FormAnnouncement';
import { Configuration } from '../../../Configuration';
import { getAppVersion } from '../../../commons/utility/util';
import MinVersionAnnouncement from '../../components/Announcement/MinVersionAnnouncement';

const Landing: React.FC = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const contactData = useRef<ITenantBasedBuildingComplexDetailsAndContacts[]>([]);
  const [announcements, setAnnouncements] = useState<IAnnouncement[]>(null);
  const whatsNew = useRef<IContentNode<IFeature>[]>(null);
  const [isWhatsNewAck, setWhatsNewAck] = useState<boolean>();
  const [hbFormSyncDone, setHbFormSyncDone] = useState<boolean>(false);
  const { tenant } = useContext(TenantDataContext);

  const { data, isFetchInProgress, fetchErrors } = useFetch<
    ITenantNameAndClientFeatures & IAnnouncements & IContent<IFeature> & ITenancyContracts & IIsPahoTenant
  >(
    {
      LaundryDayAnnouncementApi: () => LaundryDayAnnouncementApi.fetchAnnouncements(),
      RentalAnnouncementApi: () => RentalAnnouncementApi.fetchAnnouncements(),
      ContentApi: () => ContentApi.fetchContent(ContentCategory.whatsNewFeature),
      [TenantDataApi.orig + 'fetchTenantContracts']: () => TenantDataApi.fetchTenantContracts(),
      [BuildingComplexApi.orig + 'fetchIsPahoTenant']: () => BuildingComplexApi.fetchIsPahoTenant(),
      [BuildingComplexAnnouncementApi.orig]: () => BuildingComplexAnnouncementApi.fetchAnnouncements(),
      [ApplicationFormAnnouncementApi.orig]:
        tenant && tenant.details.hb
          ? () => ApplicationFormApi.fetchAllSynchronizedApplicationFormData()
          : () => ApplicationFormAnnouncementApi.fetchAnnouncements(),
    },
    NavigationItems.NAVIGATION_START
  );

  useEffect(() => {
    if (!fetchErrors.isNullOrEmpty()) {
      sendMatomoEventsBasedOnErrors();
    }
    if (!isFetchInProgress) {
      const loadDetailsBasedOnContract = async () => {
        if (data.tenancyContracts !== undefined) {
          contactData.current = await BuildingComplexApi.fetchBuildingComplexAndContactsForTenancyContract([
            // just use the first contract as we only display one contact in the landing
            data.tenancyContracts[0],
          ]);
        }
      };

      loadDetailsBasedOnContract().then(() => {
        if (!tenant?.details?.hb) {
          whatsNew.current = filterAndSortWhatsNewContent(data.contentNodes);
          setAnnouncements(
            data.announcements === undefined ? [] : data.announcements.sort((a, b) => a.priority - b.priority)
          );
        } else {
          setHbFormSyncDone(true);
        }
      });
    }
  }, [isFetchInProgress]);

  useEffect(() => {
    if (hbFormSyncDone) {
      /* fetch potentially synced announcements and concat them with the others - in case of an exception we just use the announcements of the other services */
      const fetchSyncedAnnouncements = async () => {
        try {
          const applicationFormAnnouncements = await ApplicationFormAnnouncementApi.fetchAnnouncements();
          if (data.announcements || applicationFormAnnouncements.data.announcements) {
            const aggregatedAnnouncements = data.announcements.concat(applicationFormAnnouncements.data.announcements);
            setAnnouncements(sortAnnouncements(aggregatedAnnouncements));
          } else {
            setAnnouncements([]);
          }
        } catch (e) {
          piwikEvents.trackEvent('technischer Fehler', 'Startseite', 'Fehler beim Laden der Formular-Announcements');
          setAnnouncements(sortAnnouncements(data.announcements));
        }
      };
      fetchSyncedAnnouncements();
    }
  }, [hbFormSyncDone]);

  const sortAnnouncements = (announcements: IAnnouncement[]): IAnnouncement[] =>
    announcements ? [...announcements].sort((a, b) => a.priority - b.priority) : [];

  useEffect(() => {
    if (announcements) {
      setLoading(false);
    }
  }, [announcements]);

  const sendMatomoEventsBasedOnErrors = () => {
    fetchErrors.some(error => error.contextInfo === 'fetchTenantNameAndFeatures') &&
      piwikEvents.trackEvent('technischer Fehler', 'Startseite', 'Fehler beim Laden des Namens des Mieters');
    fetchErrors.some(error => error.orig === LaundryDayAnnouncementApi.orig) &&
      piwikEvents.trackEvent('technischer Fehler', 'Startseite', 'Fehler beim Laden der Waschtage-Announcements');
    fetchErrors.some(error => error.orig === RentalAnnouncementApi.orig) &&
      piwikEvents.trackEvent('technischer Fehler', 'Startseite', 'Fehler beim Laden der Mietzinskonto-Announcements');
    fetchErrors.some(error => error.orig === ContentApi.orig) &&
      piwikEvents.trackEvent(
        WHATS_NEW_ANNOUNCEMENT_MATOMO_LABELS.category,
        WHATS_NEW_ANNOUNCEMENT_MATOMO_LABELS.action,
        WHATS_NEW_ANNOUNCEMENT_MATOMO_LABELS.fetchWhatsNewContent
      );
    fetchErrors.some(error => error.orig === 'ApplicationFormAnnouncementApi') &&
      piwikEvents.trackEvent('technischer Fehler', 'Startseite', 'Fehler beim Laden der Formular-Announcements');
    fetchErrors.some(error => error.contextInfo === 'fetchTenantContracts') &&
      piwikEvents.trackEvent('technischer Fehler', 'Startseite', 'Fehler beim Laden der Mietverträge');
  };

  const filterAndSortWhatsNewContent = (contentNodes: IContentNode<IFeature>[]): IContentNode<IFeature>[] => {
    const filteredContent = contentNodes?.filter(
      content =>
        content.tags.length === 0 ||
        (content.tags.length === 1 && content.tags.includes(ContentTag.topFeature)) ||
        (content.tags.length >= 1 && content.tags.includes(ContentTag.mobileOnly))
    );
    if (!filteredContent) {
      return null;
    }
    // top features should be on top
    return [
      ...filteredContent.filter(content => content.tags.includes(ContentTag.topFeature)),
      ...filteredContent.filter(content => !content.tags.includes(ContentTag.topFeature)),
    ];
  };

  const acknowledgeWhatNewFeatures = async () => {
    try {
      setWhatsNewAck(true); // announcement should disappear immediately, even if the call was not successful
      window.scrollTo(0, 0);
      await ContentApi.acknowledgeContent(ContentCategory.whatsNewFeature);
    } catch (error) {
      piwikEvents.trackEvent(
        WHATS_NEW_ANNOUNCEMENT_MATOMO_LABELS.category,
        WHATS_NEW_ANNOUNCEMENT_MATOMO_LABELS.action,
        WHATS_NEW_ANNOUNCEMENT_MATOMO_LABELS.acknowledgeWhatsNewContent
      );
    }
  };

  const getGreeting = () => {
    if (
      !fetchErrors.find(error => error.orig === 'TenantApi') &&
      tenant?.details &&
      (tenant.details.firstName || tenant.details.lastName)
    ) {
      return (
        <>
          <span className="Landing__heading--welcome">{LANDING_LABELS.welcome}</span>
          <span className="Landing__heading--name">
            {FORMATTER.firstNameLastNameLabel(tenant.details.firstName, tenant.details.lastName)}
          </span>
        </>
      );
    } else {
      return <span className="Landing__heading--name">{LANDING_LABELS.welcome}</span>;
    }
  };

  const announcementsToRender = (): Array<IAnnouncement> => {
    let toRender = announcements ? [...announcements] : [];
    if (toRender.find(announcement => announcement.type === 'BLOCKED_USER')) {
      //remove any other laundry announcement
      toRender = toRender.filter(
        a => a.category !== 'LAUNDRY' || (a.category === 'LAUNDRY' && a.type === 'BLOCKED_USER')
      );
    }
    return toRender;
  };

  const renderAnnouncements = (toRender: Array<IAnnouncement>) => {
    function removeAnnouncement(announcement: IAnnouncement) {
      const newAnnouncements = announcements.filter(a => a.id !== announcement.id);
      setAnnouncements(newAnnouncements);
    }

    return (
      <>
        {toRender.map((announcement, idx) => {
          let id;
          switch (announcement.type) {
            case 'BLOCKED_USER':
              return <YouAreBlockedSummaryAnnouncement id={'laundry-locked-announcement'} />;
            // @ts-ignore - intentional fall-through switch
            case 'DEFECT_WASHER':
              id = 'laundry-defect-washer';
            // @ts-ignore - intentional fall-through switch
            case 'DEFECT_DRYER':
              id = id || 'laundry-defect-dryer';
            // falls through
            case 'DEFECT_BOTH':
              id = id || 'laundry-defect-both';
              return (
                <DefectSummaryAnnouncement
                  id={`${id}-defect-announcement-${announcement.id}`}
                  key={`defect-announcement-${announcement.id}`}
                  announcement={announcement}
                />
              );
            // @ts-ignore - intentional fall-through switch
            case 'NOW':
              id = 'laundry-now';
            // @ts-ignore - intentional fall-through switch
            case 'TODAY':
              id = id || 'laundry-today';
            // @ts-ignore - intentional fall-through switch
            case 'TOMORROW':
              id = id || 'laundry-tomorrow';
              return (
                <SummaryReminderAnnouncement
                  id={`${id}-summary-announcement-${announcement.id}`}
                  key={`summary-announcement-${announcement.id}`}
                  announcement={announcement}
                />
              );
            case 'COVID_FLAT_SUPPORT':
              return (
                <CovidSupportLandingAnnouncement
                  id={`rental-announcement-covid-${announcement.id}`}
                  key={`rental-announcement-covid-${announcement.id}`}
                  announcement={announcement}
                />
              );
            case 'NEGATIVE_BALANCE':
              id = id || 'rental-negbal';
              return (
                <RentalAccountAnnouncement
                  id={`${id}-rental-announcement-negative-${announcement.id}`}
                  key={`rental-announcemnt-negative-${announcement.id}`}
                  announcement={announcement}
                />
              );
            case 'NEWS':
            case 'ALERT':
            case 'INFORMATIVE':
            case 'CLEANING':
              return (
                <PipeAnnouncement
                  id={`pipe-announcement-${announcement.id}`}
                  key={`pipe-announcement-${announcement.id}`}
                  announcement={announcement}
                  onClose={() => setAnnouncements(announcements.filter(a => a.id !== announcement.id))}
                />
              );
            case 'INFORMATION_PENDING':
            case 'FURTHER_REVIEW':
            case 'FORM_REJECTED':
            case 'FORM_COMPLETED':
            case 'ANSWER_PENDING':
            case 'FINISH_PENDING':
              return (
                <FormAnnouncement
                  id={`application-form-announcement-${announcement.id}`}
                  key={`application-form-announcement-${announcement.id}`}
                  type={announcement.type}
                  announcement={announcement}
                  onClose={() => setAnnouncements(announcements.filter(a => a.id !== announcement.id))}
                />
              );
            default:
              return (
                <DefaultAnnouncementComponent
                  id={`${idx}-announcement-id`}
                  key={`${idx}-announcement`}
                  announcement={announcement}
                  className="Landing__p"
                  onClose={() => removeAnnouncement(announcement)}
                />
              );
          }
        })}
      </>
    );
  };

  const toRender = announcementsToRender();
  const noLaundryAnnouncements = !toRender.some(a => a.category === 'LAUNDRY');
  const noRentalAnnouncements = !toRender.some(a => a.category === 'RENTAL');

  return isLoading ? (
    <LoadingIndicator id="landing" />
  ) : (
    <>
      <Helmet>
        <title>{LANDING_LABELS.pageTitle}</title>
        <meta charSet="utf-8" />
        <meta name={'description'} content={LANDING_LABELS.meta} />
      </Helmet>

      <Section id="landing" title={LANDING_LABELS.title} srOnly={true} className="Landing">
        <span role="alert" aria-live="assertive" className="sr-only">
          <em>{LANDING_LABELS.title}</em> wurde geladen
        </span>
        <h3 className="Landing__heading">{getGreeting()}</h3>

        {getAppVersion() < Configuration.applicationConfig().currentMinAppVersion && <MinVersionAnnouncement />}

        {data.isPAHOTenant && <InBoxAnnouncement />}

        {!isWhatsNewAck && (
          <WhatsNewAnnouncement features={whatsNew.current} onCloseHandler={acknowledgeWhatNewFeatures} />
        )}

        {renderAnnouncements(toRender)}

        <Card id={'landing-bezirknews-card'}>
          <CardHeader title={LANDING_LABELS.tiles.bezirksnews.title} icon={SVG.WW_Bezirksnews_Logo} />
          <CardBody>{LANDING_LABELS.tiles.bezirksnews.text}</CardBody>
          <CardFooter>
            <Hyperlink
              id={'landing-bezirknews-card'}
              to={LANDING_LABELS.tiles.bezirksnews.href}
              iconAfter={SVG.external_link}
              isExternal={true}
            >
              {LANDING_LABELS.tiles.bezirksnews.link}
            </Hyperlink>
          </CardFooter>
        </Card>

        {noLaundryAnnouncements && (
          <Card id={'landing-laundry-card'}>
            <CardHeader title={LANDING_LABELS.tiles.laundry.title} icon={SVG.laundry} />
            {fetchErrors.some(error => error.orig === 'LaundryDayAnnouncementApi') ? (
              <CardBody>
                <span>{LANDING_LABELS.tiles.laundry.serviceNotAvailable}</span>
              </CardBody>
            ) : (
              <CardFooter>
                <Hyperlink id={'landing-laundry-card'} to={LAUNDRY_DAY} iconAfter={SVG.arrow_right}>
                  {LANDING_LABELS.tiles.laundry.link}
                </Hyperlink>
              </CardFooter>
            )}
          </Card>
        )}

        {noRentalAnnouncements && <RentalAccountTeaserCard id={'landing-rental-teaser-card'} />}

        <Card id={'landing-application-form-card'}>
          <CardHeader title={LANDING_LABELS.tiles.applicationForms.title} icon={SVG.application_form} />
          <CardFooter>
            <Hyperlink
              id={'landing-application-form-card'}
              to={{ pathname: APPLICATION_FORM_BASE_ROUTE, state: { from: LANDING } }}
              iconAfter={SVG.arrow_right}
            >
              {LANDING_LABELS.tiles.applicationForms.link}
            </Hyperlink>
          </CardFooter>
        </Card>

        <Card id={'landing-service-card'}>
          <CardHeader title={LANDING_LABELS.tiles.serviceCard.title} icon={SVG.service_card} />
          <CardFooter>
            <Hyperlink id={'landing-service-card'} to={SERVICE_CARD} iconAfter={SVG.arrow_right}>
              {LANDING_LABELS.tiles.serviceCard.link}
            </Hyperlink>
          </CardFooter>
        </Card>
        {!fetchErrors.some(error => error.contextInfo === 'fetchTenantContracts') &&
          !contactData.current.isNullOrEmpty() && (
            <LocalContactCard
              key={'landing-lcc-' + 0}
              id="landing"
              index={0}
              tenancyContract={contactData.current[0].tenancyContract}
              complexDetails={contactData.current[0].buildingComplexDetails}
              complexContactInformation={contactData.current[0].contactInformation}
              showFooter={true}
            />
          )}
      </Section>
    </>
  );
};

export default Landing;
