import React, { useContext, useEffect, useRef, useState } from 'react';
import { IProcessData } from '../../decisionTree/interfaces/DecisionTree.interface';
import { useFetch } from '../../../commons/hooks/useFetch';
import {
  IApplicationForm,
  IApplicationFormsData,
  ISynchronizedApplicationFormsData,
} from '../../../commons/api/applicationForm/ApplicationForm.interface';
import ApplicationFormApi from '../../../commons/api/applicationForm/ApplicationForm.api';
import TenantDataApi from '../../../commons/api/tenantData/TenantData.api';
import { ITenancyContract, ITenancyContracts } from '../../../commons/api/tenantData/TenantData.interface';
import { piwikEvents } from '../../../commons/utility/piwikEvents';
import {
  Button,
  Collapsible3,
  ErrorBox,
  ErrorPanel,
  Hyperlink,
  Icon,
  LoadingIndicator,
  Overlay,
  Section,
  Status,
  SubSection,
  SVG,
  TabPanel,
} from 'digit.commons.ui-components-app';
import { Helmet } from 'react-helmet';
import './ApplicationFormLanding.scss';
import { TabPanelItem } from 'digit.commons.ui-components-app/dist/lib/TabPanel/TabPanel';
import { STATUS } from '../../constants/status';
import { INTERNAL_TO_EXTERNAL_REPRESENTATION_MAPPER } from '../../constants/containers/internal-to-external-representation-mapper';
import { DATE_FORMATTER } from '../../../commons/utility/DateFormatter';
import { ApplicationFormUtility } from '../../utility/ApplicationFormUtility';
import { DETAILS_ROUTE, PRE_SELECTION_ROUTE } from '../../constants/routes';
import {
  APPLICATION_FORM_LABELS,
  APPLICATION_FORM_OVERLAY_LABELS,
} from '../../constants/containers/request-and-documents-labels';
import BridgeApi, { NavigationItems } from '../../../commons/api/Bridge';
import { ERROR_APPLICATION_FORM_LANDING, ERROR_APPLICATION_FORM_SERVICE_UNAVAILABLE } from '../../constants/errors';
import moment from 'moment-timezone';
import { useHistory, useLocation } from 'react-router-dom';
import { TenantDataContext } from '../../../portal-app/context/TenantDataContext';
import NavigationHeader from '../../components/NavigationHeader/NavigationHeader';

interface ILocation {
  toRequestForms?: boolean;
  toExistingForms?: boolean;
  processData: IProcessData;
  landingState?: IApplicationFormLandingState;
  from?: string;
}

interface ILandingState {
  tab: number;
  additionalFormsLoaded: number;
}

export interface IApplicationFormLandingState extends ILandingState {
  scrollToRefId: string;
}

const ApplicationFormLanding: React.FC = () => {
  const {
    title,
    intro,
    rentalObjectChange,
    requestServiceCard,
    myRequests,
    documentWanted,
    contract,
    finance,
    completedApplications,
    noCompletedApplications,
    backLink,
    showDetails,
    processApplication,
    myDrafts,
    noDrafts,
    open,
    noOpenApplications,
    loadMore,
  } = APPLICATION_FORM_LABELS;

  const location = useLocation<ILocation>();
  const [applicationForms, setApplicationForms] = useState<IApplicationForm[]>();
  const [showOverlay, setShowOverlay] = useState(false);
  const [applicationFormIdToDelete, setApplicationFormIdToDelete] = useState('');
  const [onDeleteDraftError, setOnDeleteDraftError] = useState<boolean>(false);
  const [landingState, setLandingState] = useState<ILandingState>(
    location?.state?.landingState ?? { additionalFormsLoaded: 1, tab: 0 }
  );
  const tenancyContracts = useRef<ITenancyContract[]>();
  const { tenant } = useContext(TenantDataContext);
  const history = useHistory();

  const { data, isFetchInProgress, fetchErrors } = useFetch<
    IApplicationFormsData & ISynchronizedApplicationFormsData & ITenancyContracts
  >(
    {
      [ApplicationFormApi.orig]: tenant.details.hb
        ? () => ApplicationFormApi.fetchAllSynchronizedApplicationFormData()
        : () => ApplicationFormApi.fetchAllApplicationFormData(),
      [TenantDataApi.orig]: () => TenantDataApi.fetchTenantContracts(),
    },
    NavigationItems.NAVIGATION_APPLICATION_FORM_LANDING
  );

  useEffect(() => {
    if (!fetchErrors.isNullOrEmpty()) {
      fetchErrors.some(error => error.orig === 'ApplicationFormApi') &&
        piwikEvents.trackEvent(
          'technischer Fehler',
          'Anträge und Dokumente',
          'Fehler beim Laden der bereits eingereichten oder zwischengespeicherten Anträge'
        );
      fetchErrors.some(error => error.orig === 'TenantDataApi') &&
        piwikEvents.trackEvent('technischer Fehler', 'Anträge und Dokumente', 'Fehler beim Laden der Mietverträge');
    }
    if (!isFetchInProgress) {
      tenancyContracts.current = data.tenancyContracts;
      setApplicationForms(
        tenant.details.hb ? data.getApplicationFormDataWithFullSync : data.getApplicationFormData || null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchInProgress]);

  useEffect(() => {
    if (applicationForms && location?.state?.landingState?.scrollToRefId) {
      document.getElementById(location.state.landingState.scrollToRefId)?.scrollIntoView();
    }
  }, [applicationForms, landingState]);

  const applicationFormFetchError = fetchErrors.some(error => error.orig === 'ApplicationFormApi');
  const tenancyContractFetchError = fetchErrors.some(error => error.orig === 'TenantApi');

  const renderTabPanelWithPreviousApplicationForms = () => {
    const drafts = [];
    const openForms = [];
    const completed = [];
    applicationForms.forEach(applicationFormData => {
      if (applicationFormData.statusMappings[0]) {
        switch (applicationFormData.statusMappings[0].status) {
          case STATUS.submitted.apiStatus:
          case STATUS.inprogress.apiStatus:
          case STATUS.incomplete.apiStatus:
          case STATUS.requested.apiStatus:
          case STATUS.furtherinquiry.apiStatus:
          case STATUS.wrw_info_pending.apiStatus:
          case STATUS.hb_info_pending.apiStatus:
          case STATUS.hb_finish_pending.apiStatus:
            openForms.push(applicationFormData);
            break;
          case STATUS.draft.apiStatus:
            drafts.push(applicationFormData);
            break;
          case STATUS.approved.apiStatus:
          case STATUS.declined.apiStatus:
          case STATUS.rejected_due_to_missing_information.apiStatus:
          case STATUS.cancelled.apiStatus:
          case STATUS.readytodownload.apiStatus:
          case STATUS.expired.apiStatus:
          case STATUS.completed.apiStatus:
          case STATUS.rejected.apiStatus:
          case STATUS.documentnotavailable.apiStatus:
          case STATUS.datanotavailable.apiStatus:
          case STATUS.notcompletable.apiStatus:
          case STATUS.failed.apiStatus:
          case STATUS.hb_finalizing.apiStatus:
            completed.push(applicationFormData);
            break;
          case STATUS.hb_declined.apiStatus:
            /* don't add to any array, we don't display declined hb orders */
            break;
          default:
            openForms.push(applicationFormData);
        }
      }
    });

    openForms.sort((a, b) => b.statusMappings[0].changeDate - a.statusMappings[0].changeDate);
    drafts.sort((a, b) => b.statusMappings[0].changeDate - a.statusMappings[0].changeDate);
    completed.sort((a, b) => b.statusMappings[0].changeDate - a.statusMappings[0].changeDate);

    const tabItems: TabPanelItem[] = [
      {
        title: open,
        number: openForms.length.toString(),
        content: <>{renderOpenApplications(openForms)}</>,
      },
      {
        title: myDrafts,
        number: drafts.length.toString(),
        content: <>{renderDrafts(drafts)}</>,
      },
      {
        title: completedApplications,
        number: completed.length.toString(),
        content: <>{renderCompleted(completed)}</>,
      },
    ];

    return (
      <TabPanel
        id={'application-form-landing'}
        items={tabItems}
        sticky={true}
        preselectedTab={landingState.tab}
        ariaLabeledById={'application-form-landing-my-requests-sub-section-id'}
        onChangeTab={tab => setLandingState({ tab, additionalFormsLoaded: 1 })}
      />
    );
  };

  const renderOpenApplications = openForms => {
    if (openForms.length > 0) {
      return renderStatusAndLoadMoreButton(openForms);
    }
    return (
      <div className={'ApplicationFormLanding__emptyState'}>
        <Icon icon={SVG.empty_state} key={'application-form-landing-no-open'} />
        <p className={'ApplicationFormLanding__description'}>{noOpenApplications}</p>
      </div>
    );
  };

  const renderDrafts = drafts => {
    if (drafts.length > 0) {
      return renderStatusAndLoadMoreButton(drafts);
    }
    return (
      <div className={'ApplicationFormLanding__emptyState'}>
        <Icon icon={SVG.empty_state} key={'application-form-landing-no-drafts'} />
        <p className={'ApplicationFormLanding__description'}>{noDrafts}</p>
      </div>
    );
  };

  const renderCompleted = completed => {
    if (completed.length > 0) {
      return renderStatusAndLoadMoreButton(completed);
    }
    return (
      <div className={'ApplicationFormLanding__emptyState'}>
        <Icon icon={SVG.empty_state} key={'application-form-landing-no-completed'} />
        <p className={'ApplicationFormLanding__description'}>{noCompletedApplications}</p>
      </div>
    );
  };

  const renderStatusAndLoadMoreButton = forms => {
    if (forms.length > 10 * landingState.additionalFormsLoaded) {
      return (
        <>
          {filterApplicationFormStatus(forms.slice(0, landingState.additionalFormsLoaded * 10))}
          <Button
            id={'load-more-forms'}
            className={'ApplicationFormLanding__button'}
            modifier={'secondary'}
            onClick={() =>
              setLandingState(prevState => ({
                ...prevState,
                additionalFormsLoaded: prevState.additionalFormsLoaded + 1,
              }))
            }
          >
            {loadMore}
          </Button>
        </>
      );
    } else {
      return filterApplicationFormStatus(forms);
    }
  };

  const getTransactionLabel = (item: IApplicationForm) => {
    let transactionLabel: string | JSX.Element = null;

    if (item && item.statusMappings[0]) {
      switch (item.statusMappings[0].status) {
        case STATUS.arrived.apiStatus:
        case STATUS.inprogress.apiStatus:
        case STATUS.notcompletable.apiStatus:
        case STATUS.incomplete.apiStatus:
        case STATUS.approved.apiStatus:
        case STATUS.declined.apiStatus:
        case STATUS.rejected_due_to_missing_information.apiStatus:
        case STATUS.cancelled.apiStatus:
        case STATUS.completed.apiStatus:
        case STATUS.tenant_info_pending.apiStatus:
        case STATUS.tenant_info_resolved.apiStatus:
        case STATUS.wrw_info_pending.apiStatus:
        case STATUS.wrw_info_resolved.apiStatus:
        case STATUS.hb_info_pending.apiStatus:
        case STATUS.hb_info_resolved.apiStatus:
        case STATUS.hb_finish_pending.apiStatus:
        case STATUS.hb_finish_resolved.apiStatus:
        case STATUS.hb_finalizing.apiStatus:
          transactionLabel = `AZ ${item.transactionId ?? item.processCode}`;
          break;
        /*
         * drafts and newly submitted forms don't have a transactionlabel.
         */
        case STATUS.draft.apiStatus:
        case STATUS.submitted.apiStatus:
        /*
         * documents
         */
        case STATUS.requested.apiStatus:
        case STATUS.readytodownload.apiStatus:
        case STATUS.expired.apiStatus:
        case STATUS.rejected.apiStatus:
          transactionLabel = '';
          break;
        default:
          transactionLabel = null;
      }
    }

    return transactionLabel;
  };

  const filterApplicationFormStatus = (filteredArray: IApplicationForm[]) => {
    return filteredArray.map((item, index) => {
      const statusMap = INTERNAL_TO_EXTERNAL_REPRESENTATION_MAPPER.conversions.find(status => status.id === item.theme);
      let pathname = statusMap?.target ?? '';
      const latestStatusMapping = item.statusMappings[0];
      if (latestStatusMapping.status && latestStatusMapping.status !== STATUS.draft.apiStatus) {
        pathname = DETAILS_ROUTE.replace(':applicationFormId', item.shortId);
      }
      const processData = JSON.parse(item.json);
      const routeState = ApplicationFormUtility.buildApplicationFormHistoryState(item, processData, backLink, {
        ...landingState,
        scrollToRefId: `application-form-landing-${latestStatusMapping.status.toLowerCase()}-${index}-status-id`,
      });
      const rentalObjectInformation =
        ApplicationFormUtility.getRentalObjectFromContract(processData, tenancyContracts.current) ||
        ApplicationFormUtility.getRentalObjectFromSummary(processData);
      return (
        <>
          <Status
            id={`application-form-landing-${latestStatusMapping.status.toLowerCase()}-${index}`}
            key={index}
            transactionDescription={ApplicationFormUtility.getFormDescription(
              item.theme,
              item.workflow,
              processData,
              item.statusMappings
            )}
            transactionLabel={getTransactionLabel(item)}
            transactionDetailDescription={rentalObjectInformation}
            transactionDetailLabel={
              processData['summary'] && processData['summary']['Mietobjekt'] ? 'Mietobjekt' : null
            }
            statusDate={DATE_FORMATTER.dateFormat(latestStatusMapping.changeDate)}
            state={latestStatusMapping.status.toLowerCase()}
            linkText={latestStatusMapping.status === STATUS.draft.apiStatus ? processApplication : showDetails}
            linkTo={{
              pathname: pathname,
              state: routeState,
            }}
            linkIsExternal={false}
            onDelete={() => {
              setShowOverlay(true);
              setApplicationFormIdToDelete(item.id);
            }}
          />
        </>
      );
    });
  };

  const deleteDraft = async () => {
    try {
      const applicationFormDeleteResponse = await ApplicationFormApi.deleteApplicationForm(applicationFormIdToDelete);
      /* the response is only true, if the application form was deleted */
      if (applicationFormDeleteResponse.data.deleteApplicationForm) {
        // Filters the current applicationFormData to remove the one Status with the given id
        setApplicationForms(applicationForms.filter(form => form.id !== applicationFormIdToDelete));
        setOnDeleteDraftError(false);
      } else {
        setOnDeleteDraftError(true);
      }
    } catch (e) {
      piwikEvents.trackEvent('Anträge und Dokumente', 'Entwurfslöschung', 'Entwurf konnte nicht gelöscht werden');
      setOnDeleteDraftError(true);
    } finally {
      setShowOverlay(false);
    }
  };

  const hasFlat = tenancyContracts?.current?.some(contract => contract.xmbez === 'Wohnung');

  return (
    <>
      {(tenancyContracts.current || tenancyContractFetchError) && (applicationForms || applicationFormFetchError) ? (
        <>
          <Helmet>
            <title>{title}</title>
            <meta charSet="utf-8" />
          </Helmet>

          <Overlay
            title={APPLICATION_FORM_OVERLAY_LABELS.title}
            textBody={APPLICATION_FORM_OVERLAY_LABELS.body}
            show={showOverlay}
            id="application-form-landing"
            buttons={[
              {
                id: 'application-form-landing-cancel',
                title: APPLICATION_FORM_OVERLAY_LABELS.secondaryButtonText,
                onClick: () => {
                  setShowOverlay(false);
                },
                modifier: 'secondary',
              },
              {
                id: 'application-form-landing-delete',
                title: APPLICATION_FORM_OVERLAY_LABELS.primaryButtonText,
                onClick: deleteDraft,
              },
            ]}
          />
          <NavigationHeader
            backHandler={() => {
              if (location?.state?.from) {
                history.push(location.state.from);
              } else {
                BridgeApi.selectNavigationItem(NavigationItems.NAVIGATION_MORE_MENU);
              }
            }}
            backText={backLink}
            title={title}
          />
          <Section
            id={'application-form-landing-request'}
            title={title}
            srOnly={true}
            className={'ApplicationFormLanding__body'}
          >
            <span role="alert" aria-live="assertive" className="sr-only">
              <em>{title}</em> wurde geladen
            </span>
            {applicationFormFetchError ? (
              <ErrorPanel
                id={'application-form-service-not-available'}
                title={ERROR_APPLICATION_FORM_SERVICE_UNAVAILABLE.title}
                errorText={ERROR_APPLICATION_FORM_SERVICE_UNAVAILABLE.text}
                descriptionText={ERROR_APPLICATION_FORM_SERVICE_UNAVAILABLE.description}
              />
            ) : (
              <>
                {onDeleteDraftError && (
                  <ErrorBox
                    id={'application-form-landing-delete-draft'}
                    description={ERROR_APPLICATION_FORM_LANDING.onDelete.description}
                  />
                )}
                {tenancyContractFetchError ? (
                  <ErrorBox
                    id={'application-form-landing-tenancy-contracts'}
                    description={ERROR_APPLICATION_FORM_LANDING.fetchContracts.description}
                  />
                ) : (
                  <>
                    <Collapsible3
                      id={'application-form-landing-change'}
                      className={'ApplicationFormLanding__collapsible'}
                      title={intro}
                      initialyOpen={false}
                    >
                      <ul className={'ApplicationFormLanding__list'}>
                        {hasFlat && (
                          <li>
                            <Hyperlink
                              id={'application-form-landing-change-flat'}
                              to={{ pathname: PRE_SELECTION_ROUTE, state: { category: 'flat' } }}
                              iconBefore={SVG.construction_flat}
                              iconAfter={SVG.arrow_right}
                            >
                              {rentalObjectChange}
                            </Hyperlink>
                          </li>
                        )}
                        <li>
                          <Hyperlink
                            id={'application-form-landing-request-service-card'}
                            to={{ pathname: PRE_SELECTION_ROUTE, state: { category: 'service-cards' } }}
                            iconBefore={SVG.service_card}
                            iconAfter={SVG.arrow_right}
                          >
                            {requestServiceCard}
                          </Hyperlink>
                        </li>
                      </ul>
                    </Collapsible3>
                    <Collapsible3
                      id={'application-form-landing-request'}
                      className={'ApplicationFormLanding__collapsible'}
                      title={documentWanted}
                      initialyOpen={false}
                    >
                      <ul className={'ApplicationFormLanding__list'}>
                        <li>
                          <Hyperlink
                            id={'application-form-landing-rent-details'}
                            to={{ pathname: PRE_SELECTION_ROUTE, state: { category: 'finances' } }}
                            iconBefore={SVG.euro_bill}
                            iconAfter={SVG.arrow_right}
                          >
                            {finance}
                          </Hyperlink>
                        </li>
                        {hasFlat && (
                          <li>
                            <Hyperlink
                              id={'application-form-landing-rent-confirmation'}
                              to={{ pathname: PRE_SELECTION_ROUTE, state: { category: 'confirmations' } }}
                              iconBefore={SVG.file_confirmation}
                              iconAfter={SVG.arrow_right}
                            >
                              {contract}
                            </Hyperlink>
                          </li>
                        )}
                      </ul>
                    </Collapsible3>
                  </>
                )}
              </>
            )}
            {!applicationFormFetchError && (
              <SubSection
                id={'application-form-my-forms'}
                className={'ApplicationFormLanding__section'}
                title={myRequests}
              >
                {renderTabPanelWithPreviousApplicationForms()}
              </SubSection>
            )}
          </Section>
        </>
      ) : (
        <LoadingIndicator id={'application-form-landing'} />
      )}
    </>
  );
};

export default ApplicationFormLanding;
