import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  ErrorPanel,
  Fieldset,
  IFieldsetHandles,
  LoadingIndicator,
  Section,
} from 'digit.commons.ui-components-app';
import { IFieldsetOption } from '../../decisionTree/interfaces/Template.interface';
import { APPLICATION_FORM_BASE_ROUTE } from '../../constants/routes';
import { WIZARD_LABELS } from '../../constants/containers/wizard-labels';
import NavigationHeader from '../../components/NavigationHeader/NavigationHeader';
import './ApplicationFormPreSelection.scss';
import BridgeApi, { NavigationItems } from '../../../commons/api/Bridge';

export interface PreSelectionLocationParams {
  category: string;
}

const ApplicationFormPreSelection: React.FC = () => {
  const [selectedFlow, setSelectedFlow] = useState<string>();
  const [options, setOptions] = useState<IFieldsetOption[]>();
  const [error, setError] = useState<string>();
  const title = useRef<string>();
  const location = useLocation<PreSelectionLocationParams>();
  const history = useHistory();

  const fieldsetRef = useRef<IFieldsetHandles>(null);

  useEffect(() => {
    BridgeApi.selectNavigationItem(NavigationItems.NAVIGATION_APPLICATION_FORM_PRE_SELECTION);
    switch (location.state.category) {
      case 'flat':
        title.current = 'Was möchten Sie ändern?';
        setOptions([
          {
            text: 'Heizung und Kühlung',
            value: 'heizung-und-kühlung',
          },
          {
            text: 'Fenster und Türen',
            value: 'fenster-und-türen',
          },
          {
            text: 'Elektro-Installation',
            value: 'elektro-installation',
          },
          {
            text: 'Wasser und Sanitär',
            value: 'sanitär-umbauen',
          },
          {
            text: 'TV-Installation und Medienanschluss',
            value: 'sat-antenne-und-medienanschluss',
          },
          {
            text: 'Wand und Boden',
            value: 'wand-und-boden',
          },
        ]);
        break;
      case 'service-cards':
        title.current = 'Was möchten Sie machen?';
        setOptions([
          {
            text: 'Service-Karte nachbestellen',
            value: 'service-karte-nachbestellen',
          },
          {
            text: 'Zahlungsfunktion freischalten',
            value: 'service-karte-zahlungsfunktion',
          },
        ]);
        break;
      case 'finances':
        title.current = 'Was möchten Sie herunterladen?';
        setOptions([
          {
            text: 'Mietzinskonto-Information',
            value: 'mietzins',
          },
          {
            text: 'Details meiner monatlichen Miete (Zinszettel)',
            value: 'zinszettel',
          },
        ]);
        break;
      case 'confirmations':
        title.current = 'Was möchten Sie herunterladen?';
        setOptions([
          {
            text: 'Hauptmieterbestätigung',
            value: 'hauptmieter',
          },
        ]);
        break;
      default:
        setError('Keine Kategorie ausgewählt');
        break;
    }
  }, []);

  const onBackHandler = () => {
    history.push({ pathname: APPLICATION_FORM_BASE_ROUTE, state: { toRequestForms: true } });
  };

  const onNextHandler = () => {
    fieldsetRef.current.validate();
    if (selectedFlow) {
      history.push(`${APPLICATION_FORM_BASE_ROUTE}/${selectedFlow}`);
    }
  };

  if (options) {
    return (
      <>
        <NavigationHeader
          backHandler={onBackHandler}
          backText={'Zurück'}
          abortHandler={onBackHandler}
          abortText={'Abbrechen'}
          title={'Antrag stellen'}
        />
        <div className={'ApplicationFormPreSelection__body'}>
          <Section id={'application-form-pre-selection'} title={title.current}>
            <Fieldset
              id={'application-form-pre-selection'}
              options={options}
              flow={'grid'}
              errorMessage={'Wählen Sie die gewünschte Änderung aus um fortzufahren'}
              required={true}
              cols={1}
              multiple={false}
              onChange={selection => setSelectedFlow(selection.values().next().value)}
              ref={fieldsetRef}
            />
            <div className={'ApplicationFormPreSelection__button'}>
              <Button id={'application-form-pre-selection-forward'} onClick={onNextHandler}>
                {WIZARD_LABELS.proceed}
              </Button>
            </div>
          </Section>
        </div>
      </>
    );
  }

  if (error) {
    return (
      <ErrorPanel
        id={'application-form-pre-selection'}
        title={'Fehler'}
        errorText={'Es wurde keine gültige Kategorie für einen Antrag ausgewählt'}
        descriptionText={'Bitte gehen Sie zurück und wählen Sie was aus.'}
      />
    );
  }

  return <LoadingIndicator id={'application-form-pre-selection'} />;
};

export default ApplicationFormPreSelection;
