import React from 'react';
import './InBoxAnnouncement.scss';
import { Card, CardBody, CardHeader, Hyperlink, SVG } from 'digit.commons.ui-components-app';

export const INFORMATION_IN_BOX = 'https://www.wienerwohnen.at/Neues-aus-dem-Gemeindebau2/inbox.html';

export const IN_BOX_ANNOUNCEMENT = {
  badge: 'Information',
  title: 'Die IN-Box - Ein neues Service für Mieter*innen!',
  description: (
    <p className={'InBoxAnnouncement__description'}>
      <span className={'InBoxAnnouncement__span'}>
        Für mehr Informationen zu dem neuen Paketboxen-System in der PAHO klicken Sie bitte auf den folgenden Link:
      </span>
      <br />
      <br />
      <Hyperlink
        id={'in-box-description'}
        to={INFORMATION_IN_BOX}
        isExternal={true}
        iconAfter={SVG.external_link}
        className={'in-box__description-link'}
      >
        Die IN-Box
      </Hyperlink>
    </p>
  ),
};

export const InBoxAnnouncement: React.FC = () => {
  const { badge, title, description } = IN_BOX_ANNOUNCEMENT;

  return (
    <Card id={`in-box-information`} badge={badge} badgeType={'green'} className="InBoxAnnouncement">
      <CardHeader title={title} icon={SVG.rental_object_magazin} />
      <CardBody>{description}</CardBody>
    </Card>
  );
};
