import { formatArgumentsClause } from '../GraphQLHelper';

export const mutationSubmitApplicationFormData = (
  workflow: string,
  theme: string,
  jsonBody: string,
  status: string,
  tplnr: string,
  contentPageKey: string,
  swenr: string,
  smenr: string,
  id?: string,
  belongsToStatusMapping?: string
) => {
  const idOrNull = id ? `"${id}"` : null;
  return `mutation {operationName} {
            saveApplicationForm(applicationForm: {
              id: ${idOrNull},
              workflow: "${workflow}",
              theme: "${theme}",
              json: "${jsonBody}"
              status: ${status}
              contentPageKey: "${contentPageKey ? contentPageKey : ''}"
              tplnr: "${tplnr}"
              swenr: "${swenr}"
              smenr: "${smenr}"
              belongsToStatusMapping: "${belongsToStatusMapping}"
            })
          }`;
};

export const getAllApplicationFormData = () =>
  `query {operationName} {
    getApplicationFormData{
      id
      shortId
      gepartId
      workflow
      theme
      json
      processCode
      transactionId   
      statusMappings{
        id
        status
        changeDate
        expiryDate
        document{
          id
          applicationFormId
          size
          name
          mimeType
          type
          expired
        }
      }
    }
  }`;

export const getApplicationFormDetailData = (applicationFormShortId: string) => `query {operationName} {
  getApplicationFormDetailData(applicationFormShortId: "${applicationFormShortId}") {
    applicationForm {
      id
      shortId
      gepartId
      workflow
      theme
      json
      processCode
      statusMappings {
        id
        status
        changeDate
        expiryDate
        document {
          id
          name
          size
          mimeType
          type
          expired
          applicationFormId
        }
      }
    }
    notExistingOrNotAllowed
  }
}`;

export const queryFlowStructure = (flow: string, excludeDuplicate?: string) => {
  const args = {
    flow: flow,
    excludeDuplicate: excludeDuplicate,
  };
  return `query {operationName} {
  getFlowJson${formatArgumentsClause(args)} {
    json
    doubleSubmissions {
      id
      shortId
      gepartId
      workflow
      theme
      json
      processCode
      statusMappings{
        id
        status
        changeDate
        expiryDate
        document{
          id
          applicationFormId
          size
          name
          mimeType
          type
          expired
        }
      }
    }
  }
}`;
};

export const getAllSynchronizedApplicationFormData = () => `
query {operationName} {
  getApplicationFormDataWithFullSync {
    id
    shortId
    gepartId
    workflow
    theme
    json
    processCode
    statusMappings {
      id
      status
      changeDate
      document {
        id
        name
        size
        mimeType
        type
        expired
        applicationFormId
      }
    }
  }
}
`;

export const queryFlowStructureByTheme = (flow: string, applicationFormId: string) => `
query {operationName} {
  getFlowJsonByTheme(theme: "${flow}", formId: "${applicationFormId}") {
    json
  }
}`;

export const mutationDeleteApplicationForm = (applicationFormId: string) =>
  `mutation {operationName} {
      deleteApplicationForm(applicationFormId: "${applicationFormId}")
    }`;

export const mutationDeleteDocument = (documentId: string) =>
  `mutation {operationName} {
      deleteDocument(documentId: "${documentId}")
    }`;
