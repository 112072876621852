import {
  IDecisionTree,
  INavigationResult,
  IPossibility,
  IProcessData,
  IStep,
  ITaskPossibility,
  ITaskPossibilityData,
} from '../interfaces/DecisionTree.interface';
import { IFieldset } from '../interfaces/Template.interface';
import { IContentData } from '../interfaces/PossibleContent.interface';
import { WizardMode } from '../../constants/containers/request-theme';

function cloneDeep(object: any, customizer: (value: any) => any) {
  if (typeof object === 'string') {
    if (customizer) {
      return customizer(object);
    }
    return object;
  } else if (Array.isArray(object)) {
    return object.map(value => cloneDeep(value, customizer));
  } else if (typeof object === 'object') {
    const clone = Object.assign({}, object);
    Object.keys(clone).forEach(key => {
      clone[key] = cloneDeep(clone[key], customizer);
    });
    return clone;
  } else {
    return object;
  }
}

/** The smart part of the Anträge & Ansuchen workflow. */
export default class DecisionTreeLogic {
  private readonly theme: string;
  private readonly doubleSubmissionText?: string;
  private readonly workflow: string;
  private readonly steps: IStep[];
  private readonly possibilities: IPossibility;

  constructor(data: IDecisionTree) {
    this.workflow = data.workflow;
    this.theme = data.theme;
    this.steps = data.steps;
    this.possibilities = data.possibilities;
    this.doubleSubmissionText = data.doubleSubmissionText;
  }

  public getStepLength = (): number => (this.steps ? this.steps.length : 0);

  public getTheme = (): string => this.theme;

  public getWorkflow = (): string => this.workflow;

  public getStepByIndex = (stepIndex: number): IStep => {
    return this.steps ? this.steps.filter(step => step.index === stepIndex)[0] : null;
  };

  public getStepById = (stepId: string): IStep => {
    return this.steps.filter(step => step.id === stepId)[0];
  };

  public getDoubleSubmissionText = (): string | undefined => this.doubleSubmissionText;

  public getPipeContentPageInformationTaskId = (): string => {
    let pipeContentPageInformationTaskId = '';

    if (!this.steps) {
      return pipeContentPageInformationTaskId;
    }

    for (const step of this.steps) {
      /* if a step has fieldsets ... */
      if (step.props.fieldsets) {
        for (const fieldset of step.props.fieldsets) {
          if (fieldset.pipeContentPageInformation) {
            pipeContentPageInformationTaskId = fieldset.id;
            break;
          }
        }
      }
      if (step.pipeContentPageInformation) {
        pipeContentPageInformationTaskId = step.id;
      }
      if (pipeContentPageInformationTaskId !== '') break;
    }
    return pipeContentPageInformationTaskId;
  };

  /**
   * cleans up all processData up to the current step of the task id, e.g.,
   * processData: { rentalObject: 'a', room: ["living room"], changes: ["blinds"], changeDetails: ["shutter"] }
   * and after stepping back room is changed to 'kitchen', then the processData looks like:
   * processData: { rentalObject: 'a', room: ["kitchen"], changes: ["blind"] }
   *
   * but if someone changes a decision that makes a decision made on the same step impossible, this decsion will be removed, e.g.,
   * processData: { rentalObject: 'a', room: ["hallway"], changes: ["safety door"] }
   * and since safety door is only available for the room 'hallway', if we add another room and 'safety door' would be impossible it becomes
   * processData: { rentalObject: 'a', room: ["hallway, kitchen"], changes: [] }
   */
  public cleanUpProcessData = (
    processData: IProcessData,
    currentTaskId: string,
    possibleContent: IContentData
  ): IProcessData => {
    let processDataToCurrentStep: IProcessData = {
      currentStep: this.getAllStepsToCurrentTask(currentTaskId),
    };
    this.getAllTasksToCurrentStep(currentTaskId).forEach(taskId => {
      /* only take over the decision if there is one */
      if (processData[taskId]) {
        processDataToCurrentStep = {
          [taskId]: processData[taskId],
          ...processDataToCurrentStep,
        };
      } else {
        /* otherwise remove the step from the list of steps */
        processDataToCurrentStep = {
          ...processDataToCurrentStep,
          currentStep: processDataToCurrentStep.currentStep.filter(step => step !== taskId),
        };
      }
    });
    return this.removeImpossibleProcessData(
      processDataToCurrentStep.currentStep[processDataToCurrentStep.currentStep.length - 1],
      processDataToCurrentStep,
      possibleContent
    );
  };

  private getAllStepsToCurrentTask = (currentTaskId: string): string[] => {
    let listOfSteps = [],
      foundTask = false;
    /* note: for-of allows the usage of break whereas forEach doesn't */
    for (const step of this.steps) {
      /* if a step has fieldsets ... */
      if (step.props.fieldsets) {
        for (const fieldset of step.props.fieldsets) {
          /* ... we go through all of them until we find our current task - then we break the loop */
          if (fieldset.id === currentTaskId) {
            foundTask = true;
            break;
          }
        }
      } else if (step.id === currentTaskId) {
        foundTask = true;
      }
      listOfSteps.push(step.id);
      /* once we have found the task, we stop iterating over steps and their fieldsets */
      if (foundTask) break;
    }
    return listOfSteps;
  };

  /**
   * Use-case: for the outsideBlinds workflow we add a non-existant room "wholeFlatInfo" in order to display additional information on the summary page.
   *
   * However, if the tenant goes back to the room selection and changes his mind, we do not want to keep this info in case a different request is chosen (e.g., move door).
   * The removal of impossible processData only works for options currently, for different kind of contents we'd need different implementations.
   *
   * returns the modified processData
   */
  public removeImpossibleProcessData = (
    currentTaskId: string,
    processData: IProcessData,
    possibleContent: IContentData
  ): IProcessData => {
    if (!(processData && processData[currentTaskId])) {
      return processData;
    }

    if (possibleContent && possibleContent[currentTaskId] && possibleContent[currentTaskId].options) {
      const possibleValues = possibleContent[currentTaskId].options.map(option => option.value);
      processData[currentTaskId] = processData[currentTaskId].filter(decision => possibleValues.includes(decision));
    } else {
      /* whenever there is a new type of content that could lead to impossible data then the implementation would go here. e.g., something other than options obviously. */
    }
    return processData;
  };

  /** returns all tasks up to the current step, including subtasks within a step due to fieldsets */
  private getAllTasksToCurrentStep = (currentTaskId: string): string[] => {
    /* prevent unlockPayAuthority from getting deleted */
    let listOfTasks = ['allAttachedDocuments', 'unlockPayAuthority'],
      foundTask = false;
    /* note: for-of allows the usage of break whereas forEach doesn't */
    for (const step of this.steps) {
      /* if a step has fieldsets ... */
      if (step.props.fieldsets) {
        for (const fieldset of step.props.fieldsets) {
          listOfTasks.push(fieldset.id);
          /* ... we go through all of them until we find our current task - then we break the loop */
          if (fieldset.id === currentTaskId) {
            if (fieldset.id !== step.id) {
              listOfTasks.push(step.id);
            }
            foundTask = true;
            break;
          }
        }
      } else {
        listOfTasks.push(step.id);
        /* otherwise we go through all steps until we found the current one */
        if (step.id === currentTaskId) {
          foundTask = true;
        }
      }
      /* once we have found the task, we stop iterating over steps and their fieldsets */
      if (foundTask) break;
    }
    return listOfTasks;
  };

  /**
   * returns the first step considering processData
   *
   * to return the correct step for initialization (used when restoring a wizard)
   * it should also support if the process data is completely empty (new wizard)
   */
  public getStepForInitialization = (
    processData: IProcessData,
    possibleContent: IContentData,
    editMode?: WizardMode
  ): INavigationResult => {
    let currentStep;

    /* clone process data to return a potentially updated copy */
    processData = { ...processData };

    if (editMode === WizardMode.EDIT_MODE_ENTER || editMode === WizardMode.ADD_INFORMATION) {
      currentStep = this.getStepByIndex(0);
      return {
        step: this.cloneStepAndResolvePlaceholders(currentStep, processData),
        processData: processData,
        possibleContent: this.getAllPossibleMatchingContent(processData),
      } as INavigationResult;
    }

    /* if there isn't any possibleContent or we only have rental objects there, but we have processData with more steps, we will first find out what content we can display */
    if (
      !possibleContent ||
      (Object.keys(possibleContent).length === 1 && processData['currentStep'] && processData['currentStep'].length > 1)
    ) {
      possibleContent = this.getAllPossibleMatchingContent(processData);
    }

    /* if the process data tells us the currentStep, then use it to get the step */
    if (processData.currentStep && processData.currentStep.length > 0 && editMode !== WizardMode.DELETED_DRAFT) {
      currentStep = this.getStepById(processData.currentStep[processData.currentStep.length - 1]);
      return {
        step: this.cloneStepAndResolvePlaceholders(currentStep, processData),
        processData: processData,
        possibleContent: possibleContent,
      } as INavigationResult;
    } else {
      if (!processData.currentStep) {
        processData = { currentStep: [this.getStepByIndex(0).id] };
      }
      return {
        ...this.getNextStep(processData, possibleContent, 0, true),
        possibleContent: possibleContent,
      };
    }
  };

  /** Navigates back to a previous step */
  public navigateBackToStep = (
    backToStepId: string,
    currentStep: IStep,
    possibleContent: IContentData,
    processData: IProcessData
  ): INavigationResult => {
    const index = processData.currentStep.findIndex(s => s === backToStepId);
    if (index === -1 || index === processData.currentStep.length - 1) {
      return null;
    }
    let result: INavigationResult;
    do {
      result = this.getPreviousStep(currentStep.index, possibleContent, processData);
      processData = result.processData;
      currentStep = result.step;
    } while (result.step.id !== backToStepId);
    return result;
  };

  /**
   * returns the next step, if every decision for a step has been made
   *
   * note: keep the current step in the wizard
   */
  public getNextStep = (
    processData: IProcessData,
    possibleContent: IContentData,
    currentStepIndex: number,
    isInitalStepDetermination: boolean = false
  ): INavigationResult => {
    const currentStep = this.getStepByIndex(currentStepIndex);
    if (
      this.noDecisionToBeMade(this.getStepByIndex(currentStepIndex).id, processData, possibleContent) ||
      this.isAllowedToGoToNextStep(processData, currentStep)
    ) {
      processData = { ...processData };
      let nextStep,
        stepIncrement = 1;
      do {
        /* update the possibleContent in every loop in case processData were changed in noDecisionToBeMade */
        possibleContent = this.getAllPossibleMatchingContent(processData);
        nextStep = this.getStepByIndex(currentStep.index + stepIncrement);
        stepIncrement++;
      } while (
        (this.noDecisionToBeMade(nextStep.id, processData, possibleContent) && nextStep.isOptional) ||
        (isInitalStepDetermination && this.isAllowedToGoToNextStep(processData, nextStep))
      );
      /*
       * update currentStep to reflect the next step for the user
       */
      processData.currentStep.push(nextStep.id);
      return {
        step: this.cloneStepAndResolvePlaceholders(nextStep, processData),
        processData: processData,
      } as INavigationResult;
    } else {
      return {
        step: this.cloneStepAndResolvePlaceholders(currentStep, processData),
        processData: processData,
      } as INavigationResult;
    }
  };

  /** the next step can be reached after every decision has been made, including decisions for subtasks due to fieldsets */
  private isAllowedToGoToNextStep = (processData: IProcessData, currentStep: IStep): boolean => {
    let madeEveryDecision = true;
    const fieldsets = currentStep?.props?.fieldsets;
    if (fieldsets) {
      fieldsets.forEach((fieldset: IFieldset) => {
        if (fieldset.required && !this.madeDecisionForTask(processData, fieldset.id)) {
          madeEveryDecision = false;
        }
      });
    }
    return madeEveryDecision && this.madeDecisionForTask(processData, currentStep.id);
  };

  /** checks if a decision for a certain task has been made, e.g., it's either non-empty (empty array/string) or true */
  private madeDecisionForTask = (processData: IProcessData, taskId: string): boolean =>
    processData && processData[taskId] != null && processData[taskId].length !== 0 && processData[taskId] !== false;

  private cloneStepAndResolvePlaceholders = (step: IStep, processData: IProcessData): IStep => {
    const customizer = (value: any): any => {
      if (typeof value === 'string') {
        return this.replacePlaceholders(value, step, processData);
      }
      return value;
    };
    return cloneDeep(step, customizer);
  };

  /** looks for placeholders and replaces them with the corresponding glossary entry */
  public replacePlaceholders = (text: string, step: IStep, processData: IProcessData): string => {
    return text.replace(/#\{(.+?)\}/g, (content, group) => this.getGlossaryText(step, group, processData).toString());
  };

  /**
   * Gets the translated glossary values for a given content.
   *
   * The glossary is bound to a step definition
   * The glossary provides translations for possible content
   *
   * how the mapping looks like:
   * processData: { changes: ["outsideBlinds"] }
   * json: {id: "stepAfterChanges", [... props and stuff ...] glossary: { changes: { value: outsideBlinds, text: Montage von Außenjalousien } } }
   *
   * hence: json -> glossary.changes.value === processData.changes => Montage von Außenjalousien is returned
   * */
  public getGlossaryText = (step: IStep, contentId: string, processData: IProcessData): string[] => {
    if (!processData[contentId]) {
      return ['#{' + contentId + '}'];
    }
    return processData[contentId].map(entry => {
      const texts = step.glossary[contentId].filter(e => e.value === entry).map(e => e.text);
      if (texts.length > 0) {
        return texts.join(', ');
      } else {
        return '#{' + entry + '}'; //indicate that we could not resolve the variable
      }
    });
  };

  /** returns true if the step can be skipped, transfers a decision to a new id if there is the respective content available */
  private noDecisionToBeMade = (stepId: string, processData: IProcessData, possibleContent: IContentData): boolean => {
    if (!possibleContent || !possibleContent[stepId]) {
      /* no possible content for the step id, so no decision to be made... */
      return true;
    } else if (possibleContent[stepId].decision && !possibleContent[stepId].content) {
      /*
       * there is possible content, but actually it has a decision property
       * and therefore is a 'passthrough' where the decision from for this step is copied
       * from another step
       *
       * ie. the changeDetails step can have the same value as the changes step.
       * however, it's also possible that at a certain step a processData value is overriden, e.g.,
       * after changeDetails was chosen the decision for rooms is overriden, because outsideBlinds always affect the whole flat
       *
       * So we copy over the decision and return true (because no decision needs to be made for this step)
       *
       * decisions look like:
       * decision: [
       *    {
       *      "<decisionsItAffects>": ["<values or decision of a different step to be carried over>"]
       *    }, {
       *        ... could have more of these, that's why we map over them ...
       *    }
       * ]
       */
      possibleContent[stepId].decision.forEach(decisionElement => {
        /**
         * we can access the key only by creating an array, although decisions always have one key
         */
        Object.keys(decisionElement).forEach(key => {
          /*
           * check if the value only contains one entry and if the entry is stored in processData,
           * e.g., "changeDetails": ["changes"] => processData[changes] is set already
           */
          if (decisionElement[key].length === 1 && processData[decisionElement[key][0]]) {
            processData[key] = processData[decisionElement[key][0]];
          } else {
            /*
             * otherwise we will just store the value under the key,
             * e.g., "rooms": ["wholeFlat"] => processData["wholeFlat"] is no where to be seen thus we save wholeFlat under rooms.
             *
             * the operator is an array and can specifiy that the decision in the content is to be removed, if it isn't set then we will set the content directly into processData
             */
            if (possibleContent[stepId].operator && possibleContent[stepId].operator === 'remove') {
              processData[key] = processData[key].filter(decision => !decisionElement[key].includes(decision));
            } else {
              processData[key] = decisionElement[key];
            }
          }
        });
      });
      /*
       * Depending on whether decisions are the only part of the possibleContent true or false is returned
       * if there is content for the next step and a decision to be passed through, then it will return false
       */
      return Object.keys(possibleContent[stepId]).length === 1;
    }
    /* We have possible content so we assume that a decision has to be made */
    return false;
  };

  /**
   * returns the previous step - removes determined options/documents from the current step,
   * because if a new decision is made the step might display different options/documents
   *
   * throws an error if we would go back to a negative step index
   */
  public getPreviousStep = (
    currentStepIndex: number,
    possibleContent: IContentData,
    processData: IProcessData
  ): INavigationResult => {
    let previousStep,
      backStepCounter = 0;
    /*
     * KUN-1135: really clone processData, so that the currentStep.pop() doesn't affect the actual processData object
     */
    const clonedProcessData = cloneDeep({ ...processData }, null);
    do {
      if (currentStepIndex - backStepCounter <= 0) {
        throw new Error('cannot step back any further');
      }
      /* if the step which is being stepped-back is the head of the currentStep then pop it */
      if (
        clonedProcessData.currentStep.length > 0 &&
        this.steps[currentStepIndex - backStepCounter] &&
        clonedProcessData.currentStep[clonedProcessData.currentStep.length - 1] ===
          this.steps[currentStepIndex - backStepCounter].id
      ) {
        clonedProcessData.currentStep.pop();
      }
      backStepCounter++;
      previousStep = this.steps[currentStepIndex - backStepCounter];
    } while (
      previousStep.isOptional &&
      (!possibleContent[previousStep.id] ||
        (possibleContent[previousStep.id].decision && Object.keys(possibleContent[previousStep.id]).length <= 1))
    );
    const clonedStep = this.cloneStepAndResolvePlaceholders(previousStep, processData);
    return {
      processData: clonedProcessData,
      step: clonedStep,
    } as INavigationResult;
  };

  /**
   * maps over all possibilities and checks for conditions that are already fulfilled with the saved processData
   * returns an empty object if there are no possibilities
   */
  public getAllPossibleMatchingContent = (processData: IProcessData) => {
    let content = {};
    if (!this.possibilities) {
      return content;
    }
    for (const possibilityKey of Object.keys(this.possibilities)) {
      const matchingPossibilities: ITaskPossibilityData[] = this.getMatchingConditions(
        this.possibilities[possibilityKey],
        processData
      );
      if (matchingPossibilities && matchingPossibilities.length > 0) {
        content[possibilityKey] = this.mapMatchingPossibilitiesToContent(matchingPossibilities);
      }
    }
    return content;
  };

  /** returns all possibility data (condition and content), if there is no condition the data will be returned anyway */
  private getMatchingConditions = (
    possibility: ITaskPossibility,
    processData: IProcessData
  ): ITaskPossibilityData[] => {
    const operator = possibility.operator;

    return possibility.data.filter((dataEntry: ITaskPossibilityData) => {
      if (!dataEntry.condition) {
        /* there is no condition for this possibility, so it is considered matched. */
        return true;
      } else {
        /* there is a condition, so we have to make sure that it matches. */
        let isMatch = true;
        Object.keys(dataEntry.condition).forEach(taskId => {
          if (!this.matchesCondition(processData, dataEntry, taskId, operator)) {
            isMatch = false;
          }
        });
        return isMatch;
      }
    });
  };

  /** checks if a condition is matched - that's the case if processData (for a certain taskId) is set and
   * (IF the operator is union (default) and processData contains some of the elements specified in the condition
   * OR IF the operator is intersect and everything in processData also is part of the condition)
   */
  private matchesCondition = (
    processData: IProcessData,
    dataEntry: ITaskPossibilityData,
    taskId: string,
    operator?: string
  ) => {
    /* in case there is no decision made, but the condition requires that there was decision */
    if (!this.madeDecisionForTask(processData, taskId) && !dataEntry.condition[taskId]) {
      return true;
    } else if (!this.madeDecisionForTask(processData, taskId) || !dataEntry.condition[taskId]) {
      return false;
    }
    return (
      ((!operator || operator === 'union') &&
        Array.from(processData[taskId]).some((condition: string) => dataEntry.condition[taskId].includes(condition))) ||
      (operator === 'intersect' &&
        Array.from(processData[taskId]).every((condition: string) => dataEntry.condition[taskId].includes(condition)))
    );
  };

  /**
   * returns the content enriched with the content regarding the matching possibilities
   * duplicates get filtered (that's why set is used).
   */
  private mapMatchingPossibilitiesToContent = (matchingPossibilities: ITaskPossibilityData[]) => {
    let newContent = {};
    for (const possibility of matchingPossibilities) {
      for (const contentName of Object.keys(possibility.content)) {
        if (newContent[contentName]) {
          newContent[contentName] = new Set(newContent[contentName].concat(possibility.content[contentName]));
        } else if (Array.isArray(possibility.content[contentName])) {
          // @ts-ignore
          newContent[contentName] = new Set(possibility.content[contentName]);
        } else {
          newContent[contentName] = possibility.content[contentName];
        }
        if (newContent[contentName] instanceof Array || newContent[contentName] instanceof Set) {
          newContent[contentName] = Array.from(newContent[contentName]);
        }
      }
    }
    return newContent;
  };
}
