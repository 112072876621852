import {
  IApplicationForm,
  IApplicationFormHistoryState,
  IStatusMapping,
} from '../../commons/api/applicationForm/ApplicationForm.interface';
import { IProcessData } from '../decisionTree/interfaces/DecisionTree.interface';
import { APPLICATION_FORM_BASE_ROUTE } from '../constants/routes';
import { INTERNAL_TO_EXTERNAL_REPRESENTATION_MAPPER } from '../constants/containers/internal-to-external-representation-mapper';
import { RequestTheme, RequestWorkflow } from '../constants/containers/request-theme';
import { newMoment } from '../../commons/utility/DateFunctions';
import { FORMATTER } from './formatHandler';
import React from 'react';
import { ITenancyContract } from '../../commons/api/tenantData/TenantData.interface';
import * as Sentry from '@sentry/react';
import { IApplicationFormLandingState } from '../containers/ApplicationFormLanding/ApplicationFormLanding';
import { STATUS } from '../constants/status';

class AFUtility {
  /**
   * builds an object that both Wizard and RequestDetails can handle
   */
  buildApplicationFormHistoryState(
    applicationForm: IApplicationForm,
    processData: IProcessData,
    backLink: string,
    landingState?: IApplicationFormLandingState
  ): IApplicationFormHistoryState {
    return {
      theme: applicationForm.theme,
      workflow: applicationForm.workflow,
      processData,
      formId: applicationForm.id,
      status: applicationForm.statusMappings[0].status,
      statusMappings: applicationForm.statusMappings,
      backlinkText: backLink,
      backlinkRoute: APPLICATION_FORM_BASE_ROUTE,
      backlinkState: landingState,
      title: this.getFormDescription(
        applicationForm.theme,
        applicationForm.workflow,
        processData,
        applicationForm.statusMappings
      ),
    };
  }

  /** returns a representative string for the internal value of the request */
  getFormDescription(
    theme: string,
    workflow: string,
    processData: IProcessData,
    statusMappings: IStatusMapping[]
  ): string {
    if (processData['summary'] && processData['summary']['Antrag']) {
      if (workflow === 'unknown') {
        return processData['summary']['Antrag'][0];
      } else if (workflow !== RequestWorkflow.REQUEST_SERVICE_CARD) {
        return `Antrag ${processData['summary']['Antrag']}`;
      }
    }
    // try because imported service-card anträge will cause issues here
    try {
      /* find the corresponding conversion for the chosen theme */
      const value = this.mapTaskId(theme, processData);
      /* and return the text of the mapping */
      if (workflow === RequestWorkflow.CONSTRUCTION || workflow === RequestWorkflow.REQUEST_SERVICE_CARD) {
        return value;
      } else {
        const latestStatusMapping = statusMappings[0];
        if (latestStatusMapping.status !== STATUS.expired.apiStatus) {
          return `${value} ${this.determineDateForDescription(theme, processData, latestStatusMapping)}`;
        } else {
          return `${value} ${this.determineDateForDescription(theme, processData, statusMappings[1])}`;
        }
      }
    } catch (e) {
      if (processData['summary'] && processData['summary']['Antrag']) {
        return `Antrag ${processData['summary']['Antrag']}`;
      }
      // this should never happen.
      Sentry.captureException('Antrag ohne summary.Antrag gefunden! Anzeige: Antrag unbekannt.');
      return 'Antrag unbekannt';
    }
  }

  private mapTaskId(theme: string, processData: IProcessData): string {
    const conversionItem = INTERNAL_TO_EXTERNAL_REPRESENTATION_MAPPER.conversions.filter(
      conversion => conversion.id === theme
    )[0];

    if (conversionItem[conversionItem.taskId]) {
      /*
       * find which value text pair is chosen for the taskId of the theme, i.e.,
       * theme: windowsAndDoors stores the kind of request under 'changeDetails' (which is the taskId and the name of the mapping array in internal-to-external-representation-mapper)
       * then filter out which value is included under changeDetails (in case of windowsAndDoors - might be different for other themes), take the first matching one
       */
      let matchingTextValuePair;
      if (processData[conversionItem.taskId]) {
        matchingTextValuePair = conversionItem[conversionItem.taskId].filter(textValuePair =>
          processData[conversionItem.taskId].includes(textValuePair.value)
        )[0];
      } else {
        matchingTextValuePair = conversionItem[conversionItem.fallbackTaskId].filter(textValuePair =>
          processData[conversionItem.fallbackTaskId].includes(textValuePair.value)
        )[0];
      }

      return matchingTextValuePair.text;
    }

    return conversionItem.name;
  }

  private determineDateForDescription(
    theme: string,
    processData: IProcessData,
    latestStatusMapping: IStatusMapping
  ): string {
    switch (theme) {
      case RequestTheme.MAIN_TENANT_CONFIRMATION:
        return newMoment(latestStatusMapping.changeDate).format('MMMM YYYY');
      case RequestTheme.RENTAL_ACCOUNT_INFO:
        return newMoment(latestStatusMapping.changeDate).format('D. MMMM YYYY');
      case RequestTheme.MONTHLY_RENT_DETAILS:
        return `${this.getMonthDescription(processData['chooseDate'])} ${processData['chooseYear']}`;
      default:
        return '';
    }
  }

  /*
   * If a main tenant confirmation was downloaded today, the expiration information will show the expiration
   * date in four weeks time. Despite it's easier to use, I didn't use moment here, because the dependency caused
   * performance issues in the past and it's only this calculation anyways.
   */
  changeDateInFourWeeks(date: number): number {
    return this.changeDateInDays(date, 28);
  }

  changeDateInDays(date: number, days: number): number {
    let statusMappingDate = newMoment(date);
    statusMappingDate = statusMappingDate.clone().add(days, 'day');
    return statusMappingDate.valueOf();
  }

  getMonthDescription(month: string): string {
    return [
      'Jänner',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ][parseInt(month)];
  }

  getDocumentDisplayName(documentName: string, removeUnderscores: boolean = false): string {
    if (documentName.includes('Ablehnung_Hemmung')) {
      return 'Ablehnung (Hemmung) als PDF';
    } else if (documentName.includes('Ablehnungsschreiben')) {
      return 'Ablehnungsschreiben als PDF';
    } else if (documentName.includes('Abgeschlossen')) {
      return 'Abschlussdokument als PDF';
    }
    if (removeUnderscores) return documentName.replace('WienerWohnen', 'Wiener Wohnen').split('_').join(' ');

    return documentName;
  }

  getRentalObjectFromContract(processData: IProcessData, tenancyContracts: ITenancyContract[]): JSX.Element {
    if (!tenancyContracts) return null;
    if (!processData['rentalObject']) return null;

    const selectedContract = tenancyContracts.find(
      tenancyContract => tenancyContract.tplnr === processData['rentalObject'][0]
    );
    if (selectedContract) {
      return (
        <>
          <em>{selectedContract.xmbez}</em> {FORMATTER.formatAddressWithCity(selectedContract)}
        </>
      );
    } else {
      return null;
    }
  }

  getRentalObjectFromSummary(processData: IProcessData): JSX.Element | string {
    if (processData['summary'] && processData['summary']['Mietobjekt']) {
      const rentalObjectString = processData['summary']['Mietobjekt'][0];
      return FORMATTER.highlightRentalObjectType(rentalObjectString);
    }
    return null;
  }
}

export const ApplicationFormUtility = new AFUtility();
