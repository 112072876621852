export const LANDING = '/';
export const REGISTRATION = '/registrierungwienerwohnen';
export const REGISTRATION_CONFIRMATION = '/registrierungwienerwohnen/stadtwienerstellt';
export const PROFILE = '/profil';
export const RENTAL_ACCOUNT = '/mietzinskonto';
export const IMPRESSUM = '/impressum';
export const DATA_PROTECTION = '/datenschutz';
export const CONTACT = '/kontakt';
export const FAQ = '/fragen';
export const AGB = '/allgemeine-geschäftsbedingungen';
export const PRELANDING = '/anmelden';
export const LOGIN_REDIRECT = '/loginredirect';
export const OAUTH_TOKEN_RETRIEVER = '/oauthtokenretriever';
export const SERVICE_CARD = '/service-karte';
export const LAUNDRY_DAY = '/waschtage';
export const LOGOUT = '/logout';
export const INVALID_CONTRACT = '/abgelaufen';
export const WHATS_NEW = '/neuigkeiten';
