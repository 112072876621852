import React from 'react';
import { Hyperlink } from 'digit.commons.ui-components-app';
import { CUSTOMER_SERVICE_NUMBER, TENANT_PORTAL_SERVICE_NUMBER } from '../component-labels';

export const CONTACT_LABELS = {
  title: 'Kontakt',
  pageTitle: 'Kontakt - Wiener Wohnen Mieter*innenportal',
  meta: '',
  addressInfo: 'U3 Station Gasometer',
  street: 'Rosa-Fischer-Gasse 2',
  city: '1030 Wien',
  openingHours: 'Öffnungszeiten',
  serviceNumberTitle: 'Service-Nummern',
  serviceNumber: 'Wiener Wohnen Service-Nummer',
  serviceNumberInfo: (
    <>
      Rund um die Uhr erreichbar für Infos, Schadensmeldungen, Beschwerden oder auch Lob:{' '}
      <Hyperlink id={'contact-service-number'} to={`tel: ${CUSTOMER_SERVICE_NUMBER}`} customHref={true} isInline>
        {CUSTOMER_SERVICE_NUMBER}
      </Hyperlink>
    </>
  ),
  helpDesk: 'Helpdesk für das Portal',
  helpDeskInfo: (
    <>
      Bei technischen Problemen mit der App oder dem Portal:{' '}
      <Hyperlink id={'contact-helpdesk-number'} to={`tel: ${TENANT_PORTAL_SERVICE_NUMBER}`} customHref={true} isInline>
        {TENANT_PORTAL_SERVICE_NUMBER}
      </Hyperlink>
    </>
  ),
  onlineContactTitle: 'Online-Kontakt',
  onlineContactLabel: 'Für Infos, Schadensmeldungen, Beschwerden oder auch Lob.',
  toContactForm: 'Zum Kontaktformular',
  serviceCenterTitle: 'Service-Center',
  addressTitle: 'Adresse',
  drive: 'Anfahrt',
  toRoutePlanner: 'Zum Wiener Linien Routenplaner',
  caretakerTitle: 'Hausbesorger*in',
  neighborhoodPoliceTitle: 'Grätzlpolizist*in',
  contactOnSite: 'Kontakte vor Ort',
  tenantAdvisoryBoardTitle: 'Vorsitz Mieterbeirat',
  appointmentNote: (
    <>
      <strong>Bitte beachten Sie:</strong> Seit 15.06.2020 müssen Termine im Service-Center zuvor über{' '}
      <Hyperlink
        id={'contact-service-number-1'}
        to={`tel: ${CUSTOMER_SERVICE_NUMBER}`}
        customHref={true}
        isInline={true}
      >
        {CUSTOMER_SERVICE_NUMBER}
      </Hyperlink>{' '}
      vereinbart werden.
    </>
  ),
};
