import { IContentData, IPossibleContent } from '../decisionTree/interfaces/PossibleContent.interface';
import { IDecisionCallback, IProcessData, IStep } from '../decisionTree/interfaces/DecisionTree.interface';
import { FORMATTER } from './formatHandler';
import { IApplicationForm } from '../../commons/api/applicationForm/ApplicationForm.interface';
import { RequestTheme, RequestWorkflow, WizardMode } from '../constants/containers/request-theme';
import DecisionTreeLogic from '../decisionTree/logic/DecisionTreeLogic';
import { WIZARD_LABELS } from '../constants/containers/wizard-labels';
import { ITenancyContract } from '../../commons/api/tenantData/TenantData.interface';
import { STATUS } from '../constants/status';

class AFWizUtility {
  isCloseStep(possibleContent: IPossibleContent, currentStep: IStep) {
    return (
      possibleContent &&
      possibleContent[currentStep.id] &&
      (possibleContent[currentStep.id].isCloseStep || possibleContent[currentStep.id].isDoubleSubmission)
    );
  }

  convertTenancyContractToAddress(tenancyContract: ITenancyContract): string {
    return `${tenancyContract.xmbez} \u00B7 ${FORMATTER.formatAddressWithCity(tenancyContract)}`;
  }

  mapTplnrToTenancyContract(tenancyContracts: ITenancyContract[], tplnr: string): ITenancyContract {
    return tenancyContracts.find(tenancyContract => tenancyContract.tplnr === tplnr);
  }

  getDoubleSubmission(
    possibleContent: IContentData,
    step: IStep,
    doubleSubmissions: IApplicationForm[]
  ): IApplicationForm {
    if (!possibleContent || !possibleContent[step.id] || !doubleSubmissions || doubleSubmissions.length === 0)
      return null;

    return possibleContent[step.id].applicationFormIds
      ? doubleSubmissions.find(applicationForm => applicationForm.id === possibleContent[step.id].applicationFormIds[0])
      : step.id === 'confirmation'
      ? null
      : doubleSubmissions[0];
  }

  getTitle(
    possibleContent: IContentData,
    processData: IProcessData,
    decisionTreeLogic: DecisionTreeLogic,
    currentStep: IStep,
    doubleSubmission: IApplicationForm
  ): string {
    if (
      possibleContent &&
      possibleContent[currentStep.id] &&
      possibleContent[currentStep.id].title &&
      decisionTreeLogic
    ) {
      return decisionTreeLogic.replacePlaceholders(possibleContent[currentStep.id].title, currentStep, processData);
    } else if (doubleSubmission) {
      return WIZARD_LABELS.doubleSubmissionSentStepName;
    } else if (currentStep) {
      return currentStep.title;
    }
    return '';
  }

  getThemeInGerman(theme: string) {
    let type: string;
    switch (theme) {
      case RequestTheme.WINDOWS_AND_DOORS:
        type = 'Fenster und Türen';
        break;
      case RequestTheme.ELECTRICAL_INSTALLATION:
        type = 'Elektro-Installation';
        break;
      case RequestTheme.TV_INSTALLATION:
        type = 'Medienanschluss und SAT-Antenne';
        break;
      case RequestTheme.BATH_TUB_AND_SHOWER:
        type = 'Wasser und Sanitär';
        break;
      case RequestTheme.MAIN_TENANT_CONFIRMATION:
        type = 'Hauptmieterbestätigung';
        break;
      case RequestTheme.MONTHLY_RENT_DETAILS:
        type = 'Zinszettel';
        break;
      case RequestTheme.RENTAL_ACCOUNT_INFO:
        type = 'Mietzins-Informationen';
        break;
      case RequestTheme.REQUEST_SERVICE_CARD:
        type = 'Service-Karte nachbestellen';
        break;
      default:
        type = 'unbekannt';
    }
    return type;
  }

  getChangesInGerman(change: string) {
    let changes: string;
    switch (change) {
      case 'replaceBathtubWithShower':
        changes = 'Dusche statt Wanne einbauen';
        break;
      case 'renewShower':
        changes = 'Dusche erneuern';
        break;
      case 'renewBathtub':
        changes = 'Wanne erneuern';
        break;
      case 'replaceShowerWithBathtub':
        changes = 'Wanne statt Dusche einbauen';
        break;
      case 'installInternet':
        changes = 'Internet-Anschluss';
        break;
      case 'installTV':
        changes = 'TV-Anschluss';
        break;
      case 'installAlarm':
        changes = 'Alarmanlage installieren';
        break;
      case 'installHeavyCurrent':
        changes = 'Starkstrom-Leitung installieren';
        break;
      case 'installAC':
        changes = 'Wechselstrom-Leitung installieren';
        break;
      case 'outsideBlinds':
        changes = 'Außenjalousien oder Außenrollläden montieren';
        break;
      case 'moveDoor':
        changes = 'Tür versetzen';
        break;
      case 'shutDoor':
        changes = 'Tür zumauern';
        break;
      case 'marquee':
        changes = 'Markise oder Sichtschutz montieren';
        break;
      case 'windowNet':
        changes = 'Katzengitter oder Fenstergitter montieren';
        break;
      case 'exchangeWindowAndDoors':
        changes = 'Fenster oder Türen tauschen';
        break;
      case 'flowerBox':
        changes = 'Blumenkasten montieren';
        break;
      case 'safetyDoor':
        changes = 'Sicherheitstüre einbauen';
        break;
      case 'additionalServiceCard':
        changes = 'Zusätzliche Service-Karte anfordern';
        break;
      case 'serviceCardLoss':
        changes = 'Verlorene Service-Karte nachbestellen';
        break;
      case 'serviceCardTheft':
        changes = 'Gestohlene Service-Karte nachbestellen';
        break;
      default:
        changes = 'unbekannt';
    }
    return changes;
  }

  assignValueToLatestKeyOfNestedProcessDataEntry(decision: IDecisionCallback, processDataStateCopy: IProcessData) {
    const props = decision.key.split('.');
    const lastKey = props.pop();
    const nestedObj = props.reduce((a, prop) => {
      if (!a[prop]) a[prop] = {};
      return a[prop];
    }, processDataStateCopy);
    nestedObj[lastKey] = decision.value;
  }

  getNestedProcessData(processData: IProcessData, path: string, defaultValue?: any): any {
    const nestedPath = path.split('.');
    return this.getNestedValue(processData, nestedPath, defaultValue);
  }

  /* recursive method to get to the value within a nested object */
  private getNestedValue(processData: IProcessData, path: string[], defaultValue?: any): any {
    if (path.length === 1 && processData[path[0]]) return processData[path[0]];
    else if (!processData[path[0]]) return defaultValue ?? undefined;
    return this.getNestedValue(processData[path[0]], path.slice(1), defaultValue);
  }

  deleteFromProcessDataByPath(processData: IProcessData, path: string) {
    let currentObject = processData;
    const parts = path.split('.');
    const last = parts.pop();
    for (const part of parts) {
      currentObject = currentObject[part];
      if (!currentObject) {
        return;
      }
    }
    delete currentObject[last];
  }

  getNavigationTitle(workflow: string, editMode: WizardMode, latestStatus: string) {
    switch (workflow) {
      case RequestWorkflow.CONSTRUCTION:
      case RequestWorkflow.REQUEST_SERVICE_CARD:
        return WIZARD_LABELS.handInRequest;
      case RequestWorkflow.DOCUMENT:
        return WIZARD_LABELS.requestDocument;
      default:
        if (editMode === WizardMode.ADD_INFORMATION) {
          return WIZARD_LABELS.handInInformation;
        } else if (latestStatus === STATUS.hb_info_pending.apiStatus) {
          return WIZARD_LABELS.hbAnswerTitle;
        } else if (latestStatus === STATUS.hb_finish_pending.apiStatus) {
          return WIZARD_LABELS.hbFinish;
        }
        return WIZARD_LABELS.handInRequest;
    }
  }
}

export const AFWizardUtility = new AFWizUtility();
