import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { ITemplate, ITemplateHandles } from '../interfaces/Template.interface';
import { Dropdown, Fieldset } from 'digit.commons.ui-components-app';
import { CALENDAR_TEMPLATE_LABELS } from '../../constants/decisionTree/template-labels';
import './Template.scss';
import { IDecisionCallback } from '../interfaces/DecisionTree.interface';
import { Moment } from 'moment-timezone';
import { newMoment } from '../../../commons/utility/DateFunctions';
import { Option } from 'digit.commons.ui-components-app/dist/lib/Dropdown/Dropdown.interface';

const moreThanSevenYearsAgo = (date: Moment): boolean => newMoment().year() - date.year() > 7;

const oneMonthFrom = (date: Moment): Moment => {
  const d = newMoment(date);
  if (d.month() === 11) {
    d.year(d.year() + 1);
    d.month(0);
  } else {
    d.month(d.month() + 1);
  }
  return d;
};

const calcFromMonthAndYear = (fromDate: Moment) => {
  const now = newMoment();
  return moreThanSevenYearsAgo(fromDate)
    ? //when >7 years, user can request monthly rent details max 7 years in the past
      {
        fromYear: oneMonthFrom(now).year() - 7,
        fromMonth: oneMonthFrom(now).month(),
      }
    : //if the rental contract is shorter than 7 years, the month where the contract started is available.
      {
        fromYear: fromDate.year(),
        fromMonth: fromDate.month(),
      };
};

const calcToMonthAndYear = validTo => {
  const oneMonthFromNow = oneMonthFrom(newMoment());
  return !validTo || validTo.isAfter(oneMonthFromNow)
    ? {
        toYear: oneMonthFromNow.year(),
        toMonth: oneMonthFromNow.month(),
      }
    : {
        toYear: validTo.year(),
        toMonth: validTo.month(),
      };
};

const constrainToRange = (min, preferred, max) => constrainMax(constrainMin(preferred, min), max);
const constrainMin = (value, min) => (min != null ? Math.max(min, value) : value);
const constrainMax = (value, max) => (max != null ? Math.min(max, value) : value);

const CalendarTemplate: React.ForwardRefRenderFunction<ITemplateHandles, ITemplate> = (props, ref) => {
  const stepId = props.step.id;
  const { maxYears } = props.step.props;

  const validFrom = newMoment(props.possibleContent[stepId].contractDuration.validFrom);
  const validTo = newMoment(props.possibleContent[stepId].contractDuration.validTo);

  const [years, setYears] = useState<Option[]>([]);
  const [months, setMonths] = useState<Option[]>([]);
  /* to keep track of the selected month when the user is switching between years after selecting a month */
  const [selectedYear, setSelectedYear] = useState<string>('');
  const [selectedMonth, setSelectedMonth] = useState<string>('');

  const { fromYear, fromMonth } = calcFromMonthAndYear(validFrom);
  const { toYear, toMonth } = calcToMonthAndYear(validTo);
  const today = newMoment();
  const yearDiff = maxYears ? Math.min(toYear - fromYear, maxYears) : toYear - fromYear;

  const MONTHS = [
    'Jänner',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ];

  const {
    yearTitle,
    yearTitleOneOption,
    yearErrorMessage,
    monthTitle,
    monthTitleOneOption,
    monthErrorMessage,
  } = CALENDAR_TEMPLATE_LABELS;

  useImperativeHandle(ref, () => ({
    validateTemplate: () => {
      return true;
    },
  }));

  useEffect(() => {
    renderYears();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedYear === '') {
      setSelectedYear(toYear.toString());
    } else {
      prepareMonths(Number(selectedYear));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [years, selectedYear]);

  useEffect(() => {
    if (selectedMonth !== '') {
      props.decisionCallback(stepId, toDecisionValue(new Set<string>().add(selectedMonth)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth]);

  const renderYears = () => {
    let yearOptions: Option[] = [];

    for (let year = toYear; year >= toYear - yearDiff; year--) {
      yearOptions.push({ value: year.toString(), text: year.toString() });
    }
    setYears([...yearOptions].reverse());
  };

  const prepareMonths = (year: number) => {
    const monthsOptions: Option[] = MONTHS.map((item, idx) => {
      if (year < fromYear || (year === fromYear && idx < fromMonth) || (year === toYear && idx > toMonth)) {
        return;
      }

      return { value: `${idx}`, text: item };
    }).filter(month => !!month);
    setMonths(monthsOptions);
    let decisions: IDecisionCallback[] = [];
    /*
     * if no month has been selected manually or the selected month is after the current month of the current year we (re-)set selectedMonth
     * in case the contract is no longer valid the preselected month is the last month of the valid contract otherwise it's the current month
     */
    const preferredMonth =
      selectedMonth !== '' ? parseInt(selectedMonth) : validTo && validTo.year() === toYear ? toMonth : today.month();
    const minMonth = year === fromYear ? fromMonth : null;
    const maxMonth = year === toYear ? toMonth : null;
    const calculatedMonth = constrainToRange(minMonth, preferredMonth, maxMonth);
    if (calculatedMonth.toString() !== selectedMonth) {
      setSelectedMonth(calculatedMonth.toString());
      decisions.push({ key: stepId, value: [].concat(calculatedMonth.toString()) });
    }
    decisions.push({ key: 'chooseYear', value: [].concat(year.toString()) });
    props.decisionCallback(stepId, decisions);
  };

  const toDecisionValue = (selection: Set<string>): any => {
    return [].concat({ key: stepId, value: Array.from(selection.values()) });
  };

  return (
    <>
      {selectedYear && (
        <>
          <h3 className={'Template__heading-request'} id={'wizard-calendar-year'}>
            {yearTitle}
          </h3>
          <p className={'Template__paragraph'} id={'wizard-calendar-year-paragraph'}>
            {props.step.props.yearDescription}
          </p>
          <Dropdown
            id={'calendar-year'}
            options={years}
            label={years.length > 1 ? yearTitle : yearTitleOneOption}
            required={true}
            onChange={selection => setSelectedYear(selection as string)}
            errorMessage={yearErrorMessage}
            value={selectedYear}
          />
        </>
        // TODO: remove because it looks like shit on mobile
        // <Fieldset
        //   id={'calendar-year'}
        //   className="Template__fieldset"
        //   title={yearTitle}
        //   description={props.step.props.yearDescription}
        //   options={years}
        //   multiple={false}
        //   flow={'grid'}
        //   cols={4}
        //   size={'small'}
        //   errorMessage={yearErrorMessage}
        //   required={true}
        //   preselect={[selectedYear]}
        //   onChange={selection => {
        //     setSelectedYear(Array.from(selection)[0]);
        //   }}
        //   ref={fieldsetYearRef}
        // />
      )}
      {selectedMonth && (
        <>
          <h3 className={'Template__heading-request'} id={'wizard-calendar-month'}>
            {monthTitle}
          </h3>
          <p className={'Template__paragraph'} id={'wizard-calendar-month-paragraph'}>
            {props.step.props.monthDescription}
          </p>
          <Dropdown
            id={'calendar-months'}
            label={months.length > 1 ? monthTitle : monthTitleOneOption}
            options={months}
            errorMessage={monthErrorMessage}
            required={true}
            onChange={selection => setSelectedMonth(selection as string)}
            value={selectedMonth}
          />
        </>
        // TODO: remove because it looks like shit on mobileﬂ
        // <Fieldset
        //   id={'calendar-months'}
        //   //Fieldset.selectedMonth prop is not dynamic - so remount when selected month changes
        //   key={`selected-months-${selectedMonth}`}
        //   className="Template__fieldset"
        //   title={monthTitle}
        //   options={months}
        //   errorMessage={monthErrorMessage}
        //   description={props.step.props.monthDescription}
        //   flow={'grid'}
        //   cols={3}
        //   required={true}
        //   preselect={[selectedMonth]}
        //   onChange={selection => {
        //     setSelectedMonth(Array.from(selection)[0]);
        //   }}
        //   ref={fieldsetMonthRef}
        // />
      )}
    </>
  );
};

export default forwardRef(CalendarTemplate);
