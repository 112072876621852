import { IExtendedError } from '../ExtendedError.interface';

import LaundryDaySCSInitializer from '../../../laundryday/LaundryDaySCSInitializer';
import { IGraphQL } from '../GraphQL.interface';
import { IAnnouncements } from './Announcement.interface';
import { mutationCloseAnnouncement, queryAnnouncements } from './Announcement.graphql';
import { AbstractRequestApi } from '../AbstractRequestApi';

class LaundryDayAnnouncementApi extends AbstractRequestApi {
  constructor() {
    super(
      () => LaundryDaySCSInitializer.apiConfig().laundryDayApi,
      'LaundryDayAnnouncementApi',
      () => LaundryDaySCSInitializer.queryConfig(),
      () => LaundryDaySCSInitializer.authenticationRequiredHandler()
    );
  }

  fetchAnnouncements(): Promise<IGraphQL<IAnnouncements>> {
    return this.makeCacheableGraphQlRequest(
      LaundryDaySCSInitializer.apiConfig().paths.pathToGraphQL,
      queryAnnouncements(),
      'queryLaundryDayAnnouncements'
    ).then((response: any) => {
      response.data.getAnnouncements.forEach(it => {
        it.closeFunction = () => this.closeAnnouncement(it.id);
      });

      return {
        data: {
          announcements: response.data.getAnnouncements,
        },
        extensions: response.data.extensions,
      } as IGraphQL<IAnnouncements>;
    });
  }
}

export default new LaundryDayAnnouncementApi();
