import ExtendedError from './ExtendedError';
import { Method } from './ApiMethodEnum';
import { IExtendedError } from './ExtendedError.interface';
import { Configuration, IQueryConfig } from '../../Configuration';
import BridgeApi from './Bridge';
import { ERRORBOX_MESSAGE } from '../../laundryday/constants/errors';
import * as Sentry from '@sentry/react';
import { IGraphQL } from './GraphQL.interface';
import { IAnnouncements } from './announcement/Announcement.interface';
import { mutationCloseAnnouncement, queryAnnouncements } from './announcement/Announcement.graphql';

const STD_PORTAL_ERROR_HEADER = 'X-STDPORTAL-ERROR';
const STD_PORTAL_ERROR_AUTHENTICATION_REQUIRED = 'AUTHENTICATION_REQUIRED';
const ANTI_CSRF_TOKEN_HEADER = 'X-CSRF-Token';
const INVALID_CSRF_TOKEN_SC = 490; // Custom HTTP Status code

export abstract class AbstractRequestApi {
  protected constructor(
    hostResolver: () => string,
    orig: string,
    queryConfigResolver: () => IQueryConfig,
    onSdtpAuthenticationRequired: () => () => void = () => () => {}
  ) {
    this.orig = orig;
    this.hostResolver = hostResolver;
    this.queryConfigResolver = queryConfigResolver;
    this.onSdtpAuthenticationRequired = onSdtpAuthenticationRequired;
  }

  defaultHeader = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  readonly orig: string;
  readonly hostResolver: () => string;
  queryConfigResolver: () => IQueryConfig;
  readonly onSdtpAuthenticationRequired: () => () => void;
  lastUsedGraphQLQuery: any;
  antiCsrfTokenFetchTryCount: number = 0;

  requestCount: number = 0;

  makeCacheableGraphQlRequest(url: string, query: string, operationName: string, variables?: any): Promise<any> {
    const queryStr = query
      .replace(/{\\n}+/g, ' ')
      .replace(/\s+/g, ' ')
      .replace(/{operationName}/g, operationName);
    const variablesStr = variables ? `&variables=${encodeURIComponent(JSON.stringify(variables))}` : '';
    return this._makeRequest(
      `${url}?operationName=${operationName}&query=${encodeURIComponent(queryStr)}${variablesStr}`,
      Method.GET
    );
  }

  makeCacheDisabledGraphQlRequest(url: string, query: string, operationName: string, variables?: any): Promise<any> {
    const queryStr = query
      .replace(/{\\n}+/g, ' ')
      .replace(/\s+/g, ' ')
      .replace(/{operationName}/g, operationName);
    const variablesStr = variables ? `&variables=${encodeURIComponent(JSON.stringify(variables))}` : '';
    return this._makeRequest(
      `${url}?operationName=${operationName}&query=${encodeURIComponent(queryStr)}${variablesStr}`,
      Method.GET,
      undefined,
      {
        pragma: 'no-cache',
        'cache-control': 'no-cache',
      }
    );
  }

  postGraphQlRequest(url: string, operationName: string, body?: any, headers?: any): Promise<any> {
    const newBody = {
      ...body,
      query: body.query.replace(/{operationName}/g, operationName),
      operationName: operationName,
    };
    return this._makeRequest(`${url}?operationName=${operationName}`, Method.POST, newBody, headers);
  }

  async _makeRequest<T>(url: string, method: Method, body?: any, headers?: any): Promise<T> {
    return this.__makeRequest(1, url, method, body, headers);
  }

  private async __makeRequest<T>(
    callDepth: number,
    url: string,
    method: Method,
    body?: any,
    headers?: any
  ): Promise<T> {
    return (
      BridgeApi.getOAuthTokenPromise()
        /*
         * If a new refreshed OAuth token is pending then the promise won't have resolved yet.
         * Otherwise the promise is an already resolved OAuth token
         */
        .then(oathToken => {
          return this._makeRequest_with_oath(url, method, oathToken, body, headers).then(
            (result: T) => {
              // ajax request suceeded
              return result;
            },
            error => {
              /*
               * The ajax request failed so if the bridge can provide us with a fresh OAuth token then we should try again...
               * The bridge always waits some time between OAuth token refresh attempts, preventing us from always rerequesting on failure
               */
              if (
                callDepth === 1 &&
                (BridgeApi.askToRefreshOAuthToken() ||
                  BridgeApi.isBeingRefreshed(oathToken) ||
                  BridgeApi.hasChanged(oathToken))
              ) {
                // 'OAuth token ', oathToken, ' is no longer considered fresh, re-executing failed ajax request'
                return this.__makeRequest(callDepth + 1, url, method, body, headers);
              } else {
                // ajax request failed
              }
              throw error;
            }
          );
        })
    );
  }

  async _makeRequest_with_oath<T>(
    url: string,
    method: Method,
    oathToken?: string,
    body?: any,
    headers?: any
  ): Promise<T> {
    const newHeaders = this._addHeader(headers);
    const formData = new FormData();

    if (body instanceof File) {
      formData.append('file', body);
    }

    if (oathToken != null) {
      newHeaders['Authorization'] = 'Bearer ' + oathToken;
    } else {
      delete newHeaders['Authorization'];
    }

    if (window['failEvery5'] && this.requestCount % 5 === 0) {
      url = 'XXX';
    }
    this.requestCount = this.requestCount + 1;
    try {
      const response = await this.timeoutWrapper(
        this.getTimeout(),
        fetch(this.hostResolver() + url, {
          headers: body instanceof File ? {} : newHeaders,
          body: body instanceof File ? formData : JSON.stringify(body) || undefined,
          credentials: 'same-origin',
          method,
        })
      );

      if (!response.ok) {
        const type = response.headers.get('content-type');
        let errorMsg: string = '';
        if (type && type.startsWith('application/json')) {
          errorMsg = await response.json();
        } else if (response.body) {
          errorMsg = await response.text();
        }
        throw new ExtendedError(response.status, errorMsg, this.orig);
      }

      // we get here only with a HTTP 200 and the X-STDPORTAL-ERROR header when we have a invalid refresh token!!!
      // in the case of an invalid oauth token the AWP would answers with HTTP 400 which leads to an token refresh attempt
      // in the default __makeRequest code
      // BridgeApi.getOAuthTokenPromise().then(oathToken => { return this._makeRequest_with_oath(url, method, oathToken, body, headers).then(
      //           (result: T) => {
      //             return result;
      //           },
      //           error => {
      //              ....
      // see code above!
      let stdpError = response.headers ? response.headers.get(STD_PORTAL_ERROR_HEADER) : '';
      let stdpAuthenticationRequired = stdpError && stdpError === STD_PORTAL_ERROR_AUTHENTICATION_REQUIRED;
      if (stdpAuthenticationRequired) {
        this.onSdtpAuthenticationRequired()();
        throw new ExtendedError(403, STD_PORTAL_ERROR_AUTHENTICATION_REQUIRED, this.orig);
      }
      if (response.status === INVALID_CSRF_TOKEN_SC) {
        throw new ExtendedError(response.status, 'Anti-CSRF token invalid or missing', this.orig);
      }
      // Save the anti-csrf token to sessionStorage, if we got one
      this.saveAntiCsrfToken(url, method, response, headers);

      if (response.headers.get('Content-Type') === 'application/pdf') {
        return response;
      } else {
        return response.json().then((data: any) => this.checkForErrorData(data));
      }
    } catch (error) {
      // If we get the "Anti-CSRF token missing or invalid" error, we try to call
      // the last successfull GraphQL-Query (GET) that was sent with the token.
      // Then, we retry the original call (this retry is a different mechanism than the retry via callDepth)
      if (error.code === INVALID_CSRF_TOKEN_SC) {
        window.sessionStorage.removeItem(ANTI_CSRF_TOKEN_HEADER);

        if (this.antiCsrfTokenFetchTryCount === 0 && this.lastUsedGraphQLQuery) {
          this.antiCsrfTokenFetchTryCount++;
          // Replay the last GraphQL Query to obtain a new token
          await this._makeRequest(
            this.lastUsedGraphQLQuery.url,
            Method.GET,
            undefined,
            this.lastUsedGraphQLQuery.headers
          );
          // Retry the original request
          return this._makeRequest(url, method, body, headers);
        }
        // If no GraphQL-Query recorded, or we are trying the second time, humbly accept failure
      }
      let actualError = error;
      if (!(error instanceof ExtendedError)) {
        actualError = new ExtendedError(
          503,
          error.message,
          this.orig,
          `fetch failed for ${url.split('&query=')[0]}`,
          error.stack
        );
      }
      Sentry.captureException(actualError);
      throw actualError;
    }
  }

  getTimeout(): number {
    return this.queryConfigResolver().timeoutInMillis;
  }

  checkForErrorData(data: any) {
    if (data.errors) {
      return Promise.reject(new ExtendedError(503, `FOUND ${data.errors.length} ERRORS`, null));
    }
    return data;
  }

  handleResponse(error: IExtendedError, contextInfo?: string): never {
    if (error.code === 404) {
      error.message = ERRORBOX_MESSAGE.dataNotFound;
    }

    error.contextInfo = contextInfo;

    throw error;
  }

  _addHeader(optionalHeaders?: any): any {
    const headers = this.defaultHeader;

    if (window.sessionStorage.getItem(ANTI_CSRF_TOKEN_HEADER)) {
      headers[ANTI_CSRF_TOKEN_HEADER] = window.sessionStorage.getItem(ANTI_CSRF_TOKEN_HEADER);
    }
    if (optionalHeaders) {
      Object.keys(optionalHeaders).forEach(key => (headers[key] = optionalHeaders[key]));
    }
    return headers;
  }

  /**
   * If the response returned the Anti-CSRF token in the header, this methods saves it in browser's sessionStorage
   */
  saveAntiCsrfToken(url: string, method: Method, response: Response, headers?: any) {
    if (method === Method.GET && response.headers.get(ANTI_CSRF_TOKEN_HEADER)) {
      window.sessionStorage.setItem(ANTI_CSRF_TOKEN_HEADER, response.headers.get(ANTI_CSRF_TOKEN_HEADER));
      this.antiCsrfTokenFetchTryCount = 0;
      this.lastUsedGraphQLQuery = {
        url,
        headers,
      };
    }
  }

  timeoutWrapper(millis: number, promise): Promise<any> {
    return new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        reject(new ExtendedError(503, `Timeout of ${millis} reached while contacting service.`, 'GeneralError'));
      }, millis);
      promise.then(
        res => {
          clearTimeout(timeoutId);
          resolve(res);
        },
        err => {
          clearTimeout(timeoutId);
          reject(err);
        }
      );
    });
  }

  fetchAnnouncements(): Promise<IGraphQL<IAnnouncements>> {
    return this.makeCacheableGraphQlRequest(
      Configuration.apiConfig().paths.pathToGraphQL,
      queryAnnouncements(),
      'fetchAnnouncements'
    ).then((response: any) => {
      response.data.getAnnouncements.forEach(it => (it.closeFunction = () => this.closeAnnouncement(it.id)));
      return {
        data: {
          announcements: response.data.getAnnouncements,
        },
        extensions: response.data.extensions,
      } as IGraphQL<IAnnouncements>;
    });
  }

  closeAnnouncement(id: string): Promise<boolean> {
    const query = {
      query: mutationCloseAnnouncement(id),
    };
    return this.postGraphQlRequest(Configuration.apiConfig().paths.pathToGraphQL, 'closeAnnouncement', query)
      .then<boolean>((response: any) => response.data.closeAnnouncement)
      .catch((error: IExtendedError) => {
        this.handleResponse(error);
        return null;
      });
  }
}
