import React from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Data,
  Hyperlink,
  Key,
  SVG,
  Value,
} from 'digit.commons.ui-components-app';
import { RENTAL_OBJECT_ICON } from '../../constants/containers/rental-account-labels';
import { CONTACT_LABELS } from '../../constants/containers/contact-labels';
import {
  IComplexDetails,
  IContact,
  IContactInformation,
} from '../../../commons/api/buildingComplex/BuildingComplex.interface';
import { ITenancyContract } from '../../../commons/api/tenantData/TenantData.interface';
import { CONTACT_CARD } from '../../constants/component-labels';
import { CONTACT } from '../../constants/routes';
import './LocalContactCard.scss';

interface ILocalContactCardProps {
  id: string;
  tenancyContract: ITenancyContract;
  complexDetails: IComplexDetails;
  complexContactInformation: IContactInformation[];
  index: number;
  showFooter?: boolean;
}

interface IContactInfo {
  tenancyContract: ITenancyContract;
  caretaker: IContactInformation[];
  tenantAdvisoryBoard: IContactInformation[];
  neighborhoodPolice: IContactInformation[];
  estateName: string;
}

const LocalContactCard: React.FC<ILocalContactCardProps> = props => {
  const contact: IContactInfo = toContactInfo(props);
  if (!contact) {
    //nothing to render
    return null;
  }

  return (
    <Card
      className={'LocalContactCard'}
      key={`contact-${props.id}`}
      id={`${props.id}-${
        contact.estateName
          ? contact.estateName.replace(/ /g, '-')
          : contact.tenancyContract.streetName.replace(/ /g, '-')
      }-${props.index}`}
    >
      <CardHeader
        srOnlyTitle={toEstateNameHeader(contact)}
        icon={
          RENTAL_OBJECT_ICON[contact.tenancyContract.xmbez]
            ? RENTAL_OBJECT_ICON[contact.tenancyContract.xmbez]
            : SVG.rental_object_flat
        }
        title={CONTACT_LABELS.contactOnSite}
        subtext1={getEstateNameOrBlank(contact)}
        subtext2={concatEstateStreetName(contact)}
      />
      <CardBody>
        {contact.caretaker && renderCareTaker(contact.caretaker, contact.estateName, props.index)}
        {contact.tenantAdvisoryBoard &&
          renderAdvisoryBoard(contact.tenantAdvisoryBoard, contact.estateName, props.index)}
        {contact.neighborhoodPolice &&
          renderNeighborhoodPolice(contact.neighborhoodPolice, contact.estateName, props.index)}
      </CardBody>
      {props.showFooter ? (
        <CardFooter>
          <Hyperlink
            to={CONTACT}
            id={`contact-info-${
              contact.estateName
                ? contact.estateName.replace(/ /g, '-')
                : contact.tenancyContract.streetName.replace(/ /g, '-')
            }-${props.index}`}
            iconAfter={SVG.arrow_right}
          >
            {CONTACT_CARD.link}
          </Hyperlink>
        </CardFooter>
      ) : (
        <></>
      )}
    </Card>
  );
};

function toContactInfo(props: ILocalContactCardProps): IContactInfo {
  if (!props.tenancyContract || !props.complexDetails || !props.complexContactInformation) {
    return null;
  }
  const caretakers = props.complexContactInformation.filter(r => r.competenceType.startsWith('CARETAKER'));
  const tenantAdvisoryBoard = props.complexContactInformation.filter(r => r.competenceType === 'TENANTADVISORY');
  const neighborhoodPolice = props.complexContactInformation.filter(r => r.competenceType === 'NEIGHBORHOOD_POLICE');

  return {
    tenancyContract: props.tenancyContract,
    caretaker: caretakers,
    estateName: props.complexDetails.name,
    tenantAdvisoryBoard: tenantAdvisoryBoard,
    neighborhoodPolice: neighborhoodPolice,
  } as IContactInfo;
}

function toEstateNameHeader(contact: IContactInfo) {
  let name: string;
  if (contact.estateName && !contact.estateName.startsWith(contact.tenancyContract.streetName)) {
    name = contact.estateName + '';
  } else {
    name = contact.tenancyContract.streetName;
  }
  return name;
}

function getEstateNameOrBlank(contact: IContactInfo) {
  let name: string = '';
  if (contact.estateName && !contact.estateName.startsWith(contact.tenancyContract.streetName)) {
    name = contact.estateName.toString();
  }
  return name;
}

function concatEstateStreetName(contact: IContactInfo) {
  let name: string = contact.tenancyContract.streetName + ' ' + contact.tenancyContract.streetnumber;
  if (!!contact.tenancyContract.stairAndDoor) {
    name += '/' + contact.tenancyContract.stairAndDoor;
  }
  return name;
}

// do not show phone number for TENANTADVISORY
function renderAdvisoryBoard(advisoryBoardResponsibility: IContactInformation[], estateName: string, index: number) {
  return advisoryBoardResponsibility.map((adv, idx) => {
    return (
      <Data key={idx} id={`contact-data-advisory-board-${index}`}>
        <Key>{CONTACT_LABELS.tenantAdvisoryBoardTitle}</Key>
        <Value>{`${adv.firstname} ${adv.lastname}`}</Value>
      </Data>
    );
  });
}

function renderCareTaker(caretakerResponsibility: IContactInformation[], estateName: string, index: number) {
  return caretakerResponsibility.map((ctr, idx) => {
    return (
      <Data key={idx} id={`contact-data-caretaker-${index}`}>
        <Key>{CONTACT_LABELS.caretakerTitle}</Key>
        <Value>
          <ul>
            <li key="name">{`${ctr.firstname} ${ctr.lastname}`}</li>
            {/*show contact details only from 'CARETAKER_SPEC' */}
            {ctr.contact?.length > 0 &&
              ctr.competenceType === 'CARETAKER_SPEC' &&
              ctr.contact[0].prefix &&
              ctr.contact[0].address && (
                <li key="contact-address">
                  <Hyperlink
                    id={`contact-caretaker-${estateName}`}
                    to={`tel: +43 ${ctr.contact[0].prefix.substring(1)} ${ctr.contact[0].address}`}
                    customHref={true}
                  >
                    {`+43 ${ctr.contact[0].prefix.substring(1)} ${ctr.contact[0].address}`}
                  </Hyperlink>
                </li>
              )}
          </ul>
        </Value>
      </Data>
    );
  });
}

function renderNeighborhoodPolice(neighborHoodPolice: IContactInformation[], estateName: string, index: number) {
  return neighborHoodPolice.map((ctr, idx) => {
    const phoneNumber = ctr.contact.find(contact => contact.type === 'PHONE' || contact.type === 'MOB');
    const emailAddress = ctr.contact.find(contact => contact.type === 'EMAIL');

    return (
      <Data key={idx} id={`contact-data-neighborhood-police-${index}`}>
        <Key>{CONTACT_LABELS.neighborhoodPoliceTitle}</Key>
        <Value>
          <ul>
            <li key="name">{ctr.authority}</li>
            <li key="contact-details">
              <Hyperlink
                id={`contact-phone-neighborhood-police-${estateName}`}
                to={`tel:${convertContactToDisplayablePhoneNumber(phoneNumber)}`}
                customHref={true}
              >
                {convertContactToDisplayablePhoneNumber(phoneNumber)}
              </Hyperlink>
            </li>
            <li key="contact-details">
              <Hyperlink
                id={`contact-email-neighborhood-police-${estateName}`}
                to={`mailto:${emailAddress.address}`}
                customHref={true}
              >
                {emailAddress.address}
              </Hyperlink>
            </li>
          </ul>
        </Value>
      </Data>
    );
  });
}

function convertContactToDisplayablePhoneNumber(contact: IContact): string {
  return contact?.type === 'PHONE'
    ? `+43 ${contact.address.substr(1).replace(new RegExp('/', 'g'), ' ')}`
    : `+43 ${contact.prefix.substring(1)} ${contact.address}`;
}

export default LocalContactCard;
