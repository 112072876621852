import React, { forwardRef, useImperativeHandle } from 'react';
import { ITemplate, ITemplateHandles } from '../interfaces/Template.interface';
import { Download, Status, SVG } from 'digit.commons.ui-components-app';
import { ApplicationFormUtility } from '../../utility/ApplicationFormUtility';
import { APPLICATION_FORM_BASE_ROUTE, DETAILS_ROUTE } from '../../constants/routes';
import { STATUS } from '../../constants/status';
import ApplicationFormSCSInitializer from '../../ApplicationFormSCSInitializer';
import { DOUBLE_SUBMISSION_LABELS } from '../../constants/containers/template-labels';
import './Template.scss';
import { IProcessData } from '../interfaces/DecisionTree.interface';
import { useHistory } from 'react-router-dom';
import { RequestTheme, RequestWorkflow, WizardMode } from '../../constants/containers/request-theme';
import { INTERNAL_TO_EXTERNAL_REPRESENTATION_MAPPER } from '../../constants/containers/internal-to-external-representation-mapper';
import { DATE_FORMATTER } from '../../../commons/utility/DateFormatter';

const DoubleSubmissionTemplate: React.ForwardRefRenderFunction<ITemplateHandles, ITemplate> = (props, ref) => {
  useImperativeHandle(ref, () => ({
    validateTemplate: () => true,
  }));

  const { doubleSubmission, doubleSubmissionText, processData } = props;
  const doubleSubmissionProcessData: IProcessData = JSON.parse(doubleSubmission.json.replace('\\""', '"'));

  const apiConfig = ApplicationFormSCSInitializer.apiConfig();

  const history = useHistory();

  const {
    alreadyRequested,
    getExpirationText,
    mainTenantConfirmationDownloadLabel,
    monthlyRentDetailsParagraph,
    getMonthlyRentDetailsDownloadLabel,
    alreadySentTitle,
    alreadySentParagraph,
    alreadySavedTitle,
    alreadySavedParagraph,
    alreadyRequestedServiceCard,
    showDetails,
    continueFlow,
    backLink,
    rentalAccountInfoDownloadLabel,
  } = DOUBLE_SUBMISSION_LABELS;

  /*
   * this is here to avoid that the user gets back to the flow when clicking back in their browser
   *  */
  const addDocumentsMainPageToBrowserBack = () => {
    history.push(APPLICATION_FORM_BASE_ROUTE);
  };

  const renderDoubleSubmissionForDocument = () => {
    const isReadyToDownload = doubleSubmission.statusMappings.some(
      status => status.status === STATUS.readytodownload.apiStatus
    );
    if (doubleSubmission.theme === RequestTheme.MAIN_TENANT_CONFIRMATION && isReadyToDownload) {
      return (
        <>
          <p className={'Template__paragraph'}>
            {getExpirationText(
              ApplicationFormUtility.changeDateInFourWeeks(doubleSubmission.statusMappings[0].changeDate)
            )}
          </p>
          <Download
            id="document-already-requested"
            url={`${apiConfig.applicationFormApi}${apiConfig.paths.pathToDocument}/${doubleSubmission.id}/${doubleSubmission.statusMappings[0].document.id}`}
            icon={SVG.pdf}
          >
            {mainTenantConfirmationDownloadLabel}
          </Download>
        </>
      );
    } else if (doubleSubmission.theme === RequestTheme.MONTHLY_RENT_DETAILS && isReadyToDownload) {
      return (
        <>
          <p className={'Template__paragraph'}>{monthlyRentDetailsParagraph}</p>
          <Download
            id="document-already-requested"
            url={`${apiConfig.applicationFormApi}${apiConfig.paths.pathToDocument}/${doubleSubmission.id}/${doubleSubmission.statusMappings[0].document.id}`}
            icon={SVG.pdf}
          >
            {getMonthlyRentDetailsDownloadLabel(
              ApplicationFormUtility.getMonthDescription(doubleSubmissionProcessData['chooseDate']),
              doubleSubmissionProcessData['chooseYear']
            )}
          </Download>
        </>
      );
    } else if (doubleSubmission.theme === RequestTheme.RENTAL_ACCOUNT_INFO && isReadyToDownload) {
      return (
        <>
          <p className={'Template__paragraph'}>
            {getExpirationText(
              ApplicationFormUtility.changeDateInDays(doubleSubmission.statusMappings[0].changeDate, 1)
            )}
          </p>
          <Download
            id="document-already-requested"
            url={`${apiConfig.applicationFormApi}${apiConfig.paths.pathToDocument}/${doubleSubmission.id}/${doubleSubmission.statusMappings[0].document.id}`}
            icon={SVG.pdf}
          >
            {rentalAccountInfoDownloadLabel}
          </Download>
        </>
      );
    } else {
      return (
        <>
          <p className={'Template__paragraph'}>{alreadyRequested}</p>
          <Status
            id={`request-and-documents-${doubleSubmission.statusMappings[0].status.toLowerCase()}`}
            transactionDescription={ApplicationFormUtility.getFormDescription(
              doubleSubmission.theme,
              doubleSubmission.workflow,
              doubleSubmissionProcessData,
              doubleSubmission.statusMappings
            )}
            transactionLabel={DATE_FORMATTER.dateFormat(doubleSubmission.statusMappings[0].changeDate)}
            statusDate={DATE_FORMATTER.dateFormat(doubleSubmission.statusMappings[0].changeDate)}
            state={doubleSubmission.statusMappings[0].status.toLowerCase()}
            linkText={showDetails}
            onClick={addDocumentsMainPageToBrowserBack}
            linkTo={{
              pathname: DETAILS_ROUTE,
              state: ApplicationFormUtility.buildApplicationFormHistoryState(
                doubleSubmission,
                doubleSubmissionProcessData,
                backLink
              ),
            }}
            linkIsExternal={false}
          />
        </>
      );
    }
  };

  const renderDoubleSubmissionOther = () => {
    const isDraft = doubleSubmission.statusMappings[0].status === STATUS.draft.apiStatus;
    const pathname = isDraft ? '' : DETAILS_ROUTE.replace(':applicationFormId', doubleSubmission.shortId);
    return (
      <>
        <p className="Template__paragraph">
          <strong>{isDraft ? alreadySavedTitle : alreadySentTitle}</strong>
        </p>
        <p className="Template__paragraph">{getOtherParagraphText(isDraft)}</p>
        <Status
          id={isDraft ? 'wizard-draft' : 'wizard-duplicate'}
          transactionDescription={ApplicationFormUtility.getFormDescription(
            doubleSubmission.theme,
            doubleSubmission.workflow,
            doubleSubmissionProcessData,
            doubleSubmission.statusMappings
          )}
          transactionLabel={DATE_FORMATTER.dateFormat(doubleSubmission.statusMappings[0].changeDate)}
          statusDate={DATE_FORMATTER.dateFormat(doubleSubmission.statusMappings[0].changeDate)}
          state={doubleSubmission.statusMappings[0].status.toLowerCase()}
          linkText={isDraft ? continueFlow : showDetails}
          linkTo={{
            pathname: pathname,
            state: ApplicationFormUtility.buildApplicationFormHistoryState(
              props.doubleSubmission,
              doubleSubmissionProcessData,
              backLink
            ),
          }}
          linkIsExternal={false}
          onDelete={() => props.deleteDraft(doubleSubmission.id)}
          onClick={
            isDraft
              ? e => {
                  e.preventDefault();
                  props.switchFlows(
                    INTERNAL_TO_EXTERNAL_REPRESENTATION_MAPPER.conversions.find(
                      mapper => mapper.id === doubleSubmission.theme
                    ).flowStructure,
                    doubleSubmissionProcessData.currentStep.slice(-1)[0],
                    WizardMode.EDIT_DRAFT,
                    doubleSubmission.id,
                    doubleSubmissionProcessData
                  );
                }
              : addDocumentsMainPageToBrowserBack
          }
        />
      </>
    );
  };

  const getOtherParagraphText = (isDraft: boolean) => {
    if (isDraft) {
      return alreadySavedParagraph;
    } else if (doubleSubmissionText) {
      return doubleSubmissionText;
    } else if (
      doubleSubmission.workflow === RequestWorkflow.REQUEST_SERVICE_CARD &&
      processData['changes'] !== 'addtionalServiceCard'
    ) {
      return alreadyRequestedServiceCard;
    }
    return alreadySentParagraph;
  };

  return doubleSubmission.workflow === RequestWorkflow.DOCUMENT
    ? renderDoubleSubmissionForDocument()
    : renderDoubleSubmissionOther();
};

export default forwardRef(DoubleSubmissionTemplate);
