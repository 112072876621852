import { Card, CardBody, CardFooter, CardHeader, Hyperlink, Section, SVG } from 'digit.commons.ui-components-app';
import { isAndroidDevice } from '../../../commons/utility/util';
import React from 'react';
import { MIN_VERSION_UPDATE } from '../../constants/component-labels';

const MinVersionAnnouncement: React.FC = () => {
  return (
    <Card id={'update-app'} badge={MIN_VERSION_UPDATE.badge} badgeType={'red'}>
      <CardHeader title={MIN_VERSION_UPDATE.cardTitle} />
      <CardBody>{MIN_VERSION_UPDATE.text}</CardBody>
      <CardFooter>
        <Hyperlink
          id={'update-app'}
          onClick={() =>
            (window.location.href = isAndroidDevice()
              ? 'https://play.google.com/store/apps/details?id=at.wienerwohnen.mieterportal.app'
              : 'https://apps.apple.com/at/app/stadt-wien-wiener-wohnen/id1484310308')
          }
          iconAfter={SVG.external_link}
          isExternal={true}
        >
          {MIN_VERSION_UPDATE.toStore}
        </Hyperlink>
      </CardFooter>
    </Card>
  );
};

export default MinVersionAnnouncement;
