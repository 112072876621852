import React, { forwardRef, RefObject, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { IFieldset, ITemplate, ITemplateHandles } from '../interfaces/Template.interface';
import { Fieldset, IFieldsetHandles } from 'digit.commons.ui-components-app';
import './Template.scss';
import { TemplateComponents } from './TemplateComponents';

interface Minimized {
  [x: string]: boolean;
}

const MultiFieldsetTemplate: React.ForwardRefRenderFunction<ITemplateHandles, ITemplate> = (props, ref) => {
  const { fieldsets } = props.step.props;
  const { possibleContent, processData } = props;

  const fieldsetRefs = useRef<RefObject<IFieldsetHandles>[]>(fieldsets.map(() => React.createRef<IFieldsetHandles>()));

  const [minimized, setMinimized] = useState<Minimized>();

  useImperativeHandle(ref, () => ({
    validateTemplate: () => {
      let isValid = true;
      fieldsetRefs.current.forEach(fieldsetRef => {
        if (fieldsetRef && fieldsetRef.current) {
          fieldsetRef.current.validate();
          isValid = isValid && processData && !!processData[fieldsetRef.current.getId()];
        }
      });
      return isValid;
    },
  }));

  useEffect(() => {
    fieldsets.forEach((fieldset: IFieldset) => {
      setMinimized(prevState => ({ ...prevState, [fieldset.id]: processData && processData[fieldset.id] }));
    });
  }, []);

  const toDecisionValue = (key: string, selection: Set<string>): any => {
    return [].concat({ key, value: Array.from(selection.values()) });
  };

  const hasContent = (fieldset: IFieldset) => {
    return (
      possibleContent &&
      possibleContent[fieldset.id] &&
      possibleContent[fieldset.id].options &&
      possibleContent[fieldset.id].options.length > 0
    );
  };

  const onFieldsetChange = (selection: Set<string>, fieldset: IFieldset) => {
    setMinimized(prevState => ({ ...prevState, [fieldset.id]: true }));
    props.decisionCallback(fieldset.id, toDecisionValue(fieldset.id, selection));
  };

  // FIXME: this is not in the design but makes sense for a lot of cases
  const onMinimizedClick = (fieldset: IFieldset) => {
    setMinimized(prevState => ({ ...prevState, [fieldset.id]: false }));
  };

  return (
    <>
      {processData &&
        fieldsets.map((fieldset: IFieldset, index: number) => {
          if (hasContent(fieldset)) {
            const options = TemplateComponents.getOptionsContent(possibleContent[fieldset.id].options);
            if (
              !processData[fieldset.id] ||
              !options.some(option => option.value === processData[fieldset.id][0]) ||
              (minimized && !minimized[fieldset.id])
            ) {
              return (
                <Fieldset
                  id={fieldset.id}
                  className="Template__fieldset"
                  title={fieldset.title}
                  description={fieldset.description}
                  options={options}
                  flow={'grid'} // on mobile we only want grid fieldsets
                  size={'large'}
                  required={fieldset.required}
                  multiple={fieldset.multiple}
                  cols={1}
                  preselect={processData[fieldset.id] ? processData[fieldset.id] : null}
                  errorMessage={fieldset.errorMessage ? fieldset.errorMessage : 'Sie müssen etwas auswählen'}
                  onChange={selection => onFieldsetChange(selection, fieldset)}
                  key={`${fieldset.id}-${index}`}
                  ref={fieldsetRefs.current[index]}
                />
              );
            } else {
              return (
                <Fieldset
                  id={fieldset.id}
                  className="Template__fieldset"
                  title={fieldset.title}
                  description={fieldset.description}
                  options={[].concat(options.find(option => option.value === processData[fieldset.id][0]))}
                  flow={'grid'} // on mobile we only want grid fieldsets
                  size={'large'}
                  required={fieldset.required}
                  multiple={fieldset.multiple}
                  cols={1}
                  preselect={processData[fieldset.id] ? processData[fieldset.id] : null}
                  errorMessage={fieldset.errorMessage ? fieldset.errorMessage : 'Sie müssen etwas auswählen'}
                  onClick={() => onMinimizedClick(fieldset)}
                  key={`${fieldset.id}-${index}-minimized`}
                  minimized={true}
                  ref={fieldsetRefs.current[index]}
                />
              );
            }
          } else if (
            fieldset.dependsOn &&
            processData[fieldset.dependsOn] &&
            processData[fieldset.dependsOn].length &&
            fieldset.noPossibleContent
          ) {
            /* if a fieldset depends on the choice of another fieldset and has content provided if no possibleContent was found, then we'll render everything that is under noPossibleContent */
            return (
              <>
                <span className="Template__title">{fieldset.noPossibleContent.title}</span>
                {fieldset.noPossibleContent.texts &&
                  fieldset.noPossibleContent.texts.map((text, index) =>
                    text.strong ? (
                      <strong className="Template__text" key={`no-Possible-Content-${index}`}>
                        {text.text}
                      </strong>
                    ) : (
                      <p className="Template__text" key={`no-Possible-Content-${index}`}>
                        {text.text}
                      </p>
                    )
                  )}
              </>
            );
          }
          return null;
        })}
      {fieldsets.map(
        fieldset =>
          possibleContent &&
          possibleContent[fieldset.id] &&
          possibleContent[fieldset.id].collapsibles &&
          TemplateComponents.renderCollapsible(
            possibleContent[fieldset.id].collapsibles,
            props.step.id,
            props.switchFlows
          )
      )}
    </>
  );
};

export default forwardRef(MultiFieldsetTemplate);
