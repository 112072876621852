export const isEmptyObject = object => object && Object.keys(object).length === 0 && object.constructor === Object;

export function isIOSDevice() {
  const userAgent = navigator.userAgent || navigator.vendor;
  return /iPad|iPhone|iPod|IOS/.test(userAgent);
}

export function isAndroidDevice() {
  return !isIOSDevice();
}

export function getAppVersion(): string {
  const userAgent = navigator.userAgent;
  if (isAndroidDevice()) {
    return userAgent.split('WienerWohnen/')[1];
  } else if (isIOSDevice()) {
    return userAgent.split('WienerWohnenIOS/')[1];
  }
  return '1.5.0';
}
