import React, { ReactElement } from 'react';
import { ITenantAndClientFeatures } from '../../commons/api/tenantData/TenantData.interface';

export const TenantDataContext = React.createContext<ITenantAndClientFeatures>(null);

export const TenantDataContextProvider: React.FC<{
  tenantData: ITenantAndClientFeatures;
  children: ReactElement;
}> = props => {
  return <TenantDataContext.Provider value={props.tenantData}>{props.children}</TenantDataContext.Provider>;
};
