class DownloadHelper {
  downloadResource(url: string, filename: string) {
    fetch(url)
      .then(response => response.blob())
      .then(blob => this.saveAsDataURL(blob, filename));
  }

  saveAsDataURL(blob: Blob, filename: string) {
    this.blobToDataURL(blob, dataurl => {
      // to get the file name to the android app we need to adapt stuff here
      const dataUrlParts = dataurl.split(';');
      const dataUrlWithFilename = dataUrlParts[0] + ';name=' + filename + ';' + dataUrlParts[1];
      console.log(dataUrlWithFilename);
      let elem = window.document.createElement('a');
      elem.href = dataUrlWithFilename;
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    });
  }

  private blobToDataURL(blob, callback) {
    let a = new FileReader();
    a.onload = function (e) {
      callback(e.target.result);
    };
    a.readAsDataURL(blob);
  }
}

export const DOWNLOAD_HELPER = new DownloadHelper();
