import React from 'react';
import { Hyperlink, SVG } from 'digit.commons.ui-components-app';
import { CUSTOMER_SERVICE_NUMBER, TENANT_PORTAL_SERVICE_NUMBER } from '../component-labels';
import { INFORMATION_RENT_ARREARS_SUPPORT } from '../links';

export const RENTAL_ACCOUNT_LABELS = {
  title: 'Mietzinskonto',
  pageTitle: 'Mietzinskonto - Wiener Wohnen Mieter*innenportal',
  meta: 'Mietzinskonto - Alle Informationen zum Thema Mietzins auf einem Blick',
  rentalObject: 'Mietobjekte',
  badge: 'Erinnerung',
  dropDown: {
    errorMessage: 'Bitte wählen Sie ein Mietobjekt aus',
  },
  errorPanel: {
    title: 'Oh nein!',
    text: 'Leider kann zur Zeit nicht auf Ihre Daten zugegriffen werden. Bitte versuchen Sie es später noch einmal.',
    description: (
      <>
        Bei dringenden Fragen oder Problemen wenden Sie sich bitte an unsere Service-Nummer unter{' '}
        <Hyperlink
          id="rental-account-service-number"
          to={`tel: ${TENANT_PORTAL_SERVICE_NUMBER}`}
          isInline={true}
          customHref={true}
        >
          {TENANT_PORTAL_SERVICE_NUMBER}
        </Hyperlink>
        .
      </>
    ),
  },
  video: {
    badge: 'Aufgepasst!',
    title: 'Sie haben Fragen zu Ihrem Mietzins?',
    subtitle: '"Mein Mietzins" - Informationen zu Hauptmietzins & Betriebskosten',
    seenTitle: '"Mein Mietzins" - Video',
  },
  details: {
    dueDate: 'Fällig seit',
    onLbl: 'am ',
    depositeWithcheckLbl: 'Einzahlung per ',
    residentialHelpLbl: ' eingelangt',
    balanceAmountLbl: {
      title: 'Aktueller Stand',
      paytoday: 'Heute zu bezahlen',
      paymentcredit: 'Sie haben ein Guthaben auf Ihrem Konto',
      noPaymentsToDo: 'Sie haben aktuell keine zu zahlenden Beträge',
    },
    billCurrentMonthLbl: {
      title: 'Rechnung aktueller Monat',
      descr:
        'Diese setzt sich aus dem Gesamtzins und zusätzlichen Kosten (z.B. Waschküchenkosten, Spesen, Instandsetzungskosten oder Entrümperlungskosten) zusammen',
      payUntil: 'Zu bezahlen bis ',
      yourBalance: 'Ihr Guthaben beträgt ',
    },
    billNextMonthLbl: {
      title: 'Rechnung nächster Monat',
      descr:
        'Diese setzt sich aus dem Gesamtzins und zusätzlichen Kosten (z.B. Waschküchenkosten, Spesen, Instandsetzungskosten oder Entrümperlungskosten) zusammen',
      payUntil: 'Zu bezahlen bis ',
      yourBalance: 'Ihr Guthaben beträgt ',
    },
    lastConsideredPaymentLbl: {
      title: 'Bei Wiener Wohnen eingelangt',
    },
  },
  accordionRentalAccountNegativeBalance: [
    {
      accordionInvisibleTitle: 'Weitere Informationen',
      accordionTitle: 'Was passiert wenn ich nicht zahle?',
      accordionBody: (
        <>
          <h4>Was passiert wenn ich die Miete nicht zahlen kann?</h4>
          <p className="RentalAccount__paragraph">
            Wenn die Miete nach zwei Monaten trotz Zahlungserinnerung und Mahnung nicht bzw. nur teilweise bezahlt
            wurde, kann Wiener Wohnen eine Räumungsklage beim Gericht einbringen.
          </p>
          <p className="RentalAccount__paragraph">
            Bezahlen Sie vor diesem Termin den gesamten Rückstand inkl. der nach der Klagseinbringung anfallenden Mieten
            sowie die Gerichtskosten, wird bei der Verhandlung ein „Ruhen“ vereinbart. Das bedeutet: Die Räumungsklage
            ist damit abgewendet.{' '}
          </p>
          <p className="RentalAccount__paragraph">
            Werden die offenen Mieten nicht bezahlt, begehrt Wiener Wohnen ein Urteil oder einen Vergleich vom Gericht.
            Danach kann Wiener Wohnen bei Nichtzahlung bzw. beim Nichtzustandekommen einer anderen Zahlungsvereinbarung
            einen Antrag auf die zwangsweise Räumung (Delogierung) stellen.{' '}
          </p>
          <p className="RentalAccount__paragraph">
            {' '}
            <strong>Dies kann zum Verlust des Mietobjektes (Wohnung, Lokal, Magazin, Garage) führen! </strong>{' '}
          </p>
          <p className="RentalAccount__paragraph">
            Unsere Mitarbeiter*innen können Ihnen Hilfestellung zum Thema geben.{' '}
          </p>
          <p className="RentalAccount__paragraph">
            Wenden Sie sich dazu bitte an unsere Service-Nummer unter{' '}
            <Hyperlink
              id="rental-account-faq-service-center-tel-1"
              isInline={true}
              customHref={true}
              to={`tel:${CUSTOMER_SERVICE_NUMBER}`}
            >
              {CUSTOMER_SERVICE_NUMBER}
            </Hyperlink>{' '}
            und vereinbaren Sie einen persönlichen Beratungstermin.{' '}
          </p>
        </>
      ),
    },
    {
      accordionTitle: 'Hilfe bei finanziellen Problemen',
      accordionBody: (
        <>
          <p className={'RentalAccount__paragraph'}>
            Aktuelle Informationen zur Hilfe bei Mietzinsrückstand und Hinweise zu den städtischen Hilfseinrichtungen
            finden Sie hier:{' '}
            <Hyperlink
              id={'rental-account-details-support'}
              to={INFORMATION_RENT_ARREARS_SUPPORT}
              isExternal={true}
              iconAfter={SVG.external_link}
            >
              Hilfe bei Mietzinsrückstand
            </Hyperlink>
          </p>
          <p className="RentalAccount__paragraph">
            Die Gründe für einen Mietzinsrückstand können sehr unterschiedlich sein. Bitte rufen Sie uns unter der
            Service-Nummer{' '}
            <Hyperlink
              id="rental-account-faq-details-support"
              isInline={true}
              customHref={true}
              to={`tel:${CUSTOMER_SERVICE_NUMBER}`}
            >
              {CUSTOMER_SERVICE_NUMBER}
            </Hyperlink>{' '}
            an und vereinbaren Sie mit einer Mitarbeiterin oder einem Mitarbeiter einen persönlichen Termin. Wir wollen
            eine individuelle Lösung für Sie finden, zum Beispiel eine Ratenvereinbarung.
          </p>
        </>
      ),
    },
  ],
  accordionRentalAccountPositiveBalance: [
    {
      accordionInvisibleTitle: 'Weitere Informationen',
      accordionTitle: 'Details zu Ihrem Guthaben',
      accordionBody: (
        <>
          <h4>Wenn das Guthaben durch die Betriebskostenabrechnung entsteht:</h4>
          <p className="RentalAccount__paragraph">
            Das Guthaben wird automatisch von der nächsten Monatsmiete abgezogen. Sollte das Guthaben höher als die
            nächste Monatsmiete sein, wird der Rest automatisch an Sie ausbezahlt.
          </p>
          <h4>Wenn das Guthaben aus anderen Gründen entsteht:</h4>
          <p className="RentalAccount__paragraph">
            Ihr Guthaben wird automatisch von der nächsten Monatsmiete abgezogen. Sollten Sie eine Auszahlung wünschen,
            melden Sie sich bitte rechtzeitig bei Wiener Wohnen. Sie können den Betrag auf Ihr angegebenes Konto
            überweisen lassen.
          </p>
        </>
      ),
    },
  ],
  faqAccordion: {
    title: 'Zahlungsoptionen',
    items: [
      {
        itemTitle: 'Barzahlung am Kassenautomaten',
        itemBody: (
          <>
            <h4>Wo</h4>
            <p>Wiener Wohnen Service-Center Rosa-Fischer-Gasse 2, 1030 Wien</p>
            <h4>Öffnungszeiten</h4>
            <p className="RentalAccount__paragraph">
              Während der regulären Öffnungszeiten des Service-Centers können Sie alle 4 Kassenautomaten verwenden.
            </p>

            <p className="RentalAccount__paragraph">
              Außerhalb dieser Zeiten brauchen Sie Ihre freigeschaltene Service-Karte, um Zutritt zum Gebäude zu
              bekommen. Einer der Kassenautomaten steht Ihnen{' '}
              <em>
                Montag - Sonntag, <time dateTime={'PT24H'}>24 Stunden</time>
              </em>{' '}
              am Tag zur Verfügung.
            </p>

            <p>
              Mo., Di. und Do.: <time dateTime="08:00">08.00</time>–<time dateTime="18:00">18.00</time>
            </p>
            <p>
              Mi., Fr.: <time dateTime="08:00">08.00</time>–<time dateTime="12:00">12.00</time>
            </p>

            <h4>Gut zu wissen</h4>
            <p>Einzahlungen am Kassenautomaten werden sofort im Mietzinskonto berücksichtigt.</p>
          </>
        ),
      },
    ],
  },
  paymentsMissing: 'Zahlungen fällig für',
  showRentalAccountButton: 'Konto-Informationen anzeigen',
  nextBill: 'Nächste Rechnung',
  moneySign: '€',
};

export const RENTAL_BANK_INFO = {
  title: 'Bankverbindung Wiener Wohnen',
  paragraph: 'für ',
  iBan: 'IBAN',
  bic: 'BIC',
  note1: <h4>Gut zu wissen</h4>,
  note2: <p>Die Bankverbindung unterscheidet sich nach Mietobjekt.</p>,
  note3: <p>Die Zahlungsreferenz finden Sie auf Ihrem Zahlschein.</p>,
};

export const RENTAL_OBJECT_ICON = {
  Wohnung: SVG.rental_object_flat,
  Parkplatz: SVG.rental_object_car_parking,
  Lokal: SVG.rental_object_restaurant,
  Magazin: SVG.rental_object_magazin,
};
