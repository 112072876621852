import React from 'react';
import ReactPiwik from 'react-piwik';
import { useLocation } from 'react-router-dom';
import { piwikEvents } from '../../commons/utility/piwikEvents';

export const Piwik: React.FC<{ piwikSetup: ReactPiwik }> = ({ piwikSetup }) => {
  piwikSetup.track(useLocation());
  piwikEvents.enableHeartBeatTimer();
  piwikEvents.enableHeatMapSessionRecording();
  return <></>;
};
