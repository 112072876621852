import { Method } from '../ApiMethodEnum';
import { Configuration } from '../../../Configuration';
import { AuthStates } from './AuthenticationStateData.interface';
import { ICheckAuthenticationStateCallback } from '../Bridge';
import { AbstractRequestApi } from '../AbstractRequestApi';

class AuthenticationStateApi extends AbstractRequestApi {
  constructor() {
    super(
      () => Configuration.apiConfig().authenticationStateApi,
      'AuthenticationStateApi',
      () => Configuration.queryConfig()
    );

    /*
     * link the checkAuthenticationState bridge function
     */
    window['ha'].checkAuthenticationState = this.jsBridge_checkAuthenticationState.bind(this);
  }

  /**
   * Bridge cannot depend on AuthenticationStateApi due to circular dependencies with Bridge -> AbstractRequestApi -> Bridge
   * So we need to implement a js-bridge function here
   * @param callback
   */
  jsBridge_checkAuthenticationState(callback: ICheckAuthenticationStateCallback) {
    this.retrieveAuthenticationState()
      .then((data: any) => {
        // js-bridge: calling checkAuthenticationState callback with ', data
        window['ha'].authState = data.state;
        console.log(`Bridge  authentification state check - setting authState ${data.state}`);
        callback(data.state);
      })
      .catch(error => {
        // 'js-bridge: calling checkAuthenticationState callback with null due to error ', error
        console.log(`Bridge  authentification state check - failed, setting authState ${AuthStates.ANONYMOUS}`, error);
        window['ha'].authState = AuthStates.ANONYMOUS;
        callback(AuthStates.ANONYMOUS);
      });
  }

  async retrieveAuthenticationState(): Promise<any> {
    try {
      return this._makeRequest(Configuration.apiConfig().paths.pathToStateCheck, Method.GET);
    } catch (error) {
      return this.handleResponse(error);
    }
  }
}

export default new AuthenticationStateApi();
