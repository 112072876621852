import React, { forwardRef, Fragment, useEffect, useImperativeHandle } from 'react';
import { IInformation, IParagraph, ITemplate, ITemplateHandles } from '../interfaces/Template.interface';
import './Template.scss';

const DocumentRequestTemplate: React.ForwardRefRenderFunction<ITemplateHandles, ITemplate> = (props, ref) => {
  const stepProps = props.step.props;

  /** dummy use effect on mount, to set the processData in order you can proceed */
  useEffect(() => {
    props.decisionCallback(
      props.step.id,
      [].concat(
        { key: props.step.id, value: true },
        {
          key: 'summary',
          value: { Mietobjekt: props.getTextForRepresentation('rentalObject'), ...props.processData['summary'] },
        }
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    validateTemplate: () => true,
  }));

  const generateParagraphs = (paragraphs: IParagraph[], index: number) => (
    <>
      {paragraphs.map((item, idx) => {
        let formattedParagraph;
        if (item.differentFormats) {
          formattedParagraph = (
            <>
              {item.differentFormats.map(differentFormat =>
                differentFormat.strong ? <strong key={idx}>{differentFormat.text}</strong> : differentFormat.text
              )}
            </>
          );
        } else if (item.strong) {
          formattedParagraph = <strong>{item.text}</strong>;
        }
        return (
          <p
            className={'Template__paragraph'}
            id={`request-document-paragraph-${index}-${idx}-id`}
            key={`paragraph-${index}-${idx}`}
          >
            {formattedParagraph ? formattedParagraph : item.text}
          </p>
        );
      })}
    </>
  );

  return (
    <>
      {stepProps &&
        stepProps.information &&
        stepProps.information.map((information: IInformation, index: number) => (
          <Fragment key={index}>
            <h3
              className={'Template__heading-request'}
              id={`request-document-heading-${index}-id`}
              key={`heading-${index}`}
            >
              {information.heading}
            </h3>
            {generateParagraphs(information.paragraphs, index)}
          </Fragment>
        ))}
    </>
  );
};

export default forwardRef(DocumentRequestTemplate);
