import React from 'react';
import { Card, CardBody, CardFooter, CardHeader, Hyperlink, SVG } from 'digit.commons.ui-components-app';
import { LAUNDRY_DAY_LANDING } from '../../constants/containers/laundry-day-landing';
import { LAUNDRY_DAY_BASE_ROUTE } from '../../constants/routes';
import { SUMMARY_ANNOUNCEMENT } from '../../constants/general';
import './Announcement.scss';
import { IAnnouncementProps, ILaundryAnnouncementData } from '../../../commons/api/announcement/Announcement.interface';

const DefectSummaryAnnouncement: React.FC<IAnnouncementProps> = props => {
  const { id, announcement } = props;
  const booking = (announcement.data as ILaundryAnnouncementData).booking;
  const { brokenDryer, brokenWasher, brokenWasherAndDryer, malfunction } = LAUNDRY_DAY_LANDING.broken;
  const { title, linkBookingText } = SUMMARY_ANNOUNCEMENT;

  return (
    <Card id={id} className="Announcement" badge={malfunction} badgeType="red">
      <CardHeader title={title} icon={SVG.laundry_locked} />
      <CardBody>
        <em className="Announcement__info--locked">
          {booking.washerBroken && booking.dryerBroken
            ? `${brokenWasherAndDryer}`
            : booking.dryerBroken
            ? `${brokenDryer}`
            : booking.washerBroken
            ? `${brokenWasher}`
            : null}
        </em>
      </CardBody>
      <CardFooter>
        <Hyperlink id={id} to={LAUNDRY_DAY_BASE_ROUTE} iconAfter={SVG.arrow_right}>
          {linkBookingText}
        </Hyperlink>
      </CardFooter>
    </Card>
  );
};

export default DefectSummaryAnnouncement;
