import { SVG } from 'digit.commons.ui-components-app';

export const WIZARD_LABELS = {
  wizard: 'Assistent',
  handInRequest: 'Antrag stellen',
  requestDocument: 'Dokument anfordern',
  close: 'Schließen',
  requestCloseTitle: 'Antrag schließen',
  cancelAddInformationTitle: 'Nachreichen abbrechen',
  cancelText: 'Abbrechen',
  back: 'Zurück',
  proceed: 'Weiter',
  send: 'Antrag abschicken',
  hbAnswerTitle: 'Bestellung beantworten',
  hbAnswer: 'Antwort senden',
  hbFinish: 'Bestellung fertigstellen',
  request: 'Anfordern',
  saveEdit: 'Änderung speichern',
  saveDraft: 'Als Entwurf speichern',
  errorMessage: 'Beim Speichern des Antrags ist ein Fehler unterlaufen',
  cancelRequestTitle: 'Wollen Sie die Seite wirklich schließen?',
  cancelRequestConfirmation: 'Das gewünschte Dokument wird dann nicht angefordert.',
  cancelRequest: 'Schließen',
  handInInformation: 'Informationen nachreichen',
  continueRequest: 'Fortsetzen',
  defaultBacklinkText: 'Zurück zu Anträge und Dokumente',
  negativeFlowStepName: 'Kein Antrag möglich',
  doubleSubmissionSentStepName: 'Antrag bereits gesendet',
  doubleSubmissionDraftStepName: 'Antrag bereits gespeichert',
  doubleSubmissionDocumentStepName: 'Dokument bereits angefordert',
  step: 'Schritt',
};

export const WIZARD_OVERLAYS = {
  closeRequest: {
    title: 'Wollen Sie den Antrag wirklich schließen?',
    textBody:
      'Wenn Sie Ihren Antrag schließen, wird dieser gelöscht. Sollten Sie ihn doch einreichen wollen, müssen Sie nochmal von vorne beginnen.',
    closeButton: WIZARD_LABELS.requestCloseTitle,
    secondaryButton: 'Antrag fortsetzen',
  },
  closeAddInformation: {
    title: 'Wollen Sie das Nachreichen wirklich abbrechen?',
    textBody:
      'Wenn Sie das Nachreichen schließen, gehen alle hinzugefügten Informationen verloren. Sie können jederzeit erneut Informationen nachreichen.',
    closeButton: WIZARD_LABELS.cancelAddInformationTitle,
    secondaryButton: 'Nachreichen fortsetzen',
  },
  saveOrCloseRequest: {
    title: 'Wollen Sie den Antrag wirklich schließen?',
    textBody:
      'Sie können Ihren Antrag vor dem Schließen speichern, ihn später fertig ausfüllen und abschicken. Beim Schließen ohne zu speichern, wird Ihr Antrag gelöscht.',
    linkText: 'Antrag fortsetzen',
    closeButton: WIZARD_LABELS.requestCloseTitle,
    secondaryButton: WIZARD_LABELS.saveDraft,
  },
  savedRequest: {
    title: 'Ihr Antrag wurde gespeichert',
    textBody: 'Sie können ihn jederzeit unter Anträge und Dokumente weiter bearbeiten.',
    closeButton: WIZARD_LABELS.requestCloseTitle,
    secondaryButton: 'Antrag fortsetzen',
  },
  closeDocumentRequest: {
    title: 'Wollen Sie die Seite wirklich schließen?',
    textBody: 'Wenn Sie die Seite jetzt schließen, wird das Dokument nicht angefordert.',
    closeButton: WIZARD_LABELS.requestCloseTitle,
    secondaryButton: 'Fortsetzen',
  },
};

export const RENTAL_OBJECT_ICON = {
  Wohnung: SVG.rental_object_flat,
  Parkplatz: SVG.rental_object_car_parking,
  Lokal: SVG.rental_object_restaurant,
  Magazin: SVG.rental_object_magazin,
};
