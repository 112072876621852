import React from 'react';
import { Hyperlink } from 'digit.commons.ui-components-app';
import { TENANT_PORTAL_SERVICE_NUMBER } from '../component-labels';

export const DOWNTIME_LABELS = {
  pageTitle: 'Wartungsarbeiten - Wiener Wohnen Mieter*innenportal',
  meta: '',
  errorPanel: {
    title: 'Wir sind bald wieder für Sie da!',
    solution: (
      <>
        Bei dringenden Fragen wenden Sie sich bitte an unsere Service-Nummer unter{' '}
        <Hyperlink
          id="down-time-service-number"
          to={`tel: ${TENANT_PORTAL_SERVICE_NUMBER}`}
          isInline={true}
          customHref={true}
        >
          {TENANT_PORTAL_SERVICE_NUMBER}
        </Hyperlink>
        .
      </>
    ),
  },
};
