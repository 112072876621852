import { STATUS } from '../status';
import React from 'react';
import { Hyperlink, SVG } from 'digit.commons.ui-components-app';
import { DATE_FORMATTER } from '../../../commons/utility/DateFormatter';

export const APPLICATION_FORM_LABELS = {
  title: 'Anträge und Dokumente',
  intro: 'Ich möchte ...',
  rentalObjectChange: '... etwas in meiner Wohnung oder im Hof ändern',
  requestServiceCard: '... meine Service-Karten verwalten',
  garage: '... etwas in meiner Garage ändern',
  rentSomething: '... etwas mieten',
  quitContract: '... etwas kündigen',
  changeSomethingOrGiveToSomeone: '... etwas tauschen oder weitergeben',
  myRequests: 'Meine Anträge',
  documentWanted: 'Ich benötige ein Dokument',
  contract: 'Vertrag und Mieterbestätigung',
  finance: 'Mietzins und Finanzen',
  allCompletedApplication: 'Alle abgeschossenen Anträge',
  processApplication: 'Antrag fortsetzen',
  completedApplications: 'Abgeschlossen',
  noCompletedApplications: 'Keine abgeschlossenen Anträge',
  backLink: 'Zurück',
  showDetails: 'Details anzeigen',
  myDrafts: 'Entwürfe',
  noDrafts: 'Keine Entwürfe',
  onlyFlats: 'Derzeit sind leider nur Anträge für Wohnungen verfügbar.',
  open: 'Offen',
  noOpenApplications: 'Keine offenen Anträge',
  loadMore: 'Weitere laden',
  temporarySolution: (
    <>
      Wir arbeiten bereits an weiteren Anträgen. In der Zwischenzeit können Sie die passenden Formulare auf unserer{' '}
      <Hyperlink
        id={'request-and-documents-wiener-wohnen-forms'}
        isExternal={true}
        iconAfter={SVG.external_link}
        to={'https://www.wienerwohnen.at/dokumente-downloads.html'}
      >
        Wiener Wohnen Webseite
      </Hyperlink>{' '}
      herunterladen und uns diese per Post schicken.
    </>
  ),
};

export const RENTAL_OBJECT_CHOICE = {
  links: [
    {
      id: 'request-and-documents-heating-and-ac',
      name: 'Heizung und Kühlung',
      target: '/dokumente/heizung-und-kühlung',
    },
    /*
                - KUN-1027-TEMP -
                - Temporarily commented for Release -
    {
      id: 'request-and-documents-ventilation',
      name: 'Lüftung und Dunstabzug',
      target: '#',
    },
    { id: 'request-and-documents-wall-and-floor', name: 'Wand und Boden', target: '#' },*/
    {
      id: 'request-and-documents-windows-and-doors',
      name: 'Fenster und Türen',
      target: '/dokumente/fenster-und-türen',
    },
    {
      id: 'request-and-documents-electrical-installation',
      name: 'Elektro-Installation',
      target: '/dokumente/elektro-installation',
    },
    { id: 'request-and-documents-sanitary', name: 'Wasser und Sanitär', target: '/dokumente/sanitär-umbauen' },
    {
      id: 'request-and-documents-tv-installation',
      name: 'TV-Installation und Medienanschluss',
      target: '/dokumente/sat-antenne-und-medienanschluss',
    },
    {
      id: 'request-and-documents-wand-and-boden',
      name: 'Wand und Boden',
      target: '/dokumente/wand-und-boden',
    },
    /*
   - KUN-1027-TEMP -
                - Temporarily commented for Release -
   {
      id: 'request-and-documents-accessibility',
      name: 'Barrierefreiheit',
      target: '#',
    },
    {
      id: 'request-and-documents-yard-staircase-garden',
      name: 'Hof, Stiegenhaus und Garten',
      target: '#',
    },*/
  ],
};

export const SERVICE_CARD_CHOICE = {
  links: [
    {
      id: 'request-and-documents-request-service-card',
      name: 'Service-Karte nachbestellen',
      target: '/dokumente/service-karte-nachbestellen',
    },
    {
      id: 'request-and-documents-unlock-pay-authority-service-card',
      name: 'Zahlungsfunktion freischalten',
      target: '/dokumente/service-karte-zahlungsfunktion',
    },
  ],
};

export const REQUEST_DOCUMENTS_RENTAL_ACCOUNT = {
  links: [
    {
      id: 'request-and-documents-rental-account-info',
      name: 'Mietzinskonto-Information',
      target: '/dokumente/mietzins',
    },
    {
      id: 'request-and-documents-monthly-rent-details',
      name: 'Details meiner monatlichen Miete (Zinszettel)',
      target: '/dokumente/zinszettel',
    },
  ],
};

export const REQUEST_DOCUMENTS_CONTRACT = {
  links: [
    {
      id: 'request-and-documents-main-tenant-confirmation',
      name: 'Hauptmieterbestätigung',
      target: '/dokumente/hauptmieter',
    },
  ],
};

export const APPLICATION_FORM_DETAILS_LABELS = {
  title: 'Antragsdetails',
  pageTitle: 'Details zu Antrag',
  meta: '',
  backLink: 'Zurück',
  currentStateSection: 'Aktueller Status',
  detailsSection: 'Zusammenfassung',
  historySection: 'Verlauf',
  transactionNumber: 'Bearbeitungsnummer',
  requester: 'AntragstellerIn',
  requestDetails: 'Antragsdetails',
  eMailAddress: 'E-Mail-Adresse',
  addInformation: 'Informationen nachreichen',
  answerOrder: 'Bestellung beantworten',
  finishOrder: 'Bestellung fertigstellen',
  agreedUpon: 'Zugestimmt',
  acceptedOrder: 'Angenommen',
  declinedOrder: 'Abgelehnt',
  finishNote: 'Notiz der Fertigstellung',
  getExpirationTextForLatestStatusMapping(status: string, date: number) {
    return (
      <>
        Ihr angefordertes Dokument {status === STATUS.expired.apiStatus ? 'war' : 'ist'} gültig bis{' '}
        {DATE_FORMATTER.dateFormat(date)}.
      </>
    );
  },
};

export const APPLICATION_FORM_OVERLAY_LABELS = {
  title: 'Wollen Sie diesen Entwurf wirklich löschen?',
  body:
    'Wenn Sie einen Antrag löschen, kann dies nicht mehr rückgängig gemacht werden. Sollten Sie den Antrag danach doch einreichen wollen, müssen Sie ihn noch einmal ausfüllen.',
  secondaryButtonText: 'Abbrechen',
  primaryButtonText: 'Löschen',
  backLink: 'Zurück zu Anträge und Dokumente',
};
