import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { ITemplate, ITemplateHandles } from '../interfaces/Template.interface';
import './Template.scss';
import { TemplateComponents } from './TemplateComponents';
import { Fieldset, IFieldsetHandles } from 'digit.commons.ui-components-app';

const SingleFieldsetTemplate: React.ForwardRefRenderFunction<ITemplateHandles, ITemplate> = (
  { processData, possibleContent, step, download, decisionCallback },
  ref
) => {
  const fieldsetRef = useRef<IFieldsetHandles>(null);

  useImperativeHandle(ref, () => ({
    validateTemplate: () => {
      fieldsetRef.current.validate();
      return processData && !!processData[step.id];
    },
  }));

  const toDecisionValue = (selection: Set<string>): any => {
    return [].concat({ key: step.id, value: Array.from(selection.values()) });
  };

  return possibleContent[step.id].error ? (
    possibleContent[step.id].error.map((e, index) => (
      <h4 key={`error-${index}`} className={'Template__title'}>
        {e.text}
      </h4>
    ))
  ) : (
    <>
      {step.props.sections &&
        TemplateComponents.renderSections(step.props.sections, step.id, 'single-fieldset', null, null, download)}
      <Fieldset
        id={step.id}
        key={step.id}
        title={
          possibleContent[step.id] && possibleContent[step.id].questionTitle
            ? possibleContent[step.id].questionTitle
            : step.props.title
        }
        options={TemplateComponents.getOptionsContent(possibleContent[step.id].options)}
        description={
          step.props.subquestion
            ? step.props.subquestion
            : possibleContent[step.id] && possibleContent[step.id].subquestion
            ? possibleContent[step.id].subquestion
            : ''
        }
        multiple={step.props.multiple}
        flow={'grid'}
        cols={1}
        preselect={processData && processData[step.id] ? processData[step.id] : null}
        errorMessage={step.props.errorMessage ? step.props.errorMessage : 'Bitte auswählen, um weiter zu kommen'}
        required={step.props.required}
        onChange={selection => decisionCallback(step.id, toDecisionValue(selection))}
        ref={fieldsetRef}
      />
    </>
  );
};

export default forwardRef(SingleFieldsetTemplate);
