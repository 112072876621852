import { Hyperlink } from 'digit.commons.ui-components-app';
import { TENANT_PORTAL_SERVICE_NUMBER } from './general';
import React from 'react';
import { APPLICATION_FORM_BASE_ROUTE } from './routes';

export const ERROR_APPLICATION_FORM_SERVICE_UNAVAILABLE = {
  title: 'Service nicht erreichbar',
  text:
    'Leider kann zur Zeit nicht auf das Antrags-Service zugegriffen werden. Bitte versuchen Sie es später noch einmal.',
  description: '',
};

export const ERROR_WIZARD_API_CALLS = {
  serviceUnavailable: {
    title: 'Service nicht erreichbar',
    submitErrorText: 'Wir konnten Ihren Antrag leider nicht absenden. Bitte versuchen Sie es später noch einmal.',
    saveDraftErrorText: 'Wir konnten Ihren Antrag leider nicht speichern. Bitte versuchen Sie es später noch einmal.',
    loadErrorText:
      'Der Anträge-Service ist im Moment leider nicht erreichbar. Bitte versuchen Sie es später noch einmal.',
    description: (
      <>
        Bei dringenden Fragen wenden Sie sich bitte an unseren Helpdesk unter{' '}
        <Hyperlink id="laundry-day-booking" to={`tel:${TENANT_PORTAL_SERVICE_NUMBER}`} customHref={true}>
          {TENANT_PORTAL_SERVICE_NUMBER}
        </Hyperlink>
      </>
    ),
  },
};

export const ERROR_APPLICATION_FORM_LANDING = {
  onDelete: {
    description: 'Wir können im Moment nicht auf Ihre Daten zugreifen. Probieren Sie es bitte später noch einmal.',
  },
  fetchAllForms: {
    title: 'Hoppala!',
    description: 'Ihre Anträge konnten leider nicht geladen werden. Probieren Sie es bitte später noch einmal.',
  },
  fetchContracts: {
    title: 'Hoppala!',
    description:
      'Es konnten leider nicht alle Anträge geladen werden. Wenn Sie ein Thema vermissen, probieren Sie es bitte später noch einmal.',
  },
};

export const ERROR_APPLICATION_FORM_DETAILS = {
  onLoad: {
    title: 'Hoppala!',
    description: 'Wir können im Moment nicht auf Ihre Daten zugreifen. Probieren Sie es bitte später noch einmal.',
  },
  notFound: {
    title: 'Kein Antrag gefunden',
    errorText: (
      <>
        Der von Ihnen gesuchte Antrag wurde nicht gefunden. Sie finden alle Ihre Anträge unter dem Punkt{' '}
        <Hyperlink
          id={'request-details-back-to-start'}
          to={`${APPLICATION_FORM_BASE_ROUTE}#request-and-document-my-requests-sub-section-id`}
        >
          Meine Anträge und Dokumente
        </Hyperlink>
        .
      </>
    ),
  },
};

export const FORM_VALIDATION_MESSAGE = {
  emailError: 'Die Eingabe muss gültig sein',
  mobileError: 'Die Eingabe muss gültig sein',
  landlineError: 'Die Eingabe muss gültig sein',
};
