import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import 'moment/locale/de-at';
/** Context */
import Landing from './portal-app/containers/Landing/Landing';
/** Components */
import { ErrorPanel, LoadingIndicator, Section } from 'digit.commons.ui-components-app';
/** Constants */
import {
  CONTACT,
  DATA_PROTECTION,
  FAQ,
  IMPRESSUM,
  INVALID_CONTRACT,
  LANDING,
  LOGIN_REDIRECT,
  LOGOUT,
  OAUTH_TOKEN_RETRIEVER,
  PRELANDING,
  PROFILE,
  REGISTRATION,
  REGISTRATION_CONFIRMATION,
  RENTAL_ACCOUNT,
  SERVICE_CARD,
  WHATS_NEW,
} from './portal-app/constants/routes';
/** Authentication */
import { AuthenticationState } from './AuthenticationState';
import TenantApi from './commons/api/tenantData/TenantData.api';
import { TrackingContextProvider } from './portal-app/context/TrackingContext';
import BridgeApi from './commons/api/Bridge';
import Logout from './portal-app/containers/Logout/Logout';
import { piwikEvents } from './commons/utility/piwikEvents';
import DownTimeInfoBar from './portal-app/components/DownTimeInfoBar/DownTimeInfoBar';
import DownTime from './portal-app/containers/DownTime/DownTime';
import { Piwik } from './portal-app/utility/piwikSetup';
import ErrorBoundary from './portal-app/containers/ErrorBoundary/ErrorBoundary';
import { ERROR_BOUNDARY, PAGE_TITLE_APP } from './portal-app/constants/component-labels';
import InvalidContract from './portal-app/containers/InvalidContract/InvalidContract';
import { LAUNDRY_DAY_ROUTES } from './laundryday/constants/routes';
import ReactPiwik from 'react-piwik';
import { Configuration } from './Configuration';
import { IGraphQL } from './commons/api/GraphQL.interface';
import { ITenantNameAndClientFeatures } from './commons/api/tenantData/TenantData.interface';
/** Extensions */
import './commons/extensions/Array.extension';
import * as Sentry from '@sentry/react';
import { Breadcrumb } from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { APPLICATION_FORM_ROUTES } from './applicationform/constants/routes';
import ApplicationFormRoutes from './applicationform/ApplicationFormRoutes';
import { TenantDataContextProvider } from './portal-app/context/TenantDataContext';

const LaundryDayRoutes = lazy(() => import('./laundryday/LaundryDayRoutes'));

/** Containers */
const OAuthTokenRetriever = lazy(() => import('./portal-app/containers/OAuthTokenRetriever/OAuthTokenRetriever'));
const Registration = lazy(() => import('./portal-app/containers/Registration/Registration'));
const PreLanding = lazy(() => import('./portal-app/containers/PreLanding/PreLanding'));
const Profile = lazy(() => import('./portal-app/containers/Profile/Profile'));
const Imprint = lazy(() => import('./portal-app/containers/Imprint/Imprint'));
const RentalAccount = lazy(() => import('./portal-app/containers/RentalAccount/RentalAccount'));
const DataProtection = lazy(() => import('./portal-app/containers/DataProtection/DataProtection'));
const Contact = lazy(() => import('./portal-app/containers/Contact/Contact'));
const Faq = lazy(() => import('./portal-app/containers/Faq/Faq'));
const NoMatchingRoute = lazy(() => import('./portal-app/containers/NoMatchingRoute/NoMatchingRoute'));
const ServiceCard = lazy(() => import('./portal-app/containers/ServiceCard/ServiceCard'));
const RegistrationConfirmation = lazy(() =>
  import('./portal-app/containers/Registration/RegistrationConfirmation/RegistrationConfirmation')
);
const WhatsNewHistory = lazy(() => import('./portal-app/containers/WhatsNewHistory/WhatsNewHistory'));

/**
 * test js-bridge functionality on changes in url hash
 */
window.addEventListener(
  'hashchange',
  (e: HashChangeEvent) => {
    if (e.newURL.endsWith('fail-every')) {
      window['failEvery5'] = !window['failEvery5'];
      BridgeApi.change_min_timespan_for_testing(10 * 1000);
      // 'ajax requests fail every 5 times: ' + window['failEvery5'],
      // ' and Bridge will allow 10 seconds between refresh refresh requests'
    } else if (e.newURL.endsWith('checkAuthenticationState')) {
      window['ha'].checkAuthenticationState(state => console.log('BRIDGE CHECK AUTH STATE -> ', state));
    } else if (e.newURL.endsWith('getAppDataCallback')) {
      window['ha'].getAppDataCallback('{"username": "bingo bongo"}');
    }
  },
  false
);

const TenantNotAvailableErrorPanel = () => (
  <Section id={'app-tenant'} title={PAGE_TITLE_APP}>
    <ErrorPanel
      id={'app-tenant'}
      title={ERROR_BOUNDARY.title}
      errorText={ERROR_BOUNDARY.errorText}
      descriptionText={ERROR_BOUNDARY.description}
    />
  </Section>
);

const App: React.FC = () => {
  const [loggedInGepartId, setLoggedInGepartId] = useState<string>('');
  const [tenantData, setTenantData] = useState<ITenantNameAndClientFeatures>({ tenant: null, clientFeatures: null });
  const [numberOfRetries, setNumberOfRetries] = useState<number>(0);
  const [tenantError, setTenantError] = useState<boolean>(false);

  useEffect(() => {
    if (AuthenticationState.isLoggedIn) {
      getTenantById();
    } else {
      setTenantError(true);
    }

    Sentry.init({
      enabled: Configuration.sentryConfig().DSN !== '',
      dsn: Configuration.sentryConfig().DSN,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
      environment: Configuration.sentryConfig().env,
      beforeBreadcrumb(breadcrumb: Breadcrumb, hint) {
        // graphql queries potentially include personal data
        if (breadcrumb.category === 'fetch') {
          breadcrumb.data.url = breadcrumb.data.url.split('&query=')[0];
        }
        // js-bridge logging potentially includes personal data, therefore we filter out debug logs
        if (breadcrumb.category === 'console' && breadcrumb.level === 'debug') {
          return null;
        }
        return breadcrumb;
      },
      attachStacktrace: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const piwikSetup = new ReactPiwik({
    url: Configuration.piwikConfig().PIWIK,
    siteId: Configuration.piwikConfig().PIWIK_SITE_ID,
    trackDocumentTitle: true,
    enableLinkTracking: true,
  });

  const getTenantById = async () => {
    try {
      const responseWithData: IGraphQL<ITenantNameAndClientFeatures> = await TenantApi.fetchTenantNameAndFeatures();
      setTenantData(responseWithData.data);
      if (responseWithData.data.tenant) {
        if (responseWithData.data.tenant.details.firstName && responseWithData.data.tenant.details.lastName) {
          BridgeApi.setUserName(
            responseWithData.data.tenant.details.firstName + ' ' + responseWithData.data.tenant.details.lastName
          );
        }
        const response = responseWithData.data.tenant.details;
        setLoggedInGepartId(response.gepartId);
      } else if (numberOfRetries < 5) {
        setTimeout(() => setNumberOfRetries(numberOfRetries + 1), 1500);
      }
    } catch (error) {
      piwikEvents.trackEvent(
        'Technischer Fehler',
        'Laden der Applikation (App.tsx)',
        'Fehler beim Laden des Namens des Mieters'
      );
      setTenantError(true);
    }
  };

  const { authRedirectUrl, isLoggedIn, redirectFrom, isInDowntime, isAccessToPortalBlocked } = AuthenticationState;

  return (
    <>
      {
        /**
         * Important, due to backend changes (asychronous Kafka instead of REST call):
         * There are four scenarios:
         * 1) getTenantById will fail because the user does not have an x-pvp-wienerwohnen-customerid in the header
         * 2) getTenantById will fail because tenant-service is not available
         * 3) getTenantById will not fail, but doesn't return a tenant
         *          (NEW: this is because Kafka is asynchronous, so after registering the database entry
         *                for the registered tenant might not be persisted at this point)
         * 4) getTenantById will return the tenant
         *
         * In case 1) we redirect to login/registration
         * In case 2) the error message (data not available) is shown
         * In case 3) we will poll for the gepartId (at the moment 5 times, every 1.5 seconds) and a loading indicator
         *            will be shown all the time, until the 5th retry fails - then the error message will be shown.
         * In case 4) everything will be fine (besides short loading indicator until the tenant is fetched).
         */
        isLoggedIn && !loggedInGepartId && !tenantError && numberOfRetries < 5 ? ( // case 3)
          <LoadingIndicator id={'tenant-loading-indicator'} />
        ) : (
          // all other cases
          <TrackingContextProvider>
            <TenantDataContextProvider tenantData={tenantData}>
              <Router>
                <>
                  <main id="app-main-id" role="main">
                    <h1 className={'sr-only'}>Wiener Wohnen Mieter*innenportal App</h1>
                    <Piwik piwikSetup={piwikSetup} />
                    {(AuthenticationState.oneWeekBeforeDowntime || (isInDowntime && !isAccessToPortalBlocked)) && (
                      <aside>
                        <DownTimeInfoBar />
                      </aside>
                    )}
                    {isAccessToPortalBlocked ? (
                      <DownTime isLoggedIn={isLoggedIn} redirectPath={authRedirectUrl} />
                    ) : (
                      <ErrorBoundary>
                        <Suspense fallback={<LoadingIndicator id={'lazy-loading-indicator'} />}>
                          <Switch>
                            [...
                            {loggedInGepartId && [
                              <Route key={'route-1'} exact path={PRELANDING}>
                                <Redirect to={LANDING} />
                              </Route>,
                              <Route key={'route-2'} exact path={INVALID_CONTRACT}>
                                <Redirect to={LANDING} />
                              </Route>,
                              <Route key={'route-3'} exact path={LANDING}>
                                <Landing />
                              </Route>,
                              <Route key={'route-4'} path={PROFILE}>
                                <Profile />
                              </Route>,
                              <Route key={'route-5'} path={`${RENTAL_ACCOUNT}/:vkont?`} component={RentalAccount} />,
                              <Route key={'route-6'} path={SERVICE_CARD}>
                                <ServiceCard />
                              </Route>,
                              <Route key={'route-7'} exact path={LAUNDRY_DAY_ROUTES}>
                                <LaundryDayRoutes />
                              </Route>,
                              <Route key={'route-8'} exact path={WHATS_NEW}>
                                <WhatsNewHistory />
                              </Route>,
                              <Route key={'route-8'} exact path={APPLICATION_FORM_ROUTES}>
                                <ApplicationFormRoutes />
                              </Route>,
                            ]}
                            ,
                            <Route key={'route-9'} exact path={REGISTRATION}>
                              <Registration />
                            </Route>
                            ,
                            <Route key={'route-10'} path={REGISTRATION_CONFIRMATION}>
                              <RegistrationConfirmation />
                            </Route>
                            ,
                            <Route key={'route-11'} path={IMPRESSUM}>
                              <Imprint />
                            </Route>
                            ,
                            <Route key={'route-12'} path={DATA_PROTECTION}>
                              <DataProtection />
                            </Route>
                            ,
                            <Route key={'route-13'} path={CONTACT}>
                              <Contact />
                            </Route>
                            ,
                            <Route key={'route-14'} path={FAQ}>
                              <Faq />
                            </Route>
                            ,
                            <Route key={'route-15'} path={LOGIN_REDIRECT}>
                              <Redirect to={BridgeApi.getRedirectFrom()} />
                            </Route>
                            ,
                            <Route key={'route-16'} path={OAUTH_TOKEN_RETRIEVER}>
                              <OAuthTokenRetriever />
                            </Route>
                            ,
                            <Route key={'route-17'} path={LOGOUT}>
                              <Logout />
                            </Route>
                            ,
                            <Route key={'route-18'} path={PRELANDING}>
                              <PreLanding redirectFrom={redirectFrom} />
                            </Route>
                            ,
                            <Route key={'route-19'} exact path={INVALID_CONTRACT}>
                              <InvalidContract />
                            </Route>
                            ,
                            <Route key={'route-20'}>
                              {() => {
                                if (tenantError || numberOfRetries >= 5) {
                                  return <TenantNotAvailableErrorPanel />;
                                } else if (!isLoggedIn) {
                                  return <Redirect to={authRedirectUrl} />;
                                } else {
                                  return <NoMatchingRoute />;
                                }
                              }}
                            </Route>
                            ]
                          </Switch>
                        </Suspense>
                      </ErrorBoundary>
                    )}
                  </main>
                </>
              </Router>
            </TenantDataContextProvider>
          </TrackingContextProvider>
        )
      }
    </>
  );
};

export default App;
