import React from 'react';
import { Card, CardBody, CardFooter, CardHeader, Hyperlink, SVG } from 'digit.commons.ui-components-app';
import {
  IAnnouncement,
  IApplicationFormAnnouncementData,
} from '../../../commons/api/announcement/Announcement.interface';
import { ANNOUNCEMENT } from '../../constants/components/announcement';
import { DETAILS_ROUTE } from '../../constants/routes';
import { ApplicationFormUtility } from '../../utility/ApplicationFormUtility';
import { IProcessData } from '../../decisionTree/interfaces/DecisionTree.interface';
import '../../../portal-app/components/Announcement/InBoxAnnouncement.scss';

interface IFormAnnouncementProps {
  id: string;
  announcement: IAnnouncement;
  type: string;
  showDetails?: boolean;
  onClose?: () => void;
}

const FormAnnouncement: React.FC<IFormAnnouncementProps> = props => {
  const { id, announcement } = props;
  const { actions, badge, title, body, badgeModifier } = ANNOUNCEMENT[props.type];
  const formAnnouncementType = props.type.replace('_', '-').toLowerCase();

  const formAnnouncement = announcement.data as IApplicationFormAnnouncementData;

  const close = async () => {
    await announcement.closeFunction();
    props.onClose && props.onClose();
  };

  return (
    <Card
      id={`${id}-form-${formAnnouncementType}-announcement`}
      badge={badge}
      badgeType={badgeModifier}
      className={'InBoxAnnouncement'}
      onDismiss={close}
      dismissDescribedById={`${id}-form-${formAnnouncementType}-announcement-header-id`}
    >
      <CardHeader title={title} icon={SVG.edit_doc} />
      <CardBody>{body(formName(formAnnouncement), formAnnouncement.processCode)}</CardBody>
      <CardFooter>
        <Hyperlink
          id={`${id}-form-${formAnnouncementType}-announcement`}
          to={formDetailRoute(formAnnouncement)}
          iconAfter="arrow-right"
        >
          {actions.showDetails}
        </Hyperlink>
      </CardFooter>
    </Card>
  );
};

const formName = (announcement: IApplicationFormAnnouncementData): string => {
  const name = ApplicationFormUtility.getFormDescription(
    announcement.form.theme,
    announcement.form.workflow,
    JSON.parse(announcement.form.json) as IProcessData,
    null
  );
  if (name.toLowerCase().startsWith('antrag ')) return name.substring(7);
  else return name;
};

const formDetailRoute = (announcement: IApplicationFormAnnouncementData): string =>
  DETAILS_ROUTE.replace(':applicationFormId', announcement.form.shortId);

export default FormAnnouncement;
