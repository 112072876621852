export default class ApplicationFormSCSInitializer {
  private static configuration: IAFConfiguration;
  private static DEFAULT_FETCH_TIMEOUT = 15000;
  private static authRequiredHandler: () => void;

  static apiConfig(): IAFApiConfig {
    return ApplicationFormSCSInitializer.configuration.api;
  }

  static queryConfig(): IAFQueryConfig {
    return ApplicationFormSCSInitializer.configuration.query
      ? ApplicationFormSCSInitializer.configuration.query
      : { timeoutInMillis: this.DEFAULT_FETCH_TIMEOUT };
  }

  static init(configuration: IAFConfiguration, authenticationRequiredHandler: () => void) {
    ApplicationFormSCSInitializer.configuration = configuration;
    ApplicationFormSCSInitializer.authRequiredHandler = authenticationRequiredHandler;
  }

  static piwikConfig(): IPiwikConfig {
    return ApplicationFormSCSInitializer.configuration.piwik;
  }

  static authenticationRequiredHandler(): () => void {
    return ApplicationFormSCSInitializer.authRequiredHandler;
  }
}

export interface IAFConfiguration {
  api: IAFApiConfig;
  query: IAFQueryConfig;
  piwik: IPiwikConfig;
}
interface IAFApiConfig {
  tenantApi: string;
  applicationFormApi: string;
  paths: IAFPaths;
}
interface IAFPaths {
  pathToGraphQL: string;
  pathToDocument: string;
}
export interface IAFQueryConfig {
  timeoutInMillis: number;
}

interface IPiwikConfig {
  PIWIK: string;
  PIWIK_SITE_ID: number;
  enableHeatMapSessionRecording: boolean;
}
