import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Checkbox,
  Download,
  ErrorBox,
  ErrorPanel,
  Heading,
  LoadingIndicator,
  Section,
  SVG,
} from 'digit.commons.ui-components-app';
import { Helmet } from 'react-helmet';
import {
  APPLICATION_FORM_DETAILS_LABELS,
  REQUEST_DOCUMENTS_CONTRACT,
} from '../../constants/containers/request-and-documents-labels';
import './ApplicationFormDetails.scss';
import {
  IApplicationFormDetailData,
  IApplicationFormHistoryState,
  IDocument,
} from '../../../commons/api/applicationForm/ApplicationForm.interface';
import ApplicationFormSCSInitializer from '../../ApplicationFormSCSInitializer';
import DecisionTreeLogic from '../../decisionTree/logic/DecisionTreeLogic';
import ApplicationFormHistory from '../../components/ApplicationFormHistory/ApplicationFormHistory';
import { STATUS, STATUS_API_TO_ENUM, StatusInformation } from '../../constants/status';
import { IContentData } from '../../decisionTree/interfaces/PossibleContent.interface';
import { IBacklink } from '../../decisionTree/interfaces/IBacklink';
import ApplicationFormApi from '../../../commons/api/applicationForm/ApplicationForm.api';
import { ApplicationFormUtility } from '../../utility/ApplicationFormUtility';
import { IProcessData } from '../../decisionTree/interfaces/DecisionTree.interface';
import { ERROR_APPLICATION_FORM_DETAILS } from '../../constants/errors';
import { RequestTheme, RequestWorkflow } from '../../constants/containers/request-theme';
import { TemplateComponents } from '../../decisionTree/templates/TemplateComponents';
import { DATE_FORMATTER } from '../../../commons/utility/DateFormatter';
import { FORMATTER } from '../../utility/formatHandler';
import { useFetch } from '../../../commons/hooks/useFetch';
import TenantDataApi from '../../../commons/api/tenantData/TenantData.api';
import { ITenantAndClientFeatures } from '../../../commons/api/tenantData/TenantData.interface';
import NavigationHeader from '../../components/NavigationHeader/NavigationHeader';
import { DOWNLOAD_HELPER } from '../../../portal-app/utility/downloadHelper';
import { NavigationItems } from '../../../commons/api/Bridge';
import applicationFormApi from '../../../commons/api/applicationForm/ApplicationForm.api';

export interface ILocation extends IApplicationFormHistoryState {
  toExistingForms: boolean;
}

export interface IParams {
  applicationFormId: string;
}

const ApplicationFormDetails: React.FC = () => {
  const {
    title,
    pageTitle,
    meta,
    currentStateSection,
    detailsSection,
    requestDetails,
    requester,
    eMailAddress,
    backLink,
    addInformation,
    answerOrder,
    finishOrder,
    agreedUpon,
    acceptedOrder,
    declinedOrder,
    finishNote,
  } = APPLICATION_FORM_DETAILS_LABELS;

  const { notFound } = ERROR_APPLICATION_FORM_DETAILS;

  const routeMatch = useRouteMatch<IParams>();
  const location = useLocation<IApplicationFormHistoryState>();
  const history = useHistory();

  const { data, isFetchInProgress, fetchErrors } = useFetch<ITenantAndClientFeatures & IApplicationFormDetailData>(
    {
      [TenantDataApi.orig]: () => TenantDataApi.fetchTenantMail(),
      [ApplicationFormApi.orig]: () =>
        ApplicationFormApi.fetchApplicationFormDetailData(routeMatch.params.applicationFormId),
    },
    NavigationItems.NAVIGATION_APPLICATION_FORM_DETAILS
  );

  /** the order of the data to be displayed - if a key doesn't occur in summary then it will be skipped */
  const orderOfDetails = [
    'Antrag',
    'Ziel Adresse',
    requestDetails,
    'Service-Karte',
    'Details',
    requester,
    eMailAddress,
    'Mietobjekt',
    'Ort',
    'Raum',
    'Nachricht',
    'Dokumente',
    'Preis',
    'Kassenautomat',
  ];
  const [applicationFormHistory, setApplicationFormHistory] = useState<IApplicationFormHistoryState>();
  const [loading, setLoading] = useState<boolean>(true);
  const [possibleContent, setPossibleContent] = useState<IContentData>();
  const mailAddress = useRef<string>('');
  const status = useRef<StatusInformation>();
  const invalidApplicationFormId = useRef<boolean>(false);
  const backlink = useRef<IBacklink>();

  const apiConfig = ApplicationFormSCSInitializer.apiConfig();

  useEffect(() => {
    if (!fetchErrors.isNullOrEmpty()) {
      setLoading(false);
      if (fetchErrors.some(error => error.orig === 'ApplicationFormApi')) return;
    }
    if (!isFetchInProgress) {
      mailAddress.current = data.tenant.details.email;
      const applicationFormDetail = data.getApplicationFormDetailData;
      if (applicationFormDetail.notExistingOrNotAllowed) {
        invalidApplicationFormId.current = true;
        setLoading(false);
      } else {
        const applicationForm = applicationFormDetail.applicationForm;
        const processData: IProcessData = JSON.parse(applicationForm.json.replace('\\""', '"'));
        setApplicationFormHistory(
          ApplicationFormUtility.buildApplicationFormHistoryState(
            applicationForm,
            processData,
            backLink,
            location?.state?.backlinkState
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchInProgress]);

  /** once the applicationFormHistory is set we instantiate the decisionTree and get our possibleData */
  useEffect(() => {
    const setupDetails = async () => {
      if (applicationFormHistory) {
        /* since the whole location state is in applicationFormHistory we can set any further state here */
        status.current = applicationFormHistory.status ? STATUS_API_TO_ENUM(applicationFormHistory.status) : null;
        if (applicationFormHistory.backlinkText && applicationFormHistory.backlinkRoute) {
          backlink.current = {
            text: applicationFormHistory.backlinkText,
            route: applicationFormHistory.backlinkRoute,
            state: applicationFormHistory.backlinkState,
          };
        }
        if (
          applicationFormHistory.workflow === RequestWorkflow.CONSTRUCTION ||
          applicationFormHistory.workflow === RequestWorkflow.REQUEST_SERVICE_CARD ||
          applicationFormHistory.workflow === RequestWorkflow.UNKNOWN
        ) {
          const response = await ApplicationFormApi.fetchFlowStructureByTheme(
            applicationFormHistory.theme,
            applicationFormHistory.formId
          );
          if (response && response.data.getFlowJsonByTheme) {
            const decisionTreeLogic = new DecisionTreeLogic(JSON.parse(response.data.getFlowJsonByTheme.json).data);
            /* get all possibleContent for the finished processData that was submitted to the backend */
            const allPossibleContent = decisionTreeLogic.getAllPossibleMatchingContent(
              applicationFormHistory.processData
            );
            if (
              applicationFormHistory.workflow === RequestWorkflow.CONSTRUCTION ||
              applicationFormHistory.workflow === RequestWorkflow.UNKNOWN
            ) {
              /* since confirmation has to be the last step, it's where the content for documents is gathered logically */
              const lastStepId = decisionTreeLogic.getStepByIndex(decisionTreeLogic.getStepLength() - 1).id;
              /* and that's all we are interested in, unless we need to display a further subsection defined under 'details' */
              if (allPossibleContent['details']) {
                setPossibleContent({
                  ...allPossibleContent[lastStepId],
                  details: {
                    ...allPossibleContent['details'],
                  },
                });
              } else {
                setPossibleContent({
                  ...allPossibleContent[lastStepId],
                });
              }
            } else {
              setPossibleContent(allPossibleContent);
            }
          }
        }
        setLoading(false);
      }
    };

    setupDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationFormHistory]);

  /**
   * renders the sections with all the given information of a single application form
   * - Aktueller Status (current state including badge and status information + fetched e-mail address in some cases)
   * - Details (transaction-id, name and address of the requester, e-mail address, rental object and rooms to be changed)
   * - Verlauf (history of all status and their date + corresponding documents)
   */
  const renderCardsAndHistory = () => {
    return (
      <>
        <Card id={'application-form-details-state'} badge={status.current.visual} badgeType={status.current.badgeType}>
          <CardHeader title={currentStateSection} />
          <CardBody customClass={'ApplicationFormDetails__status-card'}>
            <div className={'ApplicationFormDetails__information'}>
              {applicationFormHistory && applicationFormHistory.status && (
                <>
                  {status.current && status.current.additionalInformation && (
                    <>
                      {status.current &&
                        status.current.additionalInformation(
                          mailAddress,
                          status.current.apiStatus === STATUS.hb_info_pending.apiStatus
                            ? applicationFormHistory.statusMappings[0].expiryDate
                            : applicationFormHistory.workflow === 'hbOrder'
                            ? applicationFormHistory.workflow
                            : applicationFormHistory.theme
                        )}
                    </>
                  )}
                </>
              )}
              {showExpirationInformation()}
              {showLatestDocument()}
              {showButtonLinkToAddInformationFlow()}
            </div>
          </CardBody>
        </Card>
        {possibleContent && possibleContent['details'] ? (
          <Card id={'application-form-details-further-information'}>
            <CardHeader title={possibleContent['details'].title} />
            <CardBody>
              {TemplateComponents.renderSections(
                possibleContent['details'] && possibleContent['details'].sections,
                'details',
                'application-form-details-further-information'
              )}
            </CardBody>
          </Card>
        ) : null}
        {applicationFormHistory && applicationFormHistory.processData && applicationFormHistory.processData['summary'] && (
          <Card id={'application-form-details-detail'}>
            <CardHeader title={detailsSection} />
            <CardBody>{generateDetails()}</CardBody>
          </Card>
        )}
        <Heading level={2} className={'ApplicationFormDetails__heading'}>
          Verlauf
        </Heading>
        {applicationFormHistory &&
          (possibleContent &&
          (applicationFormHistory.workflow === RequestWorkflow.CONSTRUCTION ||
            applicationFormHistory.workflow === RequestWorkflow.UNKNOWN) ? (
            <ApplicationFormHistory
              id={'application-form-details'}
              formId={applicationFormHistory.formId}
              possibleContent={possibleContent}
              statusMappings={applicationFormHistory.statusMappings}
              processData={applicationFormHistory.processData}
            />
          ) : (
            generateNonFlowHistory()
          ))}
      </>
    );
  };

  const showExpirationInformation = () => {
    if (
      hasStatusMappings &&
      (applicationFormHistory.theme === RequestTheme.MAIN_TENANT_CONFIRMATION ||
        applicationFormHistory.theme === RequestTheme.RENTAL_ACCOUNT_INFO)
    ) {
      const latestStatusMapping = applicationFormHistory.statusMappings[0];
      if (
        latestStatusMapping.status === STATUS.readytodownload.apiStatus ||
        latestStatusMapping.status === STATUS.expired.apiStatus
      )
        return (
          <p>
            {APPLICATION_FORM_DETAILS_LABELS.getExpirationTextForLatestStatusMapping(
              latestStatusMapping.status,
              latestStatusMapping.status === STATUS.expired.apiStatus
                ? ApplicationFormUtility.changeDateInDays(latestStatusMapping.changeDate, -1)
                : applicationFormHistory.theme === RequestTheme.MAIN_TENANT_CONFIRMATION
                ? ApplicationFormUtility.changeDateInFourWeeks(latestStatusMapping.changeDate)
                : ApplicationFormUtility.changeDateInDays(latestStatusMapping.changeDate, 1)
            )}
          </p>
        );
    } else if (hasStatusMappings && applicationFormHistory.theme === RequestTheme.RENTAL_ACCOUNT_INFO) {
    }
    return null;
  };

  const showLatestDocument = () => {
    if (!isServiceCardRequest && hasStatusMappings) {
      // access hardcoded first element in statusMappings is legit here. We're getting a sorted Set by 'Date DESC' from the backend
      const latestDocument = applicationFormHistory.statusMappings[0].document;
      if (latestDocument) {
        return (
          <div className={`ApplicationFormDetails__download${latestDocument.expired ? '--expired' : ''}`}>
            {getDownload(latestDocument, 'latest')}
          </div>
        );
      }
    }
    return null;
  };

  const getDownload = (document: IDocument, uniqueIdExtension: string) => {
    return (
      <Download
        id={`application-form-details-download-${uniqueIdExtension}-${document.name}`}
        className={'ApplicationFormDetails__download'}
        icon={SVG.pdf}
        expired={document.expired}
        onClick={() =>
          applicationFormApi.downloadWithIds(
            document.applicationFormId,
            document.id,
            `${ApplicationFormUtility.getDocumentDisplayName(document.name)}.pdf`
          )
        }
        requestAnewLink={
          REQUEST_DOCUMENTS_CONTRACT.links.find(link => link.id === 'request-and-documents-main-tenant-confirmation')
            .target
        }
      >
        {ApplicationFormUtility.getDocumentDisplayName(document.name, true)}
      </Download>
    );
  };

  const showButtonLinkToAddInformationFlow = () => {
    if (hasStatusMappings) {
      const latestStatusMapping = applicationFormHistory.statusMappings[0];
      if (latestStatusMapping.status === STATUS.tenant_info_pending.apiStatus) {
        return (
          <Button
            id={'application-form-details-add-information'}
            onClick={() =>
              history.push({
                pathname: '/dokumente/informationen-nachreichen',
                state: {
                  formId: applicationFormHistory.formId,
                  processData: applicationFormHistory.processData,
                  addInformation: true,
                  download: {
                    description: latestStatusMapping.document.name,
                    downloadLink: `${apiConfig.applicationFormApi}${apiConfig.paths.pathToDocument}/${latestStatusMapping.document.applicationFormId}/${latestStatusMapping.document.id}`,
                    icon: 'pdf',
                  },
                  addInformationToStatusMapping: latestStatusMapping.id,
                  closeRoute: routeMatch.url,
                },
              })
            }
          >
            {addInformation}
          </Button>
        );
      } else if (latestStatusMapping.status === STATUS.hb_info_pending.apiStatus) {
        return (
          <Button
            id={'application-form-details-answer-order'}
            onClick={() =>
              history.push({
                pathname: '/dokumente/bestellung-beantworten',
                state: {
                  formId: applicationFormHistory.formId,
                  processData: applicationFormHistory.processData,
                  download: {
                    description: latestStatusMapping.document.name,
                    downloadLink: `${apiConfig.applicationFormApi}${apiConfig.paths.pathToDocument}/${latestStatusMapping.document.applicationFormId}/${latestStatusMapping.document.id}`,
                    icon: 'pdf',
                  },
                  addInformation: true,
                  latestStatus: latestStatusMapping.status,
                  closeRoute: routeMatch.url,
                },
              })
            }
          >
            {answerOrder}
          </Button>
        );
      } else if (latestStatusMapping.status === STATUS.hb_finish_pending.apiStatus) {
        return (
          <Button
            id={'application-form-details-finish-order'}
            className={'ApplicationFormDetails__button--finish-hb'}
            onClick={() =>
              history.push({
                pathname: '/dokumente/bestellung-abschliessen',
                state: {
                  formId: applicationFormHistory.formId,
                  processData: applicationFormHistory.processData,
                  addInformation: true,
                  latestStatus: latestStatusMapping.status,
                  closeRoute: routeMatch.url,
                },
              })
            }
          >
            {finishOrder}
          </Button>
        );
      }
    }
    return null;
  };

  const generateDetails = () => {
    let index = -1;
    return (
      <>
        {orderOfDetails.map(key => {
          if (applicationFormHistory.processData['summary'][key]) {
            index++;
            return (
              <dl id={`application-form-details-${index}`} className="ApplicationFormDetails__dl" key={index}>
                <dt>
                  <em>{key === 'Ziel Adresse' ? 'Zieladresse' : key}</em>
                </dt>
                <dd>
                  <ul className="ApplicationFormDetails__ul">{renderSelectionSummary(key, index)}</ul>
                </dd>
              </dl>
            );
          }
        })}
        {applicationFormHistory.processData['finishOrder'] && (
          <dl id={`application-form-details-hb-finish`} className="ApplicationFormDetails__dl" key={'hb-finish'}>
            <dt>
              <em>{finishNote}</em>
            </dt>
            <dd>
              <ul className="ApplicationFormDetails__ul">{applicationFormHistory.processData['finishOrder']}</ul>
            </dd>
          </dl>
        )}
        {applicationFormHistory.processData['answerOrder'] && (
          <dl id={`application-form-details-hb-answer`} className="ApplicationFormDetails__dl" key={'hb-answer'}>
            <dt>
              <em>{agreedUpon}</em>
            </dt>
            <dd>
              <ul className="ApplicationFormDetails__ul">
                {applicationFormHistory.processData['answerOrder'][0] === 'yes' ? acceptedOrder : declinedOrder}
              </ul>
            </dd>
          </dl>
        )}
      </>
    );
  };

  const renderSelectionSummary = (key, index) => {
    if (key !== requester) {
      if (
        possibleContent &&
        possibleContent['summary'] &&
        possibleContent['summary'].summaryData?.find(data => data.title === key)?.type === 'checkbox'
      ) {
        const data = possibleContent['summary'].summaryData?.find(data => data.title === key);
        return (
          <Checkbox
            id={`summary-${index}`}
            checked={applicationFormHistory.processData[data.taskId][0] === 'true'}
            disabled={true}
          >
            {data.description}
          </Checkbox>
        );
      } else {
        return Array.isArray(applicationFormHistory.processData['summary'][key]) ? (
          applicationFormHistory.processData['summary'][key].map((selectedValue, index) => (
            <li key={index}>
              {FORMATTER.highlightRentalObjectType(selectedValue.name ? selectedValue.name : selectedValue)}
            </li>
          ))
        ) : (
          <li key={index}>{FORMATTER.highlightRentalObjectType(applicationFormHistory.processData['summary'][key])}</li>
        );
      }
    } else {
      return (
        <>
          <li className="ApplicationFormDetails">{applicationFormHistory.processData['summary'][requester]['Name']}</li>
          <li className="ApplicationFormDetails">
            {applicationFormHistory.processData['summary'][requester]['Adresse']}
          </li>
          <li className="ApplicationFormDetails">
            {applicationFormHistory.processData['summary'][requester]['Telefon']}
          </li>
        </>
      );
    }
  };

  /** Forms with the theme requestDocuments do not get a fancy ProgressList, but just the default history */
  const generateNonFlowHistory = () => {
    if (applicationFormHistory.statusMappings && applicationFormHistory.statusMappings.length) {
      return applicationFormHistory.statusMappings.map((statusMapping, index) => (
        <dl
          id={`application-form-details-history-${index}`}
          className="ApplicationFormDetails__dl ApplicationFormDetails__history"
          key={index}
        >
          <dt>
            <em>{STATUS_API_TO_ENUM(statusMapping.status).visual}</em>
          </dt>
          <dd>
            <ul className="ApplicationFormDetails__ul">
              <li>
                {statusMapping.status === STATUS.approved.apiStatus ||
                statusMapping.status === STATUS.declined.apiStatus
                  ? 'am'
                  : 'seit'}{' '}
                {DATE_FORMATTER.dateFormat(statusMapping.changeDate)}
              </li>
              {!isServiceCardRequest && statusMapping.document && !statusMapping.document.expired ? (
                <li>{getDownload(statusMapping.document, 'history')}</li>
              ) : null}
            </ul>
          </dd>
        </dl>
      ));
    }
  };

  const onNavigationBackHandler = () => {
    history.push({ pathname: backlink.current.route, state: { landingState: backlink.current.state } });
  };

  const isServiceCardRequest =
    applicationFormHistory && applicationFormHistory.workflow === RequestWorkflow.REQUEST_SERVICE_CARD;

  const requestTitle =
    applicationFormHistory && applicationFormHistory.processData && applicationFormHistory.processData['summary']
      ? applicationFormHistory.processData['summary']['Antrag']
      : applicationFormHistory && applicationFormHistory.title
      ? applicationFormHistory.title
      : title;

  const hasStatusMappings =
    applicationFormHistory && applicationFormHistory.statusMappings && applicationFormHistory.statusMappings.length;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta charSet="utf-8" />
        <meta name={'description'} content={meta} />
      </Helmet>
      {loading ? (
        <LoadingIndicator id={'application-form-details'} />
      ) : (
        <>
          <NavigationHeader
            backHandler={onNavigationBackHandler}
            backText={backlink.current?.text ?? 'Zurück'}
            title={title}
          />
          <div className={'ApplicationFormDetails__body'}>
            <Section id={'application-form-details-information'} title={requestTitle}>
              <span role="alert" aria-live="assertive" className="sr-only">
                <em>{requestTitle}</em> wurde geladen
              </span>
              {fetchErrors.some(error => error.orig === 'ApplicationFormApi') ? (
                <ErrorBox
                  id={'application-form-details'}
                  description={ERROR_APPLICATION_FORM_DETAILS.onLoad.description}
                />
              ) : invalidApplicationFormId.current ? (
                <ErrorPanel
                  id={'application-form-details-not-found'}
                  title={notFound.title}
                  errorText={notFound.errorText}
                  descriptionText={''}
                />
              ) : (
                renderCardsAndHistory()
              )}
            </Section>
          </div>
        </>
      )}
    </>
  );
};

export default ApplicationFormDetails;
