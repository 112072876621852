export enum RequestTheme {
  WINDOWS_AND_DOORS = 'windowsAndDoors',
  BATH_TUB_AND_SHOWER = 'bathTubAndShower',
  ELECTRICAL_INSTALLATION = 'electricalInstallation',
  TV_INSTALLATION = 'tvInstallation',
  MAIN_TENANT_CONFIRMATION = 'mainTenantConfirmation',
  MONTHLY_RENT_DETAILS = 'monthlyRentDetails',
  RENTAL_ACCOUNT_INFO = 'rentalAccountInfo',
  REQUEST_SERVICE_CARD = 'requestServiceCard',
  SYNCED_FORM = 'syncedForm',
}

export enum RequestWorkflow {
  CONSTRUCTION = 'construction',
  DOCUMENT = 'requestDocument',
  REQUEST_SERVICE_CARD = 'requestServiceCard',
  UNKNOWN = 'unknown',
}

export enum WizardMode {
  NO_EDIT_MODE,
  EDIT_MODE_ENTER,
  EDIT_MODE_LEFT,
  EDIT_DRAFT,
  DELETED_DRAFT,
  ADD_INFORMATION,
}
