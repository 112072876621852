import React from 'react';

import './RentalAccountAnnouncement.scss';
import { IAnnouncement } from '../../../commons/api/announcement/Announcement.interface';
import { RENTAL_ACCOUNT_LABELS } from '../../constants/containers/rental-account-labels';
import { Card, CardBody, CardHeader, Hyperlink, SVG } from 'digit.commons.ui-components-app';
import { RENTAL_ACCOUNT } from '../../constants/routes';
import { COVID_SUPPORT_ANNOUNCEMENT } from '../../constants/component-labels';

export interface IRentalAccountAnnouncementProps {
  id: string;
  announcement: IAnnouncement;
  onClose?: () => any;
}

export const CovidSupportLandingAnnouncement: React.FC<IRentalAccountAnnouncementProps> = props => {
  const close = () => {
    props.announcement.closeFunction();
    props.onClose();
  };

  const { badge, title, description } = COVID_SUPPORT_ANNOUNCEMENT;
  const { showRentalAccountButton } = RENTAL_ACCOUNT_LABELS;

  return (
    <Card
      id={`${props.id}-rental-account-teaser`}
      badge={badge}
      badgeType={'green'}
      className="RentalAccountAnnouncement"
      onDismiss={props.onClose && close}
    >
      <CardHeader title={title} icon={SVG.support_hand} />
      <CardBody>
        {description}
        <div className="RentalAccountAnnouncement__hyperlink-container">
          <Hyperlink
            className={'RentalAccountAnnouncement__hyperlink'}
            id={`${props.id}-show-rental-account`}
            to={`${RENTAL_ACCOUNT}`}
            iconAfter={SVG.arrow_right}
          >
            {showRentalAccountButton}
          </Hyperlink>
        </div>
      </CardBody>
    </Card>
  );
};
