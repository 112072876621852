import React from 'react';
import { Card, CardBody, CardHeader, Data, Hyperlink, Key, SVG, Value } from 'digit.commons.ui-components-app';
import { RENTAL_ACCOUNT_LABELS } from '../../constants/containers/rental-account-labels';
import { IAnnouncementProps, IRentalAnnouncementData } from '../../../commons/api/announcement/Announcement.interface';
import { RENTAL_ACCOUNT } from '../../constants/routes';
import './RentalAccountAnnouncement.scss';

export function RentalAccountAnnouncement(props: IAnnouncementProps) {
  const close = () => {
    props.announcement.closeFunction();
    props.onClose();
  };

  const overdue = (props.announcement.data as IRentalAnnouncementData).RentalObjects;
  const { title, paymentsMissing, showRentalAccountButton, badge } = RENTAL_ACCOUNT_LABELS;

  return (
    <Card
      id={`${props.id}-rental-account-teaser`}
      badge={badge}
      badgeType={'red'}
      className="RentalAccountAnnouncement"
      onDismiss={props.onClose && close}
    >
      <CardHeader title={title} icon={SVG.rental_account} />
      <CardBody>
        {overdue.map((ob, idx) => (
          <div key={idx} className="RentalAccountAnnouncement__paymentsMissing">
            <h3>{paymentsMissing}:</h3>
            <Data id={`${props.id}-rental-account-teaser-rental-objects-${idx}`}>
              <Key>{ob.type}</Key>
              <Value>{ob.address}</Value>
            </Data>
            <div className="RentalAccountAnnouncement__hyperlink-container">
              <Hyperlink
                className={'RentalAccountAnnouncement__hyperlink'}
                id={`${props.id}-show-rental-account-${idx}`}
                to={`${RENTAL_ACCOUNT}/${ob.vkont}`}
                iconAfter={SVG.arrow_right}
              >
                {showRentalAccountButton}
              </Hyperlink>
            </div>
          </div>
        ))}
      </CardBody>
    </Card>
  );
}
