import React from 'react';
import { STATUS, STATUS_API_TO_ENUM } from '../../constants/status';
import {
  Data,
  Document,
  Download,
  Hyperlink,
  Key,
  ProgressList,
  ProgressListItem,
  SVG,
  Value,
} from 'digit.commons.ui-components-app';
import {
  IPossibleContent,
  IProgressListItem,
  IProgressListItemBody,
  IProgressListItemStatus,
} from '../../decisionTree/interfaces/PossibleContent.interface';
import { IStatusMapping } from '../../../commons/api/applicationForm/ApplicationForm.interface';
import documentsRepository from '../../decisionTree/data/Documents.json';
import ApplicationFormSCSInitializer from '../../ApplicationFormSCSInitializer';
import './ApplicationFormHistory.scss';
import { DATE_FORMATTER } from '../../../commons/utility/DateFormatter';
import { IProcessData } from '../../decisionTree/interfaces/DecisionTree.interface';
import { useRouteMatch } from 'react-router-dom';
import { TemplateComponents } from '../../decisionTree/templates/TemplateComponents';
import { ApplicationFormUtility } from '../../utility/ApplicationFormUtility';

interface IApplicationFormHistoryProps {
  id: string;
  possibleContent: IPossibleContent;
  formId?: string;
  processData?: IProcessData;
  statusMappings?: IStatusMapping[];
  autoCheckFirstItem?: boolean;
  headingLevel?: number;
}

const ApplicationFormHistory: React.FC<IApplicationFormHistoryProps> = props => {
  const { possibleContent, statusMappings, autoCheckFirstItem, processData } = props;
  const apiConfig = ApplicationFormSCSInitializer.apiConfig();

  let statusMappingsCopy = statusMappings?.slice(0);
  statusMappingsCopy?.reverse();

  const routeMatch = useRouteMatch();

  /**
   * returns the title of a single ProgressListItem
   *
   * @param listItem the current list item
   * @param defaultTitle a title that should be rendered if there are no itemStatus entries for a ProgressListItem
   */
  const getProgressListItemTitle = (listItem: IProgressListItem, defaultTitle: string) => {
    /* if there is no status to be found the defaultTitle will be returned */
    let evaluatedTitle = defaultTitle;
    if (listItem.itemStatus) {
      /* we iterate over each itemStatus (if there is one) */
      listItem.itemStatus.forEach((modifierItem: IProgressListItemStatus) => {
        const statusMappingWithStatus = getAnyStatusMappingWithStatus(modifierItem.status);
        /* if the status is present in the statusMappings of the ApplicationForm and there is a title for it, we'll set it */
        if (statusMappingWithStatus && modifierItem.title) {
          evaluatedTitle = modifierItem.title;
        }
      });
    }
    return evaluatedTitle;
  };

  /**
   * returns the status that should be rendered for the given IProgressListItem (done, denied or empty string)
   *
   * @param listItem of which we want to determine the status
   */
  const getProgressListItemStatus = (listItem: IProgressListItem) => {
    let progressListItemStatus = '';
    if (listItem.itemStatus) {
      /* we iterate over the itemStatus of a listItem to find out for which status the status of a listItem is done or denied */
      listItem.itemStatus.forEach((modifierItem: IProgressListItemStatus) => {
        const statusMappingWithStatus = getAnyStatusMappingWithStatus(modifierItem.status);
        // statusMappingWithStatus, modifierItem.status);
        /* denied always has priority, so if it would be done for APPROVED, but then we find a DECLINED statusMapping we'll use declined
         * just to be sure, usually it shouldn't be possible to decline an approved ApplicationForm
         */
        if (statusMappingWithStatus && progressListItemStatus !== 'denied') {
          progressListItemStatus = modifierItem.modifier;
        }
      });
    }
    return progressListItemStatus;
  };

  /**
   *  returns the content of the ProgressListItem
   *  - one (or more) status if status is preset and the corresponding statusMapping is present
   *  - the body, if a body was preset
   *  - null otherwise
   *
   * @param listItem of which the content should be determined and converted to JSX
   * @param bodyItemIndex the index for setting the key of elements
   */
  const generateProgressListItemContent = (listItem: IProgressListItem, bodyItemIndex: number) => {
    /* at first we check if there is a status to be rendered for a certain listItem */
    if (listItem.status) {
      /* convert the statusMappings to status JSX rendering */
      let statusBody = listItem.status.map((status: string) => {
        const statusMappingWithStatus = getExactStatusMappingWithStatus(status);
        return statusMappingWithStatus
          ? generateProgressListItemStatus(statusMappingWithStatus, bodyItemIndex, listItem.body)
          : null;
      });
      /* filter out null/undefined values from the statusBody array, if a certain status is not present */
      statusBody = statusBody.filter(statusItem => statusItem);
      /* if the status is present we'll render it, otherwise we'll render the body (if one is present) or null */
      return statusBody && statusBody.length
        ? statusBody
        : listItem.body
        ? generateProgressListItemBody(null, listItem.body, bodyItemIndex)
        : null;
    } else if (listItem.body) {
      /* if there is no status present for a listItem we go straight to rendering the body */
      return generateProgressListItemBody(null, listItem.body, bodyItemIndex);
    }
    return null;
  };

  const generateProgressListItemStatus = (
    statusMappingWithStatus: IStatusMapping,
    index: number,
    body: IProgressListItemBody[]
  ) => {
    return (
      <>
        <Data
          id={`${props.id}-${index}-${statusMappingWithStatus.status}-application-form-history`}
          className="ApplicationFormHistory__status"
          key={statusMappingWithStatus.status}
        >
          <Key>{STATUS_API_TO_ENUM(statusMappingWithStatus.status).visual}</Key>
          <Value>
            <p>
              {statusMappingWithStatus.status === STATUS.approved.apiStatus ||
              statusMappingWithStatus.status === STATUS.declined.apiStatus
                ? 'am'
                : 'seit'}{' '}
              {DATE_FORMATTER.dateFormat(statusMappingWithStatus.changeDate)}
            </p>
            {statusMappingWithStatus.document && (
              <Download
                id={`${props.id}-download-${statusMappingWithStatus.document.name}-${index}`}
                url={`${apiConfig.applicationFormApi}${apiConfig.paths.pathToDocument}/${statusMappingWithStatus.document.applicationFormId}/${statusMappingWithStatus.document.id}`}
                icon={SVG.pdf}
              >
                {ApplicationFormUtility.getDocumentDisplayName(statusMappingWithStatus.document.name)}
              </Download>
            )}
          </Value>
        </Data>
        {generateProgressListItemBody(statusMappingWithStatus, body, index, true)}
      </>
    );
  };

  const generateProgressListItemBody = (
    statusMappingWithStatus: IStatusMapping,
    body: IProgressListItemBody[],
    bodyItemIndex: number,
    onlyMandatory: boolean = false
  ) => {
    return body?.map((bodyItem, index) => {
      if (bodyItem.paragraph && (!onlyMandatory || bodyItem.mandatory)) {
        return (
          <p key={`progress-list-item-${bodyItemIndex}-${index}`}>
            {bodyItem.paragraph.map(paragraph =>
              paragraph.emphasized ? <em key={index}>{paragraph.text}</em> : paragraph.text
            )}
          </p>
        );
      } else if (bodyItem.documents && (!onlyMandatory || bodyItem.mandatory)) {
        return (
          <div key={`progress-list-item-${bodyItemIndex}-${index}`}>
            {bodyItem.documents.map((item, idx) => {
              return (
                <Document
                  id={`document-${documentsRepository[item].title.substring(0, 3)}-${idx}`}
                  className="ApplicationFormHistory__document"
                  key={`document-${idx}`}
                  title={documentsRepository[item].title}
                  description={documentsRepository[item].description}
                  linkText={documentsRepository[item].linkText}
                  linkDestination={documentsRepository[item].linkUrl}
                  linkIsExternal={true}
                />
              );
            })}
          </div>
        );
      } else if (bodyItem.hyperlink && (!onlyMandatory || bodyItem.mandatory)) {
        let to;
        if (bodyItem.hyperlink.passProcessData) {
          let linkState: any = {
            formId: props.formId,
            processData: processData,
            addInformation: true,
            closeRoute: routeMatch.url,
          };
          if (statusMappingWithStatus) {
            linkState = {
              ...linkState,
              download: {
                description: statusMappingWithStatus.document.name,
                downloadLink: `${apiConfig.applicationFormApi}${apiConfig.paths.pathToDocument}/${statusMappingWithStatus.document.applicationFormId}/${statusMappingWithStatus.document.id}`,
                icon: 'pdf',
              },
              addInformationToStatusMapping: statusMappingWithStatus.id,
            };
          }
          to = { pathname: bodyItem.hyperlink.to, state: linkState };
        } else {
          to = bodyItem.hyperlink.to;
        }
        return (
          <Hyperlink
            id={`progress-list-item-${bodyItemIndex}-${index}`}
            to={to}
            isExternal={bodyItem.hyperlink.isExternal}
            isInline={bodyItem.hyperlink.isInline}
            iconAfter={bodyItem.hyperlink.iconAfter}
          >
            {bodyItem.hyperlink.text}
          </Hyperlink>
        );
      } else if (bodyItem.collapsibles && (!onlyMandatory || bodyItem.mandatory)) {
        return TemplateComponents.renderCollapsible(bodyItem.collapsibles, 'history', null);
      }
      return null;
    });
  };

  /**
   * returns the first item of a certain status, null if there is no such status present in the ApplicationForm.statusMappings
   *
   * @param status the string which represents the status of the API (capslock, i.e., INPROGRESS)
   */
  const getAnyStatusMappingWithStatus = (status: string) =>
    statusMappings && statusMappings.find((statusMapping: IStatusMapping) => statusMapping.status === status);

  /**
   * !!! MUST ONLY BE CALLED ONCE AS IT REMOVES STATUS MAPPINGS FROM THE ARRAY!!!
   * returns the exact status mapping for a given status, by removing the status mapping afterwards and hence making sure each status mapping is only rendered once.
   *
   * @param status the string which represents the status of the API (capslock, i.e., TENANT_INFO_PENDING)
   */
  const getExactStatusMappingWithStatus = (status: string) => {
    if (statusMappingsCopy) {
      const earliestStatusMappingWithStatus = statusMappingsCopy.find(sm => sm.status === status);
      statusMappingsCopy = statusMappingsCopy.filter(sm => sm !== earliestStatusMappingWithStatus);
      return earliestStatusMappingWithStatus;
    }
    return null;
  };

  const statusMappingDeclined = getAnyStatusMappingWithStatus(STATUS.declined.apiStatus);
  let listItemWasDenied = false;

  return (
    <ProgressList
      denied={
        statusMappingDeclined != null ||
        statusMappings?.findIndex(
          status =>
            status.status === STATUS.wrw_info_pending.apiStatus ||
            status.status === STATUS.tenant_info_pending.apiStatus
        ) === 0
      }
    >
      {possibleContent && possibleContent.progresslist
        ? possibleContent.progresslist.map((listItem, index) => {
            const progressListItemStatus = getProgressListItemStatus(listItem);
            /*
             * the listItem should only be rendered if nothing was denied beforehand.
             * thus, we only set listItemWasDenied if it was false (otherwise a status other than denied would overwrite the value)
             */
            listItemWasDenied = !listItemWasDenied ? progressListItemStatus === 'denied' : listItemWasDenied;
            /*
             * since we still want to render the item(s) that were denied themselves, we need this additional check
             * everything that is not denied afterwards will NOT be rendered
             */
            return listItemWasDenied && progressListItemStatus !== 'denied' ? null : (
              <ProgressListItem
                key={`progress-list-item-${index}`}
                title={getProgressListItemTitle(listItem, listItem.title)}
                permission={listItem.permission}
                status={progressListItemStatus || (index === 0 && autoCheckFirstItem ? 'done' : '')}
                headingLevel={props.headingLevel ? props.headingLevel : 4}
              >
                {generateProgressListItemContent(listItem, index)}
              </ProgressListItem>
            );
          })
        : null}
    </ProgressList>
  );
};

export default ApplicationFormHistory;
