import React, { forwardRef, useImperativeHandle } from 'react';
import { ITemplate, ITemplateHandles } from '../interfaces/Template.interface';
import { Button, Collapsible3, Icon, SVG } from 'digit.commons.ui-components-app';
import './Template.scss';
import ApplicationFormHistory from '../../components/ApplicationFormHistory/ApplicationFormHistory';

const ConfirmationTemplate: React.ForwardRefRenderFunction<ITemplateHandles, ITemplate> = (props, ref) => {
  const { step, possibleContent } = props;

  useImperativeHandle(ref, () => ({
    validateTemplate: () => true,
  }));

  return (
    <>
      <Icon className="Template__icon--information" icon={SVG.letter} />
      {step.title && !(possibleContent[step.id] && possibleContent[step.id].progresslist) && (
        <>
          <h3 className={'Template__heading--confirmation'}>{step.title}</h3>
          {step.props.info && <p className={'Template__paragraph'}>{step.props.info}</p>}
        </>
      )}
      {step.props.collapsibles &&
        step.props.collapsibles.map((collapsible, index) => (
          <div className="Template__collapsible-container" key={index}>
            <Collapsible3 id={collapsible.id} className="Template__collapsible" key={index} title={collapsible.title}>
              {collapsible.collapsibleContent &&
                collapsible.collapsibleContent.map(({ text }, index) => (
                  <p key={index} className="Template__paragraph">
                    {text}
                  </p>
                ))}
            </Collapsible3>
          </div>
        ))}
      <Button id={'wizard-confirmation-close'} onClick={props.onDismissHandler}>
        Zurück zur Übersicht
      </Button>
      {possibleContent[step.id] && possibleContent[step.id].progresslist && (
        <ApplicationFormHistory
          id={`wizard-${step.id}`}
          possibleContent={possibleContent[step.id]}
          autoCheckFirstItem={true}
          headingLevel={3}
        />
      )}
    </>
  );
};

export default forwardRef(ConfirmationTemplate);
