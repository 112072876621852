export const INTERNAL_TO_EXTERNAL_REPRESENTATION_MAPPER = {
  conversions: [
    {
      id: 'windowsAndDoors',
      name: 'Fenster und Türen',
      target: 'dokumente/fenster-und-türen',
      flowStructure: 'fenster-und-türen',
      /* the name where the decision can be found, must also be the name of the mapping object in the next line */
      taskId: 'changeDetails',
      /* kind of a glossary */
      changeDetails: [
        {
          value: 'blinds',
          text: 'Außenjalousien montieren',
        },
        {
          value: 'shutter',
          text: 'Außenrollläden montieren',
        },
        {
          value: 'exchangeWindowAndDoors',
          text: 'Fenster oder Türen tauschen',
        },
        {
          value: 'moveDoor',
          text: 'Tür versetzen',
        },
        {
          value: 'shutDoor',
          text: 'Tür zumauern',
        },
        {
          value: 'marquee',
          text: 'Markise oder Sichtschutz montieren',
        },
        {
          value: 'catNet',
          text: 'Katzengitter montieren',
        },
        {
          value: 'flyScreen',
          text: 'Fliegengitter montieren',
        },
        {
          value: 'pigeonNet',
          text: 'Taubennetz montieren',
        },
        {
          value: 'flowerBox',
          text: 'Blumenkasten montieren',
        },
        {
          value: 'fireBound',
          text: 'Sicherheitstüre einbauen',
        },
        {
          value: 'notFireBound',
          text: 'Sicherheitstüre einbauen',
        },
      ],
      fallbackTaskId: 'changes',
      changes: [
        {
          value: 'safetyDoor',
          text: 'Sicherheitstüre einbauen',
        },
      ],
    },
    {
      id: 'bathTubAndShower',
      name: 'Sanitär',
      target: 'dokumente/sanitär-umbauen',
      flowStructure: 'sanitär-umbauen',
      /* the name where the decision can be found, must also be the name of the mapping object in the next line */
      taskId: 'changeDetails',
      changeDetails: [
        {
          value: 'replaceBathtubWithShower',
          text: 'Dusche statt Wanne einbauen',
        },
        {
          value: 'replaceShowerWithBathtub',
          text: 'Wanne statt Dusche einbauen',
        },
      ],
    },
    {
      id: 'electricalInstallation',
      name: 'Elektro-Installation',
      target: 'dokumente/elektro-installation',
      flowStructure: 'elektro-installation',
      taskId: 'changes',
      changes: [
        {
          text: 'Wechselstrom-Leitung installieren',
          value: 'installAC',
        },
        {
          text: 'Starkstrom-Leitung installieren',
          value: 'installHeavyCurrent',
        },
        {
          text: 'Alarmanlage installieren',
          value: 'installAlarm',
        },
      ],
    },
    {
      id: 'tvInstallation',
      name: 'TV-Installation und Medienanschluss',
      target: 'dokumente/sat-antenne-und-medienanschluss',
      flowStructure: 'sat-antenne-und-medienanschluss',
      taskId: 'changes',
      changes: [
        {
          text: 'Internet-Anschluss installieren',
          value: 'installInternet',
        },
        {
          text: 'TV-Anschluss installieren',
          value: 'installTV',
        },
        {
          text: 'SAT-Antenne montieren',
          value: 'installSAT',
        },
      ],
    },
    {
      id: 'mainTenantConfirmation',
      name: 'Hauptmieterbestätigung',
      target: 'dokumente/hauptmieter',
      flowStructure: 'hauptmieter',
      /* the name where the decision can be found, must also be the name of the mapping object in the next line */
      taskId: null,
    },
    {
      id: 'monthlyRentDetails',
      name: 'Zinszettel',
      target: 'dokumente/zinszettel',
      flowStructure: 'zinszettel',
      /* the name where the decision can be found, must also be the name of the mapping object in the next line */
      taskId: null,
    },
    {
      id: 'rentalAccountInfo',
      name: 'Mietzinskonto-Information',
      target: 'dokumente/mietzins',
      flowStructure: 'mietzins',
      /* the name where the decision can be found, must also be the name of the mapping object in the next line */
      taskId: null,
    },
    {
      id: 'requestServiceCard',
      name: 'Service-Karte nachbestellen',
      target: 'dokumente/service-karte-nachbestellen',
      flowStructure: 'service-karte-nachbestellen',
      taskId: 'changes',
      changes: [
        {
          text: 'Gestohlene Service-Karte nachbestellen',
          value: 'serviceCardTheft',
        },
        {
          text: 'Verlorene Service-Karte nachbestellen',
          value: 'serviceCardLoss',
        },
        {
          text: 'Zusätzliche Service-Karte nachbestellen',
          value: 'additionalServiceCard',
        },
      ],
    },
    {
      id: 'unlockPayAuthorityServiceCard',
      name: 'Service-Karte Zahlungsfunktion freischalten',
      target: 'dokumente/service-karte-zahlungsfunktion',
      flowStructure: 'service-karte-zahlungsfunktion',
      taskId: null,
    },
    {
      id: 'heatingAndCooling',
      name: 'Heizung und Kühlung',
      target: 'dokumente/heizung-und-kühlung',
      flowStructure: 'heizung-und-kühlung',
      /* the name where the decision can be found, must also be the name of the mapping object in the next line */
      taskId: 'changeDetails',
    },
  ],
};
